import CircularProgress from '@mui/material/CircularProgress'
import Switch from '@mui/material/Switch'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentStatus } from '@traba/types'
import { ChangeEvent, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Button, Col, Icon, Input, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import EarningsSummaryItem from 'src/components/EarningsSummaryItem/EarningsSummaryItem'
import {
  EarningsSummaryResponse,
  useEarningsSummaries,
} from 'src/hooks/useEarningsSummary'
import { WorkerShiftAdjustmentFromEarningSummaryWrapper } from 'src/modals/AdjustmentModal/WorkerShiftAdjustmentFromEarningSummaryWrapper'
import Toggle from '../../../components/base/Toggle'

interface WorkerEarningsTabProps {
  workerId: string
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
}

export const WorkerEarningsTab = ({
  workerId,
  dateRange,
  setDateRange,
}: WorkerEarningsTabProps) => {
  const [paymentStatuses, setPaymentStatuses] = useState<IMenuItem[]>([])
  const [shiftId, setShiftId] = useState<string>('')
  const [shouldInstantPay, setShouldInstantPay] = useState<boolean>(true)
  const [hideZeroAmountItems, setHideZeroAmountRows] = useState<boolean>(false)

  const {
    earningsSummaries = [],
    refetch,
    isLoading: earningsLoading,
    isFetched: earningsFetched,
  } = useEarningsSummaries({
    workerId,
    after: dateRange[0]?.toISOString() ?? '',
    before: dateRange[1]?.toISOString() ?? '',
    shiftId,
    paymentStatuses: paymentStatuses.map((ps) => ps.value) as PaymentStatus[],
    order: 'desc',
  })

  const [
    selectedEarningsForWorkerShiftAdjustment,
    setSelectedEarningsForWorkerShiftAdjustment,
  ] = useState<EarningsSummaryResponse | undefined>(undefined)

  const paymentStatusesMenuOptions: IMenuItem[] = (
    Object.values(PaymentStatus) as string[]
  ).map((ps) => {
    return { label: ps, value: ps }
  })

  const earningsSummariesFiltered = useMemo(() => {
    return hideZeroAmountItems
      ? earningsSummaries.filter((es) => es.totalOwed > 0 || es.totalPaid > 0)
      : earningsSummaries
  }, [earningsSummaries, hideZeroAmountItems])

  return (
    <>
      <Row mb={theme.space.med} alignCenter>
        <Text variant="h4">Past Earnings ({earningsSummaries.length})</Text>
        <Row mt={theme.space.sm} alignCenter fullWidth>
          <DateRangePicker
            label="Date range"
            inlineLabel={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
            granularity="day"
            style={{ marginRight: theme.space.xs }}
          />
          <Col mr={theme.space.xs} width={'200px'}>
            <Input
              full
              label="Shift ID"
              value={shiftId}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setShiftId(ev.target.value)
              }
            />
          </Col>
          <Col mr={theme.space.xs} width={'200px'}>
            <SearchSelect
              multiple
              options={paymentStatusesMenuOptions}
              selectedItems={paymentStatuses}
              handleSelectMultiple={setPaymentStatuses}
              label="Payment Status"
              onlyShowLabel
            />
          </Col>
          <Row style={{ alignItems: 'center' }} mr={theme.space.xs}>
            <Text variant="caption">Standard</Text>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={shouldInstantPay}
              onClick={(event) => {
                event.stopPropagation()
                setShouldInstantPay(!shouldInstantPay)
              }}
            />
            <Text variant="caption">Instant</Text>
          </Row>
          <Toggle
            label="Hide zero pay"
            buttonState={hideZeroAmountItems}
            runOnChange={() => setHideZeroAmountRows(!hideZeroAmountItems)}
          />
          <Col>
            <Button
              full
              leftIcon={<Icon name="search" />}
              onClick={() => refetch()}
              variant={ButtonVariant.FILLED}
              loading={!earningsFetched}
              ml={theme.space.xxs}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Row>
      {earningsLoading ? (
        <Row alignCenter justifyCenter fullWidth style={{ height: '500px' }}>
          <CircularProgress
            size={theme.space.xxl}
            sx={{
              left: '50%',
            }}
          />
        </Row>
      ) : earningsSummaries && earningsSummaries.length > 0 ? (
        <Virtuoso
          data={earningsSummariesFiltered}
          style={{ height: '700px', marginTop: theme.space.xs }}
          totalCount={earningsSummariesFiltered.length}
          itemContent={(_, es) => (
            <Row mb={theme.space.xxs}>
              <EarningsSummaryItem
                earningsSummary={es}
                key={`${es.workerShiftId}_${es.totalOwed}_${es.totalPaid}`}
                shouldInstantPay={shouldInstantPay}
                setSelectedEarningsForWorkerShiftAdjustment={
                  setSelectedEarningsForWorkerShiftAdjustment
                }
                selectable={false}
              />
            </Row>
          )}
        />
      ) : (
        <Text variant="h5">No previous shifts worked</Text>
      )}
      {selectedEarningsForWorkerShiftAdjustment && (
        <WorkerShiftAdjustmentFromEarningSummaryWrapper
          handleClose={() =>
            setSelectedEarningsForWorkerShiftAdjustment(undefined)
          }
          isOpen={true}
          workerId={selectedEarningsForWorkerShiftAdjustment.workerId}
          shiftId={selectedEarningsForWorkerShiftAdjustment.shiftId}
        />
      )}
    </>
  )
}
