import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { ShiftRequest } from '@traba/types'

export const updateShiftRequest = async (
  shiftRequestId: string,
  updates: Partial<ShiftRequest>,
) => {
  try {
    const response = await trabaApi.patch(
      `shift-requests/${shiftRequestId}`,
      updates,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}
