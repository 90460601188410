import {
  FAVORITE_WORKERS,
  HAS_WORKED_AS_ROLE,
  HAS_WORKED_AT_LOCATION,
  HAS_WORKED_WITH_COMPANY,
} from '@traba/consts'
import { useAlert } from '@traba/context'
import { Roster, Worker } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { useCompanyConnections } from './useConnections'

async function getVirtualRosters(
  companyId: string,
  withLocationId?: string,
  withRoleId?: string,
): Promise<Record<string, Worker[]> | undefined> {
  try {
    const searchParams = new URLSearchParams()
    searchParams.append('withLocationId', withLocationId?.toString() ?? '')
    searchParams.append('withRoleId', withRoleId?.toString() ?? '')
    const res = await trabaApi.get(
      `companies/${companyId}/virtual-rosters?${searchParams}`,
    )
    return res.data
  } catch (error) {
    console.error('getVirtualRosters() ERROR', error)
  }
}

export function useVirtualRosters(
  companyId: string,
  locationId?: string,
  roleId?: string,
) {
  const { favoriteWorkers } = useCompanyConnections(companyId)
  const { handleError } = useAlert()

  const {
    isLoading,
    isError,
    data: workersWorkedWithCompany,
    error,
    isFetched,
    refetch,
  } = useQuery<Record<string, Worker[]> | undefined, Error>(
    [
      'virtual_rosters',
      {
        companyId,
        locationId,
        roleId,
      },
    ],
    () => getVirtualRosters(companyId, locationId, roleId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      refetchOnWindowFocus: 'always',
    },
  )

  if (isError) {
    handleError(
      error,
      'useVirtualRosters',
      'Error Fetching Workers who worked with the company',
      'Error fetching virtual rosters',
    )
  }

  const virtualRosters: Roster[] = [
    {
      id: FAVORITE_WORKERS,
      rosterName: 'Business Favorites',
      companyId,
      workers: favoriteWorkers,
    },
    {
      id: HAS_WORKED_WITH_COMPANY,
      rosterName: 'Has worked with this company',
      companyId,
      workers: workersWorkedWithCompany?.hasWorkedWithCompany ?? [],
    },
    {
      id: HAS_WORKED_AT_LOCATION,
      rosterName: 'Has worked at this location',
      companyId,
      workers: workersWorkedWithCompany?.hasWorkedAtLocation ?? [],
    },
    {
      id: HAS_WORKED_AS_ROLE,
      rosterName: 'Has worked this role',
      companyId,
      workers: workersWorkedWithCompany?.hasWorkedAsRole ?? [],
    },
  ]

  return {
    isLoading,
    isError,
    virtualRosters,
    error,
    isFetched,
    refetch,
  }
}
