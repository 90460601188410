import { FormControlLabel, Radio } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { addDays } from 'date-fns'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  Button,
  Col,
  Icon,
  IconButton,
  Input,
  Row,
} from '../../components/base'
import { CheckboxThemed } from '../../components/base/CheckboxThemed/CheckboxThemed'
import Divider from '../../components/base/Divider'
import { SearchSelect } from '../../components/base/SearchSelect/SearchSelect'
import { IMenuItem } from '../../components/base/Select/Select'
import { InlineBanner } from '../../components/InlineBanner/InlineBanner'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import { useRegions } from '../../hooks/useRegions'
import {
  CreateWorkerVettingCampaignData,
  useWorkerVetting,
} from '../../hooks/useWorkerVetting'
import {
  SupportedLanguages,
  WorkerVettingCampaign,
  WorkerVettingMethod,
} from '../../types/worker-vetting'
import { sanitizePhoneNumber } from '../../utils/stringUtils'
import * as S from './styles'

export const INITIAL_VETTING_CAMPAIGN: CreateWorkerVettingCampaignData = {
  title: '',
  initialMessage: '',
  promptDetails: '',
  phoneNumbers: '', // Parse into array at creation
  shiftId: '',
  questions: [],
  regionIds: [],
  deadline: addDays(new Date(), 7),
  type: WorkerVettingMethod.sms,
  language: SupportedLanguages.english,
}

const BASE_QUESTIONS = [
  'Can you pass a 5 panel drug test?',
  'Can you pass a 10 panel drug test?',
  'Are you looking for a full-time consistent job?',
  'Do you have a car and can reliably make it to the job location?',
  'Do you know how to drive a forklift?',
  'Do you have experience working on a production line?',
  'Do you have experience working in a manufacturing facility?',
  'Do you have experience pick and packing?',
  'Can you make it to the orientation shift?',
  'Are you comfortable working in a cold room (~35 degrees)?',
  'Can you comply with GMPs (NO fake lashes/nails, piercings/jewelry, clothing with holes/frays, gum/candy in work area)?',
]

const MINIMUM_QUESTIONS_COUNT = 3
const MAXIMUM_QUESTIONS_COUNT = 7

const QUALIFIED_EXIT_MESSAGE = `Thanks for chatting and completing the automated conversation! It seems like you could be a great fit! Our operations team will be reaching out with a phone call or text within 24 hours to go over the role in more detail and get you signed up for the shift if a good fit. They can answer any additional questions you have on the shift.`
const UNQUALIFIED_EXIT_MESSAGE = `Thanks for chatting! You’ve completed all the necessary questions and the automated chat has been ended. Our team will review your responses and reach out if you are a good fit for the role. In the meantime, check out the Traba app for open shifts and sign up for any you are interested in.`

function validateCreateVettingCampaign(
  data: CreateWorkerVettingCampaignData,
  regionIds: IMenuItem[],
  questions: string[],
  newStep: number,
) {
  if (newStep === 2) {
    if (!data.title) {
      return 'Please enter a title'
    }
    if (!data.phoneNumbers) {
      return 'Please enter phone numbers'
    }
    if (!data.promptDetails) {
      return 'Please enter shift and role details'
    }
    if (!data.initialMessage) {
      return 'Please enter an initial message'
    }
    if (!regionIds.length) {
      return 'Please select region IDs'
    }
  }
  if (newStep === 3) {
    if (!questions.length) {
      return 'Please select questions'
    }
    if (questions.length < MINIMUM_QUESTIONS_COUNT) {
      return `Please select at least ${MINIMUM_QUESTIONS_COUNT} questions`
    }
    if (questions.length > MAXIMUM_QUESTIONS_COUNT) {
      return `Please select a maximum of ${MAXIMUM_QUESTIONS_COUNT} questions`
    }
  }
}

function CreateWorkerVettingCampaign() {
  const [campaignToCreate, setCampaignToCreate] =
    useState<CreateWorkerVettingCampaignData>(INITIAL_VETTING_CAMPAIGN)
  const [selectedRegionIds, setSelectedRegionIds] = useState<IMenuItem[]>([])
  const [customQuestions, setCustomQuestions] = useState<string[]>([])
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])
  const navigate = useNavigate()
  const [showCustomQuestionInput, setShowCustomQuestionInput] =
    useState<boolean>(false)
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState<string[]>([])
  const [validPhoneNumbers, setValidPhoneNumbers] = useState<string[]>([])
  const [customQuestionValue, setCustomQuestionValue] = useState<string>('')

  const [step, setStep] = useState<number>(1)

  const displayQuestions = [...customQuestions, ...BASE_QUESTIONS]

  const { showWarning } = useAlert()
  const { regions = [] } = useRegions()
  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })

  const { createNewWorkerVettingCampaign, isLoadingVettingData } =
    useWorkerVetting()

  const onCreate = async () => {
    const campaignCreated = await createNewWorkerVettingCampaign({
      ...campaignToCreate,
      phoneNumbers: validPhoneNumbers.join(','),
      questions: selectedQuestions,
    })
    if (campaignCreated) {
      navigate('/worker-vetting/campaigns')
    }
  }

  const handleInputChange = (
    key: keyof WorkerVettingCampaign,
    value: string | string[],
  ) => {
    setCampaignToCreate((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignToCreate((prev) => ({
      ...prev,
      language: event.target.value as SupportedLanguages,
    }))
  }

  const handleQuestionsSelect = (question: string) => {
    setSelectedQuestions((prev) => {
      if (prev.find((q) => q === question)) {
        return prev.filter((q) => q !== question)
      } else {
        return [...prev, question]
      }
    })
  }

  const handleStepChange = (newStep: number) => {
    const warning = validateCreateVettingCampaign(
      campaignToCreate,
      selectedRegionIds,
      selectedQuestions,
      newStep,
    )
    if (invalidPhoneNumbers.length) {
      return showWarning(
        'Please fix the invalid phone numbers before continuing',
        'Invalid phone numbers',
      )
    }
    if (warning) {
      return showWarning(warning, 'Incomplete Fields')
    }
    setStep(newStep)
  }

  const handlePhoneNumbersInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = e.target.value
    const phoneNumbersArray = value.split(',')
    const validSanitizedPhoneNumbers: string[] = []
    const newInvalidPhoneNumbers: string[] = []

    phoneNumbersArray.forEach((phoneNumber) => {
      const sanitizedNumber = sanitizePhoneNumber(phoneNumber.trim())
      if (sanitizedNumber) {
        validSanitizedPhoneNumbers.push(sanitizedNumber)
      } else if (phoneNumber.trim() !== '') {
        newInvalidPhoneNumbers.push(phoneNumber)
      }
    })

    setCampaignToCreate((prev) => ({
      ...prev,
      phoneNumbers: value,
    }))
    setValidPhoneNumbers(validSanitizedPhoneNumbers)

    setInvalidPhoneNumbers(
      newInvalidPhoneNumbers.length ? newInvalidPhoneNumbers : [],
    )
  }

  return (
    <MainLayout title="Create Worker Vetting">
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Go back
      </Button>
      <Row justifyBetween mb={theme.space.xxs}>
        <Text variant="h4" mb={theme.space.med}>
          Create a new worker vetting campaign
        </Text>
      </Row>
      <Row justifyStart fullWidth alignCenter>
        <Row alignCenter mb={theme.space.med}>
          <Button
            variant={ButtonVariant.TRANSPARENT}
            onClick={() => handleStepChange(1)}
            style={{ padding: 0 }}
          >
            <S.RoundMarker active={step >= 1}>1</S.RoundMarker>
          </Button>
          <Text variant="h5">Basic details</Text>
        </Row>
        <Row alignCenter mb={theme.space.med}>
          <Button
            variant={ButtonVariant.TRANSPARENT}
            onClick={() => handleStepChange(2)}
            style={{ padding: 0 }}
          >
            <S.RoundMarker active={step >= 2}>2</S.RoundMarker>
          </Button>
          <Text variant="h5">Add questions</Text>
        </Row>
        <Row alignCenter mb={theme.space.med}>
          <Button
            variant={ButtonVariant.TRANSPARENT}
            onClick={() => handleStepChange(3)}
            style={{ padding: 0 }}
          >
            <S.RoundMarker active={step === 3}>3</S.RoundMarker>
          </Button>
          <Text variant="h5">Review and submit</Text>
        </Row>
      </Row>
      <Divider />

      {step === 1 && (
        <S.CreateVettingContainer>
          <Col style={{ width: '60vw' }}>
            <Text variant="h6" style={{ width: 140 }} mt={theme.space.med}>
              Campaign title
            </Text>
            <Input
              rows={1}
              type="text"
              value={campaignToCreate.title || ''}
              maxLength={400}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('title', e.target.value)
              }
              full
            />
          </Col>
          <Col style={{ width: '60vw' }} mt={theme.space.xs}>
            <Text variant="h6">
              Add the list of phone numbers to be vetted in this campaign
            </Text>
            <Input
              rows={3}
              type="textarea"
              className="xs-12"
              value={campaignToCreate.phoneNumbers || ''}
              maxLength={50000}
              onChange={handlePhoneNumbersInput}
              full
              inputStatus={InputStatus.error}
              style={{
                borderColor: invalidPhoneNumbers.length
                  ? theme.colors.Red40
                  : '',
              }}
            />

            {!!invalidPhoneNumbers.length && (
              <Row>
                <Text variant="error">Invalid phone numbers: </Text>
                <Text
                  variant="error"
                  style={{ fontWeight: '600' }}
                  ml={theme.space.xxs}
                >
                  {invalidPhoneNumbers.join(', ')}
                </Text>
              </Row>
            )}
            <InlineBanner
              severity="info"
              text={
                'Paste a comma separated list of phone numbers. You can also add a single phone number.'
              }
              style={{ marginTop: theme.space.xs }}
            />
          </Col>
          <Col style={{ width: '60vw' }} mt={theme.space.xs}>
            <Text variant="h6">Add role and company details</Text>
            <Input
              rows={3}
              type="textarea"
              className="xs-12"
              value={campaignToCreate.promptDetails || ''}
              maxLength={1600}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleInputChange('promptDetails', e.target.value)
              }
              full
            />
          </Col>
          <Col style={{ width: '60vw' }} mt={theme.space.xs}>
            <Text variant="h6">Initial message to workers</Text>
            <Row my={theme.space.xxs}>
              <RadioGroup
                name="comms-radio-buttons-group"
                value={campaignToCreate.language}
                onChange={handleLanguageChange}
                row
              >
                <FormControlLabel
                  value={SupportedLanguages.english}
                  control={<Radio />}
                  label="English"
                />
                <FormControlLabel
                  value={SupportedLanguages.spanish}
                  control={<Radio />}
                  label="Spanish"
                />
              </RadioGroup>
            </Row>
            <Input
              rows={3}
              type="textarea"
              className="xs-12"
              value={campaignToCreate.initialMessage || ''}
              maxLength={1600}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleInputChange('initialMessage', e.target.value)
              }
              full
              placeholder="Please type your message in the language you selected"
            />
            <InlineBanner
              severity="info"
              text={
                'Please note that the language you are selecting is for the initial message. Depending on the conversation and which language worker responds back in, the system may alter its response to match the language being used.'
              }
              style={{ marginTop: theme.space.xs }}
            />
          </Col>
          <Col mt={theme.space.med}>
            <Text variant="h6" style={{ width: 140 }}>
              Region IDs
            </Text>
            <SearchSelect
              multiple
              options={regionsOptions}
              selectedItems={selectedRegionIds}
              handleSelectMultiple={(regionIds: IMenuItem[]) => {
                handleInputChange(
                  'regionIds',
                  regionIds.map((region) => region.value),
                )
                setSelectedRegionIds(regionIds)
              }}
              width={260}
            />
          </Col>
          <Col style={{ width: '30vw' }} my={theme.space.med}>
            <Text variant="h6">Shift ID (optional)</Text>
            <Input
              rows={1}
              type="text"
              value={campaignToCreate.shiftId || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('shiftId', e.target.value)
              }
              full
            />
          </Col>
          <Col style={{ width: '60vw' }} mt={theme.space.xs}>
            <Text variant="h6" mb={theme.space.xxs}>
              Custom exit messages
            </Text>
            <Text variant="label">Optional - Qualified exit message</Text>
            <Input
              rows={3}
              type="textarea"
              className="xs-12"
              value={campaignToCreate.customQualifiedExitMessage}
              maxLength={1600}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleInputChange('customQualifiedExitMessage', e.target.value)
              }
              full
              placeholder={QUALIFIED_EXIT_MESSAGE}
            />
            <Text variant="label">Optional - Unqualified exit message</Text>

            <Input
              rows={3}
              type="textarea"
              className="xs-12"
              value={campaignToCreate.customUnqualifiedExitMessage}
              maxLength={1600}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleInputChange(
                  'customUnqualifiedExitMessage',
                  e.target.value,
                )
              }
              full
              placeholder={UNQUALIFIED_EXIT_MESSAGE}
            />
            <InlineBanner
              severity="info"
              text={
                'The custom exit messages are optional, if you leave them blank the default messages will be used. Be aware of using the same language for the initial message and the custom exit messages.'
              }
              style={{ marginTop: theme.space.xs }}
            />
          </Col>
        </S.CreateVettingContainer>
      )}
      {step === 2 && (
        <S.CreateVettingContainer>
          <Text variant="h6" my={theme.space.xs}>
            Select questions from the list or add your own
          </Text>
          <Row alignCenter justifyEnd mb={theme.space.xs}>
            {showCustomQuestionInput && (
              <Button
                variant={ButtonVariant.OUTLINED}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!customQuestionValue) {
                    return
                  }
                  setCustomQuestions([...customQuestions, customQuestionValue])
                  setCustomQuestionValue('')
                  setShowCustomQuestionInput(false)
                  setSelectedQuestions([
                    ...selectedQuestions,
                    customQuestionValue,
                  ])
                }}
                leftIcon={<Icon name="checkmark" />}
                ml={theme.space.xs}
              >
                Confirm
              </Button>
            )}
            <Button
              variant={
                showCustomQuestionInput
                  ? ButtonVariant.CANCEL
                  : ButtonVariant.FILLED
              }
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowCustomQuestionInput(!showCustomQuestionInput)
              }}
              ml={theme.space.xs}
              leftIcon={
                showCustomQuestionInput ? (
                  <Icon name="cancel" />
                ) : (
                  <Icon name="plus" />
                )
              }
            >
              {showCustomQuestionInput ? 'Cancel' : 'Add custom'}
            </Button>
          </Row>
          <S.CreateVettingCard>
            {showCustomQuestionInput && (
              <Input
                rows={2}
                type="text"
                value={customQuestionValue || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCustomQuestionValue(e.target.value)
                }
                style={{
                  backgroundColor: theme.colors.Green10,
                  marginBottom: theme.space.xxs,
                }}
                full
                placeholder='Type your custom question here and click "Confirm" to add it to the list'
              />
            )}
            {displayQuestions.map((question) => {
              const isSelected = !!selectedQuestions.find((q) => q === question)
              const isCustomQuestion = !BASE_QUESTIONS.find(
                (q) => q === question,
              )
              return (
                <S.CreateVettingQuestionCard
                  key={question}
                  style={{
                    backgroundColor: isSelected
                      ? theme.colors.Violet10
                      : isCustomQuestion
                        ? theme.colors.Blue10
                        : theme.colors.White,
                    borderColor: isSelected ? theme.colors.Violet : '',
                  }}
                  onClick={() => handleQuestionsSelect(question)}
                >
                  <Row fullWidth justifyStart>
                    <CheckboxThemed selected={isSelected} />
                    <Text variant="body1" ml={theme.space.xs}>
                      {question}
                    </Text>
                  </Row>
                  {isCustomQuestion && (
                    <IconButton
                      onClick={() => {
                        setCustomQuestions(
                          customQuestions.filter((q) => q !== question),
                        )
                        selectedQuestions.filter((q) => q !== question)
                      }}
                      iconName="cancel"
                    />
                  )}
                </S.CreateVettingQuestionCard>
              )
            })}
          </S.CreateVettingCard>
        </S.CreateVettingContainer>
      )}
      {step === 3 && (
        <S.CreateVettingContainer>
          <Row fullWidth alignCenter my={theme.space.xs}>
            <Text
              variant="h6"
              style={{
                width: 140,
              }}
            >
              Title:
            </Text>

            <Text ml={theme.space.xs}>{campaignToCreate.title}</Text>
          </Row>
          <Divider />
          <Row fullWidth alignCenter my={theme.space.xs}>
            <Text ml={theme.space.xs}>{campaignToCreate.phoneNumbers}</Text>
          </Row>
          <Divider />
          <Row fullWidth alignCenter my={theme.space.xs}>
            <Text
              variant="h6"
              style={{
                width: 140,
              }}
            >
              Initial message:
            </Text>
            <Icon name="ai_platform" style={{ alignSelf: 'flex-start' }} />
            <S.VioletCard>
              <Text ml={theme.space.xs}>{campaignToCreate.initialMessage}</Text>
            </S.VioletCard>
          </Row>
          <Row fullWidth alignCenter my={theme.space.xs}>
            <Text variant="h6">Initial chat language is set to:</Text>
            <Text variant="body1" ml={theme.space.xs}>
              {campaignToCreate.language}
            </Text>
          </Row>
          <Divider />
          <Row fullWidth my={theme.space.xs}>
            <Text
              variant="h6"
              style={{
                width: 140,
              }}
            >
              Questions:
            </Text>
            <Icon name="ai_platform" style={{ alignSelf: 'flex-start' }} />
            <S.VioletCard>
              <Col>
                {selectedQuestions.map((question: string) => (
                  <Row key={question} my={theme.space.xxs}>
                    <Text ml={theme.space.xs}>"{question}"</Text>
                  </Row>
                ))}
              </Col>
            </S.VioletCard>
          </Row>
          <Divider />
          <Text variant="h6" mt={theme.space.xs}>
            Exit messages
          </Text>
          <Text variant="label">
            These messages will be sent when the conversation ends and will also
            have information about how to download the Traba app
          </Text>
          <Row fullWidth my={theme.space.xs}>
            <Text
              variant="h6"
              style={{
                width: 140,
              }}
            >
              Qualified match:
            </Text>
            <Icon name="ai_platform" style={{ alignSelf: 'flex-start' }} />
            <S.VioletCard>
              <Text ml={theme.space.xs}>
                {campaignToCreate.customQualifiedExitMessage ||
                  QUALIFIED_EXIT_MESSAGE}
              </Text>
            </S.VioletCard>
          </Row>
          <Row fullWidth my={theme.space.xs}>
            <Text
              variant="h6"
              style={{
                width: 140,
              }}
            >
              Bad match:
            </Text>
            <Icon name="ai_platform" style={{ alignSelf: 'flex-start' }} />
            <S.VioletCard>
              <Text ml={theme.space.xs}>
                {campaignToCreate.customUnqualifiedExitMessage ||
                  UNQUALIFIED_EXIT_MESSAGE}
              </Text>
            </S.VioletCard>
          </Row>
          <Divider />
        </S.CreateVettingContainer>
      )}

      <Row fullWidth justifyBetween mt={theme.space.med}>
        {step > 1 && (
          <Button
            variant={ButtonVariant.OUTLINED}
            leftIcon={<Icon name="leftArrow" />}
            onClick={() => step > 1 && setStep(step - 1)}
            style={{
              height: theme.space.xl,
              width: 180,
            }}
          >
            Back
          </Button>
        )}
        <Button
          variant={ButtonVariant.FILLED}
          onClick={() => (step < 3 ? handleStepChange(step + 1) : onCreate())}
          style={{
            height: theme.space.xl,
            width: 180,
          }}
          disabled={isLoadingVettingData}
        >
          {step < 3 ? 'Next' : 'Create'}
        </Button>
      </Row>
    </MainLayout>
  )
}

export default CreateWorkerVettingCampaign
