import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import {
  CompanyConnectionResponse,
  ConnectionType,
  Worker,
  WorkerConnectionResponse,
} from '@traba/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getQueryParams } from './useApi'

async function getWorkerConnections(
  workerId: string,
): Promise<WorkerConnectionResponse[] | undefined> {
  const res = await trabaApi.get(`workers/${workerId}/connections`)
  return res.data
}

export async function deleteConnection(workerId: string, companyId: string) {
  const queryString = getQueryParams([
    ['workerId', workerId],
    ['bypassRestrictions', true],
  ])
  const res = await trabaApi.delete(
    `connections/company/${companyId}${queryString}`,
  )
  return res.data
}

export function useWorkerConnections(workerId: string) {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerConnectionResponse[] | undefined, Error>(
    `worker_connections_${workerId}`,
    () => getWorkerConnections(workerId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useWorkerConnections ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    Sentry.captureException(fullErrorMessage)
    showError(fullErrorMessage, 'Error Fetching Worker Connections')
  }

  return {
    isLoading,
    isError,
    connections,
    error,
    isFetched,
    refetch,
  }
}

async function getCompanyConnections(
  companyId?: string,
): Promise<CompanyConnectionResponse[] | undefined> {
  if (!companyId) {
    return []
  }

  try {
    const res = await trabaApi.get(`companies/${companyId}/connections`)
    return res.data
  } catch (error: any) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

export function useCompanyConnections(companyId?: string) {
  const { showError } = useAlert()
  const [favoriteWorkers, setFavoriteWorkers] = useState<Worker[]>([])
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<CompanyConnectionResponse[] | undefined, Error>(
    `company_connections_${companyId}`,
    () => getCompanyConnections(companyId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: !!companyId,
      onSuccess: (data) => {
        setFavoriteWorkers(
          data
            ?.filter(
              (connection) =>
                connection.connectionType === ConnectionType.FAVORITE,
            )
            .map((connection) => connection.worker) ?? [],
        )
      },
    },
  )

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    Sentry.captureException(fullErrorMessage)
    showError(fullErrorMessage, 'Error Fetching Company Connections')
  }

  return {
    isLoading,
    isError,
    connections,
    favoriteWorkers,
    error,
    isFetched,
    refetch,
  }
}
