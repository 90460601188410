import { useAlert } from '@traba/context'
import { trabaApi } from 'src/api/helpers'

export function useWorkerTermination() {
  const { showError } = useAlert()
  async function terminateWorker(workerId: string) {
    return await trabaApi
      .patch(`workers/${workerId}/stage-termination`)
      .catch((err) => {
        showError(err.message, `Error with terminating ${workerId}`)
      })
  }
  async function reinstateWorker(workerId: string) {
    return await trabaApi
      .patch(`workers/${workerId}/reinstate`)
      .catch((err) => {
        showError(err.message, `Error with reinstating ${workerId}`)
      })
  }
  return { terminateWorker, reinstateWorker }
}
