import { DotMenu } from '@traba/react-components'
import { Role } from '@traba/types'
import { RoleAttribute, RoleAttributeStatus } from '@traba/types'
import { Badge, CopyTextIcon, Row } from 'src/components/base'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import { truncateString } from 'src/utils/stringUtils'
import CompanyRoleBadges from './CompanyRoleBadges'

interface CompanyRolesTableProps {
  roles: Role[]
  roleAttributes: RoleAttribute[]
  onPressRoleEdit: (role: Role) => void
  onPressRoleConfig: (role: Role) => void
  handleArchiveRoleClick: (role: Role) => void
}

export default function CompanyRolesTable({
  roles,
  roleAttributes,
  onPressRoleEdit,
  onPressRoleConfig,
  handleArchiveRoleClick,
}: CompanyRolesTableProps) {
  const manageRoleSettings = usePermissions([
    UserRolePermission.ManageRoleSettings,
  ])

  const headers: (DataTableHeader | string)[] = [
    {
      key: 'roleName',
      label: 'Role Name',
      sortable: true,
    },
    {
      key: 'roleId',
      label: 'Role ID',
      sortable: true,
    },
    {
      key: 'roleDescription',
      label: 'Role Description',
    },
    {
      key: 'defaultPayRate',
      label: 'Default Pay Rate',
    },
    {
      key: 'requiredAttire',
      label: 'Required Attire',
    },
    // TODO: Add freeformAttributes to display
    {
      key: 'requiredAttributes',
      label: 'Required Attributes',
    },
    {
      key: 'requiredCertifications',
      label: 'Required Certifications',
    },
    {
      key: 'BGCRequirement',
      label: 'BGC Requirement',
    },
    {
      key: 'shiftBadges',
      label: 'Shift badges',
    },
    {
      key: 'genderPreference',
      label: 'Gender Preference',
    },
    {
      label: '',
    },
  ]

  const rows: TableRow[] = roles?.map((role) => {
    const {
      extraBGCRequirement,
      roleName,
      roleId,
      roleDescription,
      defaultPayRate,
      requiredAttire,
      requiredAttributes,
      requiredCertifications,
      genderPreference,
    } = role

    const cells: TableCell[] = [
      {
        sortKey: roleName,
        renderFn: () => roleName,
      },
      {
        sortKey: roleId,
        renderFn: () => (
          <Row alignCenter>
            {truncateString(roleId)}
            <CopyTextIcon textToCopy={roleId} />
          </Row>
        ),
      },
      {
        renderFn: () => roleDescription,
      },
      {
        renderFn: () => defaultPayRate,
      },
      {
        renderFn: () => requiredAttire,
      },
      {
        renderFn: () => (
          <Row wrap>
            {requiredAttributes?.map((attribute) => {
              const roleAttribute = roleAttributes?.find(
                (sa) => sa.type === attribute.type,
              )
              const name = roleAttribute?.displayName ?? ''
              const active =
                roleAttribute?.status === RoleAttributeStatus.Active
              return <Badge sm title={`${active ? '🔒' : ''} ${name}`} />
            })}
          </Row>
        ),
      },
      {
        renderFn: () =>
          requiredCertifications?.map((c) => (
            <Badge sm title={c.replace('_', ' ')} />
          )),
      },
      {
        renderFn: () => extraBGCRequirement,
      },
      {
        renderFn: () => <CompanyRoleBadges roleId={roleId} />,
      },
      {
        renderFn: () => genderPreference,
      },
      {
        renderFn: () => (
          <DotMenu
            type="roles"
            dotMenuKey={roleId}
            menuItems={[
              {
                title: 'Edit Role',
                onClick: () => onPressRoleEdit(role),
                disabled: !manageRoleSettings,
              },
              {
                title: 'Role Settings',
                onClick: () => onPressRoleConfig(role),
                disabled: !manageRoleSettings,
              },
              {
                title: 'Archive role',
                onClick: () => handleArchiveRoleClick(role),
                disabled: !manageRoleSettings,
              },
            ]}
          />
        ),
      },
    ]
    return {
      key: role.roleId,
      cells,
    }
  })

  return (
    <DataTable
      headers={headers}
      rows={rows}
      initialSortByColumnIndex={0} // sort by role name column
    />
  )
}
