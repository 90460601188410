import { CoordinatesDto } from '@traba/types'
import _ from 'lodash'

export const openInNewTab = (url: string | URL | undefined) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export function downloadBlob(dataBlob: any, filename: string) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.setAttribute('target', '_blank')
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(dataBlob),
  )
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function sortByProp<T>(
  prop: keyof T,
  sortOrder: 'ASC' | 'DESC' = 'ASC',
) {
  return (a: T, b: T) => {
    if (a[prop] > b[prop]) {
      return sortOrder === 'ASC' ? 1 : -1
    }
    if (a[prop] < b[prop]) {
      return sortOrder === 'ASC' ? -1 : 1
    }
    return 0
  }
}

export function sortByNestedProp<T>(
  prop: string,
  sortOrder: 'ASC' | 'DESC' = 'ASC',
) {
  return (a: T, b: T) => {
    if (_.get(a, prop) > _.get(b, prop)) {
      return sortOrder === 'ASC' ? 1 : -1
    }
    if (_.get(a, prop) < _.get(b, prop)) {
      return sortOrder === 'ASC' ? -1 : 1
    }
    return 0
  }
}

export function getDistanceBetweenCoords(
  coordsOrigin: CoordinatesDto,
  coordsDestination: CoordinatesDto,
  inMiles = false,
): number {
  const { latitude: lat1, longitude: long1 } = coordsOrigin
  const { latitude: lat2, longitude: long2 } = coordsDestination
  const RADIUS_OF_EARTH_KM = 6371

  const deltaLat = lat2 - lat1
  const deltaLong = long2 - long1
  const radiansLat = toRad(deltaLat)
  const radiansLong = toRad(deltaLong)

  const a =
    Math.sin(radiansLat / 2) * Math.sin(radiansLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(radiansLong / 2) *
      Math.sin(radiansLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = RADIUS_OF_EARTH_KM * c

  if (inMiles) {
    return d / 1.60934
  }
  return d
}

function toRad(num: number) {
  return (num * Math.PI) / 180
}

export function trimParams(params: any) {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([, value]) => value !== undefined && value !== '',
    ),
  )
}

export async function chunkRequests<T, R>(
  asyncFn: (input: T[]) => Promise<R>,
  array: T[],
  chunkSize: number,
): Promise<R[]> {
  const results: R[] = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize)
    const result = await asyncFn(chunk)
    results.push(result)
  }
  return results
}

export function getInvalidPostalCodes(postalCodes: string[]): string[] {
  const postalCodeRegExp = new RegExp(/^\d{5}$/)
  const invalidCodes: string[] = []
  for (const code of postalCodes) {
    if (!postalCodeRegExp.test(code)) {
      invalidCodes.push(code)
    }
  }
  return invalidCodes
}
