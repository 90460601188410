import { useAlert } from '@traba/context'
import { CompletedAdjustments } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getQueryParams } from 'src/hooks/useApi'
import { TimesheetAdjustment } from '../timesheet.types'
import { buildErrorMessage } from '../utils'
import { TimesheetAdjustmentForm } from './TimesheetAdjustmentForm'

type TimesheetAdjustModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShiftAdjustments: TimesheetAdjustment[]
  prevTotalPay: number
  prevMinutesWorked: number
  newTotalPay: number
  newMinutesWorked: number
}

export default function TimesheetAdjustModal({
  workerShiftAdjustments,
  isOpen,
  handleClose,
  prevTotalPay,
  prevMinutesWorked,
  newTotalPay,
  newMinutesWorked,
}: TimesheetAdjustModalProps) {
  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()

  const handleAdjustments = async ({
    shouldAdjustPayment,
    shouldInstantPay,
    workerShiftAdjustments,
  }: {
    shouldAdjustPayment: boolean
    shouldInstantPay: boolean
    workerShiftAdjustments: TimesheetAdjustment[]
  }) => {
    const queryString = getQueryParams([
      ['shouldAdjustPayment', shouldAdjustPayment.toString()],
      ['shouldInstantPay', shouldInstantPay.toString()],
    ])
    try {
      const result = await trabaApi.post(
        `worker-shifts/bulk-adjustments${
          shouldAdjustPayment ? `${queryString}` : ''
        }`,
        workerShiftAdjustments,
      )
      const { succeededWorkerIds, failedWorkerIdsAndReasons } =
        result.data as CompletedAdjustments

      if (failedWorkerIdsAndReasons.length > 0) {
        const errorMessage = buildErrorMessage(failedWorkerIdsAndReasons)
        showError(
          errorMessage,
          `Error Adjusting ${failedWorkerIdsAndReasons.length} Workers. ${
            succeededWorkerIds.length
              ? `${succeededWorkerIds.length} Succeeded`
              : ''
          }`,
        )
      } else {
        showSuccess(`${succeededWorkerIds.length} WorkerShifts Updated`)
      }
    } catch (error) {
      console.error('call to bulk adjust failed with error:', error)
      showError(
        'Error Adjusting Workers, check the console for more information',
      )
    } finally {
      refetchActiveQueries()
      handleClose()
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Make Adjustment for ${workerShiftAdjustments.length} Worker${
        workerShiftAdjustments.length > 1 ? 's' : ''
      }`}
      size={MODAL_SIZE.LARGE}
    >
      <TimesheetAdjustmentForm
        handleClose={handleClose}
        workerShiftAdjustments={workerShiftAdjustments}
        handleAdjustments={handleAdjustments}
        prevTotalPay={prevTotalPay}
        prevMinutesWorked={prevMinutesWorked}
        newTotalPay={newTotalPay}
        newMinutesWorked={newMinutesWorked}
      />
    </Modal>
  )
}
