import { HotSettings } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

async function getHotSettings(): Promise<HotSettings | undefined> {
  try {
    const res = await trabaApi.get(`system/hot-settings`)
    return res.data
  } catch (error) {
    console.error('useHotSettings -> getHotSettings() ERROR', error)
  }
}

export function useHotSettings() {
  const {
    isLoading,
    isError,
    data: hotSettings,
    error,
    isFetched,
    refetch,
  } = useQuery<HotSettings | undefined, Error>(`hotsettings`, () =>
    getHotSettings(),
  )

  return {
    isLoading,
    isError,
    hotSettings,
    error,
    isFetched,
    refetch,
  }
}
