import { Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { TransactionDocumentType } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import { Col, Row, Table } from 'src/components/base'
import FilterFields from 'src/components/FilterFields/FilterFields'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useFilteredData from 'src/hooks/useFilteredData'
import {
  getPopulatedTransactionsFromWorkerShifts,
  PopulatedTransaction,
} from 'src/utils/workerShiftUtils'
import { transactionsTableFilterFields } from './TransactionsTable.filters'
import TransactionsTableRow from './TransactionsTableRow'

export type TransactionsTableProps = {
  workerShifts: WorkerShift[]
}

export default function TransactionsTable(props: TransactionsTableProps) {
  const populatedTransactions = getPopulatedTransactionsFromWorkerShifts(
    props.workerShifts,
  )

  const transactionsFilter = useFilteredData<PopulatedTransaction>(
    populatedTransactions,
    transactionsTableFilterFields,
  )

  const numberOfTransactions = transactionsFilter.filteredData?.length
  const { refetchActiveQueries } = useActiveQueries()

  async function reverseTransfer(transaction: PopulatedTransaction) {
    const apiRes = await trabaApi.post(
      `/stripe/transfers/${transaction.id}/reversals`,
      {
        uniqueId: `${transaction.id}_reversal`,
        documentType: TransactionDocumentType.WORKER_SHIFT,
        workerId: transaction.worker?.uid || transaction.worker?.workerId,
        shiftId: transaction.shiftId,
      },
    )
    await refetchActiveQueries()
    return apiRes
  }

  return (
    <>
      <Row mt={60} mb={24}>
        <Col lg={4}>
          <Text variant="h4">Transactions ({numberOfTransactions})</Text>
        </Col>
        <Col>
          <Row>
            <FilterFields {...transactionsFilter} />
          </Row>
        </Col>
      </Row>
      <Table
        headers={[
          'Worker Name',
          'Worker Id',
          'Transaction Type',
          'Date',
          'Status',
          'Instant Pay',
          'Amount',
          '',
        ]}
      >
        {transactionsFilter.filteredData?.map((transaction) => (
          <TransactionsTableRow
            key={`${transaction.created}-${transaction.id}`}
            transaction={transaction}
            reverseTransfer={reverseTransfer}
          />
        ))}
      </Table>
    </>
  )
}
