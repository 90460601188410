import { theme } from '@traba/theme'
import { useEffect, useRef, useState } from 'react'
import { Icon, Button, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  DropdownContainer,
  ListContainer,
  ListItem,
  ListStyling,
} from './Dropdown.styles'

export interface DropdownProps {
  dropdownOptions: DropdownElement[]
  orientation?: 'right' | 'left'
  onOpenDropdown?: () => void
  buttonVariant?: ButtonVariant
  buttonStyling?: React.CSSProperties
  dropdownStyling?: React.CSSProperties
  containerStyling?: React.CSSProperties
  title?: string
  loading?: boolean
}

export type DropdownElement = {
  label: string
  handleClick: () => void
  disabled?: boolean
  icon?: () => JSX.Element
}

export const Dropdown = (props: DropdownProps) => {
  const ref = useRef<any>()
  useEffect(() => {
    const element = ref.current
    const handleClickOutside = (event: MouseEvent) => {
      if (element && !element.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [open, setOpen] = useState<boolean>(false)

  function handleClick() {
    if (!open && typeof props.onOpenDropdown === 'function') {
      props.onOpenDropdown()
    }
    setOpen(!open)
  }

  return (
    <DropdownContainer ref={ref} style={{ ...props.containerStyling }}>
      <Button
        style={{
          ...props.buttonStyling,
        }}
        variant={props.buttonVariant || ButtonVariant.TRANSPARENT}
        onClick={handleClick}
        loading={props.loading}
      >
        {props.title !== undefined ? (
          props.title
        ) : (
          <Icon name={open ? 'elipses_active' : 'elipses'} />
        )}
      </Button>
      {open && (
        <ListContainer orientation={props.orientation}>
          <ListStyling style={{ ...props.dropdownStyling }}>
            {props.dropdownOptions.map((option, i) => (
              <ListItem
                key={i}
                onClick={() => {
                  if (!option.disabled) {
                    option.handleClick()
                    setOpen(false)
                  }
                }}
                disabled={option.disabled}
              >
                <Row gap={theme.space.xxs}>
                  {!!option.icon && option.icon()}
                  {option.label}
                </Row>
              </ListItem>
            ))}
          </ListStyling>
        </ListContainer>
      )}
    </DropdownContainer>
  )
}
