import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import {
  Incentive,
  IncentiveRules,
  IncentiveStatus,
  IncentiveTypeIds,
  RuleLine,
  SourceType,
  TargetType,
  ValueType,
} from '@traba/types'
import { Shift } from '@traba/types'
import { WorkerIncentiveStatus } from '@traba/types'
import { AxiosRequestConfig } from 'axios'
import { addDays, format, subMonths } from 'date-fns'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { convertPayRateToCents } from 'src/utils/moneyUtils'
import { trabaApi } from '../api/helpers'
import { trimParams } from '../utils/helperUtils'
import {
  DEFAULT_PAGE_SIZE,
  PaginationParams,
  basePaginationParams,
  useBasicPagination,
} from './usePagination'
import { makeSearchParameters } from './utils/searchUtils'

const BASE_HOURLY_INCENTIVE = {
  category: 'Existing Worker Incentive - Engagement',
  categoryId: 'EWI-ENG',
  type: 'shift_hourly_bonus',
  typeId: IncentiveTypeIds.shift_hrly,
  status: IncentiveStatus.Active,
  title: 'Hourly Shift Bonus',
  rules: [],
  target: TargetType.Worker,
  source: SourceType.Traba,
  companyId: 'TRABA',
  valueType: ValueType.HourlyRate,
}

const getBaseHourlyIncentive = (
  shift: Shift,
  bonusAmount: number,
): Omit<Incentive, 'incentiveId'> => {
  return {
    ...BASE_HOURLY_INCENTIVE,
    description: `Earn an extra ${bonusAmount}/hr bonus when you complete this shift.`,
    campaignId: `${format(new Date(shift.startTime), 'yyyymmdd')}_${
      shift.employerName
    }_${shift.shiftId}_${bonusAmount}hr`,
    shiftId: shift.shiftId,
    startTime: new Date(),
    endTime: addDays(new Date(shift.startTime), 7),
    internalMemo: `Hourly bonus of ${bonusAmount}/hr for shift ${shift.shiftId}`,
    regionIds: [shift.regionId],
    total: {
      amount: convertPayRateToCents(bonusAmount),
      currency: 'USD',
    },
  }
}

export enum IncentiveOrFields {
  id = 'id',
  shiftId = 'shiftId',
  ruleType = 'ruleType',
  title = 'title',
  description = 'description',
  companyName = 'companyName',
  companyId = 'companyId',
}

export type IncentivesSelectFields = {
  [key: string]: string[]
}

export type IncentivesSearchParams = {
  id?: string
  regionIds?: string[]
  statuses?: IncentiveStatus[]
  typeIds?: IncentiveTypeIds[]
  ruleTypes?: IncentiveRules[]
  valueTypes?: ValueType[]
  orFields?: {
    id?: string
    shiftId?: string
    companyId?: string
    title?: string
    description?: string
    ruleType?: string
  }
}

export type IncentivesSearchQuery = {
  withCount?: boolean
  parameters: IncentivesSearchParams
  select?: IncentivesSelectFields
}

export type IncentiveFromSearch = Omit<Incentive, 'rules'> & {
  rules: {
    incentiveId: string
    ruleLine: RuleLine
  }[]
}

export async function runSearchIncentives(
  parameters: IncentivesSearchParams,
  paginationParams: PaginationParams,
  activeOrFields?: IncentiveOrFields[],
  fullTextSearchParam?: string,
  select?: IncentivesSelectFields,
  config?: AxiosRequestConfig,
): Promise<{ incentives: IncentiveFromSearch[]; count: number } | undefined> {
  try {
    const payload: IncentivesSearchQuery = {
      parameters: makeSearchParameters(
        parameters,
        activeOrFields,
        fullTextSearchParam,
      ),
      select,
      withCount: true,
    }
    const { limit, offset, sortBy, sortOrder } = paginationParams
    const URL = `incentives/search?startAt=${offset}&limit=${limit}${
      sortBy ? `&orderBy=${String(sortBy)}` : ''
    }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
    const res = await trabaApi.post(URL, payload, config)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

const SEARCH_INCENTIVES_QUERY_KEY = 'incentivesSearchCacheKey'

export function useSearchIncentives({
  textSearchValue,
  params,
  paginationParams,
  activeOrFields,
  fullTextSearchParam,
  select,
  config,
}: {
  textSearchValue?: string
  params: IncentivesSearchParams
  paginationParams: PaginationParams
  activeOrFields?: IncentiveOrFields[]
  fullTextSearchParam?: string
  select?: IncentivesSelectFields
  config?: AxiosRequestConfig
}) {
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()

  const offset = currentPage * (paginationParams.limit || DEFAULT_PAGE_SIZE)
  const pagination = {
    ...basePaginationParams,
    ...paginationParams,
    offset,
  }

  const {
    data: searchResults,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [
      SEARCH_INCENTIVES_QUERY_KEY,
      textSearchValue,
      params.regionIds,
      params.statuses,
      params.ruleTypes,
      params.valueTypes,
      params.typeIds,
      currentPage,
    ],
    () =>
      runSearchIncentives(
        trimParams(params),
        pagination,
        activeOrFields,
        fullTextSearchParam,
        select,
        config,
      ),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  )

  return {
    incentives: searchResults?.incentives || [],
    totalFound: searchResults?.count || 0,
    isLoading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  }
}

const getIncentiveById = async (
  incentiveId: string,
): Promise<Incentive | undefined> => {
  try {
    const response = await trabaApi.get(`incentives/${incentiveId}`)
    return response.data.incentive
  } catch (error) {
    Sentry.captureException(error)
  }
}

const getWorkerIncentives = async (
  workerId: string,
  status?: WorkerIncentiveStatus,
) => {
  try {
    const url = `worker-incentives/ops/${workerId}${
      status ? `?status=${status}` : ''
    }`
    const response = await trabaApi.get(url)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

const getWorkerIncentivesForIncentive = async (
  incentiveId: string,
  statuses?: WorkerIncentiveStatus[],
) => {
  try {
    const statusesQuery = statuses?.length
      ? statuses.map((s) => `statuses[]=${s}`).join('&')
      : []
    const url = `worker-incentives/query/${incentiveId}?${statusesQuery}`
    const response = await trabaApi.get(url)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function useIncentives() {
  const [bonusAmount, setBonusAmount] = useState(0)
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(false)
  const [isCreatingIncentive, setIsCreatingIncentive] = useState(false)
  const { showError, showSuccess } = useAlert()

  const handleIncentiveCreationForShift = async (shift: Shift) => {
    try {
      const newIncentive = getBaseHourlyIncentive(shift, bonusAmount)
      setIsLoadingIncentives(true)
      await trabaApi.post(`incentives`, newIncentive)
      showSuccess('Incentive saved successfully', '')
    } catch (error: unknown) {
      showError(`There was an error creating incentive`, 'Error')
      Sentry.captureException(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  const useIncentiveById = (incentiveId: string) => {
    return useQuery(
      ['incentive', incentiveId],
      () => getIncentiveById(incentiveId),
      {
        staleTime: 60 * 1000,
        enabled: !!incentiveId,
      },
    )
  }

  async function createNewIncentive(incentive: Omit<Incentive, 'incentiveId'>) {
    try {
      setIsCreatingIncentive(true)
      await trabaApi.post('incentives', incentive)
      showSuccess('Incentive created successfully', '')
    } catch (error) {
      showError('There was an error creating an incentive', 'Error')
      Sentry.captureException(error)
    } finally {
      setIsCreatingIncentive(false)
    }
  }

  async function getIncentivesForShift(shiftId: string) {
    try {
      setIsLoadingIncentives(true)
      const url = `incentives/query?shiftId=${encodeURIComponent(
        shiftId,
      )}&status=${IncentiveStatus.Active}`
      const response = await trabaApi.get(url)
      return response.data
    } catch (error) {
      showError('There was an error fetching a shift incentive', 'Error')
      Sentry.captureException(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  async function resyncWorkerIncentive(
    workerId: string,
    incentiveId: string,
    shiftId?: string,
  ) {
    try {
      const response = await trabaApi.patch(`incentives/${workerId}/resync`, {
        from: subMonths(new Date(), 6).toISOString(),
        incentiveIds: [incentiveId],
        shiftId,
      })
      return response.data
    } catch (error) {
      showError('There was an error running the resync', 'Error')
      Sentry.captureException(error)
    }
  }

  async function reverseWorkerIncentive(
    workerId: string,
    workerIncentiveId: string,
    reversalReason: string,
  ) {
    try {
      const response = await trabaApi.patch(
        `worker-incentives/${workerIncentiveId}/reverse`,
        {
          workerId,
          reversalReason,
        },
      )
      return response.data
    } catch (error) {
      showError('There was an error reversing the worker incentive', 'Error')
      Sentry.captureException(error)
    }
  }

  async function getActiveIncentivesForWorkerId(workerId: string) {
    try {
      setIsLoadingIncentives(true)
      const url = `incentives/active/${workerId}`
      const response = await trabaApi.get(url)
      return response.data
    } catch (error) {
      showError(
        `There was an error fetching active incentives for workerId ${workerId}`,
        'Error',
      )
      Sentry.captureException(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  async function updateIncentiveById(
    incentiveId: string,
    updates: Partial<Incentive>,
  ) {
    try {
      setIsLoadingIncentives(true)
      const url = `incentives/${incentiveId}`
      const response = await trabaApi.patch(url, updates)
      return response.data
    } catch (error) {
      showError('There was an error updating an incentive', 'Error')
      Sentry.captureException(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  const useGetWorkerIncentivesForWorkerId = (workerId: string) => {
    return useQuery(
      ['workerIncentives', workerId],
      () => getWorkerIncentives(workerId),
      {
        staleTime: 60 * 1000,
        enabled: !!workerId,
      },
    )
  }

  const useGetWorkerIncentivesForIncentiveId = (incentiveId: string) => {
    return useQuery(
      ['workerIncentives', incentiveId],
      () => getWorkerIncentivesForIncentive(incentiveId),
      {
        staleTime: 60 * 1000,
        enabled: !!incentiveId,
      },
    )
  }

  return {
    useIncentiveById,
    useGetWorkerIncentivesForIncentiveId,
    useGetWorkerIncentivesForWorkerId,
    bonusAmount,
    setBonusAmount,
    handleIncentiveCreationForShift,
    createNewIncentive,
    getIncentivesForShift,
    getActiveIncentivesForWorkerId,
    isLoadingIncentives,
    isCreatingIncentive,
    updateIncentiveById,
    getWorkerIncentives,
    resyncWorkerIncentive,
    reverseWorkerIncentive,
    getWorkerIncentivesForIncentive,
  }
}
