import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Card,
  LoadingSpinner,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  ParentInvoiceGroup,
  Shift,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import {
  getNextStartAndEndTime,
  getShiftDateString,
  getShiftTimeString,
} from '@traba/utils'
import { useMemo, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { useModal } from 'src/components/base/Modal/Modal'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import {
  CreateShiftRequest,
  useShiftRequests,
} from 'src/hooks/useShiftRequests'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { CreateOrEditInvoiceGroupModal } from 'src/screens/CompanyDetailsScreen/components/CreateOrEditInvoiceGroupModal'
import { ConfirmShiftRequestCreationDialog } from 'src/screens/PostShiftScreen/components/PostShiftForm/ConfirmShiftRequestCreationDialog'
import { CreateShiftRequestButton } from 'src/screens/PostShiftScreen/components/PostShiftForm/CreateShiftRequestButton'
import { InvoiceSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/InvoiceSection'
import { RolesAndWorkersSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/RolesAndWorkersSection'
import {
  getShiftRequestForEditOrAdd,
  validateShiftCreationForm,
} from 'src/screens/PostShiftScreen/components/PostShiftForm/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { validateDynamicOverbookAllowed } from 'src/utils/shiftFormUtils'

interface Props {
  company: Company
  firstRecurringShiftRequest: ShiftRequest
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onBack: () => void
  shifts?: Shift[]
  onSuccess: () => void
}

export const EditScheduleNewRole = ({
  company,
  firstRecurringShiftRequest,
  shiftRequestParent,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const { companyId } = company
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const [selectedShift, setSelectedShift] = useState<Shift>()
  const { roles, isLoading: isLoadingRoles } = useRoles(companyId)
  const { showError } = useAlert()
  const { locations } = useLocations(companyId)
  const { companyUsers, isLoading: isLoadingUsers } = useCompanyUsers(companyId)
  const { rosters, isLoading: isLoadingRosters } = useRosters(companyId)
  const { hotSettings, isLoading: isLoadingHotsettings } = useHotSettings()
  const { createShiftReqLoading, createShiftRequest: submitShiftRequest } =
    useShiftRequests()
  const { activeInvoiceGroups, refetch: refetchInvoiceGroups } =
    useInvoiceGroups(companyId)
  const createOrEditInvoiceGroupModal = useModal()
  const [createShiftRequests, setCreateShiftRequests] = useState<
    CreateShiftRequest[]
  >([
    getShiftRequestForEditOrAdd(shiftRequestParent, firstRecurringShiftRequest),
  ])
  const shiftsList = shifts?.filter(
    (s) =>
      s.shiftRequestId === firstRecurringShiftRequest.shiftRequestId && !!s.id,
  )

  const handleCreateInvoiceGroupModalClose = async (
    newGroup?: ParentInvoiceGroup,
  ) => {
    await refetchInvoiceGroups()
    if (newGroup) {
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          return {
            ...sr,
            parentInvoiceGroupId: newGroup.id,
          }
        })
      })
    }
    createOrEditInvoiceGroupModal.handleClose()
  }

  const minHourlyPayRate = useMemo(() => {
    return getPayRate({
      companyMin: company.minHourlyPayRate,
      hotSettingMin: hotSettings?.platformMinHourlyPayRate ?? 0,
    })
  }, [])

  if (
    isLoadingUsers ||
    isLoadingHotsettings ||
    isLoadingRoles ||
    isLoadingRosters
  ) {
    return <LoadingSpinner />
  }

  const handleSubmitShiftRequest = async () => {
    try {
      /* for edit schedule/role, to reuse some components, createShiftRequest is changed to be createShiftRequests, 
      but essentially it only has one item there so it's safe to use createShiftRequests[0] */
      await submitShiftRequest(createShiftRequests[0])
      onSuccess()
    } catch (e) {
      showError('Error creating shift request')
    }
  }
  const selectedLocation = locations?.find(
    (loc) => loc.locationId === createShiftRequests[0].locationId,
  )
  const timezone = selectedLocation?.timezone || 'America/New_York'

  return (
    <>
      <Card style={{ marginBottom: theme.space.sm }}>
        <Text variant="h5">When should the date of first shift be?</Text>
        <Text variant="body2" mb={theme.space.xs}>
          Selected date will be the first shift for this role on this schedule{' '}
        </Text>
        <Divider />
        {shiftsList?.map((shift: Shift) => (
          <Row
            alignCenter
            gap={theme.space.xxs}
            mt={theme.space.xxs}
            key={shift.shiftId}
            onClick={() => {
              setSelectedShift(shift)
              // Biweekly case
              if (createShiftRequests[0].schedules.length === 2) {
                const updatedScheduleA = createShiftRequests[0].schedules[0]
                updatedScheduleA.startTime = shift.startTime
                updatedScheduleA.endTime = shift.endTime
                const updatedScheduleB = createShiftRequests[0].schedules[1]
                const { startTime, endTime } = getNextStartAndEndTime(
                  updatedScheduleA,
                  updatedScheduleB,
                )
                updatedScheduleB.startTime = startTime
                updatedScheduleB.endTime = endTime
                setCreateShiftRequests((prev) => {
                  return prev.map((sr) => ({
                    ...sr,
                    schedules: [updatedScheduleA, updatedScheduleB],
                  }))
                })
              } else {
                // Weekly case - schedules will be a 1-item array
                setCreateShiftRequests((prev) => {
                  return prev.map((sr) => ({
                    ...sr,
                    schedules: [
                      {
                        ...sr.schedules[0],
                        startTime: shift.startTime,
                        endTime: shift.endTime,
                      },
                    ],
                  }))
                })
              }
            }}
          >
            <RadioButton selected={selectedShift?.id === shift.id} size={20} />
            <Text>
              {getShiftDateString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
              {', '}
              {getShiftTimeString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
            </Text>
          </Row>
        ))}
      </Card>
      <RolesAndWorkersSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        roles={roles}
        companyId={companyId}
        companyUsers={companyUsers}
        minHourlyPayRate={minHourlyPayRate}
        businessStartTime={shiftsList?.[0].businessStartTime ?? null}
        workersToInvite={workersToInvite}
        setWorkersToInvite={setWorkersToInvite}
        rosters={rosters}
      />
      <InvoiceSection
        createOrEditInvoiceGroupModal={createOrEditInvoiceGroupModal}
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        activeInvoiceGroups={activeInvoiceGroups}
      />
      <Row alignCenter justifyBetween>
        <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
          Back
        </Button>
        <CreateShiftRequestButton
          onClick={() => setShowConfirmationDialog(true)}
          buttonTitle={'Add role to schedule'}
          isLoadingButton={createShiftReqLoading}
          disabledButton={
            !validateShiftCreationForm(
              createShiftRequests[0],
              minHourlyPayRate,
              hotSettings?.paidBackupPayAmountMax,
            ) ||
            !validateDynamicOverbookAllowed(
              createShiftRequests[0].slotsRequested,
              !!createShiftRequests[0].enableDynamicOverbooking,
            )
          }
        />
      </Row>
      <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={companyId}
      />
      <ConfirmShiftRequestCreationDialog
        showConfirmationDialog={showConfirmationDialog}
        setShowConfirmationDialog={setShowConfirmationDialog}
        handleSubmitShiftRequest={handleSubmitShiftRequest}
        createShiftRequests={createShiftRequests}
        timezone={timezone}
        businessStartTime={shiftsList?.[0].businessStartTime ?? null}
        selectedLocation={selectedLocation}
        selectedSingleShiftDates={null}
        companyId={companyId}
      />
    </>
  )
}
