import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Row,
  StarRating,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { AttributeStarLevels } from '@traba/types'
import { WorkerRatingModal } from 'src/modals/WorkerRatingModal/WorkerRatingModal'
import { getLevelNumberFromStarAttributeLevel } from 'src/utils/attributeUtils'
import styled from 'styled-components'
import { useModal } from '../base/Modal/Modal'

interface WorkerRatingProps {
  workerId: string
  opsStarLevel: AttributeStarLevels | undefined
  bizRating?: number
  showBizRating?: boolean
  workerName: string
  isLoading: boolean
}

export function WorkerRating(props: WorkerRatingProps) {
  const {
    workerId,
    opsStarLevel,
    bizRating,
    showBizRating,
    workerName,
    isLoading,
  } = props

  const { isOpen, handleClose, open } = useModal()

  function handleAddRatingClick() {
    open()
  }

  return (
    <>
      <RatingContainer>
        <Row justifyBetween alignCenter>
          <Text variant="caption">OPS RATING</Text>
          <Button
            variant={ButtonVariant.TEXT}
            leftIcon={<SvgIcon name="plus" size={16} />}
            slim
            onClick={handleAddRatingClick}
          >
            Add
          </Button>
        </Row>
        {!isLoading ? (
          <StarRating
            id={workerId}
            value={getLevelNumberFromStarAttributeLevel(opsStarLevel)}
            readOnly
            hideLabel
            showValue
            size={24}
          />
        ) : (
          <LoadingSpinner style={{ height: 24, width: 24 }} />
        )}
        {showBizRating && (
          <>
            <Row mt={theme.space.xs}>
              <Text variant="caption" mb={theme.space.xxxs}>
                BIZ RATING
              </Text>
            </Row>
            <StarRating
              id={workerId}
              value={bizRating ?? 0}
              readOnly
              hideLabel
              showValue
              size={24}
            />
          </>
        )}
      </RatingContainer>
      <WorkerRatingModal
        isOpen={isOpen}
        handleClose={handleClose}
        workerId={workerId}
        workerName={workerName}
      />
    </>
  )
}

export const RatingContainer = styled.div`
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  padding-left: ${({ theme }) => `${theme.space.xs}px`};
  padding-right: ${({ theme }) => `${theme.space.xs}px`};
  padding-bottom: ${({ theme }) => `${theme.space.xs}px`};
  margin-right: ${({ theme }) => `${theme.space.med}px`};
  margin-top: ${({ theme }) => `${theme.space.xs}px`};
  margin-bottom: ${({ theme }) => `${theme.space.xs}px`};
  width: auto;
`
