import { useAlert } from '@traba/context'
import { ArchiveUserModalContent } from '@traba/react-components'
import { User } from '@traba/types'
import { useState } from 'react'
import { useCallback } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyUserMutations } from 'src/hooks/useCompanyUsers'

export type UserEditModalProps = {
  user: User
  handleClose: () => void
  isOpen: boolean
  replacementMembers: User[]
}

export function ArchiveUserModal({
  user,
  handleClose,
  isOpen,
  replacementMembers,
}: UserEditModalProps) {
  const [activeShiftsError, setActiveShiftsError] = useState(false)
  const { archiveCompanyMember, isArchiveCompanyMemberRoleLoading } =
    useCompanyUserMutations(user.companyId || '')
  const { showError } = useAlert()

  const handleArchiveMember = useCallback(
    (replacementUserId?: string) => {
      archiveCompanyMember(
        { uid: user.uid, replacementUserId },
        {
          onSuccess: () => {
            window.analytics.track(`User Archived Member`, {
              member: user,
            })
            handleClose()
          },
          onError: (err) => {
            if (err.message === 'archive/active-shifts') {
              setActiveShiftsError(true)
              return
            } else {
              showError(
                'An error has occurred. Please try again or contact support if the issue persists.',
                `Unable to archive user`,
              )
            }
          },
        },
      )
    },
    [user, archiveCompanyMember, handleClose, showError],
  )

  return (
    <Modal size={MODAL_SIZE.MEDIUM} isOpen={isOpen} handleClose={handleClose}>
      <ArchiveUserModalContent
        member={user}
        handleClose={handleClose}
        onArchiveMember={handleArchiveMember}
        loading={isArchiveCompanyMemberRoleLoading}
        replacementMembers={replacementMembers}
        activeShiftsError={activeShiftsError}
      />
    </Modal>
  )
}
