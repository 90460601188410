import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Worker } from '@traba/types'
import { useEffect, useMemo, useState } from 'react'
import { Select } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { useCompanies } from 'src/hooks/useCompanies'
import { useRoles } from 'src/hooks/useRoles'
import { useWardenExemptions } from 'src/hooks/useWardenExemptions'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import {
  CreateWardenExemption,
  WardenExemptionType,
  wardenExemptionsInfoMap,
} from 'src/types/warden'

const RoleSelector = ({
  companyId,
  selectedRoleId,
  setSelectedRoleId,
}: {
  companyId: string
  selectedRoleId: string
  setSelectedRoleId: (value: React.SetStateAction<string>) => void
}) => {
  const { roles = [], isLoading } = useRoles(companyId)
  const options = useMemo(
    () =>
      roles.map((role) => ({
        label: role.roleName,
        value: role.roleId,
      })),
    [roles],
  )
  return (
    <>
      <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
        Select Role
      </Text>
      {isLoading ? (
        <Text> Loading... </Text>
      ) : (
        <SearchSelect
          selectItem={options.find((option) => option.value === selectedRoleId)}
          options={[
            {
              label: '--',
              value: '',
            },
            ...options,
          ]}
          handleSelect={(option) => setSelectedRoleId(option?.value || '')}
          label="Role"
        />
      )}
    </>
  )
}

const CompanySelector = ({
  selectedCompanyId,
  setSelectedCompanyId,
}: {
  selectedCompanyId: string
  setSelectedCompanyId: (value: React.SetStateAction<string>) => void
}) => {
  const { companies = [], isLoading } = useCompanies({ isApproved: true })
  const options = useMemo(
    () =>
      companies.map((company) => ({
        label: company.employerName,
        value: company.id,
      })),
    [companies],
  )
  return (
    <>
      <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
        Select Company
      </Text>
      {isLoading ? (
        <Text> Loading... </Text>
      ) : (
        <SearchSelect
          selectItem={options.find(
            (option) => option.value === selectedCompanyId,
          )}
          options={[
            {
              label: '--',
              value: '',
            },
            ...options,
          ]}
          handleSelect={(option) => setSelectedCompanyId(option?.value || '')}
          label="Company"
        />
      )}
    </>
  )
}

const WorkerSelector = ({
  selectedWorkerId,
  setSelectedWorkerId,
  workers,
}: {
  selectedWorkerId: string
  setSelectedWorkerId: (value: React.SetStateAction<string>) => void
  workers?: Worker[]
}) => {
  // If workers are not provided, use the search workers component
  const [selectedSearchWorkers, setSelectedSearchWorkers] = useState<
    PopulatedWorker[]
  >([])

  useEffect(() => {
    setSelectedWorkerId(selectedSearchWorkers[0]?.id || '')
  }, [selectedSearchWorkers])

  const workerOptions = useMemo(
    () =>
      workers
        ? workers.map((worker) => ({
            label: `${worker.firstName} ${worker.lastName}`,
            value: worker.workerId,
          }))
        : [],
    [workers],
  )

  return (
    <>
      <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
        Select Worker
      </Text>
      {workers ? (
        <SearchSelect
          selectItem={workerOptions.find(
            (option) => option.value === selectedWorkerId,
          )}
          options={[
            {
              label: '--',
              value: '',
            },
            ...workerOptions,
          ]}
          handleSelect={(option) => setSelectedWorkerId(option?.value || '')}
          label="Worker"
        />
      ) : (
        <SearchWorkers
          checkedWorkers={selectedSearchWorkers}
          setCheckedWorkers={setSelectedSearchWorkers}
          selectOneOnly
          openInline
        />
      )}
    </>
  )
}

export function CreateWardenExemptionModal({
  title,
  isOpen,
  setIsOpen,
  refetch,
  companyId,
  workerId,
  shiftId,
  workersOnShift,
}: {
  title: string
  isOpen: boolean
  setIsOpen: (value: React.SetStateAction<boolean>) => void
  refetch: () => void
  companyId?: string
  workerId?: string
  shiftId?: string
  workersOnShift?: Worker[]
}) {
  const { createWardenExemption } = useWardenExemptions()
  const { handleError } = useAlert()

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [selectedWardenExemptionType, setSelectedBypassType] =
    useState<WardenExemptionType>()
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
    companyId || '',
  )
  const [selectedRoleId, setSelectedRoleId] = useState<string>('')
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>('')

  const handleClose = () => {
    setIsOpen(false)
    setSelectedBypassType(undefined)
    setSelectedCompanyId(companyId || '')
    setSelectedRoleId('')
    setSelectedWorkerId('')
  }

  const handleCreate = async () => {
    if (!selectedWardenExemptionType) {
      return
    }

    setConfirmLoading(true)

    const createWardenExemptionData: CreateWardenExemption = {
      exemptionType: selectedWardenExemptionType,
      companyId: selectedCompanyId || undefined,
      roleId: selectedRoleId || undefined,
      workerId: selectedWorkerId || workerId,
      shiftId,
    }

    createWardenExemption(createWardenExemptionData, {
      onSuccess: () => {
        refetch()
        handleClose()
      },
      onError: (error) => {
        handleError(
          error,
          'CreateWardenExemptionModal -> handleCreate()',
          error.message,
          'Error creating warden exemption',
        )
      },
      onSettled: () => setConfirmLoading(false),
    })
  }

  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
      onConfirmCTA="Confirm"
      dialogTitle={title}
      maxWidth="lg"
      onConfirm={handleCreate}
      confirmDisabled={
        !(selectedWardenExemptionType && (!!selectedCompanyId || !!shiftId))
      }
      confirmLoading={confirmLoading}
    >
      <Text variant="h5" mb={theme.space.xs}>
        Select Type
      </Text>
      <Select
        value={selectedWardenExemptionType || ''}
        menuItems={Object.values(WardenExemptionType).map((exemptionType) => ({
          label: wardenExemptionsInfoMap[exemptionType].title,
          value: exemptionType,
        }))}
        handleSelect={(value) =>
          setSelectedBypassType(value as WardenExemptionType)
        }
        label="Exemption Type"
        placeholder="Exemption Type"
      />
      {!companyId && !shiftId && (
        <CompanySelector
          selectedCompanyId={selectedCompanyId}
          setSelectedCompanyId={setSelectedCompanyId}
        />
      )}
      {selectedCompanyId && (
        <RoleSelector
          companyId={selectedCompanyId}
          selectedRoleId={selectedRoleId}
          setSelectedRoleId={setSelectedRoleId}
        />
      )}
      {!workerId && (
        <WorkerSelector
          selectedWorkerId={selectedWorkerId}
          setSelectedWorkerId={setSelectedWorkerId}
          workers={workersOnShift}
        />
      )}
    </Dialog>
  )
}
