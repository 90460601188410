import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
} from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ShiftBackfill,
  ShiftBackfillCallResponseDto,
  ShiftBackfillStatus,
} from '@traba/types'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DotText } from 'src/components/base/DotText'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { AvatarWrapper } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/styles'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import useTimezonedDates from '../../../hooks/useTimezonedDates'
import { Anchor, Button, CopyTextIcon, IconButton, Link, Row } from '../../base'
import {
  useBackfillCall,
  useShiftBackfillCalls,
  useShiftBackfills,
} from '../hooks/useBackfillCall'
import * as S from '../styles'

type ScheduledBackfillCallsScreenProps = {
  shift: Shift
}

type ShiftBackfillCardProps = {
  shift: Shift
  shiftBackfill: ShiftBackfill
}

export const ScheduledBackfillCallsScreen = ({
  shift,
}: ScheduledBackfillCallsScreenProps) => {
  const { shiftBackfills, isLoading: loadingBackfills } = useShiftBackfills(
    shift.shiftId,
  )

  return (
    <S.ScheduleSectionContainer>
      {loadingBackfills ? (
        <Row fullHeight fullWidth alignCenter justifyCenter>
          <CircularProgress />{' '}
        </Row>
      ) : shiftBackfills && !!shiftBackfills.length ? (
        <>
          {shiftBackfills.map((shiftBackfill) => (
            <ShiftBackfillCard shift={shift} shiftBackfill={shiftBackfill} />
          ))}
        </>
      ) : (
        <Text>No Backfill Calls found for this shift</Text>
      )}
    </S.ScheduleSectionContainer>
  )
}

export const ShiftBackfillCard = (props: ShiftBackfillCardProps) => {
  const { shiftBackfill, shift } = props
  const tz = useTimezonedDates(shift.timezone)
  const { cancelBackfill } = useBackfillCall(shift.shiftId)
  const {
    shiftBackfillCalls,
    refetch: refetchShiftBackfillCalls,
    isLoading: shiftBackfillCallsLoading,
  } = useShiftBackfillCalls(shiftBackfill.shiftBackfillId, true)
  const { workerShifts } = useWorkerShifts(shift.shiftId)

  const isCancelable =
    shiftBackfill.status === ShiftBackfillStatus.InProgress ||
    shiftBackfill.status === ShiftBackfillStatus.Queued

  const [expanded, setExpanded] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const handleCancel = async () => {
    setLoadingCancel(true)
    await cancelBackfill(shiftBackfill.shiftBackfillId)
    setLoadingCancel(false)
  }

  return (
    <Row mb={theme.space.xs} flexCol fullWidth>
      <S.ShiftBackfillCardContainer>
        <Row justifyBetween fullWidth alignCenter>
          <Text variant="h5">ID: {shiftBackfill.shiftBackfillId}</Text>
          <Text variant="h6">
            Started At: {tz.getDateTime(shiftBackfill.createdAt)}
          </Text>
        </Row>
        <Row alignCenter justifyBetween fullWidth>
          <Row alignCenter>
            <Text variant="h6" style={{ marginRight: theme.space.xxxs }}>
              Message:
            </Text>
            <Anchor href={shiftBackfill.message}>Voice Message</Anchor>
          </Row>
          {!!shiftBackfill.completedAt && (
            <Text variant="h6">
              Completed At: {tz.getDateTime(shiftBackfill.completedAt)}
            </Text>
          )}
        </Row>
        <Row>
          <Text variant="h6">
            Call Position: {shiftBackfill.curIndex}/{shiftBackfill.listLength}
          </Text>
        </Row>
        <Row justifyBetween fullWidth alignCenter>
          <Row>
            <Text variant="h6" style={{ marginRight: theme.space.xxxs }}>
              Status:
            </Text>
            <Text variant="h6" style={{ color: theme.colors.TrabaBlue }}>
              {shiftBackfill.status}
            </Text>
          </Row>
          <Row>
            <IconButton
              iconName="refresh"
              onClick={() => refetchShiftBackfillCalls()}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: theme.space.xxxs,
              }}
              loading={shiftBackfillCallsLoading}
            />
            <Button
              slim
              disabled={!isCancelable}
              loading={loadingCancel}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Row>
      </S.ShiftBackfillCardContainer>
      <Row alignCenter center flexCol>
        {expanded &&
          (shiftBackfillCallsLoading ? (
            <Row style={{ height: 50 }} mt={theme.space.xs}>
              <CircularProgress color={'primary'} />
            </Row>
          ) : (
            <List sx={{ maxHeight: 300, overflow: 'auto', width: '100%' }}>
              {shiftBackfillCalls &&
                shiftBackfillCalls.map((shiftBackfillCall) => (
                  <ShiftBackfillCallCard
                    shiftBackfillCall={shiftBackfillCall}
                    timezone={shift.timezone}
                    isOnShift={
                      !!workerShifts?.find(
                        (ws) => ws.workerId === shiftBackfillCall.workerId,
                      )
                    }
                  />
                ))}
            </List>
          ))}
        <Button
          variant={ButtonVariant.PURPLEGRADIENT}
          full
          onClick={() => {
            refetchShiftBackfillCalls()
            setExpanded(!expanded)
          }}
        >
          {expanded ? 'Collapse Call' : 'View Calls'}
        </Button>
      </Row>
    </Row>
  )
}

interface ShiftBackfillCallCardProps {
  shiftBackfillCall: ShiftBackfillCallResponseDto
  timezone: string
  isOnShift: boolean
}

export const ShiftBackfillCallCard = (props: ShiftBackfillCallCardProps) => {
  const { shiftBackfillCall, timezone, isOnShift } = props
  const tz = useTimezonedDates(timezone)
  const { worker } = shiftBackfillCall
  if (!worker) {
    return null
  }

  return (
    <ListItem
      sx={{
        backgroundColor:
          shiftBackfillCall.index % 2 === 0
            ? theme.colors.Grey20
            : theme.colors.White,
      }}
      key={`${shiftBackfillCall.shiftBackfillId}_${shiftBackfillCall.workerId}`}
    >
      {shiftBackfillCall.index + 1}

      <ListItemAvatar sx={{ marginLeft: theme.space.xxxs }}>
        <AvatarWrapper>
          <Avatar
            alt={`worker_${worker.id}_avatar`}
            src={worker.photoUrl}
            sizes="40px"
          />
        </AvatarWrapper>
      </ListItemAvatar>
      <Row style={{ width: 200 }}>
        <Link to={`/workers/${shiftBackfillCall.workerId}`} target="_blank">
          <Text variant="h7">
            {truncateString(`${worker.firstName} ${worker.lastName}`, 25)}
          </Text>
        </Link>
      </Row>
      <DotText />
      <Row style={{ width: 200 }}>
        <Text variant="h7">
          {formatPhoneNumber(shiftBackfillCall.phoneNumber, true)}
        </Text>
        <CopyTextIcon textToCopy={shiftBackfillCall.phoneNumber} />
      </Row>
      <Row ml={theme.space.xs}>
        <Text
          variant="h7"
          style={{
            color: isOnShift ? theme.colors.Green70 : theme.colors.TrabaBlue,
          }}
        >
          {shiftBackfillCall.response || '-'}
        </Text>
      </Row>
      <Row ml={theme.space.xs}>
        <Text variant="caption" style={{ color: theme.colors.MidnightBlue }}>
          {tz.getTime(shiftBackfillCall.calledAt)}
        </Text>
      </Row>
      <Row ml={theme.space.xs} style={{ color: theme.colors.MidnightBlue }}>
        <Text variant="caption">{shiftBackfillCall.callInput}</Text>
      </Row>
    </ListItem>
  )
}
