import {
  StarRating,
  Row,
  Text,
  ModalButtons,
  Input,
  Col,
  SvgIcon,
  Button,
  ButtonVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InputStatus,
  ROLE_ATTRIBUTE_STAR_RATING_TYPE,
  RoleAttributeCategory,
  WorkerEvents,
} from '@traba/types'
import { addDays, subMonths } from 'date-fns'
import { useCallback, useState } from 'react'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useAuditLogs } from 'src/hooks/useAuditLogs'
import { useWorkerProfileMutations } from 'src/hooks/useWorkerProfile'
import {
  getLevelNumberFromStarAttributeLevel,
  getStarAttributeLevelFromNumber,
} from 'src/utils/attributeUtils'

interface WorkerRatingModalProps {
  handleClose: () => void
  isOpen: boolean
  workerId: string
  workerName: string
}

export const WorkerRatingModal = (props: WorkerRatingModalProps) => {
  const { isOpen, handleClose, workerId, workerName } = props
  const [rating, setRating] = useState<number | null>(null)
  const [ratingNote, setRatingNote] = useState<string | undefined>()
  const [showPreviousRatings, setShowPreviousRatings] = useState(false)

  const begOfToday = new Date(new Date().setHours(0, 0, 0, 0))
  const begOfTomorrow = addDays(begOfToday, 1)
  const { auditLogs, refetch: refetchAuditLogs } = useAuditLogs({
    beforeCreationDate: begOfTomorrow,
    afterCreationDate: subMonths(begOfTomorrow, 1),
    workerId,
    event: WorkerEvents.WorkerAttributesUpdated,
    enabled: isOpen,
  })
  const { updateWorkerProfileOpsLevelMutation } = useWorkerProfileMutations()

  function updateStarRating(rating: number | null) {
    setRating(rating)
  }

  const handleSaveOpsStarRating = useCallback(
    (rating: number, ratingNote: string | undefined) => {
      updateWorkerProfileOpsLevelMutation.mutate(
        {
          workerId,
          items: [
            {
              profileField: ROLE_ATTRIBUTE_STAR_RATING_TYPE,
              opsLevel: getStarAttributeLevelFromNumber(rating),
              ...(ratingNote && { opsNotes: ratingNote }),
              category: RoleAttributeCategory.TRAITS,
            },
          ],
        },
        {
          onSuccess: () => refetchAuditLogs(),
        },
      )
    },
    [updateWorkerProfileOpsLevelMutation, workerId, refetchAuditLogs],
  )

  function handleChangeRatingNote(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const value = e.target.value
    setRatingNote(value)
  }

  function handleConfirm() {
    if (rating !== null) {
      handleSaveOpsStarRating(rating, ratingNote)
    }
    onClose()
  }

  function onClose() {
    setRating(null)
    setRatingNote(undefined)
    setShowPreviousRatings(false)
    handleClose()
  }

  return (
    <Modal
      handleClose={onClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_SMALL}
      title={`Rate ${workerName}`}
    >
      <Text variant="h5" mb={theme.space.xs}>
        Overall rating
      </Text>
      <StarRating
        id={`${workerId}_star_rating`}
        value={rating}
        onValueChange={updateStarRating}
        size={36}
      />
      <Row alignCenter mt={theme.space.sm}>
        <Text variant="h6">Notes</Text>
        <Text variant="body3" ml={theme.space.xms}>
          (optional)
        </Text>
      </Row>
      <Input
        rows={3}
        type="textarea"
        placeholder="Add notes about why you are giving the worker this rating"
        value={ratingNote}
        maxLength={50000}
        onChange={handleChangeRatingNote}
        inputStatus={InputStatus.error}
        style={{
          backgroundColor: theme.colors.Grey10,
        }}
      />
      <Row justifyEnd>
        <ModalButtons
          handleClose={onClose}
          handleConfirm={handleConfirm}
          isDisabled={!rating}
          confirmText="Save Rating"
        />
      </Row>
      <Row alignCenter gap={theme.space.sm} mt={theme.space.sm}>
        <Button
          variant={ButtonVariant.TEXT}
          rightIcon={
            <SvgIcon
              name={showPreviousRatings ? 'chevronUp' : 'chevronDown'}
              size={20}
              color={theme.colors.Grey}
            />
          }
          iconWidth={20}
          onClick={() => setShowPreviousRatings((prev) => !prev)}
        >
          View previous ratings
        </Button>
      </Row>
      <Col>
        {showPreviousRatings &&
          auditLogs
            ?.filter(
              (log) =>
                log.properties?.profileField ===
                ROLE_ATTRIBUTE_STAR_RATING_TYPE,
            )
            .map((auditLog) => (
              <Col key={auditLog.auditLogId} mb={theme.space.xxs}>
                <Row alignCenter mb={theme.space.xs}>
                  <StarRating
                    id={`${auditLog.auditLogId}_star_rating`}
                    value={getLevelNumberFromStarAttributeLevel(
                      auditLog.properties?.opsLevel ?? '0',
                    )}
                    readOnly
                    hideLabel
                    showValue
                    size={24}
                  />
                  <Row ml={theme.space.sm}>
                    <Text variant="body1">
                      {`${auditLog.actorId} · ${new Date(auditLog.createdAt).toLocaleDateString()}`}
                    </Text>
                  </Row>
                </Row>
                {auditLog.properties?.opsNotes && (
                  <div
                    style={{
                      backgroundColor: theme.colors.Grey10,
                      padding: theme.space.xs,
                      border: `1px solid ${theme.colors.Grey30}`,
                      borderRadius: 10,
                      maxWidth: '460px',
                    }}
                  >
                    <Text variant="body1">{auditLog.properties.opsNotes}</Text>
                  </div>
                )}
                <HorizontalRule
                  marginTop={theme.space.xxs}
                  marginBottom={theme.space.xs}
                />
              </Col>
            ))}
      </Col>
    </Modal>
  )
}
