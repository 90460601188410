import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyInvitation, USER_ROLE_OPTIONS, UserRole } from '@traba/types'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { Input, Row, Select } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'

export type CreateInvitationModalProps = {
  onClose: () => void
  isOpen: boolean
  companyId: string
  createInvitationMutation: UseMutateAsyncFunction<
    CompanyInvitation,
    AxiosError<unknown, any>,
    {
      companyId: string
      email: string
      role: UserRole
    },
    unknown
  >
}

export const CreateInvitationModal = (props: CreateInvitationModalProps) => {
  const { showSuccess, showError } = useAlert()
  const { onClose, isOpen, createInvitationMutation, companyId } = props
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [email, setEmail] = useState<string | undefined>()
  const [userRole, setUserRole] = useState<UserRole>()

  async function handleCreate(
    companyId: string,
    email: string,
    role: UserRole,
  ) {
    setConfirmLoading(true)
    try {
      await createInvitationMutation({ companyId, email, role })
      showSuccess(
        'Invitation was created successfully. The invited user will receive an email with a link to accept the invitation.',
        'Successfully sent invitation',
      )
    } catch (error) {
      showError(
        (error as Error).message ??
          'Error occurred during sending invitation. Please check your input and try again.',
        'Error creating invitation',
      )
    }
    setConfirmLoading(false)
    setEmail(undefined)
    setUserRole(undefined)
    onClose()
  }

  return (
    <Dialog
      dialogTitle="Send New Invitation"
      open={isOpen}
      onConfirmCTA="Confirm"
      confirmLoading={confirmLoading}
      onConfirm={async () => {
        if (!email || !userRole) {
          return
        }
        await handleCreate(companyId, email, userRole)
      }}
      confirmDisabled={!email || !userRole}
      onClose={onClose}
    >
      <Text variant="body2" mb={theme.space.xs}>
        Enter an email address and choose a role for the new member below and an
        invitation to join the company’s account will be sent.
      </Text>
      <Row
        style={{
          gap: theme.space.xs,
        }}
      >
        <Input
          label="Email address"
          name="email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
          }}
          full
          containerStyle={{ flex: '4' }}
          style={{ height: theme.space.lg }}
        />
        <Select
          value={userRole || ''}
          menuItems={USER_ROLE_OPTIONS}
          handleSelect={(value) => setUserRole(value as UserRole)}
          label="Role"
          placeholder="Role"
          containerStyle={{ flex: '3' }}
          style={{ height: theme.space.lg }}
          fullWidth
        />
      </Row>
    </Dialog>
  )
}
