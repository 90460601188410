import { CircularProgress } from '@mui/material'

import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CreateInvitationsData,
  Roster,
  ShiftInvitation,
  ShiftInvitationStatus,
  Shift,
} from '@traba/types'
import { AxiosError } from 'axios'
import React, { useMemo, useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { Back } from 'src/assets/svg/icons/Back'
import { Row } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { useWorkersWithDetails } from 'src/hooks/useWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { SearchAddWorkersView } from './SearchAddWorkersView'
import { SelectRostersView } from './SelectRostersView'
import { createRosterMap, getRostersInOrder, getUniqueWorkerIds } from './utils'

/**
 * Generate the confirmation text before invites get sent out. Includes a list
 * of users who have already been invited as well as new invites so that user
 * can confirm the correct invitees
 */
const generateConfirmationMessage = (
  toUnrescind: PopulatedWorker[],
): string => {
  return toUnrescind.length
    ? `The invitations for the following selected workers have been rescinded. Are you sure you want to create new invitations for these workers?\n${toUnrescind
        .map(
          (worker, i) =>
            `${i + 1}: ${worker.firstName} ${worker.lastName} (${worker.id})`,
        )
        .join('\n')}`
    : ''
}

interface SearchInviteWorkersModalProps {
  open: boolean
  handleClose: () => void
  rosters: Roster[]
  setAllWorkersToInvite: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>

  // the following props are used when inviting workers on an active shift
  shift?: Shift
  sendInvitations?: UseMutateFunction<
    ShiftInvitation[],
    AxiosError<any, any>,
    CreateInvitationsData,
    unknown
  >
  shiftInvitations?: ShiftInvitation[]
}

/**
 * There are two states this modal can be in:
 *   1. State 1 – Roster selection
 *   2. State 2 – Adding workers outside of—or deselecting workers from—the selected roster(s)
 */
enum InviteFlowState {
  SelectRoster = 'Step 1: Select rosters',
  AddWorkers = 'Step 2: Add workers',
}

export const SearchInviteWorkersModal: React.FC<
  SearchInviteWorkersModalProps
> = (props) => {
  const {
    open,
    handleClose,
    rosters,
    setAllWorkersToInvite,
    shift,
    sendInvitations,
    shiftInvitations,
  } = props

  const [currentStep, setCurrentStep] = useState<InviteFlowState>(
    InviteFlowState.SelectRoster,
  )

  const [selectedRosters, setSelectedRosters] = useState<Set<string>>(new Set())

  const [
    selectedRosterIdToSelectedWorkers,
    setSelectedRosterIdToSelectedWorkers,
  ] = useState<Record<string, Record<string, PopulatedWorker>>>({})

  const [
    selectedWorkerIdsFromGeneralPool,
    setSelectedWorkerIdsFromGeneralPool,
  ] = useState<PopulatedWorker[]>([])

  const { workersWithDetails: allWorkers, isLoading: isLoadingAllWorkers } =
    useWorkersWithDetails(getUniqueWorkerIds(rosters))

  const rosterMap = useMemo(() => {
    if (allWorkers && allWorkers?.length && !isLoadingAllWorkers) {
      return createRosterMap(rosters, allWorkers)
    }
    return {}
  }, [allWorkers, isLoadingAllWorkers, rosters])

  const onConfirm = () => {
    switch (currentStep) {
      case InviteFlowState.SelectRoster: {
        // At this point, the user has selected the rosters they want to
        // invite so we can pre-populate the selected workers from those
        // before advancing the step.
        setSelectedRosterIdToSelectedWorkers(
          Object.fromEntries(
            Array.from(selectedRosters).map((key) => [
              key,
              Object.fromEntries(
                rosterMap[key].workers
                  .filter((worker) => !!worker.id)
                  .map((worker) => [worker.id || worker.uid, worker]),
              ),
            ]),
          ),
        )
        setCurrentStep(InviteFlowState.AddWorkers)
        break
      }

      case InviteFlowState.AddWorkers: {
        const allWorkers: PopulatedWorker[] = []
        // Use a set to ensure no duplicate workers
        const allWorkerIds: Set<string> = new Set()

        // Gather all workers from the selected rosters
        Object.values(selectedRosterIdToSelectedWorkers).forEach(
          (workerIdToWorker) => {
            Object.values(workerIdToWorker).forEach((populatedWorker) => {
              if (!populatedWorker.id || allWorkerIds.has(populatedWorker.id)) {
                return
              }
              allWorkerIds.add(populatedWorker.id)
              allWorkers.push(populatedWorker)
            })
          },
        )

        // Gather all workers selected from the general pool
        selectedWorkerIdsFromGeneralPool
          .filter((worker) => !!worker.id)
          .forEach((populatedWorker) => {
            if (!populatedWorker.id || allWorkerIds.has(populatedWorker.id)) {
              return
            }
            allWorkerIds.add(populatedWorker.id)
            allWorkers.push(populatedWorker)
          })

        // Set the overall list of workers to invite
        setAllWorkersToInvite(allWorkers)

        if (shift && sendInvitations) {
          const invitedWorkers = new Set(
            shiftInvitations?.map((i) => i.workerId),
          )
          const rescindedWorkers = new Set(
            shiftInvitations
              ?.filter((i) => i.status === ShiftInvitationStatus.Rescinded)
              .map((i) => i.workerId),
          )

          const toSend: PopulatedWorker[] = []
          const toUnrescind: PopulatedWorker[] = []

          for (const worker of allWorkers) {
            if (rescindedWorkers.has(worker.id || worker.workerId)) {
              toUnrescind.push(worker)
            } else if (!invitedWorkers.has(worker.id || worker.workerId)) {
              toSend.push(worker)
            }
          }

          if (toSend.length || toUnrescind.length) {
            // TODO: this should eventually use a Modal instead
            if (toUnrescind.length) {
              const confirmationMessage =
                generateConfirmationMessage(toUnrescind)
              if (!window.confirm(confirmationMessage)) {
                return
              }
            }

            sendInvitations({
              shiftId: shift.shiftId,
              workerIds: toSend
                .concat(toUnrescind)
                .map((worker) => worker.id || worker.workerId),
              includeRescinded: true,
            })
          }
        }

        handleClose()
        break
      }

      default: {
        break
      }
    }
  }

  const DialogTitle: React.FC = () => {
    return (
      <Row alignCenter>
        <Back
          onClick={() => {
            if (currentStep === InviteFlowState.SelectRoster) {
              handleClose()
            } else {
              setCurrentStep(InviteFlowState.SelectRoster)
            }
          }}
          color={theme.colors.MidnightBlue}
          size={20}
          style={{ cursor: 'pointer' }}
        />
        <Text
          ml="xxs"
          variant="h4"
          style={{ flex: 1, marginLeft: theme.space.xxs }}
        >
          {currentStep === InviteFlowState.SelectRoster
            ? InviteFlowState.SelectRoster
            : InviteFlowState.AddWorkers}
        </Text>
      </Row>
    )
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      dialogTitle={<DialogTitle />}
      onClose={handleClose}
      onConfirm={onConfirm}
      onConfirmCTA={
        currentStep === InviteFlowState.SelectRoster
          ? 'Select workers'
          : 'Add workers'
      }
      style={{ margin: 0 }}
    >
      {isLoadingAllWorkers && <CircularProgress size={75} />}

      {/* 
        If the business doesn't have any rosters, don't render the custom 
        roster selection view and instead prompt the user to continue to 
        the next step.
      */}
      {!isLoadingAllWorkers &&
        currentStep === InviteFlowState.SelectRoster &&
        Object.keys(rosterMap).length === 0 && (
          <div style={{ minHeight: 300 }}>
            <Text variant="h5">
              This business has not created any rosters. Continue by clicking
              "Select workers" below to invite workers from the general worker
              pool.
            </Text>
          </div>
        )}

      {!isLoadingAllWorkers &&
        currentStep === InviteFlowState.SelectRoster &&
        Object.keys(rosterMap).length > 0 && (
          <SelectRostersView
            rosters={getRostersInOrder(rosterMap)}
            selectedRosters={selectedRosters}
            setSelectedRosters={setSelectedRosters}
          />
        )}

      {!isLoadingAllWorkers && currentStep === InviteFlowState.AddWorkers && (
        <SearchAddWorkersView
          rosters={getRostersInOrder(rosterMap).filter((rosterInfo) =>
            selectedRosters.has(rosterInfo.id),
          )}
          rosterMap={rosterMap}
          selectedRosterIdToSelectedWorkers={selectedRosterIdToSelectedWorkers}
          setSelectedRosterIdToSelectedWorkers={
            setSelectedRosterIdToSelectedWorkers
          }
          selectedWorkerIdsFromGeneralPool={selectedWorkerIdsFromGeneralPool}
          setSelectedWorkerIdsFromGeneralPool={
            setSelectedWorkerIdsFromGeneralPool
          }
        />
      )}
    </Dialog>
  )
}
