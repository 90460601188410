import { useAlert } from '@traba/context'
import { AcceptShiftBypasses } from '@traba/types'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { ConfirmationStatuses } from '../FieldMonitorScreen/components/AddWorkers/types'

type useAddWorkersByShiftProps = {
  refetchWorkerShifts: () => void
  setIsLoading: (isLoading: boolean) => void
}

export const useAddWorkersByShift = ({
  refetchWorkerShifts,
  setIsLoading,
}: useAddWorkersByShiftProps) => {
  const [workerShiftsWithStatus, setWorkerShiftsWithStatus] = useState<
    ConfirmationStatuses[]
  >([])
  const { showError } = useAlert()

  const getLoadingStatus = (list: ConfirmationStatuses[]) =>
    list.map((ws) => ({
      ...ws,
      status: 'loading' as ConfirmationStatuses['status'],
    }))

  /**
   * Adding workers to shifts
   */
  const handleAcceptShiftWithBypasses = async (
    workerIds: string[],
    shiftId: string,
  ) => {
    setIsLoading(true)
    const loadingState = getLoadingStatus(workerShiftsWithStatus)
    setWorkerShiftsWithStatus(loadingState)

    const bypasses: AcceptShiftBypasses = {
      allowOverbook: true,
      bypassSignupStatus: true,
      bypassFavoritesOnly: true,
      bypassFavoritesFirst: true,
      bypassUnprovenThreshold: true,
      bypassUnprovenTodoLimit: true,
      bypassExtraBGCRequirement: true,
      bypassAttributeRequirements: true,
      bypassCertifications: true,
      bypassWorkerAvailability: true,
      bypassAccountStatus: true,
      bypassMinimumAcceptedTier: true,
      bypassShiftStatus: true,
      bypassForwardFill: true,
      bypassRequiredMultiShift: true,
      bypassInvitations: true,
      bypassNoBackfill: true,
      bypassWorkerShiftIsNotBizCancelled: true,
      bypassWorkerShiftIsNotOpsCancelled: true,
    }

    try {
      const res = await trabaApi.post(
        `/worker-shifts/${shiftId}/accept-shift`,
        {
          workerIds,
          bypasses,
        },
      )

      const errorWorkers = res.data.filter((d: any) => !!d.error)
      setWorkerShiftsWithStatus((prev: ConfirmationStatuses[]) =>
        prev.map((ws) => {
          const errorFound = errorWorkers.find(
            (errorWorker: ConfirmationStatuses) =>
              errorWorker.workerId === ws.workerId && shiftId === ws.shiftId,
          )
          if (errorFound) {
            console.error(
              errorFound,
              'ERROR adding workers to shift',
              ws.shiftId,
            )
            return {
              ...ws,
              status: 'error',
              message: errorFound.message,
            }
          }
          if (workerIds.includes(ws.workerId) && shiftId === ws.shiftId) {
            return {
              ...ws,
              status: 'success',
            }
          }
          return ws
        }),
      )
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      console.error('ERROR adding workers to shifts', e)
      showError('Error adding some workers to shifts', e.message)
    }
  }

  async function handleAddWorkers(workersByShiftId: Record<string, string[]>) {
    await Promise.all(
      Object.entries(workersByShiftId).map(([shiftId, workerIds]) =>
        handleAcceptShiftWithBypasses(workerIds, shiftId),
      ),
    )
    refetchWorkerShifts()
  }

  return {
    handleAddWorkers,
    workerShiftsWithStatus,
  }
}
