import { Time } from '@internationalized/date'
import { TimeValue } from '@react-types/datepicker'
import { theme } from '@traba/theme'
import { WorkerShiftAsTimesheetRow } from '@traba/types'
import { useState, useEffect, useMemo, Dispatch } from 'react'
import {
  Button,
  Col,
  CopyTextIcon,
  DataTable,
  DatePicker,
  Icon,
  Row,
  Select,
} from 'src/components/base'
import TimeField from 'src/components/base/AriaDatePicker/TimeField'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import Toggle from 'src/components/base/Toggle'
import { JobStatus } from 'src/types'
import { parseDateString, parseTimeString } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
import { TableRow } from '../timesheet.types'
import { determineIconName, getStyle, updateParsedData } from '../utils'

const headers = [
  '',
  { label: 'Worker Name', sortable: true },
  'Shift Name',
  'Clock In Date (New)',
  'Clock In Date (Old)',
  'Clock In Time (New)',
  'Clock In Time (Old)',
  'Clock Out Date (New)',
  'Clock Out Date (Old)',
  'Clock Out Time (New)',
  'Clock Out Time (Old)',
  'Break Time (New)',
  'Break Time (Old)',
  'Pay Rate (New)',
  'Pay Rate (Old)',
  'Units Worked (New)',
  'Units Worked (Old)',
  'Min Paid Time (New)',
  'Min Paid Time (Old)',
  'Job Status (New)',
  'Job Status (Old)',
]

const JOB_STATUS_OPTIONS = [
  {
    label: 'Complete',
    value: JobStatus.Complete,
  },
  {
    label: 'No Show',
    value: JobStatus.NoShow,
  },
  {
    label: 'Abandoned',
    value: JobStatus.Abandoned,
  },
  {
    label: 'Canceled',
    value: JobStatus.Canceled,
  },
  {
    label: 'Rejected',
    value: JobStatus.Rejected,
  },
]

function getTableRows(
  tableRows: TableRow[],
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>,
) {
  return tableRows.map((row) => {
    const name = determineIconName(row)
    const clockInTime = parseTimeString(
      row.clockInTime.parsedWorkerShiftValue.toString(),
    )
    const clockOutTime = parseTimeString(
      row.clockOutTime.parsedWorkerShiftValue.toString(),
    )
    return {
      key: `${row.workerId.parsedWorkerShiftValue}_${row.shiftId.parsedWorkerShiftValue}`,
      cells: [
        {
          renderFn: () => {
            return (
              <Row justifyCenter>
                <Icon name={name} />
              </Row>
            )
          },
        },
        {
          sortKey: row.workerName.parsedWorkerShiftValue,
          renderFn: () => (
            <Row style={{ width: 180 }}>
              {row.workerName.parsedWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row style={{ width: 200 }}>
              <Col>
                {`${row.shiftName.parsedWorkerShiftValue} (${truncateString(
                  row.shiftId.originalWorkerShiftValue.toString(),
                  8,
                )})`}
                <CopyTextIcon
                  textToCopy={row.shiftId.originalWorkerShiftValue.toString()}
                />
              </Col>
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInDate), width: 150 }}
            >
              <DatePicker
                showTimeFieldInPopover={false}
                setDate={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockInDate', val),
                  )
                }
                width={150}
                date={parseDateString(
                  row.clockInDate.parsedWorkerShiftValue.toString(),
                )}
                defaultTime={new Time(0, 0)}
                granularity="day"
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInDate, true), width: 80 }}
            >
              {row.clockInDate.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInTime), width: 120 }}
            >
              <TimeField
                value={
                  clockInTime
                    ? new Time(clockInTime.getHours(), clockInTime.getMinutes())
                    : null
                }
                onChange={(val: TimeValue) => {
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockInTime', val),
                  )
                }}
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockInTime, true), width: 80 }}
            >
              {row.clockInTime.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutDate), width: 150 }}
            >
              <DatePicker
                showTimeFieldInPopover={false}
                setDate={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockOutDate', val),
                  )
                }
                width={150}
                date={parseDateString(
                  row.clockOutDate.parsedWorkerShiftValue.toString(),
                )}
                defaultTime={new Time(0, 0)}
                granularity="day"
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutDate, true), width: 80 }}
            >
              {row.clockOutDate.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutTime), width: 120 }}
            >
              <TimeField
                value={
                  clockOutTime
                    ? new Time(
                        clockOutTime.getHours(),
                        clockOutTime.getMinutes(),
                      )
                    : null
                }
                onChange={(val: TimeValue) => {
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'clockOutTime', val),
                  )
                }}
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.clockOutTime, true), width: 80 }}
            >
              {row.clockOutTime.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.breakTime)}>
              <NumberInput
                width="80px"
                value={Number(row.breakTime.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'breakTime', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.breakTime, true)}>
              {row.breakTime.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.payRate)}>
              <NumberInput
                width="80px"
                isMoney
                value={Number(row.payRate.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'payRate', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.payRate, true)}>
              {row.payRate.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.unitsWorked)}>
              <NumberInput
                width="80px"
                value={Number(row.unitsWorked.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'unitsWorked', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.unitsWorked, true)}>
              {row.unitsWorked.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.minimumPaidTime)}>
              <NumberInput
                width="80px"
                value={Number(row.minimumPaidTime.parsedWorkerShiftValue)}
                setValue={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'minimumPaidTime', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row justifyCenter style={getStyle(row.minimumPaidTime, true)}>
              {row.minimumPaidTime.originalWorkerShiftValue}
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row
              justifyCenter
              style={{ ...getStyle(row.jobStatus), width: 150 }}
            >
              <Select
                value={row.jobStatus.parsedWorkerShiftValue.toString()}
                menuItems={JOB_STATUS_OPTIONS}
                handleSelect={(val) =>
                  setParsedData((prev) =>
                    updateParsedData(prev, row, 'jobStatus', val),
                  )
                }
              />
            </Row>
          ),
        },
        {
          renderFn: () => (
            <Row style={{ ...getStyle(row.jobStatus, true), width: 150 }}>
              {row.jobStatus.originalWorkerShiftValue}
            </Row>
          ),
        },
      ],
    }
  })
}

export default function TimesheetAdjustmentsTable({
  allTableRows,
  diffedTableRows,
  openModal,
  setParsedData,
}: {
  allTableRows: TableRow[]
  diffedTableRows: TableRow[]
  openModal: () => void
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
}) {
  const [tableRows, setTableRows] = useState<TableRow[]>([])
  const [showOnlyDiscrepancies, setShowOnlyDiscrepancies] =
    useState<boolean>(false)

  const toggleDiscrepancies = () => {
    if (showOnlyDiscrepancies) {
      setTableRows(allTableRows)
      setShowOnlyDiscrepancies(false)
    } else {
      setTableRows(diffedTableRows)
      setShowOnlyDiscrepancies(true)
    }
  }

  useEffect(() => {
    if (allTableRows.length || diffedTableRows.length) {
      setTableRows(showOnlyDiscrepancies ? diffedTableRows : allTableRows)
    }
  }, [showOnlyDiscrepancies, allTableRows, diffedTableRows])

  const rows = useMemo(
    () => getTableRows(tableRows, setParsedData),
    [tableRows, setParsedData],
  )

  return (
    <Col>
      <Row alignCenter justifyBetween my={theme.space.xs}>
        <Toggle
          label="Show only rows with discrepancies"
          buttonState={showOnlyDiscrepancies}
          runOnChange={toggleDiscrepancies}
        />
        <Button disabled={!diffedTableRows.length} onClick={openModal}>
          Continue
        </Button>
      </Row>
      <DataTable headers={headers} rows={rows} />
    </Col>
  )
}
