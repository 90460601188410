import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { JobStatus } from '@traba/types'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Button, Col, Icon, Modal, Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { getErrorMessage } from 'src/utils/errorUtils'
import { useAnalytics } from '../../hooks/useAnalytics'

type AbandonModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
  sentinelNotificationToUserId?: string
}

export default function AbandonModal({
  isOpen,
  handleClose,
  workerShift,
  sentinelNotificationToUserId,
}: AbandonModalProps) {
  const [clockOutTime, setClockOutTime] = useState<Date>(
    workerShift.shiftInfo.endTime,
  )
  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()

  const [loading, setLoading] = useState<boolean>(false)
  const { trackAnalytics } = useAnalytics()

  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const handleAbandonShift = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}`,
        {
          clockOutTime: clockOutTime,
          jobStatus: JobStatus.Abandoned,
          sentinelNotificationToUserId,
        },
      )
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken Abandon', {
          sentinelNotificationToUserId,
          workerId: workerShift.workerId,
          shiftId: workerShift.shiftId,
        })
      }
      showSuccess(
        `Successfully marked worker ${workerShift.workerId} as abandonded.`,
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Abandoning Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_SMALL}
    >
      <Row flexCol fullWidth fullHeight justifyBetween>
        <div>
          <Row justifyBetween alignCenter style={{ marginBottom: '16px' }}>
            <Text variant="h4">Abandon Worker Shift</Text>
            <Button variant={ButtonVariant.TRANSPARENT} onClick={handleClose}>
              <Icon name="cancel" />
            </Button>
          </Row>
          <Divider />
          <Row flexCol style={{ marginTop: '32px' }}>
            <Text variant="h5">Clock Out Time</Text>
            <Row style={{ marginTop: '24px' }}>
              <Col>
                <Text style={{ marginBottom: '32px' }} variant="caption">
                  SHIFT LOCAL TIME
                </Text>
                <div style={{ width: '50%' }}>
                  <DatePicker
                    showTimeFieldInPopover={true}
                    setDate={(clockOutTime) => {
                      if (clockOutTime) {
                        setClockOutTime(clockOutTime)
                      }
                    }}
                    isClearable={false}
                    inlineLabel={true}
                    label="Time"
                    date={clockOutTime}
                    timezone={workerShift.shiftInfo.timezone}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '32px' }}>
              <Col>
                <Text variant="caption">SHIFT LOCAL TIME</Text>
                <Text style={{ marginTop: '16px' }} variant="body1">
                  {`${tz.getDate(clockOutTime, false)}, ${tz.getTime(
                    clockOutTime,
                  )}`}
                </Text>
              </Col>
            </Row>
          </Row>
        </div>
        <Row fullWidth justifyBetween>
          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ width: '200px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '200px' }}
            onClick={handleAbandonShift}
            loading={loading}
          >
            Submit
          </Button>
        </Row>
      </Row>
    </Modal>
  )
}
