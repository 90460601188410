import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InstantPayEligibilityStatus } from '@traba/types'
import { useState } from 'react'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import Divider from 'src/components/base/Divider'
import { formatDuration } from 'src/utils/dateUtils'
import { TimesheetAdjustment } from '../timesheet.types'

const WORKER_ELIGIBLE_FOR_INSTANT_PAY = new Set([
  InstantPayEligibilityStatus.ELIGIBLE,
  InstantPayEligibilityStatus.ELIGIBLE_WITH_WARNING,
])

function workerCanGetInstantPay(
  workerShiftAdjustments: TimesheetAdjustment[],
): boolean {
  return workerShiftAdjustments.some(
    (ws) =>
      ws.accountStatus?.payment?.instantPayEnabled &&
      ws.accountStatus?.payment?.instantPayEligibility.status &&
      WORKER_ELIGIBLE_FOR_INSTANT_PAY.has(
        ws.accountStatus.payment.instantPayEligibility.status,
      ),
  )
}

type AdjustmentFormProps = {
  handleClose: () => void
  workerShiftAdjustments: TimesheetAdjustment[]
  unitsPerWorker?: number
  prevTotalPay: number
  prevMinutesWorked: number
  newTotalPay: number
  newMinutesWorked: number
  handleAdjustments: (params: {
    shouldAdjustPayment: boolean
    shouldInstantPay: boolean
    workerShiftAdjustments: TimesheetAdjustment[]
  }) => Promise<void>
}

export function TimesheetAdjustmentForm({
  handleClose,
  workerShiftAdjustments,
  handleAdjustments,
  prevMinutesWorked,
  prevTotalPay,
  newMinutesWorked,
  newTotalPay,
}: AdjustmentFormProps) {
  /*
  States
  */
  const [shouldAdjustPayment, setShouldAdjustPayment] = useState<boolean>(false)
  const [shouldInstantPay, setShouldInstantPay] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const onConfirm = async () => {
    setLoading(true)
    await handleAdjustments({
      shouldAdjustPayment,
      shouldInstantPay,
      workerShiftAdjustments,
    })
    setLoading(false)
  }

  return (
    <Row flexCol fullWidth fullHeight justifyBetween>
      <>
        <Row alignCenter justifyBetween>
          <Col mb={theme.space.med}>
            <Text variant="h5">Total Hours:</Text>
            <Text variant="body2">
              Summed across all shifts in uploaded timesheet
            </Text>
          </Col>

          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h5" mb={theme.space.med}>
              {formatDuration(newMinutesWorked)}
            </Text>
            <Text
              variant="h5"
              mb={theme.space.med}
              style={{ textDecoration: 'line-through' }}
            >
              {formatDuration(prevMinutesWorked)}
            </Text>
          </Row>
        </Row>
        <Row alignCenter justifyBetween>
          <Col mb={theme.space.med}>
            <Text variant="h5">Total Pay:</Text>
            <Text variant="body2">
              Summed across all shifts in uploaded timesheet
            </Text>
          </Col>
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h5" mb={theme.space.med}>
              ${newTotalPay.toFixed(2)}
            </Text>
            <Text
              variant="h5"
              mb={theme.space.med}
              style={{ textDecoration: 'line-through' }}
            >
              ${prevTotalPay.toFixed(2)}
            </Text>
          </Row>
        </Row>
      </>
      <Divider />
      <Row
        justifyBetween
        style={{
          padding: '36px',
        }}
      >
        <Checkbox
          checked={shouldAdjustPayment}
          label={'Should Adjust Payment'}
          onChange={(e) => {
            setShouldAdjustPayment(e.target.checked)
          }}
        />
        {shouldAdjustPayment &&
          workerCanGetInstantPay(workerShiftAdjustments) && (
            <Checkbox
              checked={shouldInstantPay}
              label={'Should Instant Pay'}
              onChange={(e) => {
                setShouldInstantPay(e.target.checked)
              }}
            />
          )}
      </Row>
      <Divider />
      <Row fullWidth mt={theme.space.med} justifyBetween>
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ width: '200px' }}
          onClick={handleClose}
        >
          Back
        </Button>
        <Button
          style={{ width: '200px' }}
          onClick={onConfirm}
          loading={loading}
        >
          Submit Adjustment
        </Button>
      </Row>
    </Row>
  )
}
