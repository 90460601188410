import { Billing, BillingUpdate } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export async function getBilling(
  companyId: string,
): Promise<Billing | undefined> {
  try {
    const res = await trabaApi.get(`companies/${companyId}/billing`)
    return res.data
  } catch (error: any) {
    console.error('getBilling ERROR', error.message ?? error)
  }
}

export function useBilling(companyId: string) {
  const {
    isLoading,
    isError,
    data: billing,
    error,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<Billing | undefined, Error>(`billing_${companyId}`, () =>
    getBilling(companyId),
  )

  const patchBillingMutation = useMutation<
    Billing,
    AxiosError,
    BillingUpdate,
    Billing
  >(
    (updatedBilling) =>
      trabaApi.patch(`companies/${companyId}/billing`, updatedBilling),
    {
      onSuccess: async () => {
        await refetch()
      },
    },
  )

  return {
    isLoading,
    isError,
    billing,
    error,
    isFetched,
    refetch,
    isFetching,
    patchBilling: patchBillingMutation.mutateAsync,
  }
}
