import { CircularProgress } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import { PaidBackup } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import {
  Button,
  CopyTextIcon,
  DataTable,
  Icon,
  Link,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Dropdown } from 'src/components/base/Dropdown'
import { TableCell } from 'src/components/base/Table/DataTable'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { PaidBackupAction } from 'src/hooks/usePaidBackups'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import PaidBackupStatusBadge from 'src/screens/ShiftDetailsScreen/components/PaidBackupsTable/PaidBackupStatusBadge'
import { toDate } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { convertCentsToDollars } from 'src/utils/moneyUtils'
import { truncateString } from 'src/utils/stringUtils'

interface PaidBackupsTabProps {
  paidBackups: PaidBackup[]
  isLoadingPaidBackups: boolean
  refetchPaidBackups: () => void
}

function ShiftDateCell({ paidBackup }: { paidBackup: PaidBackup }) {
  const { timezone, startTime } = paidBackup.shiftInfo
  const tz = useTimezonedDates(timezone)
  const timezonedStartDate = tz.getDate(startTime, false)
  return timezonedStartDate
}

function ShiftStartTimeCell({ paidBackup }: { paidBackup: PaidBackup }) {
  const { timezone, startTime } = paidBackup.shiftInfo
  const tz = useTimezonedDates(timezone)
  const timezonedStartTime = tz.getTime(startTime)
  return timezonedStartTime
}

const updatePaidBackupByWorkerIdShiftId = async (
  { workerId, shiftId }: { workerId: string; shiftId: string },
  action: PaidBackupAction,
): Promise<PaidBackup | undefined> => {
  try {
    const { data } = await trabaApi.patch(`paid-backup/${action}`, {
      shiftId,
      workerId,
    })
    return data
  } catch (error: any) {
    console.error('updatePaidBackupByShiftId() ERROR', error.message ?? error)
    Sentry.captureException(error)
    throw error
  }
}

const paidBackupsTableHeaders = [
  'Action',
  { label: 'Shift Name', key: 'shiftName' },
  { label: 'Shift ID', key: 'shiftId' },
  { label: 'Company', key: 'company', sortable: true },
  { label: 'Date', key: 'date', sortable: true },
  { label: 'Time', key: 'time' },
  { label: 'Status', key: 'status', sortable: true },
  { label: 'Incentive Amount', key: 'incentiveAmount' },
]

function getPaidBackupsTableRow(
  paidBackup: PaidBackup,
  showSuccess: (message: string, title?: string, durationInMs?: number) => void,
  showError: (message: string, title?: string, durationInMs?: number) => void,
  refetchPaidBackups: () => void,
) {
  const handleClick = async (
    paidBackup: PaidBackup,
    action: PaidBackupAction,
  ) => {
    try {
      await updatePaidBackupByWorkerIdShiftId(paidBackup, action)
      showSuccess('Paid Backup Updated', 'Success')
    } catch (err) {
      showError(getErrorMessage(err), 'Update Paid Backup Failed')
    } finally {
      refetchPaidBackups()
    }
  }
  const paidBackupActions = [
    {
      label: 'Add to Shift',
      handleClick: () => handleClick(paidBackup, PaidBackupAction.ACCEPT_SHIFT),
      icon: () => <Icon name="checkmark" />,
    },
    {
      label: 'Dismiss and Pay',
      handleClick: () => handleClick(paidBackup, PaidBackupAction.DISMISS),
      icon: () => <Icon name="cancel" />,
    },
    {
      label: 'Cancel',
      handleClick: () => handleClick(paidBackup, PaidBackupAction.CANCEL),
      icon: () => <Icon name="close" />,
    },
  ]
  const cells: TableCell[] = [
    {
      renderFn: () => {
        return (
          <Dropdown orientation="right" dropdownOptions={paidBackupActions} />
        )
      },
    },
    {
      renderFn: () => {
        return (
          <Link to={`/field-monitor/${paidBackup.shiftId}`}>
            <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
              {paidBackup.shiftInfo.role?.roleName}{' '}
              {paidBackup.shiftInfo.shortLocation
                ? ` @ ${paidBackup.shiftInfo.shortLocation}`
                : ''}
            </Button>
          </Link>
        )
      },
    },
    {
      renderFn: () => {
        return (
          <Row style={{ width: 80 }} alignCenter>
            {truncateString(paidBackup.shiftId ?? '')}
            <CopyTextIcon textToCopy={paidBackup.shiftId} />
          </Row>
        )
      },
    },
    {
      sortKey: paidBackup.shiftInfo.employerName,
      renderFn: () => {
        return paidBackup.shiftInfo.employerName
      },
    },
    {
      sortKey: toDate(paidBackup.shiftInfo.startTime).toISOString(),
      renderFn: () => <ShiftDateCell paidBackup={paidBackup} />,
    },
    {
      renderFn: () => <ShiftStartTimeCell paidBackup={paidBackup} />,
    },
    {
      sortKey: paidBackup.status,
      renderFn: () => {
        return (
          <PaidBackupStatusBadge
            status={paidBackup.status}
            source={paidBackup.source}
            cancellationReason={paidBackup.cancellationReason}
          />
        )
      },
    },
    {
      renderFn: () => {
        return convertCentsToDollars(
          paidBackup.shiftInfo.paidBackupPayAmount ?? 0,
        )
      },
    },
  ]
  return {
    cells,
    key: paidBackup.shiftId,
  }
}

export const PaidBackupsTab = ({
  paidBackups,
  isLoadingPaidBackups,
  refetchPaidBackups,
}: PaidBackupsTabProps) => {
  const { refetchLoading, refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()

  const paidBackupsTableRows = paidBackups.map((pb) =>
    getPaidBackupsTableRow(pb, showSuccess, showError, refetchPaidBackups),
  )

  return (
    <>
      <Row mb={theme.space.sm} justifyBetween alignCenter mt={theme.space.xxs}>
        <Button
          slim
          leftIcon={<Icon name="refresh" />}
          onClick={async () => await refetchActiveQueries()}
          variant={ButtonVariant.OUTLINED}
          loading={refetchLoading}
          mr={theme.space.xxs}
        >
          Refresh
        </Button>
      </Row>
      {isLoadingPaidBackups || !paidBackups ? (
        <CircularProgress
          size={36}
          sx={{
            left: '50%',
          }}
        />
      ) : (
        <DataTable
          initialSortByColumnIndex={4}
          initialSortByAscOrder={false}
          headers={paidBackupsTableHeaders}
          rows={paidBackupsTableRows}
        />
      )}
    </>
  )
}
