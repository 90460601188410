import { assetNameType } from '../SvgIconProps'
import accountSettings from './account-settings.svg'
import accountSettings_active from './account-settings_active.svg'
import add_inverse from './add-inverse.svg'
import add from './add.svg'
import aiPlatform_active from './ai-platform-active.svg'
import aiPlatform from './ai-platform-gray.svg'
import ai_platform from './ai-platform.svg'
import alert from './alert.svg'
import amex from './amex.svg'
import approve_hours from './approve-hours.svg'
import approve_hours_alt from './approve-hours_alt.svg'
import arrowBottom_primary from './arrow-bottom-primary.svg'
import arrowTop_primary from './arrow-top-primary.svg'
import avatar from './avatar.svg'
import backPack from './backPack.svg'
import bell from './bell.svg'
import billing from './billing.svg'
import billing_active from './billing_active.svg'
import billing_small_disabled from './billing_small_disabled.svg'
import billing_small_primary from './billing_small_primary.svg'
import blocked from './blocked.svg'
import blocked_active from './blocked_active.svg'
import Bugle from './Bugle'
import calendar from './calendar.svg'
import calendar_active from './calendar_active.svg'
import calendarv2 from './calendarv2.svg'
import Cancel from './Cancel'
import cancel from './cancel.svg'
import cardPurple from './card-purple.svg'
import card from './card.svg'
import caution from './caution.svg'
import { Checkmark } from './Checkmark'
import checkmark from './checkmark.svg'
import chevronDown from './chevron-down.svg'
import chevronLeft from './chevron-left.svg'
import chevronLeft_active from './chevron-left_active.svg'
import chevronRight from './chevron-right.svg'
import chevronRight_active from './chevron-right_active.svg'
import chevronUp from './chevron-up.svg'
import { ChevronDown } from './ChevronDown'
import { ChevronUp } from './ChevronUp'
import { default as clock, default as clock_active } from './clock.svg'
import close from './close.svg'
import close_filled from './close_filled.svg'
import company from './company.svg'
import company_active from './company_active.svg'
import { Copy } from './Copy'
import dashboard from './dashboard.svg'
import dashboard_active from './dashboard_active.svg'
import document from './document.svg'
import { DocumentFolded } from './DocumentFolded'
import { Dollar } from './Dollar'
import dollarRound from './dollar-round.svg'
import dollarRound_active from './dollar-round_active.svg'
import download from './download.svg'
import { Edit } from './Edit'
import edit_active from './edit-purple.svg'
import edit from './edit.svg'
import elipses from './elipses.svg'
import elipses_active from './elipses_active.svg'
import { EllipsesCircle } from './EllipsesCircle'
import email_small from './email_small.svg'
import error from './error.svg'
import eyeSlash from './eye-slash.svg'
import Filter from './Filter'
import es from './flags/es.svg'
import uk from './flags/uk.svg'
import folder from './folder.svg'
import greenCheck from './green-check.svg'
import heart from './heart.svg'
import heart_active from './heart_active.svg'
import incentives from './incentives.svg'
import incentives_active from './incentives_active.svg'
import { Info } from './Info'
import info from './info.svg'
import Left from './Left'
import leftArrow from './left-arrow.svg'
import link from './link.svg'
import { Location } from './Location'
import location from './location.svg'
import logout from './logout.svg'
import mastercard from './mastercard.svg'
import message from './message.svg'
import minus from './minus.svg'
import monitor from './monitor.svg'
import monitor_active from './monitor_active.svg'
import { More } from './More'
import noteAdd from './note-add.svg'
import noteAdd_active from './note-add_active.svg'
import Openphone from './Openphone'
import OpenphoneCall from './OpenphoneCall'
import parking from './parking.svg'
import Phone from './Phone'
import phone from './phone.svg'
import plus from './plus.svg'
import plus_active from './plus_active.svg'
import refresh from './refresh.svg'
import Right from './Right'
import search from './search.svg'
import search_active from './search_active.svg'
import send from './send.svg'
import settings from './settings.svg'
import settings_active from './settings_active.svg'
import SideNav from './SideNav'
import star from './star.svg'
import star_active from './star_active.svg'
import subtract_inverse from './subtract-inverse.svg'
import subtract from './subtract.svg'
import SystemMessage from './SystemMessage'
import { ThumbsDown } from './ThumbsDown'
import { ThumbsUp } from './ThumbsUp'
import timesheet from './timesheet.svg'
import timesheet_active from './timesheet_active.svg'
import { Traba } from './Traba'
import { Trash } from './Trash'
import trash from './trash.svg'
import two_user from './two-user.svg'
import undo from './undo.svg'
import userProfile from './user-profile.svg'
import userProfile_active from './user-profile_active.svg'
import visa from './visa.svg'
import { VolumeSlash } from './VolumeSlash'
import workers from './workers.svg'
import workers_active from './workers_active.svg'

export type SidebarIconName =
  | 'dashboard'
  | 'calendar'
  | 'company'
  | 'workers'
  | 'timesheet'
  | 'billing'
  | 'accountSettings'
  | 'logout'
  | 'monitor'
  | 'clock'
  | 'monitor'
  | 'edit'
  | 'noteAdd'
  | 'search'
  | 'settings'
  | 'plus'
  | 'incentives'
  | 'aiPlatform'

const iconAssets: Partial<Record<assetNameType, any>> = {
  arrowBottom_primary,
  arrowTop_primary,
  bell,
  checkmark,
  chevronDown,
  chevronUp,
  chevronLeft,
  chevronRight,
  chevronLeft_active,
  chevronRight_active,
  company,
  company_active,
  es,
  eyeSlash,
  eyeSlash_completed: eyeSlash,
  eyeSlash_active: eyeSlash,
  info,
  leftArrow,
  logout,
  search,
  settings,
  uk,
  userProfile,
  userProfile_active,
  leftArrow_completed: leftArrow,
  backPack,
  error,
  dashboard,
  dashboard_active,
  calendar,
  calendarv2,
  calendar_active,
  alert,
  workers,
  workers_active,
  timesheet,
  timesheet_active,
  billing,
  billing_active,
  accountSettings,
  accountSettings_active,
  clock,
  clock_active,
  edit,
  edit_active,
  trash,
  approve_hours,
  phone,
  cancel,
  message,
  approve_hours_alt,
  amex,
  mastercard,
  visa,
  card,
  cardPurple,
  send,
  two_user,
  billing_small_primary,
  billing_small_disabled,
  document,
  add,
  add_inverse,
  subtract,
  subtract_inverse,
  folder,
  location,
  email_small,
  star,
  star_active,
  undo,
  blocked,
  close,
  close_filled,
  blocked_active,
  heart,
  heart_active,
  monitor,
  monitor_active,
  greenCheck,
  refresh,
  elipses,
  elipses_active,
  plus,
  plus_active,
  minus,
  download,
  link,
  parking,
  noteAdd,
  noteAdd_active,
  search_active,
  settings_active,
  dollarRound,
  dollarRound_active,
  caution,
  ai_platform,
  aiPlatform,
  aiPlatform_active,
  avatar,
  incentives,
  incentives_active,
}

export const iconSvgAssets: Partial<Record<assetNameType, any>> = {
  info: Info,
  trash: Trash,
  copy: Copy,
  cancel: Cancel,
  filter: Filter,
  sideNav: SideNav,
  left: Left,
  right: Right,
  thumbsDown: ThumbsDown,
  thumbsUp: ThumbsUp,
  more: More,
  location: Location,
  phone: Phone,
  dollar: Dollar,
  edit: Edit,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  documentFolded: DocumentFolded,
  checkmark: Checkmark,
  volumeSlash: VolumeSlash,
  ellipsesCircle: EllipsesCircle,
  traba: Traba,
  openphoneCall: OpenphoneCall,
  openphone: Openphone,
  bugle: Bugle,
  systemMessage: SystemMessage,
}

export { SideNav, type assetNameType }
export default iconAssets
