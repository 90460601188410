import { Badge } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { TabsContainer, Tabs, Tab } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom'
import { Row } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompanies } from 'src/hooks/useCompanies'
import { usePendingAdjustments } from 'src/hooks/usePendingAdjustments'
import { usePendingClockOuts } from 'src/hooks/usePendingClockOuts'
import { useRegions } from 'src/hooks/useRegions'
import { FilterProps } from './components/FiltersSection'
import PendingAdjustments from './components/PendingAdjustments/PendingAdjustments'
import { PendingClockOuts } from './components/PendingClockOuts'
import { ClearingHouse } from './screens/ClearingHouse'

const DEFAULT_PAYMENT_STATUSES = [
  PaymentStatus.Overpaid,
  PaymentStatus.Underpaid,
  PaymentStatus.PayoutFailed,
]

export default function ActionsCenterScreen() {
  const navigate = useNavigate()
  const location = useLocation()
  const { hotSettings } = useHotSettings()

  const [currentTab, setCurrentTab] = useState(0)
  const { companies = [], isLoading: companiesLoading } = useCompanies({
    isApproved: true,
  })
  const { regions = [], isLoading: regionsLoading } = useRegions()
  const { pendingAdjustments } = usePendingAdjustments()
  const { pendingClockOuts } = usePendingClockOuts({})

  const [search, setSearch] = useSearchParams()

  const before = search.get('before')
  const after = search.get('after')
  const companyIds = search.getAll('companyIds')
  const regionIds = search.getAll('regionIds')
  const workerId = search.get('workerId') ?? ''
  const shiftId = search.get('shiftId') ?? ''
  const excludeCompanies = search.get('excludeCompanies') === 'true'
  const paymentStatuses = search.getAll('paymentStatuses')
  const wardenHalted = search.get('wardenHalted') === 'true'
  const workerEditedOnly = search.get('workerEditedOnly') === 'true'

  const paymentStatusesArray = Object.values(PaymentStatus) as string[]

  const currentParams = {
    ...(before && { before }),
    ...(after && { after }),
    companyIds,
    regionIds,
    ...(workerId && { workerId }),
    ...(shiftId && { shiftId }),
    paymentStatuses,
    excludeCompanies: excludeCompanies ? 'true' : 'false',
    wardenHalted: wardenHalted ? 'true' : 'false',
    workerEditedOnly: workerEditedOnly ? 'true' : 'false',
  }

  // Set payment statuses to defaults if first time loading the page and there are no statuses
  useEffect(() => {
    if (paymentStatuses.length === 0) {
      setSearch({ ...currentParams, paymentStatuses: DEFAULT_PAYMENT_STATUSES })
    }
  }, [])

  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })

  const dateRange: [Date | null, Date | null] = [
    after && after !== '' ? new Date(after) : null,
    before && before !== '' ? new Date(before) : null,
  ]

  const companyOptions: IMenuItem[] = companies.map((company) => {
    return { label: company.employerName, value: company.id }
  })

  const paymentStatusesMenuOptions: IMenuItem[] = paymentStatusesArray.map(
    (ps) => {
      return { label: ps, value: ps }
    },
  )

  const selectedCompanyIds = companyIds
    .map((companyId) => companyOptions.find((o) => o.value === companyId))
    .filter((r) => r !== undefined) as IMenuItem[]

  const selectedPaymentStatuses = paymentStatuses
    .map((ps) => paymentStatusesMenuOptions.find((o) => o.value === ps))
    .filter((r) => r !== undefined) as IMenuItem[]

  const setCompanyIds = (companyIds: IMenuItem[]) => {
    const ids = companyIds.map((c) => c.value)
    setSearch({ ...currentParams, companyIds: ids })
  }
  const setRegionIds = (regionIds: string[]) => {
    setSearch({ ...currentParams, regionIds })
  }

  const setPaymentStatuses = (paymentStatuses: IMenuItem[]) => {
    const ids = paymentStatuses.map((c) => c.value)
    setSearch({ ...currentParams, paymentStatuses: ids })
  }

  const setDateRange = (dateRange: [Date | null, Date | null]) => {
    setSearch({
      ...currentParams,
      after: dateRange[0]?.toISOString() ?? '',
      before: dateRange[1]?.toISOString() ?? '',
    })
  }

  const filterProps: FilterProps = {
    shiftId,
    setShiftId: (shiftId) => setSearch({ ...currentParams, shiftId }),
    workerId,
    setWorkerId: (workerId) => setSearch({ ...currentParams, workerId }),
    dateRange,
    setDateRange,
    companyIds: selectedCompanyIds,
    setCompanyIds,
    regionIds,
    setRegionIds,
    excludeCompanies,
    setExcludeCompanies: (excludeCompanies) =>
      setSearch({
        ...currentParams,
        excludeCompanies: excludeCompanies ? 'true' : 'false',
      }),
    wardenHalted,
    setWardenHalted: (wardenHalted) =>
      setSearch({
        ...currentParams,
        wardenHalted: wardenHalted ? 'true' : 'false',
      }),
    workerEditedOnly,
    setWorkerEditedOnly: (workerEditedOnly) =>
      setSearch({
        ...currentParams,
        workerEditedOnly: workerEditedOnly ? 'true' : 'false',
      }),
    companyOptions,
    regionsOptions,
    paymentStatuses: selectedPaymentStatuses,
    setPaymentStatuses,
    paymentStatusOptions: paymentStatusesMenuOptions,
  }

  enum TabIndex {
    CLEARING_HOUSE = 0,
    PENDING_ADJUSTMENTS = 1,
    PENDING_CLOCK_OUTS = 2,
  }

  const navWithParams = (path: string) => {
    navigate({ pathname: path, search: search.toString() })
  }

  const TABS = [
    {
      key: 'clearing-house',
      label: 'Clearing House',
      navigateTo: () => navWithParams('clearing-house'),
    },
    {
      key: 'pending-adjustments',
      label: 'Pending Adjustments',
      navigateTo: () => navWithParams('pending-adjustments'),
    },
  ]

  if (hotSettings?.enableWorkerEditTime) {
    TABS.push({
      key: 'pending-clock-outs',
      label: 'Pending clock-outs',
      navigateTo: () => navWithParams('pending-clock-outs'),
    })
  }

  function getBadgeStyles(index: number) {
    return {
      '.MuiBadge-badge': {
        fontFamily: 'Poppins',
        width: 'auto',
        height: '30px',
        minWidth: '30px',
        top: '50%',
        right: '-28px',
        transform: 'scale(1) translate(50%, -50%)',
        borderRadius: '12px',
        fontSize: '0.75rem',
        padding: '0 6px',
        backgroundColor:
          index === currentTab ? theme.colors.Violet10 : theme.colors.Grey10,
        color: index === currentTab ? theme.colors.Violet : theme.colors.Grey60,
        '& .MuiBadge-anchorOriginTopRightRectangular': {
          transform: 'scale(1) translate(50%, -50%)',
        },
      },
    }
  }

  function getTabLabel(index: number, tabLabel: string) {
    const labelComponent = (
      <Text
        variant="h5"
        color={
          currentTab === index ? theme.colors.MidnightBlue : theme.colors.Grey60
        }
      >
        {tabLabel}
      </Text>
    )

    switch (index) {
      case TabIndex.PENDING_ADJUSTMENTS:
        return (
          <Badge
            max={99}
            badgeContent={pendingAdjustments?.length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ marginRight: theme.space.xl }}
            sx={getBadgeStyles(index)}
          >
            {labelComponent}
          </Badge>
        )
      case TabIndex.PENDING_CLOCK_OUTS:
        return (
          <Badge
            max={99}
            badgeContent={pendingClockOuts?.length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ marginRight: theme.space.xl }}
            sx={getBadgeStyles(index)}
          >
            {labelComponent}
          </Badge>
        )
      case TabIndex.CLEARING_HOUSE:
      default:
        return labelComponent
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case '/action-center/pending-adjustments':
        setCurrentTab(TabIndex.PENDING_ADJUSTMENTS)
        break
      case '/action-center/pending-clock-outs':
        if (hotSettings?.enableWorkerEditTime) {
          setCurrentTab(TabIndex.PENDING_CLOCK_OUTS)
        } else {
          setCurrentTab(TabIndex.CLEARING_HOUSE)
        }
        break
      case '/action-center/clearing-house':
      default:
        setCurrentTab(TabIndex.CLEARING_HOUSE)
        break
    }
  }, [location.pathname, hotSettings?.enableWorkerEditTime])

  return (
    <MainLayout title="Action Center">
      {companiesLoading || regionsLoading ? (
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={theme.space.xxl} />
        </Row>
      ) : (
        <>
          <Row justifyBetween>
            <Text variant="h3">Action Center</Text>
          </Row>
          <TabsContainer>
            <Tabs
              variant="scrollable"
              value={currentTab}
              onChange={(_, v) => setCurrentTab(v)}
            >
              {TABS.map((tab, index) => (
                <Tab
                  key={tab.key}
                  onClick={tab.navigateTo}
                  label={getTabLabel(index, tab.label)}
                />
              ))}
            </Tabs>
          </TabsContainer>
        </>
      )}
      <Routes>
        <Route
          path="clearing-house"
          element={<ClearingHouse filterProps={filterProps} />}
        />
        <Route path="pending-adjustments" element={<PendingAdjustments />} />
        <Route
          path="pending-clock-outs"
          element={<PendingClockOuts filterProps={filterProps} />}
        />
        <Route path="*" element={<Navigate to="clearing-house" />} />
      </Routes>
    </MainLayout>
  )
}
