import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { WorkerProfile, OpsUpdateWorkerProfileItem } from '@traba/types'
import { AxiosError } from 'axios'
import { trabaApi } from 'src/api/helpers'

const WORKER_PROFILE_QUERY_KEY = 'workerProfileQueryKey'

interface UpdateWorkerProfileOpsLevelParams {
  workerId: string
  items: OpsUpdateWorkerProfileItem[]
}

async function getWorkerProfile(
  workerId: string,
  includeAttributeFeedback?: boolean,
): Promise<WorkerProfile[] | undefined> {
  const res = await trabaApi.get(
    includeAttributeFeedback
      ? `workers/${workerId}/profile?includeAttributeFeedback=true`
      : `workers/${workerId}/profile`,
  )
  return res.data
}

async function updateWorkerProfileOpsLevel(
  params: UpdateWorkerProfileOpsLevelParams,
) {
  const { workerId, items } = params
  const response = await trabaApi.post(`workers/${workerId}/profile/`, {
    items,
    bypassRestrictions: true,
  })
  return response.data
}

export function useWorkerProfile(
  workerId: string,
  includeAttributeFeedback?: boolean,
) {
  const {
    isLoading,
    isFetching,
    isError,
    data: workerProfileAttributes,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerProfile[] | undefined, Error>({
    queryKey: [WORKER_PROFILE_QUERY_KEY, workerId],
    queryFn: () => getWorkerProfile(workerId, includeAttributeFeedback),
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: 'always',
  })

  return {
    isLoading,
    isFetching,
    isError,
    workerProfileAttributes,
    error,
    isFetched,
    refetch,
  }
}

export type CreateOrUpdateWorkerProfilePayload = Pick<
  WorkerProfile,
  'category' | 'profileField' | 'status' | 'value'
>

export async function createOrUpdateWorkerProfiles(
  workerId: string,
  workerProfiles: CreateOrUpdateWorkerProfilePayload[],
): Promise<WorkerProfile[] | undefined> {
  const res = await trabaApi.post(`workers/${workerId}/profile`, {
    items: workerProfiles,
    bypassRestrictions: true,
  })
  return res.data
}

export async function deleteWorkerProfile(
  workerId: string,
  profileField: string,
): Promise<void> {
  const res = await trabaApi.delete(
    `workers/${workerId}/profile/${profileField}?bypassRestrictions=true`,
  )
  return res.data
}

export function useWorkerProfileMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const updateWorkerProfileOpsLevelMutation = useMutation<
    WorkerProfile[] | undefined,
    AxiosError,
    UpdateWorkerProfileOpsLevelParams
  >({
    mutationFn: updateWorkerProfileOpsLevel,
    onSuccess: (_data, { workerId }) => {
      showSuccess(`Worker profile updated successfully`)
      queryClient.invalidateQueries({
        queryKey: [WORKER_PROFILE_QUERY_KEY, workerId],
      })
    },
    onError: (error: AxiosError) => {
      showError(`Failed to update worker profile: ${error.message}`)
    },
  })

  return { updateWorkerProfileOpsLevelMutation }
}
