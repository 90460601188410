import { useHotSettings } from '@traba/hooks'
import {
  Button,
  ButtonVariant,
  Card,
  Row,
  ScheduleDaySquares,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { formatScheduleDateRange } from '@traba/utils'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { useShiftRequestsWithShifts } from 'src/screens/FieldMonitorScreen/components/AddWorkers/hooks/useShiftRequestWithShifts'
import { Badge } from '../base'
import { ScheduleListItem } from '../Schedule/ScheduleListItem'

interface ShiftDetailsScheduleInfoProps {
  shift: OpsExtendedShift
}

export const ShiftDetailsScheduleInfo = (
  props: ShiftDetailsScheduleInfoProps,
) => {
  const { shift } = props
  const navigate = useNavigate()
  const { shiftRequestId } = shift
  const { shiftRequests } = useShiftRequestsWithShifts([shiftRequestId])
  const shiftRequestParentId = shiftRequests?.[0]?.shiftRequestParentId
  const { shiftRequestParent } = useShiftRequestParent(
    shiftRequestParentId ?? '',
  )
  const { hotSettings } = useHotSettings()
  const firstRecurringShiftRequest = useMemo(
    () =>
      shiftRequestParent?.shiftRequests?.find((sr) =>
        sr.schedules.some((s) => s.isRecurringSchedule),
      ),
    [shiftRequestParent],
  )
  if (
    !shiftRequestParent ||
    !firstRecurringShiftRequest ||
    (!hotSettings?.recurringSchedulesEnabled &&
      !hotSettings?.recurringSchedulesEnabledCompanies?.includes(
        shiftRequestParent.companyId,
      ))
  ) {
    return null
  }

  const roleNames = shiftRequestParent.shiftRequests.map(
    (sr) => `${sr.slotsRequested} ${sr.shiftRole}`,
  )

  return (
    <Card
      style={{
        backgroundColor: theme.colors.Grey10,
        marginBottom: theme.space.xs,
      }}
    >
      <Row alignCenter justifyBetween>
        <Row alignCenter gap={theme.space.xxs}>
          <Text variant="h6">{shiftRequestParent.title}</Text>
          <Badge title="schedule" variant="brand" />
        </Row>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => navigate(`/schedule/${shiftRequestParentId}`)}
        >
          Go to full schedule
        </Button>
      </Row>
      <Row alignCenter>
        <ScheduleListItem
          label="SCHEDULE RANGE"
          content={formatScheduleDateRange(
            firstRecurringShiftRequest?.schedules,
          )}
        />
        <ScheduleListItem
          label="ROLES FOR EACH SHIFT"
          content={roleNames.join(', ')}
        />
        <ScheduleListItem
          label="WEEKLY PATTERN"
          content={
            <Row wrap>
              {firstRecurringShiftRequest.schedules.map((schedule, index) => (
                <ScheduleDaySquares
                  repeatOn={schedule.recurringSchedule?.repeatOn || []}
                  instance={index}
                />
              ))}
            </Row>
          }
        />
      </Row>
    </Card>
  )
}
