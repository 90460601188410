import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { TrainingVideo } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'

async function getTrainingVideo(
  trainingVideoId: string,
): Promise<TrainingVideo | undefined> {
  const res = await trabaApi.get(`training-video/${trainingVideoId}`)
  return res.data
}

export function useTrainingVideo(trainingVideoId: string) {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: videos,
    error,
    isFetched,
    refetch,
  } = useQuery<TrainingVideo | undefined, Error>(
    `training_video_${trainingVideoId}`,
    () => getTrainingVideo(trainingVideoId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useTrainingVideo ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    showError(
      fullErrorMessage,
      `Error Fetching Training Video for ${trainingVideoId}`,
    )
  }

  return {
    isLoading,
    isError,
    videos,
    error,
    isFetched,
    refetch,
  }
}

export interface CreateOrUpdateTrainingVideoData
  extends Omit<TrainingVideo, 'id' | 'createdAt' | 'updatedAt' | 'companyId'> {}

async function getTrainingVideos(companyId?: string): Promise<TrainingVideo[]> {
  try {
    if (!companyId) {
      return []
    }
    const res = await trabaApi.get(`training-video/company/${companyId}`)
    return res.data || []
  } catch (e) {
    return []
  }
}

export const useTrainingVideos = (companyId?: string) => {
  const queryClient = useQueryClient()
  const { handleError } = useAlert()
  const {
    isLoading,
    isError,
    data: trainingVideos,
    error,
    isFetched,
  } = useQuery<TrainingVideo[], Error>(`training_videos_${companyId}`, () =>
    getTrainingVideos(companyId),
  )
  const createTrainingVideoMutation = useMutation<
    TrainingVideo,
    AxiosError,
    CreateOrUpdateTrainingVideoData
  >(
    async (video) => {
      const res = await trabaApi.post(
        `training-video/company/${companyId}`,
        video,
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          `training_videos_${companyId}`,
          (existingVideos: TrainingVideo[] | undefined) => {
            if (existingVideos) {
              return [...existingVideos, data]
            }
            return [data]
          },
        )
      },
    },
  )

  const removeTrainingVideoMutation = useMutation<
    TrainingVideo,
    AxiosError,
    string,
    TrainingVideo
  >((videoId) => trabaApi.delete(`training-video/${videoId}`), {
    onSuccess: (_, values: any) => {
      queryClient.setQueryData(
        'training-video',
        (existingVideos: TrainingVideo[] | undefined) => {
          if (existingVideos) {
            return existingVideos.filter(
              (video: TrainingVideo) => video.id !== values,
            )
          }
          return []
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useTrainingVideos -> removeTrainingVideoMutation()',
        'Please try again or contact support if the issue persists.',
        'An error has occurred',
      )
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    trainingVideos: trainingVideos ?? [],
    requiredForAllTrainingVideos: trainingVideos?.filter(
      (tv: TrainingVideo) => tv.requiredForAll,
    ),
    createTrainingVideo: createTrainingVideoMutation.mutate,
    removeTrainingVideo: removeTrainingVideoMutation.mutate,
  }
}
