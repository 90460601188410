import { useAlert } from '@traba/context'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { getErrorMessage } from 'src/utils/errorUtils'

export type WorkerResumeDto = {
  workerId: string
  resumeId: string
  downloadUrl: string
  createdAt: Date
}

export type GetWorkerResumesResponseDto = {
  resumes: WorkerResumeDto[]
}

async function getWorkerResumes(
  workerId: string,
  showError: (message: string, title?: string, durationInMs?: number) => void,
): Promise<GetWorkerResumesResponseDto | undefined> {
  try {
    const response = await trabaApi.get(
      `worker-resumes/resumes-for-worker/${workerId}`,
    )
    return response.data
  } catch (e) {
    showError(getErrorMessage(e), 'Could not get resumes')
  }
}

async function deleteResume(
  resumeId: string,
  showError: (message: string, title?: string, durationInMs?: number) => void,
) {
  try {
    await trabaApi.delete(`worker-resumes/${resumeId}`)
  } catch (e) {
    showError(getErrorMessage(e), 'Could not delete resume')
  }
}

export const useWorkerUploadedResumes = (workerId: string) => {
  const { showError } = useAlert()

  const {
    data: resumes,
    isLoading,
    refetch,
    error,
  } = useQuery<GetWorkerResumesResponseDto | undefined, Error>(
    `worker_resumes_${workerId}`,
    () => getWorkerResumes(workerId, showError),
  )

  return {
    isLoading,
    refetch,
    resumes,
    error,
    deleteResume: async (resumeId: string) => {
      await deleteResume(resumeId, showError)
      await refetch()
    },
  }
}
