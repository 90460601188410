import { useInternalUsers } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InternalUser, InternalUserStatus } from '@traba/types'
import { useMemo, useState } from 'react'
import { Button, CopyTextIcon, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTableHeader, TableCell } from 'src/components/base/Table/DataTable'
import { VirtualizedTable } from 'src/components/base/Table/VirtualizedTable'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import Toggle from '../../../../components/base/Toggle'
import InternalUserModal from './InternalUserModal'

export const InternalUsersTable = () => {
  const { internalUsers } = useInternalUsers({})
  const { handleClose, isOpen, open } = useModal()

  const [selectedUser, setSelectedUser] = useState<InternalUser | undefined>()
  const [hideArchived, setHideArchived] = useState<boolean>(true)

  const headers: DataTableHeader[] = [
    {
      key: 'firstName',
      label: 'First Name',
    },
    {
      key: 'lastName',
      label: 'Last Name',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'phoneNumber',
      label: 'Phone',
    },
    {
      key: 'role',
      label: 'Role',
    },
    {
      key: 'rank',
      label: 'Rank',
    },
    {
      key: 'id',
      label: 'ID',
    },
    {
      label: 'Edit',
    },
  ]

  const rows =
    useMemo(() => {
      // First, sort the users by rank
      const sortedUsers = internalUsers
        ?.filter((user) =>
          hideArchived ? user.status === InternalUserStatus.Active : true,
        )
        .sort((a, b) => a.rank.localeCompare(b.rank))

      // Then, transform sorted users into table rows
      return sortedUsers?.map((user) => {
        const cells: TableCell[] = [
          {
            sortKey: user.firstName,
            renderFn: () => <Row style={{ width: 80 }}>{user.firstName}</Row>,
          },
          {
            sortKey: user.lastName,
            renderFn: () => <Row style={{ width: 80 }}>{user.lastName}</Row>,
          },
          {
            sortKey: String(user.status),
            renderFn: () => <Row style={{ width: 80 }}>{user.status}</Row>,
          },
          {
            renderFn: () => <Row style={{ width: 160 }}>{user.email}</Row>,
          },
          {
            renderFn: () => (
              <Row style={{ width: 160 }}>
                {user.phoneNumber ? formatPhoneNumber(user.phoneNumber) : ''}
              </Row>
            ),
          },
          {
            sortKey: String(user.role),
            renderFn: () => user.role,
          },
          {
            sortKey: String(user.rank),
            renderFn: () => user.rank,
          },
          {
            renderFn: () => (
              <Row>
                <>
                  {truncateString(user.id)}{' '}
                  <CopyTextIcon textToCopy={user.id} />
                </>
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Button
                variant={ButtonVariant.TEXT}
                style={{ padding: 0 }}
                onClick={() => {
                  setSelectedUser(user)
                  open()
                }}
              >
                Edit
              </Button>
            ),
          },
        ]

        return {
          key: user.id,
          cells,
        }
      })
    }, [internalUsers, setSelectedUser, open, hideArchived]) || []

  const handleToggle = () => {
    setHideArchived(!hideArchived)
  }

  return (
    <Row
      flexCol
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        padding: theme.space.sm,
        flex: 1,
      }}
    >
      <Text variant="h4" mb={theme.space.xs}>
        Internal Users
      </Text>
      <Row mb={theme.space.sm} alignCenter justifyBetween>
        <Toggle
          label="Hide archived"
          buttonState={hideArchived}
          runOnChange={handleToggle}
        />
        <Button
          variant={ButtonVariant.TEXT}
          leftIcon={<Icon name="plus" />}
          onClick={() => {
            setSelectedUser(undefined)
            open()
          }}
        >
          Create New User
        </Button>
      </Row>
      <VirtualizedTable
        headers={headers.map((header) =>
          typeof header === 'string' ? header : header.label?.toString() || '',
        )}
        rows={rows}
      />
      <InternalUserModal
        handleClose={handleClose}
        isOpen={isOpen}
        key={`userModal_${selectedUser?.id}`}
        user={selectedUser}
      />
    </Row>
  )
}
