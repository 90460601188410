import { WorkerShiftAsTimesheetRow } from '@traba/types'
import { useEffect, useMemo, Dispatch } from 'react'
import { Col } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import {
  LeanWorkerShiftAsTimesheetRow,
  TableRow,
  TimesheetAdjustment,
} from '../timesheet.types'
import {
  buildBaseTableRow,
  calculateTotalMinutesWorked,
  calculateTotalPay,
  hasDiff,
  tableRowToTimesheetAdjustment,
  checkAndSetDiff,
  FIELDS_TO_CHECK,
} from '../utils'
import NewWorkersTable from './NewWorkersTable'
import TimesheetAdjustmentsTable from './TimesheetAdjustmentsTable'

function compareDataAndBuildTableRows(
  parsedData: WorkerShiftAsTimesheetRow[],
  workerShifts: WorkerShiftAsTimesheetRow[],
): {
  allTableRows: TableRow[]
  diffedTableRows: TableRow[]
  workerShiftAdjustments: TimesheetAdjustment[]
  newWorkerShifts: TableRow[]
} {
  const allTableRows: TableRow[] = []
  const diffedTableRows: TableRow[] = []
  const workerShiftAdjustments: TimesheetAdjustment[] = []
  const newWorkerShifts: TableRow[] = []
  parsedData.forEach((parsedWorkerShift) => {
    const workerShift = workerShifts.find(
      (item) =>
        item.workerId === parsedWorkerShift.workerId &&
        item.shiftId === parsedWorkerShift.shiftId,
    )

    if (workerShift) {
      const tableRow: TableRow = buildBaseTableRow(
        parsedWorkerShift,
        workerShift,
      )
      // TODO(gavin): may one day need to check workerName as well to see if business worker name is different from ops console worker name
      FIELDS_TO_CHECK.forEach((field) => {
        const key = field as keyof LeanWorkerShiftAsTimesheetRow
        checkAndSetDiff(tableRow, key) // this function mutates tableRow
      })

      allTableRows.push(tableRow)
      if (hasDiff(tableRow)) {
        diffedTableRows.push(tableRow)
        workerShiftAdjustments.push(
          tableRowToTimesheetAdjustment(tableRow, workerShift),
        )
      }
    } else {
      const tableRow: TableRow = buildBaseTableRow(
        parsedWorkerShift,
        {} as WorkerShiftAsTimesheetRow,
      )
      newWorkerShifts.push(tableRow)
    }
  })

  return {
    allTableRows,
    diffedTableRows,
    workerShiftAdjustments,
    newWorkerShifts,
  }
}

export default function TimesheetTable({
  parsedData,
  openModal,
  setWorkerShiftAdjustments,
  setPrevMinutesWorked,
  setPrevTotalPay,
  setNewMinutesWorked,
  setNewTotalPay,
  workerShifts,
  refetchWorkerShifts,
  setParsedData,
}: {
  parsedData: WorkerShiftAsTimesheetRow[]
  openModal: () => void
  setWorkerShiftAdjustments: Dispatch<
    React.SetStateAction<TimesheetAdjustment[]>
  >
  setPrevMinutesWorked: Dispatch<React.SetStateAction<number>>
  setPrevTotalPay: Dispatch<React.SetStateAction<number>>
  setNewMinutesWorked: Dispatch<React.SetStateAction<number>>
  setNewTotalPay: Dispatch<React.SetStateAction<number>>
  workerShifts: WorkerShiftAsTimesheetRow[]
  refetchWorkerShifts: () => void
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
}) {
  const {
    allTableRows,
    diffedTableRows,
    workerShiftAdjustments,
    newWorkerShifts,
  } = useMemo(
    () => compareDataAndBuildTableRows(parsedData, workerShifts),
    [parsedData, workerShifts],
  )

  const [{ newPay, prevPay }, { newMinutesWorked, prevMinutesWorked }] =
    useMemo(
      () => [
        calculateTotalPay(allTableRows),
        calculateTotalMinutesWorked(allTableRows),
      ],
      [allTableRows],
    )

  useEffect(() => {
    setWorkerShiftAdjustments(workerShiftAdjustments)
    setNewTotalPay(newPay)
    setNewMinutesWorked(newMinutesWorked)
    setPrevTotalPay(prevPay)
    setPrevMinutesWorked(prevMinutesWorked)
  }, [
    newMinutesWorked,
    newPay,
    prevMinutesWorked,
    prevPay,
    setNewMinutesWorked,
    setNewTotalPay,
    setPrevMinutesWorked,
    setPrevTotalPay,
    setWorkerShiftAdjustments,
    workerShiftAdjustments,
  ])

  function getShiftMenuItems(): IMenuItem[] {
    const rowsWithShiftIds = [...parsedData, ...workerShifts].filter(
      ({ shiftId }) => Boolean(shiftId),
    )

    // set shiftIds as default menu label
    const shiftMap = new Map(
      rowsWithShiftIds.map((item) => [item.shiftId, item.shiftId]),
    )

    // Update the menu label if we find a shiftName for the shift
    rowsWithShiftIds
      .filter((item) => Boolean(item.shiftName))
      .forEach((item) =>
        shiftMap.set(item.shiftId, `${item.shiftName} (${item.shiftId})`),
      )

    return Array.from(shiftMap).map(([value, label]) => ({ value, label }))
  }

  return (
    <Col style={{ overflow: 'scroll' }}>
      {allTableRows.length > 0 && (
        <TimesheetAdjustmentsTable
          allTableRows={allTableRows}
          diffedTableRows={diffedTableRows}
          openModal={openModal}
          setParsedData={setParsedData}
        />
      )}
      {newWorkerShifts.length > 0 && (
        <NewWorkersTable
          tableRows={newWorkerShifts}
          shiftMenuItems={getShiftMenuItems()}
          refetchWorkerShifts={refetchWorkerShifts}
          setParsedData={setParsedData}
        />
      )}
    </Col>
  )
}
