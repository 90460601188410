import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company, ShiftRequestParent } from '@traba/types'
import { formatShiftDateWithTimezone, getShiftTimeString } from '@traba/utils'
import { addDays } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ONE_MONTH_IN_DAYS, useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { DataTable, Row } from '../base'
import DateRangePicker from '../base/AriaDatePicker/DateRangePicker'

interface Props {
  company: Company
  shiftRequestParent: ShiftRequestParent
}
export const ScheduleDetailsShiftsTab = ({
  company,
  shiftRequestParent,
}: Props) => {
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const { rawShifts, hasNextPage, fetchNextPage, isFetching } =
    useCompanyShifts(
      {
        shiftRequestParentIds: [shiftRequestParent.shiftRequestParentId],
        companyId: company.companyId,
        startAfter: dateRange[0] ?? new Date(),
        endBefore: dateRange[1] ?? addDays(new Date(), ONE_MONTH_IN_DAYS),
      },
      true,
    )
  const shifts = rawShifts?.pages.map((page) => page?.data).flat()

  const shiftsTableHeaders = [
    { label: 'Date', key: 'date', sortable: true },
    { label: 'Time', key: 'time' },
    { label: 'Roles', key: 'roles' },
    { label: 'Slots', key: 'slots' },
  ]

  const shiftTableRows = useMemo(() => {
    return shifts?.map((shift) => ({
      key: shift.shiftId,
      cells: [
        {
          key: 'date',
          renderFn: () => (
            <Text>
              {formatShiftDateWithTimezone(shift.startTime, shift.timezone)}
            </Text>
          ),
        },
        {
          key: 'time',
          renderFn: () => (
            <Text>
              {getShiftTimeString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
            </Text>
          ),
        },
        {
          key: 'roles',
          renderFn: () => (
            <Row
              alignCenter
              onClick={() => {
                navigate(`/field-monitor/${shift.shiftId}`)
              }}
            >
              <Text>{shift.shiftRole}</Text>
              <SvgIcon name="link" />
            </Row>
          ),
        },
        {
          key: 'slots',
          renderFn: () => (
            <Text>{`${shift.slotsFilled} / ${shift.slotsRequested}`}</Text>
          ),
        },
      ],
    }))
  }, [shifts])

  return (
    <>
      <Row alignCenter>
        <DateRangePicker
          label="Date range"
          inlineLabel={true}
          dateRange={dateRange}
          setDateRange={setDateRange}
          granularity="day"
          style={{ marginBottom: theme.space.xs, marginTop: theme.space.xs }}
        />
      </Row>
      <DataTable
        initialSortByAscOrder={false}
        headers={shiftsTableHeaders}
        rows={shiftTableRows ?? []}
      />
      {dateRange[0] === null &&
        dateRange[1] === null &&
        (isFetching ? (
          <LoadingSpinner />
        ) : (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              hasNextPage && fetchNextPage()
            }}
          >
            load more
          </Button>
        ))}
    </>
  )
}
