import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CreateInvitationsData,
  ForwardFillMax,
  ShiftInvitation,
  ShiftInvitationWithWorkerInfo,
} from '@traba/types'
import { getTimeUntilShiftOpen } from '@traba/utils'
import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { Badge, Button, Row, Table } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { useRosters } from 'src/hooks/useRosters'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import AcceptShiftModal from 'src/modals/AcceptShiftModal/AcceptShiftModal'
import { SearchInviteWorkersModal } from 'src/screens/PostShiftScreen/components/SearchInviteWorkers/SearchInviteWorkersModal'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { BulkResendInvitationsResponse } from 'src/types/shift-invitations'
import ShiftInvitationsTableRow from './ShiftInvitationsTableRow'

export type ShiftInvitationsTableProps = {
  shift: OpsExtendedShift
  shiftInvitations?: ShiftInvitationWithWorkerInfo[]
  isLoadingShiftInvitations?: boolean
  sendInvitations: UseMutateFunction<
    ShiftInvitation[],
    AxiosError<unknown, any>,
    CreateInvitationsData,
    unknown
  >
  bulkResendInvitations: UseMutateFunction<
    BulkResendInvitationsResponse,
    AxiosError<unknown, any>,
    {
      shiftId: string
    }
  >
}

export default function ShiftInvitationsTable({
  shift,
  shiftInvitations,
  isLoadingShiftInvitations,
  sendInvitations,
  bulkResendInvitations,
}: ShiftInvitationsTableProps) {
  const { companyId } = shift
  const { rosters } = useRosters(companyId)
  const { virtualRosters } = useVirtualRosters(
    companyId,
    shift.locationId,
    shift.roleId,
  )

  const [_, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const [isShiftInvitationModalOpen, setIsShiftInvitationModalOpen] =
    useState(false)
  const acceptShiftModal = useModal()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [isResendingAllInvitations, setIsResendingAllInvitations] =
    useState(false)

  const tableHeaders = [
    {},
    { label: 'Worker Name', key: 'worker.firstName' },
    { label: 'Account Status', key: 'worker.accountApprovalStatus' },
    { label: 'Worker Id', key: 'worker.workerId' },
    { label: 'Phone Number', key: 'worker.phoneNumber' },
    { label: 'Status', key: 'status' },
    { label: 'Invited At', key: 'invitedAt' },
    { label: 'Tier', key: 'worker.tierLevel' },
    { label: 'Distance (MI)', key: '' },
    { label: '# Shifts Worked', key: 'worker.shiftsCompleted' },
    {},
  ]

  const tz = useTimezonedDates(shift.timezone)
  const isInviteFirst = shift.forwardFillMax === ForwardFillMax.INVITED_FIRST

  const handleAddWorkersToShiftClick = useCallback(() => {
    setIsShiftInvitationModalOpen(false)
    acceptShiftModal.open()
  }, [acceptShiftModal, setIsShiftInvitationModalOpen])

  const handleShiftInvitationClick = useCallback(() => {
    acceptShiftModal.handleClose()
    setIsShiftInvitationModalOpen(true)
  }, [acceptShiftModal, setIsShiftInvitationModalOpen])

  const handleResendAllInvitationsClick = useCallback(() => {
    setIsResendingAllInvitations(true)
    bulkResendInvitations(
      { shiftId: shift.shiftId },
      { onSettled: () => setIsResendingAllInvitations(false) },
    )
  }, [bulkResendInvitations, shift.shiftId, setIsResendingAllInvitations])

  return (
    <>
      <Row mt={theme.space.sm} mb={theme.space.sm} justifyBetween>
        <Row alignCenter>
          <Text mr={theme.space.xxs} variant="h4">
            Invitations
          </Text>
          <Badge
            style={{ margin: 0 }}
            title={shift.forwardFillMax?.replace('_', ' ') || ''}
            variant="info"
          />
          {isInviteFirst && (
            <Text variant="body1" style={{ marginLeft: theme.space.xxxs }}>
              Until{' '}
              {tz.getDateTime(
                getTimeUntilShiftOpen(
                  new Date(shift.createdAt),
                  new Date(shift.startTime),
                ),
              )}
            </Text>
          )}
        </Row>
        <Row>
          <Button
            slim
            variant={ButtonVariant.OUTLINED}
            onClick={handleShiftInvitationClick}
            disabled={selectedRows.length > 0}
          >
            Send New Invitations
          </Button>
          <Button
            slim
            variant={ButtonVariant.OUTLINED}
            onClick={handleResendAllInvitationsClick}
            disabled={selectedRows.length > 0 || shiftInvitations?.length === 0}
            loading={isResendingAllInvitations}
          >
            Resend All Invitations
          </Button>
          <Button
            slim
            onClick={handleAddWorkersToShiftClick}
            ml={theme.space.ms}
            disabled={selectedRows.length === 0}
          >
            Add Workers to shift
          </Button>
        </Row>
      </Row>

      {isLoadingShiftInvitations ? null : (
        <Table headers={tableHeaders}>
          {shiftInvitations?.map((shiftInvitation) => (
            <ShiftInvitationsTableRow
              shiftInvitation={shiftInvitation}
              shift={shift}
              setSelectedRows={(newSelectedRows: string[]) =>
                setSelectedRows(newSelectedRows)
              }
              selectedRows={selectedRows}
              openAddWorkersModal={handleAddWorkersToShiftClick}
            />
          ))}
        </Table>
      )}
      <SearchInviteWorkersModal
        open={isShiftInvitationModalOpen}
        handleClose={() => setIsShiftInvitationModalOpen(false)}
        rosters={virtualRosters.concat(rosters)}
        setAllWorkersToInvite={setWorkersToInvite}
        shift={shift}
        sendInvitations={sendInvitations}
        shiftInvitations={shiftInvitations || []}
      />
      <AcceptShiftModal
        {...acceptShiftModal}
        workerIds={selectedRows}
        shift={shift}
        handleSuccess={() => setSelectedRows([])}
      />
    </>
  )
}
