import { Menu, MenuItem } from '@mui/material'
import { Col, Row, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeBaseLevels,
  AttributeLevelType,
  AttributeSkillLevels,
  OpsAttributeLevel,
} from '@traba/types'
import { isAttributeLevelRevoked } from 'src/utils/attributeUtils'

interface AttributePillMenuProps {
  menuAnchorEl: HTMLElement | null
  attributeLevel: OpsAttributeLevel
  levelType: AttributeLevelType
  handleClick: (opsLevel: OpsAttributeLevel) => void
  handleClose: () => void
}

interface IAttributePillMenuItem {
  attributeLevel: OpsAttributeLevel
  title: string
  subtitle?: string
}

const baseLevelingMenuItems: IAttributePillMenuItem[] = [
  {
    attributeLevel: AttributeBaseLevels.PRESENT,
    title: 'Present',
    subtitle: 'Worker has attribute',
  },
  {
    attributeLevel: AttributeBaseLevels.REVOKED,
    title: 'Revoked',
    subtitle: 'Confirmed inept',
  },
  {
    attributeLevel: AttributeBaseLevels.UNREPORTED,
    title: 'Unknown',
    subtitle: 'No information about this',
  },
]

const skillLevelingMenuItems: IAttributePillMenuItem[] = [
  {
    attributeLevel: AttributeSkillLevels.HIGH,
    title: 'Expert',
    subtitle: 'Good enough to teach others',
  },
  {
    attributeLevel: AttributeSkillLevels.MEDIUM,
    title: 'Competent',
    subtitle: 'Can perform without assistance',
  },
  {
    attributeLevel: AttributeSkillLevels.LOW,
    title: 'Beginner',
    subtitle: 'Requires instruction periodically',
  },
  {
    attributeLevel: AttributeSkillLevels.REPORTED,
    title: 'Worker Reported',
    subtitle: 'Skill level is not verified',
  },
  {
    attributeLevel: AttributeSkillLevels.REVOKED,
    title: 'Revoked',
    subtitle: 'Confirmed inept',
  },
  {
    attributeLevel: AttributeSkillLevels.UNREPORTED,
    title: 'Unknown',
    subtitle: 'No information about this',
  },
]

export function AttributePillMenu(props: AttributePillMenuProps) {
  const { menuAnchorEl, attributeLevel, levelType, handleClick, handleClose } =
    props

  function onClick(opsLevel: OpsAttributeLevel) {
    handleClick(opsLevel)
    handleClose()
  }

  const menuItems =
    levelType === AttributeLevelType.BASE_LEVELING
      ? baseLevelingMenuItems
      : skillLevelingMenuItems

  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={handleClose}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.attributeLevel}
          onClick={() => onClick(item.attributeLevel)}
          selected={attributeLevel === item.attributeLevel}
        >
          <Col>
            {isAttributeLevelRevoked(item.attributeLevel) ? (
              <Row>
                <Text variant="body1" color={theme.colors.Red80}>
                  {item.title}
                </Text>
                <SvgIcon
                  name="block"
                  color={theme.colors.Red80}
                  style={{ marginLeft: theme.space.xxs }}
                />
              </Row>
            ) : (
              <Text variant="body1">{item.title}</Text>
            )}
            <Text variant="body3">{item.subtitle}</Text>
          </Col>
        </MenuItem>
      ))}
    </Menu>
  )
}
