import * as Sentry from '@sentry/react'
import {
  CompanyResponse,
  ShiftInvoicedStatus,
  ShiftStatus,
  ShiftWithSlimCharges,
} from '@traba/types'
import { Invoice, InvoiceStatus, Pagination } from '@traba/types'
import { Region } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

type GetShiftForInvoicingBody = {
  invoicedStatus: ShiftInvoicedStatus
  startBefore?: string
  startAfter?: string
  companyIds?: string[]
  regionIds?: string[]
  limit?: number
  startAt?: number
  sortBy?: string
  sortDir?: string
  statuses?: ShiftStatus[]
  hasInvoiceGroup?: boolean
}

export async function getShiftsForInvoicing(
  body: GetShiftForInvoicingBody,
): Promise<
  | { shifts: ShiftWithSlimCharges[]; totalCount: number }
  | ShiftWithSlimCharges[]
  | undefined
> {
  try {
    const res = await trabaApi.post(`invoices/query-shifts`, body)
    return res.data
  } catch (error: any) {
    const errorMessage = `useShiftsForInvoicing -> getShiftsForInvoicing() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
  }
}

export function useShiftsForInvoicing(props: GetShiftForInvoicingBody): {
  isFetching: boolean
  isError: boolean
  shifts: ShiftWithSlimCharges[] | undefined
  totalCount: number | undefined
  error: Error | null
  isFetched: boolean
  refetch: () => void
} {
  const { isFetching, isError, data, error, isFetched, refetch } = useQuery<
    | { shifts: ShiftWithSlimCharges[]; totalCount: number }
    | ShiftWithSlimCharges[]
    | undefined,
    Error
  >(['shiftsForInvoicing', props], () => getShiftsForInvoicing(props), {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes stale time
  })

  return {
    isFetching,
    isError,
    shifts: Array.isArray(data) ? data : data?.shifts, // using this format for backwards compatibility with endpoint response type
    totalCount: Array.isArray(data) ? undefined : data?.totalCount, // will clean up later. @praneeth-traba
    error,
    isFetched,
    refetch,
  }
}

async function getInvoices(
  filters: {
    statuses?: string[]
    regions?: Region[]
    companies?: CompanyResponse[]
    dueDateMax?: Date
  },
  pagination?: Pagination,
): Promise<
  { invoices: Invoice[]; totalCount: number } | Invoice[] | undefined
> {
  try {
    const res = await trabaApi.get(`invoices`, {
      params: {
        statuses: filters.statuses,
        regionIds: filters.regions?.map((region) => region.regionId),
        companyIds: filters.companies?.map((company) => company.id),
        startAt: pagination?.startAt,
        orderBy: pagination?.orderBy,
        limit: pagination?.limit,
        dueDateMax: filters.dueDateMax,
      },
    })
    return res.data
  } catch (error: any) {
    console.error('useInvoices -> getInvoices', error.message ?? error)
  }
}

export function useInvoices(
  filters: {
    statuses?: InvoiceStatus[]
    regions?: Region[]
    companies?: CompanyResponse[]
    dueDateMax?: Date
  },
  pagination?: Pagination,
) {
  const { isFetching, isError, data, error, isFetched, refetch } = useQuery<
    { invoices: Invoice[]; totalCount: number } | Invoice[] | undefined,
    Error
  >([`invoices`, filters, pagination], () => getInvoices(filters, pagination), {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes stale time
  })

  return {
    isFetching,
    isError,
    invoices: Array.isArray(data) ? data : data?.invoices, // using this format for backwards compatibility with endpoint response type
    invoiceCount: Array.isArray(data) ? undefined : data?.totalCount, // will clean up later. @praneeth-traba
    error,
    isFetched,
    refetch,
  }
}
