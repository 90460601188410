import * as Sentry from '@sentry/react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAlert } from '@traba/context'
import { User, UserRole } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'

export const COMPANY_USERS_QUERY_KEY = 'companyUsersCacheKey'

async function getCompanyUsers(
  companyId?: string,
): Promise<User[] | undefined> {
  if (!companyId) {
    return []
  }

  try {
    const res = await trabaApi.get(`companies/${companyId}/users`)
    return res.data
  } catch (error: any) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyUsers ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

interface ChangeMemberRoleParams {
  uid: User['uid']
  role: UserRole
}

interface DeleteUserParams {
  uid: User['uid']
  firstName?: string
  lastName?: string
}

interface ArchiveMemberRequest {
  uid: string
  replacementUserId?: string | undefined
}

const changeMemberRole =
  (companyId: string) =>
  async ({ uid, role }: ChangeMemberRoleParams) => {
    const response = await trabaApi.patch(
      `companies/${companyId}/users/${uid}/role`,
      { role },
    )
    return response.data
  }

const deleteUser = async ({ uid }: DeleteUserParams) => {
  const response = await trabaApi.delete(`users/${uid}`)
  return response.data
}

const archiveMember = async ({
  uid,
  replacementUserId,
}: ArchiveMemberRequest) => {
  const response = await trabaApi.patch(`/users/${uid}/archive`, {
    replacementUserId,
  })
  return response.data
}

export function useCompanyUserMutations(companyId: string) {
  const queryClient = useQueryClient()
  const { showSuccess, handleError, showError } = useAlert()

  const changeMemberRoleMutation = useMutation<
    User,
    Error,
    ChangeMemberRoleParams,
    User
  >({
    mutationFn: changeMemberRole(companyId),
    onSuccess: ({
      role,
      uid,
      companyId,
    }: Pick<User, 'uid' | 'companyId' | 'role'>) => {
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.map((m) =>
            m.uid === uid ? { ...m, role, uid, companyId } : m,
          )
        },
      )
      showSuccess(`User role was successfully updated to ${role}`)
    },
    onError: (error: Error) => {
      const message =
        error?.message === 'user/no-admins'
          ? 'There must be at least one team member with the Admin role at all times.'
          : 'There was an error changing your team member‘s role. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useCompanyUserMutations -> changeMemberRoleMutation',
        message,
        'Error changing role',
      )
    },
  })

  const deleteUserMutation = useMutation<User, Error, DeleteUserParams, User>({
    mutationFn: deleteUser,
    onSuccess: (_response, { uid, firstName, lastName }: DeleteUserParams) => {
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.filter((m) => m.uid !== uid)
        },
      )
      const userName = `${firstName} ${lastName}`.trim() || undefined
      showSuccess(
        userName
          ? `${userName}'s user was successfully deleted`
          : `User was successfully deleted`,
      )
    },
    onError: (error: Error) => {
      handleError(
        error,
        'useCompanyUserMutations -> deleteUserMutation',
        error.message,
        'Error deleting user',
      )
      showError(error.message, 'Error')
    },
  })

  const archiveMemberMutation = useMutation<
    User,
    Error,
    ArchiveMemberRequest,
    User
  >({
    mutationFn: archiveMember,
    onSuccess: ({ uid }: Pick<User, 'uid'>) => {
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.filter((m) => m.uid !== uid)
        },
      )
      showSuccess(`User was successfully archived`)
    },
    onError: (error: Error) => {
      const message =
        error?.message === 'archive/active-shifts'
          ? 'This user is a supervisor on current or upcoming shifts, please select a replacement.'
          : 'An error has occurred. Unable to archive user.'
      handleError(
        error,
        'useCompanyUserMutations -> archiveMemberMutation',
        message,
        'Error archiving user',
      )
    },
  })

  return {
    changeCompanyMemberRole: changeMemberRoleMutation.mutate,
    isChangeCompanyMemberRoleLoading: changeMemberRoleMutation.isPending,
    deleteUser: deleteUserMutation.mutate,
    isDeleteUserLoading: deleteUserMutation.isPending,
    archiveCompanyMember: archiveMemberMutation.mutate,
    isArchiveCompanyMemberRoleLoading: archiveMemberMutation.isPending,
  }
}

export function useCompanyUsers(companyId?: string) {
  const {
    isLoading,
    isError,
    data: companyUsers,
    error,
    isFetched,
    refetch,
  } = useQuery<User[] | undefined, Error>({
    queryKey: [COMPANY_USERS_QUERY_KEY, { companyId }],
    queryFn: () => getCompanyUsers(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  return {
    isLoading,
    isError,
    companyUsers,
    error,
    isFetched,
    refetch,
  }
}
