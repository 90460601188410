export enum MODAL_SIZE {
  DIALOG = 'dialog',
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  MEDIUM_LOCKED = 'medium-locked',
  LARGE = 'large',
  LARGE_LOCKED = 'large_locked',
  EXTRA_LARGE = 'extra_large',
}
