import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ExtendedShift } from '@traba/types'
import { ChangeEvent, useEffect, useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Button, Col, Input, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getErrorMessage } from 'src/utils/errorUtils'

type ShiftLocationEditModalProps = {
  handleClose: () => void
  isOpen: boolean
  prePopulatedText?: string
  shift: ExtendedShift
}

export default function ShiftLocationEditModal(
  props: ShiftLocationEditModalProps,
) {
  const { handleClose, isOpen, prePopulatedText, shift } = props

  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const [loadingSaveShift, setLoadingSaveShift] = useState<boolean>(false)
  const [loadingSaveLocation, setLoadingSaveLocation] = useState<boolean>(false)
  const [locationDetails, setLocationDetails] = useState<string | undefined>(
    prePopulatedText,
  )
  useEffect(() => {
    setLocationDetails(prePopulatedText)
  }, [prePopulatedText])

  // Set on this shift, and as the default for all other shifts at the same location
  const handleSaveForLocation = async () => {
    setLoadingSaveLocation(true)

    try {
      await trabaApi.patch(
        `/companies/${shift.companyId}/locations/${shift.location.locationId}`,
        {
          opsLocationDetails: locationDetails || null,
        },
      )
      // Sequential simply so that we don't end up deleting the override field if the location update failed
      await trabaApi.patch(
        `/shifts?shiftIds=${shift.id}&shouldNotifyUpcomingOrInProgressShifts=false&shouldNotifyCompletedShifts=false`,
        {
          shiftUpdates: {
            opsLocationDetailsOverride: null,
          },
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error saving location details')
    }

    await refetchActiveQueries()
    setLoadingSaveLocation(false)
    handleClose()
  }

  // Set for this shift only
  const handleSaveForShift = async () => {
    setLoadingSaveShift(true)

    try {
      await trabaApi.patch(
        `/shifts?shiftIds=${shift.id}&shouldNotifyUpcomingOrInProgressShifts=false&shouldNotifyCompletedShifts=false`,
        {
          shiftUpdates: {
            opsLocationDetailsOverride: locationDetails || null,
          },
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error saving location details')
    }

    await refetchActiveQueries()
    setLoadingSaveShift(false)
    handleClose()
  }

  return (
    <Modal
      size={MODAL_SIZE.MEDIUM}
      handleClose={handleClose}
      isOpen={isOpen}
      style={{ minHeight: `calc(100% - 500px)` }}
      title={'Update Location Details'}
    >
      <Col style={{ display: 'flex' }}>
        <Row fullWidth mb={theme.space.xs} mt={theme.space.xs}>
          <Input
            full
            placeholder="Add your notes..."
            rows={6}
            type="textarea"
            value={locationDetails}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setLocationDetails(event.target.value)
            }
          />
        </Row>

        <Row mb={theme.space.sm} fullWidth>
          <Text variant="caption">
            Note: This will not automatically be shared with workers. For manual
            sharing purposes only for now.
          </Text>
        </Row>

        <Row fullWidth mt={'auto'}>
          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ minWidth: '100px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              minWidth: '100px',
              marginLeft: 'auto',
              marginRight: theme.space.xxs,
            }}
            onClick={handleSaveForLocation}
            loading={loadingSaveLocation}
            disabled={loadingSaveShift}
            variant={ButtonVariant.OUTLINED}
          >
            Save for All Shifts at Location
          </Button>
          <Button
            style={{ minWidth: '100px' }}
            onClick={handleSaveForShift}
            loading={loadingSaveShift}
            disabled={loadingSaveLocation}
          >
            Save for Shift Only
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
