import {
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { MediaUploader } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AccountApprovalStatus,
  AccountStatus,
  ApprovalStatusReason,
  InstantPayEligibilityStatus,
  Transportation,
} from '@traba/types'
import { Worker, WorkerGender } from '@traba/types'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import {
  AccountStatusBadge,
  Button,
  Col,
  Icon,
  Input,
  Row,
  Select,
} from 'src/components/base'
import GenderBadge from 'src/components/base/Badge/GenderBadge'
import { TransportationBadge } from 'src/components/base/Badge/WorkPreferencesBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useAccountStatus } from 'src/hooks/useAccountStatus'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useWorkerTermination } from 'src/hooks/useWorkerTermination'
import { getErrorMessage } from 'src/utils/errorUtils'
import { regenerateWorkerMetrics } from '../../../../hooks/useWorkerMetrics'
import { updateWorkerData } from '../../../../hooks/useWorkers'
import { formatPhoneNumber } from '../../../../utils/stringUtils'
import * as S from './styles'

type WorkerAccountManagementProps = {
  workerName: string
  workerId: string
  worker: Worker & AccountStatus
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const WorkerAccountManagement = ({
  workerName,
  workerId,
  worker,
  isOpen,
  setIsOpen,
}: WorkerAccountManagementProps) => {
  const handleDrawerClose = () => {
    setIsOpen(false)
  }

  const [isTerminationLoading, setIsTerminationLoading] = useState(false)
  const [isReinstateLoading, setIsReinstateLoading] = useState(false)
  const [isMetricsLoading, setIsMetricsLoading] = useState(false)
  const { refetchActiveQueries } = useActiveQueries()
  const { terminateWorker, reinstateWorker } = useWorkerTermination()
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  /**
   * Account Status Logic
   */
  const {
    accountStatusLoading,
    updateAccountStatus,
    instantPayLoading,
    updateInstantPay,
  } = useAccountStatus(workerId)
  const accountStatus = worker.accountStatus

  const accountStatusApprovalReasons = Object.values(
    ApprovalStatusReason,
  ) as string[]

  const accountStatusMenuItems: IMenuItem[] = [
    { label: 'Approved', value: AccountApprovalStatus.Approved },
    { label: 'Unapproved', value: AccountApprovalStatus.Unapproved },
    { label: 'Rejected', value: AccountApprovalStatus.Rejected },
    { label: 'Suspended', value: AccountApprovalStatus.Suspended },
    { label: 'Banned', value: AccountApprovalStatus.Banned },
  ]

  const workerGenderMenuItems: IMenuItem[] = [
    { label: 'Male', value: WorkerGender.Male },
    { label: 'Female', value: WorkerGender.Female },
    { label: 'Other', value: WorkerGender.Other },
    { label: 'Prefer Not To Say', value: WorkerGender.PreferNotToSay },
  ]

  const workerTransportationMenuItems: IMenuItem[] = [
    { label: 'DRIVE', value: Transportation.DRIVE },
    { label: 'PUBLIC', value: Transportation.PUBLIC },
    { label: 'CARPOOL', value: Transportation.CARPOOL },
    { label: 'SHARE', value: Transportation.SHARE },
  ]

  const accountStatusApprovalReasonsMenuItems: IMenuItem[] =
    accountStatusApprovalReasons.map((tag) => {
      return { label: tag, value: tag }
    })

  const [accountStatusValue, setAccountStatusValue] =
    useState<string>(accountStatus)
  const [accountStatusApprovalReason, setAccountStatusApprovalReason] =
    useState<string>('')

  const [editingAccountStatus, setEditingAccountStatus] =
    useState<boolean>(false)

  const [editingGender, setEditingGender] = useState<boolean>(false)
  const [editingTransportation, setEditingTransportation] =
    useState<boolean>(false)
  const [workerReportedGender, setWorkerReportedGender] = useState<
    string | undefined
  >(worker.reportedGender)
  const [selectedTransportation, setSelectedTransportation] = useState<
    string | undefined
  >(worker.workPreferences?.transportation)
  const [opsReportedGender, setOpsReportedGender] = useState<
    string | undefined
  >(worker.opsReportedGender)

  const [editing, setEditingField] = useState('')
  const [error, setError] = useState({
    email: '',
    phoneNumber: '',
    zipCode: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleAccountStatusChange = async () => {
    try {
      await updateAccountStatus(
        accountStatusValue as AccountApprovalStatus,
        accountStatusApprovalReason as ApprovalStatusReason,
      )
    } catch (e) {
      //already handled in updateAccountStatus
    } finally {
      refetchActiveQueries()
      setEditingAccountStatus(false)
      setAccountStatusApprovalReason('')
    }
  }

  const handleGenderChange = async (opsReported: boolean) => {
    setIsLoading(true)
    try {
      await updateWorkerData(workerId, {
        ...(opsReported
          ? { opsReportedGender: opsReportedGender as WorkerGender }
          : {
              reportedGender: workerReportedGender as WorkerGender,
            }),
      })
    } catch (e) {
      showError(getErrorMessage(e), "Error updating worker's gender")
    } finally {
      refetchActiveQueries()
      setEditingGender(false)
      setIsLoading(false)
    }
  }

  const handleTransportationChange = async () => {
    setIsLoading(true)
    try {
      await updateWorkerData(workerId, {
        workPreferences: {
          transportation: selectedTransportation as Transportation,
        },
      })
    } catch (e) {
      showError(getErrorMessage(e), "Error updating worker's transportation")
    } finally {
      queryClient.invalidateQueries([`/workers/${workerId}/worker-and-profile`])
      setEditingTransportation(false)
      setIsLoading(false)
    }
  }

  const allowedToTerminate =
    accountStatus !== AccountApprovalStatus.Terminated &&
    accountStatus !== AccountApprovalStatus.TerminationPending

  const allowedToReinstate =
    accountStatus === AccountApprovalStatus.TerminationPending ||
    accountStatus === AccountApprovalStatus.Terminated

  const allowedToEditAccountStatus = !allowedToReinstate

  const handleTerminateWorker = async () => {
    setIsTerminationLoading(true)
    try {
      if (!window.confirm('Are you sure you want to terminate this worker?')) {
        throw new Error('User cancelled termination')
      }
      await terminateWorker(workerId)
    } finally {
      setIsTerminationLoading(false)
      refetchActiveQueries()
    }
  }

  const handleReinstateWorker = async () => {
    setIsReinstateLoading(true)
    try {
      await reinstateWorker(workerId)
    } finally {
      setIsReinstateLoading(false)
      refetchActiveQueries()
    }
  }

  /**
   * Instant Pay Logic
   */
  const instantPayEligibilityStatus =
    worker.payment?.instantPayEligibility?.status

  const ipEligible =
    instantPayEligibilityStatus === InstantPayEligibilityStatus.ELIGIBLE ||
    instantPayEligibilityStatus ===
      InstantPayEligibilityStatus.ELIGIBLE_WITH_WARNING

  const handleIPChange = async (enabled: boolean, permanent?: boolean) => {
    try {
      await updateInstantPay(enabled, permanent)
    } finally {
      refetchActiveQueries()
    }
  }

  const handleRegenerateMetrics = async () => {
    setIsMetricsLoading(true)
    await regenerateWorkerMetrics(workerId)
    refetchActiveQueries()
    showSuccess("Worker's metrics have been regenerated", 'Metrics regenerated')
    setIsMetricsLoading(false)
  }

  const updateWorkerField = async (
    field: 'phoneNumber' | 'email' | 'zipCode',
    input: string,
  ) => {
    if (!input) {
      return
    }
    try {
      setIsLoading(true)
      await updateWorkerData(workerId, { [field]: input })
      await refetchActiveQueries()
    } catch (e: any) {
      setError({ ...error, [field]: e.message })
    } finally {
      setEditingField('')
      setIsLoading(false)
    }
  }

  const handleToggleEditingField = (
    field: 'email' | 'phoneNumber' | 'zipCode' | '',
  ) => {
    setEditingField(field)
    setError({ email: '', phoneNumber: '', zipCode: '' })
  }

  /* Profile photo logic*/
  const { handleUpload } = useFileUploader()
  const [file, setFile] = useState<File | undefined>()
  async function onChangeFile(f: File | undefined) {
    if (!f) {
      return
    }
    setFile(f)
    const confirmed = window.confirm(
      'Are you sure you want to upload this file as a profile photo?',
    )
    if (!confirmed) {
      return
    }

    try {
      const url = await handleUpload({
        fileType: FileType.WORKER_AVATARS,
        media: f,
        userId: workerId,
        resizeOptions: {
          maxWidth: 300,
          maxHeight: 300,
          quality: 70,
          compressionFormat: 'JPEG',
        },
      })
      await updateWorkerData(workerId, {
        photoUrl: url,
        // Assuming that the photo is valid if it was uploaded by ops
        isProfilePhotoValid: true,
      })
      showSuccess('Profile photo updated successfully', 'Success')
    } catch (e) {
      showError(getErrorMessage(e), 'Error updating profile photo')
    }

    setFile(undefined)
    refetchActiveQueries()
  }

  function onDeleteFile() {
    setFile(undefined)
  }

  const RenderField = ({
    field,
    label,
    value,
    editingField,
    updateWorkerField,
  }: {
    field: 'phoneNumber' | 'email' | 'zipCode'
    label: string
    value: string
    editingField: string
    updateWorkerField: (
      field: 'phoneNumber' | 'email' | 'zipCode',
      input: string,
    ) => void
  }) => {
    const isEditing = editingField === field
    const [input, setInput] = useState<string>(value)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value)
    }

    return (
      <>
        <ListItem style={{ height: 50 }}>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            {label}:
          </Text>
          {isEditing ? (
            <Input
              placeholder={input}
              value={input}
              style={{ height: 40 }}
              onChange={handleInputChange}
              containerStyle={{ width: 170 }}
            />
          ) : (
            <Text variant="body1">{value}</Text>
          )}
          <IconButton
            onClick={() => {
              setInput('')
              handleToggleEditingField(isEditing ? '' : field)
            }}
          >
            {isEditing ? (
              <Icon name="cancel" />
            ) : (
              <Icon name="edit" color={theme.colors.Green70} />
            )}
          </IconButton>
          {isEditing ? (
            isLoading ? (
              <CircularProgress size={16} />
            ) : (
              <IconButton
                aria-label="cancel"
                onClick={() => updateWorkerField(field, input)}
                disabled={isLoading}
              >
                <Icon name="checkmark" />
              </IconButton>
            )
          ) : null}
        </ListItem>
        {!!error[field] && (
          <Text variant="error" pl={theme.space.med}>
            {error[field]}
          </Text>
        )}
      </>
    )
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleDrawerClose}>
      <S.DrawerContainer>
        <List
          subheader={
            <S.SubHeaderWrapper style={{ paddingTop: 0 }}>
              <Text variant="h5">Manage worker</Text>
              <Text variant="label" my={theme.space.xs}>
                Take action on {workerName}'s account
              </Text>
            </S.SubHeaderWrapper>
          }
        >
          <ListItem disableGutters>
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Text variant="h5">Account status</Text>
              <Text variant="label" my={theme.space.xs}>
                Manage {workerName}'s account status
              </Text>
            </ListItemText>
          </ListItem>
          <List
            component="div"
            disablePadding
            style={{ paddingTop: theme.space.xxs }}
          >
            <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
              <Row alignCenter>
                {editingAccountStatus ? (
                  <Row>
                    <Col>
                      <Select
                        containerStyle={{
                          margin: `${theme.space.xs}px 0px`,
                        }}
                        dropdownStyle={{ height: '48px' }}
                        menuItems={accountStatusMenuItems}
                        value={accountStatusValue}
                        handleSelect={setAccountStatusValue}
                      />
                      <Select
                        containerStyle={{
                          margin: `${theme.space.xs}px 0px`,
                        }}
                        dropdownStyle={{ height: '48px' }}
                        menuItems={accountStatusApprovalReasonsMenuItems}
                        value={accountStatusApprovalReason}
                        handleSelect={setAccountStatusApprovalReason}
                      />
                      <Button
                        onClick={handleAccountStatusChange}
                        loading={accountStatusLoading}
                        full
                      >
                        Save
                      </Button>
                    </Col>
                    <Row flexCol alignStart>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => setEditingAccountStatus(false)}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Icon name="cancel" />
                      </IconButton>
                    </Row>
                  </Row>
                ) : (
                  <>
                    <AccountStatusBadge
                      accountStatus={worker.accountStatus}
                      approvalStatusReason={worker.approvalStatusReason}
                      accountSuspensionExpiration={worker.suspensionExpiration}
                    />
                    <Button
                      style={{
                        display: 'inline',
                        padding: 0,
                        marginLeft: `${theme.space.xxxs}px`,
                        cursor: allowedToReinstate ? 'not-allowed' : 'pointer',
                      }}
                      variant={ButtonVariant.TRANSPARENT}
                      disabled={!allowedToEditAccountStatus}
                      onClick={() => setEditingAccountStatus(true)}
                    >
                      <Icon name="edit_active" width={18} />
                    </Button>
                  </>
                )}
              </Row>
            </ListItem>
            <Divider wrapperStyle={{ margin: theme.space.med }} />
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Text variant="label" my={theme.space.xs}>
                Terminate or reinstate {workerName}'s account
              </Text>
            </ListItemText>
            <ListItem>
              <Button
                variant={ButtonVariant.OUTLINED}
                color="primary"
                style={{ width: '100%' }}
                onClick={handleTerminateWorker}
                loading={isTerminationLoading}
                disabled={!allowedToTerminate}
              >
                Terminate Worker
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant={ButtonVariant.OUTLINED}
                color="primary"
                style={{ width: '100%' }}
                onClick={handleReinstateWorker}
                loading={isReinstateLoading}
                disabled={!allowedToReinstate}
              >
                Reinstate worker
              </Button>
            </ListItem>
          </List>
          <Divider wrapperStyle={{ margin: theme.space.med }} />
          <ListItem disableGutters>
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Text variant="h5">Instant Pay Eligibility</Text>
              <Text variant="label" my={theme.space.xs}>
                Turn on or off instant pay for {workerName}'s account
              </Text>
            </ListItemText>
          </ListItem>
          <List
            component="div"
            disablePadding
            style={{ paddingTop: theme.space.xxs }}
          >
            <ListItem>
              <Button
                variant={ButtonVariant.OUTLINED}
                color="primary"
                style={{ width: '100%' }}
                onClick={() => handleIPChange(true)}
                loading={instantPayLoading}
                disabled={ipEligible}
              >
                Enable IP
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant={ButtonVariant.OUTLINED}
                color="primary"
                style={{ width: '100%' }}
                onClick={() => handleIPChange(false)}
                loading={instantPayLoading}
                disabled={!ipEligible}
              >
                Disable IP
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant={ButtonVariant.OUTLINED}
                color="primary"
                style={{ width: '100%' }}
                onClick={() => handleIPChange(false, true)}
                loading={instantPayLoading}
                disabled={!ipEligible}
              >
                Permanent Disable IP
              </Button>
            </ListItem>
          </List>

          <Divider wrapperStyle={{ margin: theme.space.med }} />
          <ListItem disableGutters>
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Text variant="h5">Worker Metrics</Text>
            </ListItemText>
          </ListItem>
          <List
            component="div"
            disablePadding
            style={{ paddingTop: theme.space.xxs }}
          >
            <ListItem>
              <Button
                variant={ButtonVariant.OUTLINED}
                color="primary"
                style={{ width: '100%' }}
                onClick={handleRegenerateMetrics}
                loading={isMetricsLoading}
              >
                {isMetricsLoading ? 'Regenerating...' : 'Regenerate Metrics'}
              </Button>
            </ListItem>
          </List>

          <Divider wrapperStyle={{ margin: theme.space.med }} />
          <ListItem disableGutters>
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Text variant="h5">Profile</Text>
            </ListItemText>
          </ListItem>
          <List
            component="div"
            disablePadding
            style={{ paddingLeft: theme.space.xxs }}
          >
            <RenderField
              field="phoneNumber"
              label="Phone"
              value={formatPhoneNumber(worker.phoneNumber, true)}
              editingField={editing}
              updateWorkerField={updateWorkerField}
            />
            <RenderField
              field="email"
              label="Email"
              value={worker.email}
              editingField={editing}
              updateWorkerField={updateWorkerField}
            />
            <RenderField
              field="zipCode"
              label="ZIP Code"
              value={worker.zipCode}
              editingField={editing}
              updateWorkerField={updateWorkerField}
            />
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Row alignCenter mb={theme.space.xs}>
                <Text variant="h6">Gender</Text>
                <IconButton
                  onClick={() => {
                    setEditingGender(!editingGender)
                  }}
                >
                  {editingGender ? (
                    <Icon name="cancel" />
                  ) : (
                    <Icon name="edit" color={theme.colors.Green70} />
                  )}
                </IconButton>
              </Row>
            </ListItemText>
            <Col>
              <Row style={{ justifyContent: 'space-around' }}>
                <Text variant="body1">Reported Gender</Text>
                {editingGender ? (
                  <Row>
                    <Select
                      containerStyle={{
                        width: '120px',
                      }}
                      dropdownStyle={{ height: '32px' }}
                      menuItems={workerGenderMenuItems}
                      value={workerReportedGender || ''}
                      handleSelect={setWorkerReportedGender}
                    />
                    <IconButton
                      onClick={() => handleGenderChange(false)}
                      disabled={isLoading}
                    >
                      <Icon name="checkmark" />
                    </IconButton>
                  </Row>
                ) : (
                  <GenderBadge gender={worker.reportedGender} />
                )}
              </Row>
              <Row
                style={{ justifyContent: 'space-around' }}
                mt={theme.space.xs}
              >
                <Text variant="body1">Ops Reported Gender</Text>
                {editingGender ? (
                  <Row>
                    <Select
                      containerStyle={{
                        width: '120px',
                      }}
                      dropdownStyle={{ height: '32px' }}
                      menuItems={workerGenderMenuItems}
                      value={opsReportedGender || ''}
                      handleSelect={setOpsReportedGender}
                    />
                    <IconButton
                      onClick={() => handleGenderChange(true)}
                      disabled={isLoading}
                    >
                      <Icon name="checkmark" />
                    </IconButton>
                  </Row>
                ) : (
                  <GenderBadge gender={worker.opsReportedGender} />
                )}
              </Row>
            </Col>
            <ListItemText
              style={{
                paddingLeft: theme.space.xs,
              }}
            >
              <Row alignCenter mb={theme.space.xs}>
                <Text variant="h6">Work Preferences</Text>
                <IconButton
                  onClick={() => {
                    setEditingTransportation(!editingTransportation)
                  }}
                >
                  {editingTransportation ? (
                    <Icon name="cancel" />
                  ) : (
                    <Icon name="edit" color={theme.colors.Green70} />
                  )}
                </IconButton>
              </Row>
            </ListItemText>
            <Row style={{ justifyContent: 'space-around' }} mt={theme.space.xs}>
              <Text variant="body1">Ops Reported Transportation</Text>
              {editingTransportation ? (
                <Row>
                  <Select
                    containerStyle={{
                      width: '120px',
                      textAlign: 'center',
                    }}
                    dropdownStyle={{ height: '32px' }}
                    menuItems={workerTransportationMenuItems}
                    value={selectedTransportation || ''}
                    handleSelect={setSelectedTransportation}
                  />
                  <IconButton
                    onClick={() => handleTransportationChange()}
                    disabled={isLoading}
                  >
                    <Icon name="checkmark" />
                  </IconButton>
                </Row>
              ) : (
                <TransportationBadge
                  transportationMethod={
                    selectedTransportation as Transportation
                  }
                />
              )}
            </Row>
          </List>
          <MediaUploader
            label="Upload Profile Photo"
            file={file}
            onChange={onChangeFile}
            onDelete={onDeleteFile}
            onError={(error) => {
              showError(error, 'File upload/delete error. Please try again.')
            }}
            fileType="image"
            uploadButtonWidth={'100%'}
            maxFileSizeMB={1}
            hidePreview={true}
          />
        </List>
      </S.DrawerContainer>
    </Drawer>
  )
}

export default WorkerAccountManagement
