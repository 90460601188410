import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { MediaUploader } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftAsTimesheetRow } from '@traba/types'
import { ParseResult, parse } from 'papaparse'
import { Dispatch, useState } from 'react'
import { useModal } from 'src/components/base/Modal/Modal'
import { useWorkerShiftsForTimesheet } from 'src/hooks/useWorkerShiftsForTimesheet'
import { TimesheetAdjustment } from '../timesheet.types'
import TimesheetAdjustModal from './TimesheetAdjustModal'
import TimesheetTable from './TimesheetTable'

function TimesheetTableWrapper({
  shiftIds,
  parsedData,
  setParsedData,
}: {
  shiftIds: string[]
  parsedData: WorkerShiftAsTimesheetRow[]
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
}) {
  const timesheetAdjustModal = useModal()
  const [workerShiftAdjustments, setWorkerShiftAdjustments] = useState<
    TimesheetAdjustment[]
  >([])
  // States to store the original hours worked and pay before adjustments
  const [prevMinutesWorked, setPrevMinutesWorked] = useState<number>(0)
  const [prevTotalPay, setPrevTotalPay] = useState<number>(0)
  // States to store the new hours worked and pay after adjustments
  const [newMinutesWorked, setNewMinutesWorked] = useState<number>(0)
  const [newTotalPay, setNewTotalPay] = useState<number>(0)
  const {
    workerShifts = [],
    refetch: refetchWorkerShifts,
    isLoading,
  } = useWorkerShiftsForTimesheet(shiftIds)

  if (isLoading) {
    return <CircularProgress size={theme.space.xxl} />
  }
  return (
    <>
      <TimesheetTable
        parsedData={parsedData}
        openModal={timesheetAdjustModal.open}
        setWorkerShiftAdjustments={setWorkerShiftAdjustments}
        setPrevMinutesWorked={setPrevMinutesWorked}
        setPrevTotalPay={setPrevTotalPay}
        setNewMinutesWorked={setNewMinutesWorked}
        setNewTotalPay={setNewTotalPay}
        workerShifts={workerShifts}
        refetchWorkerShifts={refetchWorkerShifts}
        setParsedData={setParsedData}
      />
      <TimesheetAdjustModal
        {...timesheetAdjustModal}
        workerShiftAdjustments={workerShiftAdjustments}
        prevMinutesWorked={prevMinutesWorked}
        prevTotalPay={prevTotalPay}
        newMinutesWorked={newMinutesWorked}
        newTotalPay={newTotalPay}
      />
    </>
  )
}

export default function TrabaTimesheetUpload() {
  const { showError } = useAlert()

  const [file, setFile] = useState<File | undefined>()
  const [parsedData, setParsedData] = useState<WorkerShiftAsTimesheetRow[]>([])
  const [shiftIds, setShiftIds] = useState<string[]>([])

  function onChangeFile(f: File | undefined) {
    if (!f) {
      return
    }
    setFile(f)
    parse(f, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: {
        payRate: true,
        unitsWorked: true,
        breakTime: true,
        minimumPaidTime: true,
      },
      complete: (results: ParseResult<WorkerShiftAsTimesheetRow>) => {
        const { data } = results
        const shiftIdsSet: Set<string> = new Set()
        data.map((d) => shiftIdsSet.add(d.shiftId))
        setShiftIds(Array.from(shiftIdsSet))
        setParsedData(data)
      },
    })
  }

  async function onDeleteFile() {
    setFile(undefined)
    setParsedData([])
    setShiftIds([])
  }

  return (
    <>
      <MediaUploader
        label="Upload Traba Timesheet"
        file={file}
        onChange={onChangeFile}
        onDelete={onDeleteFile}
        onError={(error) => {
          showError(error, 'Something went wrong')
        }}
        fileType="csv"
      />
      {shiftIds.length > 0 && parsedData.length > 0 ? (
        <TimesheetTableWrapper
          shiftIds={shiftIds}
          parsedData={parsedData}
          setParsedData={setParsedData}
        />
      ) : (
        <Card
          style={{
            marginTop: theme.space.lg,
            width: '50%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Upload a CSV to get started!
          <br />
          <br />
          You can Download a CSV from a Company Page or a Shift's Details page.
          Once you make your changes to Clock Ins, Clock Outs, etc. Come back to
          compare and submit your changes!
        </Card>
      )}
    </>
  )
}
