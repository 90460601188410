import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { JobStatus, Shift, ShiftStatus } from '@traba/types'
import { addDays } from 'date-fns'
import { omitBy } from 'lodash'
import { useInfiniteQuery } from 'react-query'

export const ONE_MONTH_IN_DAYS = 30

export type ShiftSearchParams = {
  companyId: string
  startBefore?: Date
  startAfter?: Date
  endBefore?: Date
  endAfter?: Date
  startAt?: string
  startAtIndex?: string
  sortBy?: string
  sortDir?: 'asc' | 'desc'
  timeApproved?: string
  totalCharge?: string
  id?: string
  limit?: number
  regionId?: string
  status?: ShiftStatus
  statuses?: ShiftStatus[]
  supervisorId?: string
  locationId?: string
  activeLocationIds?: string[]
  workerId?: string
  workerShiftStatuses?: JobStatus[]
  shiftIds?: string[]
  shiftRequestParentIds?: string[]
}

export function useCompanyShifts(
  searchParams: ShiftSearchParams,
  queryRecurringShifts?: boolean,
) {
  const queryKey = [
    'shifts',
    searchParams.startBefore?.getDate(),
    searchParams.startAfter?.getDate(),
    searchParams.endBefore?.getDate(),
    searchParams.endAfter?.getDate(),
    searchParams.limit,
    searchParams.sortBy,
    searchParams.sortDir,
    searchParams.startAt,
    searchParams.startAtIndex,
    searchParams.timeApproved,
    searchParams.totalCharge,
    searchParams.regionId,
    searchParams.status,
    searchParams.statuses,
    searchParams.supervisorId,
    searchParams.workerId,
    searchParams.workerShiftStatuses,
    searchParams.shiftIds,
  ]
  const queryShifts = async ({
    pageParam = { start: searchParams.startAfter, end: searchParams.endBefore },
  }): Promise<{ data: Shift[]; nextPage: { start?: Date; end?: Date } }> => {
    // Remove empty string fields because post request will take them as empty strings
    searchParams = omitBy(
      searchParams,
      (value) => value === '',
    ) as ShiftSearchParams
    try {
      const res = await trabaApi.post(
        `shifts/query-company-shifts?queryRecurringShifts=${queryRecurringShifts ? true : false}`,
        {
          ...searchParams,
          startAfter: pageParam.start,
          endBefore: pageParam.end,
        },
      )
      const data = res.data.shifts

      return {
        data,
        nextPage: {
          start: pageParam.start
            ? addDays(pageParam.start, ONE_MONTH_IN_DAYS)
            : undefined,
          end: pageParam.end
            ? addDays(pageParam.end, ONE_MONTH_IN_DAYS)
            : undefined,
        },
      }
    } catch (error) {
      console.error(
        'Error querying shifts: ',
        error,
        'Query body: ',
        searchParams,
      )
      Sentry.captureException(error, {
        tags: { action: 'queryShifts' },
        extra: { searchParams },
      })
      throw error
    }
  }

  const {
    isLoading,
    isError,
    error,
    isFetched,
    isFetching,
    data: rawShifts,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<
    { data: Shift[]; nextPage?: { start?: Date; end?: Date } },
    Error
  >([queryKey], queryShifts, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.length === 0) {
        return null
      }

      return lastPage?.nextPage
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    rawShifts,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
  }
}
