import {
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  Shift,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import { useState } from 'react'
import { EditScheduleOption } from './EditScheduleOption'
import { EditScheduleRoleDetails } from './EditScheduleRoleDetails'

interface Props {
  company: Company
  firstRecurringShiftRequest: ShiftRequest
  shiftRequests: ShiftRequest[]
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  shifts?: Shift[]
  onBack: () => void
  onSuccess: () => void
}

enum EDIT_ROLE_STEPS {
  'SELECT_ROLE',
  'MODIFY_ROLE',
}

export const EditScheduleRole = ({
  company,
  firstRecurringShiftRequest,
  shiftRequestParent,
  shiftRequests,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const [curStep, setCurStep] = useState<EDIT_ROLE_STEPS>(
    EDIT_ROLE_STEPS.SELECT_ROLE,
  )
  const [selectedShiftRequest, setSelectedShiftRequest] =
    useState<ShiftRequest>()

  if (curStep === EDIT_ROLE_STEPS.SELECT_ROLE) {
    return (
      <>
        <Text variant="h5" mb={theme.space.sm}>
          Select which role you'd like to edit
        </Text>
        <Col gap={theme.space.xs}>
          {shiftRequests.map((sr) => (
            <EditScheduleOption
              key={sr.shiftRequestId}
              selected={
                selectedShiftRequest?.shiftRequestId === sr.shiftRequestId
              }
              title={sr.shiftRole}
              onClick={() => {
                setSelectedShiftRequest(sr)
              }}
            />
          ))}
        </Col>
        <Row justifyBetween mt={theme.space.sm}>
          <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
            Back
          </Button>
          <Button
            onClick={() => {
              setCurStep(EDIT_ROLE_STEPS.MODIFY_ROLE)
            }}
          >
            Next
          </Button>
        </Row>
      </>
    )
  }
  if (!selectedShiftRequest) {
    return <LoadingSpinner />
  }
  return (
    <EditScheduleRoleDetails
      shifts={shifts}
      selectedShiftRequest={selectedShiftRequest}
      shiftRequestParent={shiftRequestParent}
      firstRecurringShiftRequest={firstRecurringShiftRequest}
      company={company}
      onBack={() => setCurStep(EDIT_ROLE_STEPS.SELECT_ROLE)}
      onSuccess={onSuccess}
    />
  )
}
