import { Card, Row, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeSkillLevels,
  IconName,
  RoleAttribute,
  RoleAttributeCategory,
  WorkerProfile,
} from '@traba/types'
import { useMemo } from 'react'
import { AttributePill } from 'src/components/AttributePill/AttributePill'

interface WorkerAttributesSectionProps {
  workerId: string
  sectionTitle: string
  svgIconName: IconName
  svgIconSize?: number
  attributeCategories: RoleAttributeCategory[]
  attributes: RoleAttribute[]
  workerProfileAttributes: WorkerProfile[] | undefined
}

export function WorkerAttributesSection(props: WorkerAttributesSectionProps) {
  const {
    workerId,
    sectionTitle,
    svgIconName,
    svgIconSize = 24,
    attributeCategories,
    attributes,
    workerProfileAttributes,
  } = props

  const filteredAttributes = useMemo(() => {
    return attributes?.filter((attribute) =>
      attributeCategories.includes(attribute.category),
    )
  }, [attributes, attributeCategories])

  return (
    <Card>
      <Row alignCenter mb={theme.space.xs}>
        <SvgIcon name={svgIconName} size={svgIconSize} />
        <Text variant="h4" ml={theme.space.xxs}>
          {sectionTitle}
        </Text>
      </Row>
      <Row style={{ flexWrap: 'wrap' }} gap={theme.space.xxs}>
        {filteredAttributes.map((attribute) => {
          const opsLevel =
            workerProfileAttributes?.find(
              (workerProfile: WorkerProfile) =>
                workerProfile.profileField === attribute.type,
            )?.opsLevel ?? AttributeSkillLevels.UNREPORTED
          return (
            <AttributePill
              key={attribute.type}
              attributeType={attribute.type}
              workerId={workerId}
              attributeName={attribute.displayName}
              attributeLevel={opsLevel}
              category={attribute.category}
              levelType={attribute.levelType}
            />
          )
        })}
      </Row>
    </Card>
  )
}
