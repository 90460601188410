import { LoadingSpinner, Pill, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeBaseLevels,
  AttributeLevelType,
  AttributeSkillLevels,
  OpsAttributeLevel,
} from '@traba/types'
import { useState } from 'react'
import { useWorkerProfileMutations } from 'src/hooks/useWorkerProfile'
import { isAttributeLevelRevoked } from 'src/utils/attributeUtils'
import { AttributePillLevel } from './AttributePillLevel'
import { AttributePillMenu } from './AttributePillMenu'
import { AttributePillProps } from './types'
import { getPillColorsForAttributeLevel } from './utils'

function getWorkerProfileValueForOpsLevel(
  opsLevel: OpsAttributeLevel,
): boolean {
  switch (opsLevel) {
    case AttributeBaseLevels.REVOKED:
    case AttributeSkillLevels.REVOKED:
    case AttributeBaseLevels.UNREPORTED:
    case AttributeSkillLevels.UNREPORTED:
      return false
    case AttributeSkillLevels.REPORTED:
    case AttributeBaseLevels.PRESENT:
    case AttributeSkillLevels.LOW:
    case AttributeSkillLevels.MEDIUM:
    case AttributeSkillLevels.HIGH:
      return true
    default:
      throw new Error('Unknown ops level')
  }
}

export function AttributePill(props: AttributePillProps) {
  const {
    workerId,
    attributeName,
    attributeType,
    attributeLevel,
    levelType,
    category,
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { updateWorkerProfileOpsLevelMutation } = useWorkerProfileMutations()

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleUpdateOpsLevel(opsLevel: OpsAttributeLevel) {
    updateWorkerProfileOpsLevelMutation.mutate({
      workerId,
      items: [
        {
          category,
          opsLevel,
          profileField: attributeType,
          value: getWorkerProfileValueForOpsLevel(opsLevel),
        },
      ],
    })
  }

  const { textColor, color, hoverColor, borderColor, borderHoverColor } =
    getPillColorsForAttributeLevel(attributeLevel)

  const showAttributeLevel =
    (levelType === AttributeLevelType.SKILL_LEVELING &&
      attributeLevel !== AttributeSkillLevels.UNREPORTED &&
      attributeLevel !== AttributeSkillLevels.REPORTED) ||
    isAttributeLevelRevoked(attributeLevel)

  return (
    <>
      <Pill
        width="fit-content"
        addMargin={true}
        color={color}
        hoverColor={hoverColor}
        borderColor={borderColor}
        borderHoverColor={borderHoverColor}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          paddingLeft: theme.space.xs,
          paddingRight: theme.space.xs,
          paddingTop: theme.space.xxxs,
          paddingBottom: theme.space.xxxs,
        }}
      >
        {updateWorkerProfileOpsLevelMutation.isPending ? (
          <LoadingSpinner style={{ height: 20, width: 20 }} />
        ) : (
          <>
            <Text
              color={textColor}
              variant="h6"
              style={{ whiteSpace: 'nowrap' }}
            >
              {attributeName}
            </Text>
            {showAttributeLevel && (
              <>
                <Text
                  ml={theme.space.xxxs}
                  mr={theme.space.xxxs}
                  color={theme.colors.Grey30}
                  variant="body1"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  |
                </Text>
                <AttributePillLevel
                  attributeName={attributeName}
                  attributeLevel={attributeLevel}
                  levelType={levelType}
                />
              </>
            )}
          </>
        )}
      </Pill>
      <AttributePillMenu
        menuAnchorEl={anchorEl}
        attributeLevel={attributeLevel}
        levelType={levelType}
        handleClose={handleClose}
        handleClick={handleUpdateOpsLevel}
      />
    </>
  )
}
