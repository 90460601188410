import { trabaApi } from '@traba/api-utils'
import { openInNewTab } from 'src/utils/helperUtils'

export const useStripeInvoice = ({
  stripeInvoiceId,
}: {
  stripeInvoiceId: string
}) => {
  const getStripeInvoice = async (stripeInvoiceId: string) => {
    const response = await trabaApi.get(`/stripe/invoices/${stripeInvoiceId}`)
    return response.data
  }

  const downloadStripeInvoice = async () => {
    const stripeInvoice = await getStripeInvoice(stripeInvoiceId)
    openInNewTab(stripeInvoice.invoice_pdf)
  }

  return { downloadStripeInvoice }
}
