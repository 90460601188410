import { Company, Role } from '@traba/types'
import { useState } from 'react'

import { Modal } from 'src/components/base'
import { ModalProps } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { RoleForm } from 'src/components/Roles/RoleForm'
import { RoleStepper } from 'src/components/Roles/RoleStepper'
import { EditRoleData, useRoles } from 'src/hooks/useRoles'
import { parseFreeFormAttributesToRequiredAttributes } from 'src/utils/roleUtils'

export type EditRoleModalProps = {
  role: Role
  company: Company
  roleEditModal: Omit<ModalProps, 'children'>
  isEventCompany: boolean
}

export const EditRoleModal: React.FC<EditRoleModalProps> = (props) => {
  const { role, company, roleEditModal, isEventCompany } = props

  const { editRole } = useRoles(company.companyId)
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false)

  const handleEdit = (editRoleData: EditRoleData) => {
    setIsLoadingEdit(true)
    editRole(
      {
        roleId: role.roleId,
        companyId: company.companyId,
        editRoleData,
      },
      {
        onSettled: () => {
          setIsLoadingEdit(false)
          roleEditModal.handleClose()
        },
      },
    )
  }

  return (
    <Modal
      size={MODAL_SIZE.EXTRA_LARGE}
      {...roleEditModal}
      title={`Editing role: ${role.roleName}`}
    >
      {isEventCompany ? (
        <RoleForm
          onCancel={roleEditModal.handleClose}
          onConfirmEdit={handleEdit}
          isEdit={true}
          company={company}
          initialRoleState={{
            ...role,
            requiredCertifications: role.requiredCertifications || [],
            requiredAttributes: role.requiredAttributes || [],
            videoIds: role.videoIds || [],
          }}
          submitLoading={isLoadingEdit}
        />
      ) : (
        <RoleStepper
          onCancel={roleEditModal.handleClose}
          onConfirmEdit={handleEdit}
          isEdit={true}
          initialRoleState={{
            ...role,
            requiredCertifications: role.requiredCertifications || [],
            requiredAttributes: parseFreeFormAttributesToRequiredAttributes(
              role.requiredAttributes,
              role.freeformAttributes,
            ),
            freeformAttributes: role.freeformAttributes || {},
            videoIds: role.videoIds || [],
          }}
          fromPrevious={false}
          submitLoading={isLoadingEdit}
          company={company}
        />
      )}
    </Modal>
  )
}
