import { DEFAULT_TIMEZONE } from '@traba/consts'
import { LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Locations, PaymentType } from '@traba/types'
import {
  combineRecurringShifts,
  getEarliestStartInSchedules,
  getRecurringShifts,
} from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { Col, Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useRoles } from 'src/hooks/useRoles'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import useTimezonedDates from 'src/hooks/useTimezonedDates'

import { calculateEstimatedPay } from 'src/modals/EditShiftModal/utils'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import {
  calculatePaidShiftTime,
  getScheduledBreakTotal,
} from 'src/utils/moneyUtils'
import { ShiftScheduleSummary } from '../ShiftScheduleSummary'

interface Props {
  setShowConfirmationDialog: Dispatch<SetStateAction<boolean>>
  showConfirmationDialog: boolean
  createShiftRequests: CreateShiftRequest[]
  timezone: string
  companyId: string
  selectedLocation: Locations | undefined
  handleSubmitShiftRequest: (navigateToCompany: boolean) => Promise<void>
  businessStartTime: Date | null
  selectedSingleShiftDates: Date[] | null
}

const getShiftCountForRecurring = (sr: CreateShiftRequest) => {
  const [scheduleA, scheduleB] = sr.schedules
  const { startTime, recurringSchedule } = scheduleA

  if (!recurringSchedule) {
    return {
      shiftCount: 1,
      allShiftDates: [startTime],
    }
  }
  const recurringShiftsA = getRecurringShifts(scheduleA)
  if (scheduleB) {
    const recurringShiftsB = getRecurringShifts(scheduleB)
    return combineRecurringShifts(recurringShiftsA, recurringShiftsB)
  }
  return recurringShiftsA
}

export const ConfirmShiftRequestCreationDialog = ({
  setShowConfirmationDialog,
  showConfirmationDialog,
  createShiftRequests,
  timezone,
  selectedLocation,
  handleSubmitShiftRequest,
  businessStartTime,
  selectedSingleShiftDates,
  companyId,
}: Props) => {
  const tz = useTimezonedDates(timezone)
  const { roles, isLoading: rolesLoading } = useRoles(companyId)
  const earlyArrivalTimeBufferInMinutes = useMemo(
    () =>
      getEarlyArrivalTimeBufferInMinutes({
        shiftStartTime: createShiftRequests[0].schedules[0].startTime,
        businessStartTime,
      }),
    [businessStartTime, createShiftRequests[0].schedules],
  )

  const startDateText = useMemo(() => {
    if (!selectedSingleShiftDates || selectedSingleShiftDates.length === 0) {
      if (
        createShiftRequests[0].schedules.length === 1 &&
        !createShiftRequests[0].schedules[0].isRecurringSchedule
      ) {
        return `Date: ${tz.getShiftDate(
          createShiftRequests[0].schedules[0].startTime,
          createShiftRequests[0].schedules[0].endTime,
        )}`
      }
      return `First shift date: ${getEarliestStartInSchedules(
        createShiftRequests[0].schedules,
      ).toLocaleDateString()}`
    } else {
      return (
        'Dates: ' +
        selectedSingleShiftDates
          .map((date) => tz.getShiftDate(date, date))
          .join(', ')
      )
    }
  }, [createShiftRequests[0].schedules, selectedSingleShiftDates, tz])

  if (rolesLoading) {
    return <LoadingSpinner />
  }
  return (
    <ConfirmationDialog
      title="Confirm shift requests creation"
      open={showConfirmationDialog}
      onClose={() => setShowConfirmationDialog(false)}
      content={
        <Row style={{ width: 500, minHeight: 200 }}>
          <Col>
            <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
              Shift summary - {createShiftRequests.length} roles
            </Text>
            <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
              {startDateText}
            </Text>
            <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
              Time:{' '}
              {tz.getShiftTime(
                createShiftRequests[0].schedules[0].startTime,
                createShiftRequests[0].schedules[0].endTime,
              )}
            </Text>
            {earlyArrivalTimeBufferInMinutes !== undefined && (
              <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
                Early Arrival Buffer: {earlyArrivalTimeBufferInMinutes} minutes
              </Text>
            )}
            {createShiftRequests.map((sr) => {
              const totalShiftTime = differenceInMinutes(
                sr.schedules[0].endTime,
                sr.schedules[0].startTime,
              )

              const totalBreakTime = getScheduledBreakTotal(
                sr.scheduledBreaks || [],
              )

              const totalPaidTime = calculatePaidShiftTime(
                totalBreakTime,
                totalShiftTime,
                sr.breakType,
              )

              const { payString: estimatedPay, pay } = calculateEstimatedPay(
                sr.payRate,
                sr.payType,
                totalPaidTime,
                sr.numberOfUnits || 0,
                sr.slotsRequested,
              )
              const role = roles?.find((r) => r.roleId === sr.roleId)
              const { shiftCount } = getShiftCountForRecurring(sr)
              const isRecurringWithNoEnd =
                !!sr.schedules[0].isRecurringSchedule &&
                !sr.schedules[0].recurringSchedule?.endDate
              return (
                <>
                  <Divider />
                  <Text variant="h6" mt={theme.space.xs}>
                    {role?.roleName}
                  </Text>
                  <Text
                    variant="body3"
                    style={{ marginBottom: theme.space.xxs }}
                  >
                    Pay per worker shift: {estimatedPay}
                  </Text>
                  {!isRecurringWithNoEnd && (
                    <Text
                      variant="body3"
                      style={{ marginBottom: theme.space.xxs }}
                    >
                      Total pay: ${pay.toFixed(2)} *{' '}
                      {selectedSingleShiftDates?.length ?? 1} shifts *{' '}
                      {sr.slotsRequested} workers = $
                      {pay *
                        (selectedSingleShiftDates?.length ?? shiftCount) *
                        sr.slotsRequested}
                    </Text>
                  )}
                  {sr.paymentType === PaymentType.Auto && (
                    <Text variant="error">
                      You are about to post a shift with payment type AUTOMATIC,
                      are you sure?
                    </Text>
                  )}
                  <ShiftScheduleSummary
                    shiftRequest={sr}
                    shiftTimeZone={
                      selectedLocation?.timezone || DEFAULT_TIMEZONE
                    }
                  />
                </>
              )
            })}
          </Col>
        </Row>
      }
      onConfirm={() => handleSubmitShiftRequest(true)}
      secondaryConfirmButton
      secondaryConfirmButtonText="Confirm & Post Again"
      secondaryConfirmButtonAction={() => handleSubmitShiftRequest(false)}
    />
  )
}
