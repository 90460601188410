import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { TabPanel, TabsContainer } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyResponse } from '@traba/types'
import { InvoiceStatus } from '@traba/types'
import { compact } from 'lodash'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, Col, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import RegionsSelector from 'src/components/selectors/RegionsSelector'
import { useCompanies } from 'src/hooks/useCompanies'
import useRegionsSelector from 'src/hooks/useRegionsSelector'
import InvoicesResults from './components/InvoicesResults'
import UninvoicedShifts from './components/UninvoicedShifts'

// Other statuses will be shown in an 'Other' column
const INVOICE_TAB_COLUMNS = [
  { invoiceStatus: InvoiceStatus.DRAFT, columnName: 'Draft' },
  { invoiceStatus: InvoiceStatus.OPEN, columnName: 'Open' },
  { invoiceStatus: InvoiceStatus.PAID, columnName: 'Paid' },
  { invoiceStatus: InvoiceStatus.VOID, columnName: 'Void' },
]

export default function BillingScreen() {
  /* Status Tab */
  const [statusTabValue, setStatusTabValue] = useState(0)

  const handleStatusTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setStatusTabValue(newValue)
  }

  /* Input States */
  const [search, setSearch] = useSearchParams()

  /* Fetch Global Hook Data */

  const { companies, isLoading: isLoadingCompanies } = useCompanies({
    isApproved: true,
  })

  /* Get defaults from URL */
  const regions = search.getAll('region')
  const company = search.getAll('company')

  const currentUrlParams = {
    region: regions,
    company: company,
  }

  const setRegionIds = (ids: string[]) => {
    setSearch({
      ...currentUrlParams,
      region: ids,
    })
  }

  /* Regions */
  const {
    isLoadingRegions,
    regionMenuOptions,
    selectedRegions,
    selectedRegionIds,
  } = useRegionsSelector(regions, setRegionIds)

  /* Companies */
  const companyToMenuItem = (company: CompanyResponse) =>
    ({
      label: company.employerName,
      value: company.id,
    }) as IMenuItem
  const companyIdToCompany = (companyId: string) => {
    const selectedCompany = companies?.findLast(
      (company) => company.id === companyId,
    )
    return selectedCompany
  }
  const companyIdToMenuItem = (companyId: string) => {
    const selectedCompany = companyIdToCompany(companyId)
    return selectedCompany ? companyToMenuItem(selectedCompany) : null
  }
  const menuItemToCompanyId = (menuItem: IMenuItem) => menuItem.value

  const companyMenuOptions: IMenuItem[] = companies
    ? companies.map(companyToMenuItem)
    : []

  const menuCompanies = compact(company.map(companyIdToMenuItem))

  const handleUpdateMenuCompanies = (newMenuCompanies: IMenuItem[]) => {
    setSearch({
      ...currentUrlParams,
      company: newMenuCompanies.map(menuItemToCompanyId),
    })
  }

  const selectors = (
    <Row gap={theme.space.xxs}>
      {/* Companies */}
      <SearchSelect
        multiple
        onlyShowLabel
        options={companyMenuOptions}
        selectedItems={menuCompanies}
        handleSelectMultiple={handleUpdateMenuCompanies}
        multipleNoneSelectedLabel={'All'}
        label={'Companies'}
        width={260}
        showClearButton
      />
      {/* Regions */}
      <RegionsSelector
        regionMenuOptions={regionMenuOptions}
        selectedItems={selectedRegionIds}
        handleSelectMultiple={setRegionIds}
      />
    </Row>
  )

  const tabHeaders = (
    <TabsContainer>
      <Tabs
        value={statusTabValue}
        onChange={handleStatusTabChange}
        visibleScrollbar
        sx={{
          '.MuiTab-root': {
            fontFamily: 'Poppins',
            textTransform: 'none',
            fontWeight: 'bold',
          },
        }}
      >
        {INVOICE_TAB_COLUMNS.map((item) => {
          return <Tab key={item.columnName} label={item.columnName} />
        })}
        <Tab label="Past Due" />
        <Tab label="Other" />
        <Tab label="Uninvoiced Shifts" />
      </Tabs>
    </TabsContainer>
  )

  const filteredRegions = compact(selectedRegions)
  const filteredCompanies = compact(
    menuCompanies.map(menuItemToCompanyId).map(companyIdToCompany),
  )

  const invoiceResultForStatuses = (
    statuses: InvoiceStatus[],
    showStatus?: boolean,
    dueDateMax?: Date,
  ) => {
    return (
      <InvoicesResults
        statuses={statuses}
        regions={filteredRegions}
        companies={filteredCompanies}
        showStatus={showStatus}
        dueDateMax={dueDateMax}
      />
    )
  }

  const tabContent = (
    <Col>
      {/* // Tabs for Invoice Statuses (Draft, Open, Paid, Void) */}
      {INVOICE_TAB_COLUMNS.map((item, index) => {
        return (
          <TabPanel key={item.columnName} value={statusTabValue} index={index}>
            {invoiceResultForStatuses([item.invoiceStatus])}
          </TabPanel>
        )
      })}
      <TabPanel // Past Due Tab
        key={INVOICE_TAB_COLUMNS.length}
        value={statusTabValue}
        index={INVOICE_TAB_COLUMNS.length}
      >
        {/* Past Due = Open Invoices with due date before now. */}
        {invoiceResultForStatuses([InvoiceStatus.OPEN], false, new Date())}
      </TabPanel>
      <TabPanel // Other Tab
        key={INVOICE_TAB_COLUMNS.length + 1}
        value={statusTabValue}
        index={INVOICE_TAB_COLUMNS.length + 1}
      >
        {invoiceResultForStatuses(
          Object.values(InvoiceStatus).filter(
            (status: InvoiceStatus) =>
              !INVOICE_TAB_COLUMNS.map((item) => item.invoiceStatus).includes(
                status,
              ),
          ),
          true,
        )}
      </TabPanel>
      <TabPanel // Uninvoiced Shifts Tab
        key={INVOICE_TAB_COLUMNS.length + 2}
        value={statusTabValue}
        index={INVOICE_TAB_COLUMNS.length + 2}
      >
        <UninvoicedShifts
          regions={filteredRegions}
          companies={filteredCompanies}
        />
      </TabPanel>
    </Col>
  )

  return (
    <MainLayout title="Billing">
      <Col>
        <Row>
          <Col>
            <Text variant="h3">Invoices Dashboard</Text>
          </Col>
        </Row>
        <Row mt={theme.space.sm}>
          <div style={{ flex: 1 }}>{selectors}</div>
          <Link to={'/billing/invoices/new'}>
            <Button
              variant={ButtonVariant.FILLED}
              leftIcon={<Plus color="white" />}
              iconPadding="0 10px 0 0"
            >
              Create new
            </Button>
          </Link>
        </Row>
        {tabHeaders}
        <Row justifyCenter>
          {isLoadingRegions || isLoadingCompanies ? (
            <CircularProgress size={20} />
          ) : (
            tabContent
          )}
        </Row>
      </Col>
    </MainLayout>
  )
}
