import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus } from '@traba/types'
import { format, startOfDay } from 'date-fns'
import Badge from '.'

type StripeAccountPayoutsBadgeProps = {
  accountStatus: AccountStatus
}

const getBadgeProps = ({
  payoutsEnabled,
  payoutsDisabledDeadline,
  payoutsDisabledReason,
}: {
  payoutsEnabled: boolean
  payoutsDisabledDeadline?: Date
  payoutsDisabledReason?: string
}): { title: string; variant: string; tooltip?: string } | undefined => {
  if (!payoutsEnabled) {
    return {
      title: 'Payouts Disabled',
      variant: 'danger',
      tooltip: payoutsDisabledReason && `Reason: ${payoutsDisabledReason}`,
    }
  }

  const deadlineTodayOrLater =
    payoutsDisabledDeadline &&
    startOfDay(new Date(payoutsDisabledDeadline)) >= startOfDay(new Date())
  if (!deadlineTodayOrLater) {
    return
  }

  return {
    title: 'Payouts Disabled Soon',
    variant: 'warning',
    tooltip: `Will be disabled on ${format(
      new Date(payoutsDisabledDeadline),
      'MMM d, yyyy',
    )}`,
  }
}

export default function StripeAccountPayoutsBadge(
  props: StripeAccountPayoutsBadgeProps,
) {
  const { payment } = props.accountStatus

  if (
    !payment ||
    payment.payoutsEnabled === undefined ||
    payment.payoutsEnabled === null
  ) {
    return
  }

  const badgeProps = getBadgeProps({
    payoutsEnabled: payment.payoutsEnabled,
    payoutsDisabledDeadline: payment.payoutsDisabledDeadline,
    payoutsDisabledReason: payment.payoutsDisabledReason,
  })

  if (!badgeProps) {
    return
  }

  return (
    <>
      <Badge
        title={badgeProps.title}
        variant={badgeProps.variant}
        style={{ marginLeft: theme.space.xxs }}
      />
      {badgeProps.tooltip && <InfoTooltip title={badgeProps.tooltip} />}
    </>
  )
}
