import { Text } from '@traba/react-components'
import { Card, CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Role } from '@traba/types'
import { useState } from 'react'
import { Button, Icon, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useRoles } from 'src/hooks/useRoles'

export type ArchiveRoleModalProps = {
  companyId: string
  role: Role
  handleClose: () => void
  isOpen: boolean
}

export const ArchiveRoleModal = (props: ArchiveRoleModalProps) => {
  const { isOpen, handleClose, companyId, role } = props
  const { archiveRole } = useRoles(companyId)
  const [loading, setLoading] = useState(false)

  const handleArchiveClick = async () => {
    try {
      setLoading(true)
      archiveRole(
        {
          roleId: role.roleId,
          companyId: companyId,
        },
        {
          onSettled: () => {
            setLoading(false)
            handleClose()
          },
        },
      )
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Archive Role"
      isOpen={isOpen}
      handleClose={handleClose}
      size={MODAL_SIZE.LARGE}
    >
      <Text variant="body2">
        Archiving a role will remove it from the dropdown list when creating a
        shift. However, the role information will continue to show up for any
        previous shift it was tied to.
      </Text>

      <Text
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        variant="h5"
      >
        Role to Archive
      </Text>
      <Card>
        <div>
          <Row alignCenter justifyBetween>
            <Row justifyCenter>
              <CardTile size="56px">
                <Icon name="backPack" />
              </CardTile>
              <span style={{ marginLeft: theme.space.sm }}>
                <Text variant="h5">{role.roleName}</Text>
              </span>
            </Row>
          </Row>
          <div style={{ marginTop: theme.space.med }}>
            <Text variant="caption">DESCRIPTION</Text>
            <Text>{role.roleDescription}</Text>
          </div>
        </div>
      </Card>

      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={() => props.handleClose()}
        >
          Back
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          onClick={handleArchiveClick}
          loading={loading}
        >
          Archive
        </Button>
      </Row>
    </Modal>
  )
}
