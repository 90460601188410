import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyCategory } from '@traba/types'
import { ChangeEvent, useEffect, useState } from 'react'
import { Row, Input, Button, Icon } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompanies } from 'src/hooks/useCompanies'
import CompanySearchResults from './components/CompanySearchResults'

export default function CompanySearchScreen() {
  const [approvedOnly, setApprovedOnly] = useState<boolean>(true)
  const [employerName, setEmployerName] = useState<string>('')

  const companyCategories = Object.values(CompanyCategory) as string[]
  const companyCategoriesMenuItems: IMenuItem[] = companyCategories.map(
    (category) => {
      return { label: category, value: category }
    },
  )

  const [companyCategoriesValue, setCompanyCategoriesValue] = useState<
    IMenuItem[]
  >([])

  const {
    companies = [],
    isLoading,
    error,
    refetch,
  } = useCompanies({
    ...(approvedOnly ? { isApproved: true } : {}),
    ...(employerName ? { employerName } : {}),
    ...(companyCategoriesValue.length > 0
      ? {
          categories: companyCategoriesValue.map(
            (item) => item.value,
          ) as CompanyCategory[],
        }
      : {}),
  })

  useEffect(() => {
    const handleEnterKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        refetch()
      }
    }

    window.addEventListener('keydown', handleEnterKey)
    return () => {
      window.removeEventListener('keydown', handleEnterKey)
    }
  }, [refetch])

  return (
    <MainLayout>
      <Text variant="h4">Company Search</Text>
      <Row mt={theme.space.xs} alignCenter justifyBetween>
        <Row alignCenter>
          <Row mr={theme.space.xs}>
            <Input
              label="Company Name"
              value={employerName}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setEmployerName(ev.target.value)
              }
              style={{ height: 40, width: 260 }}
            />
          </Row>
          <Row mr={theme.space.xs}>
            <SearchSelect
              multiple
              onlyShowLabel
              options={companyCategoriesMenuItems}
              selectedItems={companyCategoriesValue}
              handleSelectMultiple={setCompanyCategoriesValue}
              label={`Categories`}
              width={260}
            />
          </Row>
          <Row mr={theme.space.xs}>
            <Checkbox
              label="Approved Only"
              textvariant="h7"
              checked={approvedOnly}
              onChange={(e) => {
                setApprovedOnly(e.target.checked)
              }}
            />
          </Row>
        </Row>
        <Button
          leftIcon={<Icon name="search" />}
          loading={isLoading}
          onClick={() => refetch()}
          style={{ width: 220 }}
        >
          Search
        </Button>
      </Row>
      <CompanySearchResults
        error={error}
        isLoading={isLoading}
        companies={companies}
      />
    </MainLayout>
  )
}
