import { TrainingShift, TrainingShiftStatus } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { ONE_HOUR_IN_MS } from 'src/libs/constants'

async function getWorkerTrainingShifts(
  workerId?: string,
): Promise<TrainingShift[] | undefined> {
  try {
    if (!workerId) {
      return []
    }
    const res = await trabaApi.get(`training-shift/worker/${workerId}`)
    return res.data
  } catch (error: any) {
    console.error(
      'useWorkerTrainingShifts -> getWorkerTrainingShifts() ERROR',
      error.message ?? error,
    )
  }
}

export function useWorkerTrainingShifts(workerId?: string) {
  const {
    isLoading,
    isError,
    data: workerTrainingShifts,
    error,
    isFetched,
    refetch,
  } = useQuery<TrainingShift[] | undefined, Error>(
    `workerTrainingShifts_${workerId}`,
    async () => {
      const res = await getWorkerTrainingShifts(workerId)
      return res
    },
    {
      refetchInterval: ONE_HOUR_IN_MS,
    },
  )
  const noShows = workerTrainingShifts?.filter(
    (ts) => ts.status === TrainingShiftStatus.NoShow,
  )
  const completedShifts = workerTrainingShifts?.filter(
    (ts) => ts.status === TrainingShiftStatus.Complete,
  )
  const completedShiftsWithScore = workerTrainingShifts?.filter(
    (ts) => ts.status === TrainingShiftStatus.Complete && ts.quizScore !== null,
  )
  let totalScore = 0
  completedShiftsWithScore?.forEach(
    (shift) => (totalScore += shift.quizScore === null ? 0 : shift.quizScore),
  )

  const trainingSummary = `Total number of Completion: ${completedShifts?.length};
  Total number of No Shows:  ${noShows?.length ?? 0};
  Average quiz score: ${
    completedShiftsWithScore && completedShiftsWithScore.length > 0
      ? (totalScore / completedShiftsWithScore?.length).toFixed(2)
      : 'Not availble'
  }`
  return {
    isLoading,
    isError,
    workerTrainingShifts,
    error,
    isFetched,
    refetch,
    trainingSummary,
  }
}
