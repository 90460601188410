import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { useState } from 'react'
import { Button } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'

export default function useInvoiceActions({
  actionFn,
  refetchFn,
  successMessage,
  errorMessage,
  modalConfirmationTitle,
  modalContent,
  buttonVariant,
  buttonText,
  successFn,
}: {
  actionFn: () => Promise<void>
  refetchFn: () => void
  successMessage: string
  errorMessage: string
  modalConfirmationTitle: string
  modalContent: string | React.ReactNode
  buttonVariant: ButtonVariant
  buttonText: string
  successFn?: () => void
}) {
  const { showError, showSuccess } = useAlert()
  const [showModal, setShowModal] = useState(false)
  const [showButtonLoading, setShowButtonLoading] = useState(false)

  const onConfirmAction = async () => {
    setShowModal(false)
    setShowButtonLoading(true)

    try {
      await actionFn()
      refetchFn()
      showSuccess(successMessage)
      setShowButtonLoading(false)
      successFn?.()
    } catch (error: any) {
      showError(`${errorMessage}: ${error.message}`)
      setShowButtonLoading(false)
    }
  }

  const modal = (
    <ConfirmationDialog
      open={showModal}
      onClose={() => setShowModal(false)}
      onConfirm={onConfirmAction}
      title={modalConfirmationTitle}
      confirmButtonText={buttonText}
      content={
        typeof modalContent === 'string' ? (
          <Text>{modalContent}</Text>
        ) : (
          modalContent
        )
      }
    />
  )

  const button = ({
    disabled,
    tooltipText,
  }: {
    disabled?: boolean
    tooltipText?: string
  }) => {
    return (
      <Button
        variant={buttonVariant}
        onClick={() => setShowModal(true)}
        disabled={disabled}
        tooltipText={tooltipText}
      >
        {showButtonLoading ? (
          <CircularProgress color="inherit" size={10} />
        ) : (
          buttonText
        )}
      </Button>
    )
  }

  return {
    button,
    isLoading: showButtonLoading,
    modal,
  }
}
