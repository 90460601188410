import * as Sentry from '@sentry/react'
import { CoordinatesDto } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export type WorkerZipCodes = {
  workerId: string
  zipCode?: string
}[]

const getWorkerDistances = async (
  workerZipCodes: WorkerZipCodes,
  shiftCoords: CoordinatesDto,
) => {
  try {
    const { data } = await trabaApi.post(`/google/query-geocode`, {
      workerZipCodes,
      shiftCoords,
    })
    return data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}

export const useWorkerCoordinates = (
  workerZipCodes: WorkerZipCodes,
  shiftCoords: CoordinatesDto,
) => {
  const {
    isLoading,
    isError,
    data: workerDistances,
    error,
    isFetched,
  } = useQuery(
    `${shiftCoords.latitude}_${shiftCoords.longitude}_workerCoords`,
    () => getWorkerDistances(workerZipCodes, shiftCoords),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    workerDistances,
  }
}
