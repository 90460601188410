import * as Sentry from '@sentry/react'
import { PaidBackup } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export enum PaidBackupAction {
  ACCEPT_SHIFT = 'accept-shift',
  DISMISS = 'dismiss',
  CANCEL = 'cancel',
}

async function getPaidBackups(
  shiftId: string,
): Promise<PaidBackup[] | undefined> {
  try {
    const { data } = await trabaApi.get(`paid-backup/shifts/${shiftId}`)
    return data
  } catch (error: any) {
    console.error(
      'usePaidBackups -> getPaidBackups() ERROR',
      error.message ?? error,
    )
    Sentry.captureException(error)
  }
}

export function usePaidBackups(shiftId: string) {
  const {
    isLoading,
    isError,
    data: paidBackups,
    error,
    isFetched,
    refetch,
  } = useQuery<PaidBackup[] | undefined, Error>(
    `paidBackups_${shiftId}`,
    () => getPaidBackups(shiftId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const updatePaidBackup = async (
    workerId: string,
    action: PaidBackupAction,
  ): Promise<PaidBackup | undefined> => {
    try {
      const { data } = await trabaApi.patch(`paid-backup/${action}`, {
        shiftId,
        workerId,
      })
      return data
    } catch (error: any) {
      console.error(
        'usePaidBackups -> updatePaidBackup() ERROR',
        error.message ?? error,
      )
      Sentry.captureException(error)
      throw error
    }
  }

  return {
    isLoading,
    isError,
    paidBackups,
    error,
    isFetched,
    refetch,
    updatePaidBackup,
  }
}
