import { InputAdornment, TextField } from '@mui/material'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Row, Table, Td, Tr } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getErrorMessage } from 'src/utils/errorUtils'
import { SummaryRow } from '../SummaryRow/SummaryRow'

type AdjustmentFormProps = {
  handleClose: () => void
  shift: Shift
  workerShifts: WorkerShift[]
}

export function FutureAdjustmentForm({
  handleClose,
  shift,
  workerShifts,
}: AdjustmentFormProps) {
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()
  const { state: userContext } = useUserContext()

  /*
  States
  */
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const [workerShiftAdjustments, setWorkerShiftAdjustments] = useState({
    shiftInfo: {
      payRate: workerShifts[0]?.shiftInfo.payRate ?? 0,
      hourlyRate: workerShifts[0]?.shiftInfo.payRate ?? 0,
    },
  })

  const [loading, setLoading] = useState<boolean>(false)

  /*
  Callback Functions
  */

  const onChangePayRate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const parsedRate = parseFloat(ev.currentTarget.value)
    setWorkerShiftAdjustments({
      ...workerShiftAdjustments,
      shiftInfo: {
        ...workerShiftAdjustments.shiftInfo,
        payRate: parsedRate,
        hourlyRate: parsedRate,
      },
    })
    setIsDirty(true)
  }

  const onConfirm = async () => {
    setLoading(true)
    try {
      const patchPromises = workerShifts.map((workerShift) =>
        trabaApi.patch(
          `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}`,
          workerShiftAdjustments,
        ),
      )
      await Promise.all(patchPromises)
      window.analytics.track('Future Worker Shift Adjusted From Console', {
        workerIds: workerShifts.map((ws) => ws.workerId),
        shiftId: workerShifts.map((ws) => ws.shiftId),
        adjustedBy: userContext.userProfile?.email,
      })
      handleClose()
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Adjusting Worker')
    }
    refetchActiveQueries()
    handleClose()
    setLoading(false)
  }

  const adjustedPayRate = workerShiftAdjustments.shiftInfo?.payRate
  const oldPayRate = shift.payRate

  return (
    <Row
      flexCol
      fullWidth
      fullHeight
      style={{ flexGrow: 1, justifyContent: 'space-between' }}
    >
      <Row flexCol>
        <Row style={{ marginBottom: theme.space.xs }}>
          <Text variant="h5">Pay Rate</Text>
        </Row>
        <TextField
          margin="dense"
          style={{ width: '100%' }}
          placeholder="Pay rate"
          label="Pay rate"
          value={isNaN(adjustedPayRate || NaN) ? '' : adjustedPayRate}
          onChange={onChangePayRate}
          inputMode="decimal"
          inputProps={{ step: 'any' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            type: 'number',
          }}
          variant="outlined"
        />
      </Row>
      <Row flexCol gap={12}>
        <Text variant="h5">Summary</Text>
        <Text variant="body2">
          {`A summary of the changes made by this adjustment to ${workerShifts.length} workers 
          for the upcoming shift. Please verify that the adjustment details are correct.`}
        </Text>
        <Row fullWidth center flexCol>
          <Table headers={['', 'Old', 'New']}>
            {/* Pay Rate */}
            <Tr>
              <Td>
                <Text variant="caption">PAY RATE</Text>
              </Td>
              <SummaryRow
                original={oldPayRate}
                adjusted={adjustedPayRate}
                originalString={`$${oldPayRate}`}
                adjustedString={`$${
                  !isNaN(adjustedPayRate || NaN) ? adjustedPayRate : 0
                }`}
              />
            </Tr>
          </Table>
        </Row>
      </Row>
      <Row flexCol>
        <Divider />
        <ModalButtons
          handleClose={handleClose}
          handleConfirm={onConfirm}
          loading={loading}
          isDisabled={
            !isDirty || isNaN(adjustedPayRate || NaN) || adjustedPayRate === 0
          }
        />
      </Row>
    </Row>
  )
}
