import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import { ChangeEvent, useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Button, Col, Input, Modal, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import NumberSlider from 'src/components/base/NumberSlider'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useComplaints } from 'src/hooks/useComplaints'
import { complaintDisplayName } from 'src/utils/complaintUtils'
import { getErrorMessage } from 'src/utils/errorUtils'

type BusinessComplaintModalProps = {
  handleClose: () => void
  isOpen: boolean
  shift: Shift
}

export default function BusinessComplaintModal({
  isOpen,
  handleClose,
  shift,
}: BusinessComplaintModalProps) {
  /**
   * Hooks
   */
  const { complaints } = useComplaints()
  const { showError, showSuccess } = useAlert()

  /**
   * Build Biz complaint types
   */
  const businessComplaintTypes: Array<IMenuItem> = complaints
    ? complaints
        .map((complaint) => {
          return {
            label: complaintDisplayName(complaint.complaintId),
            value: complaint.complaintId,
          }
        })
        .sort((a, b) => {
          // Put 'other' options at the bottom of the list
          if (a.label.includes('OTHER')) {
            return 1
          } else if (b.label.includes('OTHER')) {
            return -1
          } else {
            return a.label.localeCompare(b.label)
          }
        })
    : []

  /**
   * States
   */
  const [complaintTag, setComplaintTag] = useState<string>(
    businessComplaintTypes[0]?.value?.toString(),
  )

  const [complaintReason, setComplaintReason] = useState<string>('')
  const [severity, setSeverity] = useState<number>(1)
  const [requiresManualInvoiceAdjustment, setRequiresManualInvoiceAdjustment] =
    useState<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await trabaApi.post(`/companies/${shift.companyId}/business-complaints`, {
        complaintId: complaintTag,
        severity,
        shiftId: shift.id,
        complaintReason,
        requiresManualInvoiceAdjustment,
      })
    } catch (e: unknown) {
      setLoading(false)
      handleClose()
      showError(getErrorMessage(e), 'Error Submitting Business Complaint')
      return
    }

    setLoading(false)
    handleClose()
    showSuccess('Successfully submitted complaint', 'Success!')
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Add Business Complaint'}
      size={MODAL_SIZE.MEDIUM}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Row fullWidth mb={theme.space.xs} style={{ flex: 1 }}>
          <Col
            mb={theme.space.xs}
            style={{
              width: '100%',
              display: 'flex',
            }}
          >
            <Text variant="h5">Type</Text>
            <Select
              containerStyle={{ margin: `${theme.space.xs}px 0px` }}
              menuItems={businessComplaintTypes}
              value={complaintTag}
              handleSelect={(value) => {
                setComplaintTag(value)
              }}
            />
            <Text variant="caption" style={{ paddingLeft: theme.space.xxxs }}>
              {
                complaints?.find(
                  (complaint) => complaint.complaintId === complaintTag,
                )?.tagDescription
              }
            </Text>

            <Row mt={theme.space.lg} mb={theme.space.lg}>
              <NumberSlider
                value={severity}
                setValue={setSeverity}
                rangeMin={1}
                rangeMax={5}
                step={1}
                label={'Severity'}
              />
            </Row>

            <Text variant="h5">Complaint details</Text>
            <Input
              containerStyle={{
                marginTop: theme.space.xs,
                marginBottom: theme.space.med,
              }}
              full
              placeholder="Add your notes..."
              rows={3}
              type="textarea"
              value={complaintReason}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setComplaintReason(ev.target.value)
              }
            />

            <Checkbox
              checked={requiresManualInvoiceAdjustment}
              label="Requires manual invoice adjustment"
              textvariant="body1"
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                setRequiresManualInvoiceAdjustment(ev.target.checked)
              }}
            />
          </Col>
        </Row>
        <Row fullWidth justifyBetween>
          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ width: '200px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '200px' }}
            onClick={handleSubmit}
            loading={loading}
          >
            Submit
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
