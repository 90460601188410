import { CircularProgress, ToggleButton } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentLog, PaymentLogEarnings } from '@traba/types'
import { format, isAfter, isBefore } from 'date-fns'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Button, Col, Icon, Input, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import ToggleButtonGroup from 'src/components/ToggleButtonGroup'
import { useEarnings } from 'src/hooks/useEarnings'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { usePaymentLogs } from 'src/hooks/usePaymentLogs'
import { anyToDate } from 'src/shared/utils/dateUtils'
import { getLocalTimezone } from 'src/utils/dateUtils'
import { PaymentLogEarningsRow } from './PaymentHistory/PaymentLogEarningsRow'
import { PaymentLogRow } from './PaymentHistory/PaymentLogRow'

interface PaymentHistoryTabProps {
  workerId: string
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
}

export const PaymentHistoryTab = ({
  workerId,
  dateRange,
  setDateRange,
}: PaymentHistoryTabProps) => {
  const { hotSettings } = useHotSettings()
  const [searchInput, setSearchInput] = useState<string>('')
  const localTimeZone = getLocalTimezone()
  const [showGroupedPaymentLogs, setShowGroupedPaymentLogs] = useState(true)

  const {
    paymentLogs = [],
    refetchPaymentLogs,
    isLoading: isLoadingPaymentLogs,
    isFetched: isFetchedPaymentLogs,
  } = usePaymentLogs(workerId, {
    after: dateRange[0]?.toISOString() ?? '',
    before: dateRange[1]?.toISOString() ?? '',
    cutOffDate: hotSettings?.newEarningsScreenCutoffDate,
  })

  const {
    earnings = [],
    refetchEarnings,
    isLoadingEarnings,
    isFetched: isFetchedEarnings,
  } = useEarnings(workerId)

  const isLoading =
    (showGroupedPaymentLogs && isLoadingEarnings) || isLoadingPaymentLogs
  const isFetched =
    (showGroupedPaymentLogs && isFetchedEarnings) || isFetchedPaymentLogs

  const filteredEarnings = useMemo(() => {
    return earnings.filter(
      (earning) =>
        (dateRange[0]
          ? isAfter(anyToDate(earning.latestPaymentLogCreatedAt), dateRange[0])
          : true) &&
        (dateRange[1]
          ? isBefore(anyToDate(earning.latestPaymentLogCreatedAt), dateRange[1])
          : true) &&
        (searchInput ? earning.trabaEntityId.includes(searchInput) : true),
    )
  }, [earnings, dateRange[0], dateRange[1], searchInput])

  const filteredPaymentLogs = useMemo(() => {
    return paymentLogs.filter((paymentLog) =>
      searchInput ? paymentLog.trabaEntityId.includes(searchInput) : true,
    )
  }, [paymentLogs, searchInput])

  const showTable =
    (showGroupedPaymentLogs &&
      filteredEarnings &&
      filteredEarnings.length > 0) ||
    (!showGroupedPaymentLogs &&
      filteredPaymentLogs &&
      filteredPaymentLogs.length > 0)

  const renderItemContent = useCallback(
    (_: number, item: PaymentLogEarnings | PaymentLog) => {
      if (showGroupedPaymentLogs) {
        const earning = item as PaymentLogEarnings
        return (
          <PaymentLogEarningsRow
            workerId={workerId}
            paymentLogEarnings={earning}
            timezone={localTimeZone}
          />
        )
      } else {
        const paymentLog = item as PaymentLog
        return (
          <PaymentLogRow paymentLog={paymentLog} timezone={localTimeZone} />
        )
      }
    },
    [workerId, localTimeZone, showGroupedPaymentLogs],
  )

  return (
    <div style={{ marginLeft: theme.space.sm, marginRight: theme.space.sm }}>
      <Row alignCenter>
        <Col>
          <Text variant="h4">Payment History</Text>
        </Col>
        <Row mt={theme.space.sm} alignCenter>
          <DateRangePicker
            label="Date range"
            inlineLabel={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
            granularity="day"
            style={{ marginRight: theme.space.xs }}
          />
          <Col mr={theme.space.xs} width={'250px'}>
            <Input
              label="Shift or Worker Incentive ID"
              value={searchInput}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setSearchInput(ev.target.value)
              }
              width="250px"
            />
          </Col>
          <Col>
            <Button
              leftIcon={<Icon name="refresh" />}
              onClick={() => {
                refetchPaymentLogs()
                refetchEarnings()
              }}
              variant={ButtonVariant.OUTLINED}
              loading={!isFetched}
            >
              Refresh
            </Button>
          </Col>
        </Row>
      </Row>
      <Row mb={theme.space.med} mt={theme.space.sm}>
        <Text variant="body1">
          {`NOTE: This will only show payment history for shifts that started or incentives that were completed after ${
            hotSettings?.newEarningsScreenCutoffDate
              ? format(
                  new Date(hotSettings?.newEarningsScreenCutoffDate),
                  'MMMM dd, yyyy',
                )
              : 'March 20, 2024'
          }`}
          .
        </Text>
      </Row>
      <Row>
        <ToggleButtonGroup>
          <ToggleButton
            value="shift"
            selected={showGroupedPaymentLogs}
            onClick={() => setShowGroupedPaymentLogs(true)}
          >
            Shifts & Incentives
          </ToggleButton>
          <ToggleButton
            value="worker"
            selected={!showGroupedPaymentLogs}
            onClick={() => setShowGroupedPaymentLogs(false)}
          >
            Payment Logs
          </ToggleButton>
        </ToggleButtonGroup>
      </Row>
      {isLoading ? (
        <Row alignCenter justifyCenter fullWidth style={{ height: '500px' }}>
          <CircularProgress
            size={theme.space.xxl}
            sx={{
              left: '50%',
            }}
          />
        </Row>
      ) : showTable ? (
        <Virtuoso
          data={showGroupedPaymentLogs ? filteredEarnings : filteredPaymentLogs}
          style={{
            height: '700px',
            marginTop: theme.space.sm,
            border: `1px solid ${theme.colors.Grey}`,
            borderLeft: `1px solid ${theme.colors.Grey}`,
          }}
          totalCount={
            showGroupedPaymentLogs
              ? filteredEarnings.length
              : filteredPaymentLogs.length
          }
          itemContent={renderItemContent}
        />
      ) : (
        <Text variant="h5" mt={theme.space.sm}>
          No payment log earnings for this worker
        </Text>
      )}
    </div>
  )
}
