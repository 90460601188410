import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import {
  CompanyNote,
  NoteSource,
  NoteType,
  ShiftNote,
  WorkerNote,
  WorkerNotesCount,
  WorkersNotesCounts,
} from '@traba/types'
import { capitalize } from 'lodash'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useUserContext } from 'src/context/user/UserContext'
import { ONE_MINUTE_IN_MS } from 'src/libs/constants'

const useNotes = () => {
  const { showError, showSuccess } = useAlert()
  const { state } = useUserContext()

  async function createNote(
    id: string,
    note: string,
    noteType: NoteType,
    notify?: string[],
  ) {
    try {
      const noteToAdd: Partial<ShiftNote | CompanyNote | WorkerNote> & {
        notify?: string[]
      } = {
        note,
        author: state.userProfile?.email ?? 'OPS',
        source: NoteSource.Ops,
        notify,
      }

      switch (noteType) {
        case NoteType.Shift:
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(noteToAdd as ShiftNote).shiftId = id
          break
        case NoteType.Company:
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(noteToAdd as CompanyNote).companyId = id
          break
        case NoteType.Worker:
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(noteToAdd as WorkerNote).workerId = id
          break
      }

      await trabaApi.post(`notes/${noteType}`, noteToAdd)
      showSuccess(
        'Note added successfully',
        `${capitalize(noteType)} Note Created`,
      )
    } catch (error: any) {
      const errorMessage = `Error Creating New Note from Console for ${capitalize(
        noteType,
      )} ${id}: ${error.message ?? JSON.stringify(error)}`
      Sentry.captureException(errorMessage)
      showError(errorMessage, 'Error Creating Note')
      throw error
    }
  }

  async function deleteNote(noteId: string, noteType: NoteType) {
    try {
      await trabaApi.delete(`notes/${noteId}?noteType=${noteType}`)
      showSuccess(
        'Note deleted successfully',
        `${capitalize(noteType)} Note Deleted`,
      )
    } catch (error: any) {
      const errorMessage = `Error deleting note ${noteId} from console for ${noteType}: ${
        error.message ?? JSON.stringify(error)
      }`
      Sentry.captureException(errorMessage)
      showError(errorMessage, 'Error Deleting Note')
    }
  }

  async function getShiftNotes(
    shiftId: string,
  ): Promise<ShiftNote[] | undefined> {
    try {
      const res = await trabaApi.get(`notes/shift/${shiftId}`)
      return res.data
    } catch (error) {
      console.error('useNotes -> getShiftNotes() ERROR', error)
    }
  }

  function useShiftNotes(shiftId: string) {
    const {
      isLoading,
      isError,
      data: shiftNotes,
      error,
      isFetched,
      refetch,
    } = useQuery<ShiftNote[] | undefined, Error>(
      ['shift_notes', { shiftId }],
      () => getShiftNotes(shiftId),
      {
        staleTime: ONE_MINUTE_IN_MS,
        enabled: !!shiftId,
      },
    )

    return {
      isLoading,
      isError,
      shiftNotes,
      error,
      isFetched,
      refetch,
    }
  }

  async function getCompanyNotes(
    companyId: string,
  ): Promise<CompanyNote[] | undefined> {
    try {
      const res = await trabaApi.get(`notes/company/${companyId}`)
      return res.data
    } catch (error) {
      console.error('useNotes -> getCompanyNotes() ERROR', error)
    }
  }

  function useCompanyNotes(companyId: string) {
    const {
      isLoading,
      isError,
      data: companyNotes,
      error,
      isFetched,
      refetch,
    } = useQuery<CompanyNote[] | undefined, Error>(
      ['company_notes', { companyId }],
      () => getCompanyNotes(companyId),
      {
        staleTime: ONE_MINUTE_IN_MS,
      },
    )

    return {
      isLoading,
      isError,
      companyNotes,
      error,
      isFetched,
      refetch,
    }
  }

  async function getWorkerNotes(
    workerId: string,
  ): Promise<WorkerNote[] | undefined> {
    try {
      const res = await trabaApi.get(`notes/worker/${workerId}`)
      return res.data
    } catch (error) {
      console.error('useNotes -> getWorkerNotes() ERROR', error)
    }
  }

  async function getWorkersNotesCounts(
    workerIds: string[],
  ): Promise<WorkersNotesCounts | undefined> {
    try {
      const res = await trabaApi.post(`notes/workers-notes-counts`, {
        workerIds,
      })
      return res.data
    } catch (error) {
      console.error('useNotes -> getWorkersNotesCounts() ERROR', error)
    }
  }

  function useWorkerNotes(workerId: string) {
    const {
      isLoading,
      isError,
      data: workerNotes,
      error,
      isFetched,
      refetch,
    } = useQuery<WorkerNote[] | undefined, Error>(
      ['worker_notes', { workerId }],
      () => getWorkerNotes(workerId),
      {
        staleTime: ONE_MINUTE_IN_MS,
      },
    )

    return {
      isLoading,
      isError,
      workerNotes,
      error,
      isFetched,
      refetch,
    }
  }

  function useWorkersNotesCounts(workerIds: string[]) {
    const {
      isLoading,
      isError,
      data: workersNotesCounts,
      error,
      isFetched,
      refetch,
    } = useQuery<WorkersNotesCounts | undefined, Error>(
      ['workers_notes_counts', workerIds],
      () => getWorkersNotesCounts(workerIds),
      {
        staleTime: ONE_MINUTE_IN_MS,
      },
    )

    const workersNotesCountMap = workersNotesCounts?.workersNotesCounts?.reduce(
      (acc: Record<string, number>, wn: WorkerNotesCount) => {
        acc[wn.workerId] = wn.notesCount
        return acc
      },
      {},
    )

    return {
      isLoading,
      isError,
      workersNotesCountMap,
      error,
      isFetched,
      refetch,
    }
  }

  return {
    createNote,
    deleteNote,
    useShiftNotes,
    useCompanyNotes,
    useWorkerNotes,
    useWorkersNotesCounts,
  }
}

export default useNotes
