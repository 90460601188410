import { useAlert } from '@traba/context'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { getErrorMessage } from 'src/utils/errorUtils'
import { openInNewTab } from 'src/utils/helperUtils'

export enum TimeFormat {
  UnixTimestamp = 'UNIX_TIMESTAMP',
  LocaleDateString = 'LOCALE_DATE_STRING',
}

type WorkersReportInput = {
  to: Date
  from: Date
  timeFormat: TimeFormat
}

export function useDownloadCompanyReport(companyId: string) {
  const [isLoading, setIsLoading] = useState(false)
  const { showError } = useAlert()

  const getReport = async (
    reportType: string,
    data?: Record<string, unknown>,
  ) => {
    try {
      setIsLoading(true)
      const url = `/companies/${companyId}/${reportType}-report`
      const response = await trabaApi.put(url, data)
      const link = response.data

      if (!link) {
        throw new Error('Error getting link to company report')
      }
      openInNewTab(link)
      return response.data
    } catch (error) {
      showError(getErrorMessage(error), 'Error')
    } finally {
      setIsLoading(false)
    }
  }
  return {
    isLoading,
    getWorkersReport: async (data: WorkersReportInput) =>
      getReport('workers', data),
    getTimesheetReport: async (data: { shiftIds: string[] }) =>
      getReport('timesheet', data),
  }
}
