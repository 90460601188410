import { Text, TextVariant } from '@traba/react-components'
import React from 'react'

export default function Anchor(props: {
  children: React.ReactNode
  href: string
  variant?: TextVariant
  style?: React.CSSProperties
}) {
  return (
    <Text
      variant={props.variant || 'link'}
      rel="noreferrer"
      target="_blank"
      style={{
        ...props.style,
      }}
      href={props.href}
    >
      {props.children}
    </Text>
  )
}
