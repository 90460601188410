import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns'
import { debounce } from 'lodash'
import { useState } from 'react'
import { Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { useEarlyArrivalBuffer } from 'src/hooks/useEarlyArrivalBuffer'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import { EditShiftField } from './EditShiftForm'

interface EditShiftTimesProps {
  shift: Shift
  //Prop drilling state
  startTime: Date
  businessStartTime: Date | undefined | null
  endTime: Date
  setStartTime: React.Dispatch<React.SetStateAction<Date>>
  setBusinessStartTime: React.Dispatch<
    React.SetStateAction<Date | undefined | null>
  >
  setEndTime: React.Dispatch<React.SetStateAction<Date>>
  isInvalidBuffer: boolean
  setIsInvalidBuffer: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditShiftTimes = (props: EditShiftTimesProps) => {
  const {
    shift,
    startTime,
    businessStartTime,
    endTime,
    setStartTime,
    setBusinessStartTime,
    setEndTime,
    setIsInvalidBuffer,
  } = props

  const originalBuffer =
    getEarlyArrivalTimeBufferInMinutes({
      shiftStartTime: shift.startTime,
      businessStartTime: shift.businessStartTime,
    }) ?? 0
  const [buffer, setBuffer] = useState<number>(originalBuffer)
  const [lastFullStartTime, setLastFullStartTime] = useState<Date>(startTime)
  const [bufferErrorMessage, setBufferErrorMessage] = useState<string | null>(
    null,
  )

  const { getEarlyArrivalBufferUpdates } = useEarlyArrivalBuffer()

  const calculateBufferForTimeChange = (
    businessStartTime: Date | null | undefined,
    startTime: Date,
  ) => {
    let newBuffer
    if (
      businessStartTime &&
      startTime.getTime() === businessStartTime?.getTime()
    ) {
      newBuffer = 0
    } else {
      newBuffer =
        getEarlyArrivalTimeBufferInMinutes({
          shiftStartTime: startTime,
          businessStartTime,
        }) ?? 0
    }
    setBuffer(newBuffer)
  }

  const changeStartTime = (newDate: Date | null, isFourDigitYear?: boolean) => {
    if (newDate) {
      let newEndTime = endTime

      if (isBefore(endTime, newDate) && isFourDigitYear) {
        const shiftLength = differenceInMinutes(endTime, lastFullStartTime)
        newEndTime = addMinutes(newDate, shiftLength)
      }
      if (isFourDigitYear) {
        setLastFullStartTime(newDate)
      }
      setStartTime(newDate)
      setEndTime(newEndTime)
    }
  }

  const changeBusinessStartTime = (newDate: Date | null) => {
    if (newDate) {
      let newEndTime = endTime

      if (isBefore(endTime, newDate)) {
        const shiftLength = differenceInMinutes(endTime, startTime)
        newEndTime = addMinutes(newDate, shiftLength)
      }
      setBusinessStartTime(newDate)
      setEndTime(newEndTime)
    }
  }

  const changeEndTime = (newDate: Date | null) => {
    if (newDate) {
      setEndTime(newDate)
    }
  }

  const hydrateBufferStatus = debounce(async (buffer: number) => {
    try {
      const bufferData = await getEarlyArrivalBufferUpdates(
        buffer,
        startTime,
        businessStartTime,
      )
      setIsInvalidBuffer(false)
      const {
        startTime: shiftStartTime,
        businessStartTime: shiftBusinessStartTime,
      } = bufferData

      if (shiftBusinessStartTime) {
        changeBusinessStartTime(new Date(shiftBusinessStartTime))
      } else {
        setBusinessStartTime(null)
      }

      changeStartTime(new Date(shiftStartTime), true)
      setBufferErrorMessage(null)
    } catch (e: any) {
      setIsInvalidBuffer(!!businessStartTime)
      if (buffer > 0) {
        setBufferErrorMessage(e.message)
      }
    }
  }, 200)

  //cannot set a buffer for a shift that starts in the past
  const now = new Date()

  const fieldDisabled = isBefore(startTime, now)

  const withinTwoHours = differenceInMinutes(startTime, now) < 120

  return (
    <>
      <Row fullWidth mb={theme.space.xs}>
        <EditShiftField
          currentValue={new Date(startTime)}
          originalValue={new Date(shift.startTime)}
          label="Start Time"
          setter={changeStartTime}
          input={
            <Row style={{ width: 300 }}>
              <DatePicker
                date={startTime}
                showTimeFieldInPopover={true}
                setDate={changeStartTime}
                isClearable={false}
                timezone={shift.timezone}
                isDisabled={!!businessStartTime}
                aria-label="Start time"
              />
            </Row>
          }
        />
      </Row>
      <Row fullWidth mb={theme.space.xs}>
        <EditShiftField
          currentValue={buffer}
          originalValue={originalBuffer}
          label="Early Arrival Buffer"
          setter={(value) => {
            if (value) {
              setBuffer(value)
              hydrateBufferStatus(value)
            } else {
              setBuffer(0)
              hydrateBufferStatus(0)
            }
          }}
          input={
            <NumberInput
              value={buffer}
              setValue={(value) => {
                if (value) {
                  setBuffer(value)
                  hydrateBufferStatus(value)
                } else {
                  setBuffer(0)
                  hydrateBufferStatus(0)
                }
              }}
              min={0}
              max={120}
              step={1}
              onError={() => setIsInvalidBuffer(true)}
              disabled={fieldDisabled}
              placeholder={
                !fieldDisabled
                  ? 'e.g. 15'
                  : 'Cannot add buffer to a shift in the past'
              }
              error={!!bufferErrorMessage}
              customErrorMessage={bufferErrorMessage ?? undefined}
              warningTip={
                withinTwoHours
                  ? 'This shift is scheduled for less than 2 hours from now. Notifications may not work as expected if the buffer is edited.'
                  : undefined
              }
            />
          }
        />
      </Row>
      {!!businessStartTime && (
        <Row fullWidth mb={theme.space.xs}>
          <EditShiftField
            currentValue={new Date(businessStartTime)}
            originalValue={new Date(businessStartTime)}
            label="Business Start Time"
            setter={(newDate) => {
              if (newDate) {
                calculateBufferForTimeChange(newDate, startTime)
              }
              changeBusinessStartTime(newDate)
            }}
            disabled={true}
            input={
              <Row style={{ width: 300 }}>
                <DatePicker
                  date={businessStartTime}
                  showTimeFieldInPopover={true}
                  setDate={(newDate) => {
                    if (newDate) {
                      calculateBufferForTimeChange(newDate, startTime)
                    }
                    changeBusinessStartTime(newDate)
                  }}
                  isClearable={false}
                  timezone={shift.timezone}
                  aria-label="Business Start Time"
                />
              </Row>
            }
          />
        </Row>
      )}
      <Row fullWidth mb={theme.space.xs}>
        <EditShiftField
          currentValue={new Date(endTime)}
          originalValue={new Date(shift.endTime)}
          label="End Time"
          setter={setEndTime}
          input={
            <Row style={{ width: 300 }}>
              <DatePicker
                date={endTime}
                minDate={startTime}
                showTimeFieldInPopover={true}
                setDate={changeEndTime}
                isClearable={false}
                timezone={shift.timezone}
                aria-label="End time"
              />
            </Row>
          }
        />
      </Row>
    </>
  )
}
