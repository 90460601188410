import { useAlert } from '@traba/context'
import { GenderPreference } from '@traba/types'
import { Role } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export interface CreateRoleData extends Omit<Role, 'roleId' | 'companyId'> {}
export interface EditRoleData extends Partial<Omit<Role, 'genderPreference'>> {
  genderPreference?: GenderPreference | null
}
export interface ArchiveRoleData {
  roleId: string
  companyId: string
}

export interface UpdateRoleResponseDto {
  role?: Role
  previousRoleId?: string
  updatedShiftIds?: string[]
  failedToUpdateShiftIds?: string[]
}

async function getRoles(companyId: string): Promise<Role[]> {
  try {
    const res = await trabaApi.get(`companies/${companyId}/roles`)
    return res.data?.roles || []
  } catch (error: any) {
    console.error('useRoles -> getRoles() ERROR', error.message ?? error)
    return []
  }
}

export function useRoles(companyId: string) {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()
  const {
    isLoading,
    isError,
    data: roles,
    error,
    isFetched,
    refetch,
  } = useQuery<Role[], Error>(`roles_${companyId}`, () => getRoles(companyId), {
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const createRoleMutation = useMutation<Role, AxiosError, CreateRoleData>(
    async (roleToCreate: CreateRoleData) => {
      const res = await trabaApi.post(
        `companies/${companyId}/roles`,
        roleToCreate,
      )
      return res.data
    },
    {
      onSuccess: (data: Role) => {
        queryClient.setQueryData(
          `roles_${companyId}`,
          (currentRoles: Role[] | undefined) => {
            return currentRoles ? [...currentRoles, data] : [data]
          },
        )
        showSuccess(
          `Role "${data.roleName}" created for company ${data.companyId}`,
          'Successfully created role',
        )
      },
      onError: (error) => {
        showError(JSON.stringify(error), 'Error creating role')
      },
    },
  )

  const editRoleMutation = useMutation<
    UpdateRoleResponseDto,
    AxiosError,
    {
      roleId: string
      companyId: string
      editRoleData: EditRoleData
    }
  >(
    async ({
      roleId,
      companyId,
      editRoleData,
    }: {
      roleId: string
      companyId: string
      editRoleData: EditRoleData
    }) => {
      const response = await trabaApi.patch(
        `companies/${companyId}/roles/${roleId}`,
        editRoleData,
      )
      return response.data
    },
    {
      onSuccess: () => {
        refetch()
        showSuccess('Successfully edited role')
      },
      onError: (error) => {
        showError(JSON.stringify(error), 'Error editing role')
      },
    },
  )

  const archiveRoleMutation = useMutation<Role, AxiosError, ArchiveRoleData>(
    async (archiveRoleData: ArchiveRoleData) => {
      const { companyId, roleId } = archiveRoleData
      const response = await trabaApi.patch(
        `companies/${companyId}/roles/${roleId}/archive`,
      )
      return response.data
    },
    {
      onSuccess: (archivedRole: Role) => {
        refetch()
        showSuccess(
          `Role "${archivedRole.roleId}" archived`,
          'Successfully archived role',
        )
      },
      onError: (error) => {
        if (error.message === 'archive/active-shifts') {
          showError(
            'You cannot archive a role that is tied to shifts in the future.',
            'Unable to archive role',
          )
        } else {
          showError(JSON.stringify(error), 'Error archiving role')
        }
      },
    },
  )

  return {
    isLoading,
    isError,
    roles,
    error,
    isFetched,
    refetch,
    createRole: createRoleMutation.mutate,
    editRole: editRoleMutation.mutate,
    archiveRole: archiveRoleMutation.mutate,
  }
}
