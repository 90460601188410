import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CustomizedBadgeVariant } from '@traba/types'
import { useMemo, useState } from 'react'
import { Row } from 'src/components/base'
import Toggle from 'src/components/base/Toggle'
import { useUserContext } from 'src/context/user/UserContext'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { internalUserHasOpsLeadPrivileges } from 'src/utils/sentinelNotificationUtils'
import { BadgePreview } from './BadgePreview'
import { BadgesListCard } from './BadgesListCard'
import { CreateNewBadgeSection } from './CreateNewBadgeSection'

export const CustomizedBadge = () => {
  const { state } = useUserContext()
  const { activeBadges, archivedBadges } = useCustomBadges()
  const [isCreatingNew, setIsCreatingNew] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [selectedPreviewBadgeId, setSelectedPreviewBadgeId] = useState<
    string | null
  >(null)
  const [selectedVariant, setSelectedVariant] =
    useState<CustomizedBadgeVariant | null>(null)
  const [badgeName, setBadgeName] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')
  const canModifyBadges = useMemo(
    () => internalUserHasOpsLeadPrivileges(state.userProfile?.internalUser),
    [state.userProfile?.internalUser],
  )
  return (
    <Row
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        padding: theme.space.sm,
        flex: 1,
      }}
    >
      <Row
        flexCol
        style={{
          paddingRight: theme.space.sm,
          marginRight: theme.space.sm,
          borderRight: `1px solid ${theme.colors.Grey30}`,
          width: '50%',
        }}
      >
        <Row alignCenter>
          <Text variant="h4">Custom badges</Text>
          {canModifyBadges && (
            <>
              <Toggle
                buttonState={isCreatingNew}
                label="Create new"
                runOnChange={() => setIsCreatingNew(!isCreatingNew)}
                color="transparent"
              />
              <Toggle
                buttonState={showArchived}
                label="Show archived"
                runOnChange={() => setShowArchived(!showArchived)}
                color="transparent"
              />
            </>
          )}
        </Row>
        <BadgesListCard
          selectedPreviewBadgeId={selectedPreviewBadgeId}
          setSelectedPreviewBadgeId={setSelectedPreviewBadgeId}
          setShortDescription={setShortDescription}
          setLongDescription={setLongDescription}
          setSelectedVariant={setSelectedVariant}
          setBadgeName={setBadgeName}
          badges={activeBadges}
          isActive
          isCreatingNew={isCreatingNew}
        />
        {showArchived && (
          <BadgesListCard
            selectedPreviewBadgeId={selectedPreviewBadgeId}
            setSelectedPreviewBadgeId={setSelectedPreviewBadgeId}
            setShortDescription={setShortDescription}
            setLongDescription={setLongDescription}
            setSelectedVariant={setSelectedVariant}
            setBadgeName={setBadgeName}
            badges={archivedBadges}
            isActive={false}
            isCreatingNew={isCreatingNew}
          />
        )}
        {isCreatingNew && (
          <CreateNewBadgeSection
            setIsCreatingNew={setIsCreatingNew}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            badgeName={badgeName}
            setBadgeName={setBadgeName}
            shortDescription={shortDescription}
            longDescription={longDescription}
            setShortDescription={setShortDescription}
            setLongDescription={setLongDescription}
          />
        )}
      </Row>
      <BadgePreview
        shortDescription={shortDescription}
        badgeName={badgeName}
        longDescription={longDescription}
        selectedVariant={selectedVariant}
      />
    </Row>
  )
}
