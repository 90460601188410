import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  SvgIcon,
  Text,
  WorkersOnScheduleTable,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { formatRangeForCalendar } from '@traba/utils'
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useWorkersOnSchedule } from 'src/hooks/useWorkersOnSchedule'
import { Row } from '../base'

interface Props {
  shiftRequestParentId: string
  companyId: string
}

export const ScheduleDetailsWeeklyScheduleTab = ({
  shiftRequestParentId,
}: Props) => {
  const navigate = useNavigate()
  const weekStart = startOfWeek(new Date())
  const weekEnd = endOfWeek(new Date())
  const [onOrAfter, setOnOrAfter] = useState<Date>(weekStart)
  const [onOrBefore, setOnOrBefore] = useState<Date>(weekEnd)
  const {
    scheduledWorkers,
    replacementWorkers,
    isLoading,
    getStylesForShiftRequest,
  } = useWorkersOnSchedule(shiftRequestParentId, {
    onOrAfter,
    onOrBefore,
  })

  const title = useMemo(() => {
    return formatRangeForCalendar(onOrAfter, onOrBefore)
  }, [onOrAfter, onOrBefore])

  const onClickWorker = (workerId: string) => {
    navigate(`/worker/${workerId}`)
  }

  return (
    <>
      <Row mb={theme.space.med} alignCenter gap={theme.space.xs}>
        <Text variant="h4">{title}</Text>
        <Button
          slim
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            if (new Date() < onOrAfter) {
              setOnOrAfter(subWeeks(onOrAfter, 1))
              setOnOrBefore(subWeeks(onOrBefore, 1))
            } else {
              return
            }
          }}
        >
          <SvgIcon name="left" color={theme.colors.Grey60} />
        </Button>
        <Button
          slim
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            setOnOrAfter(addWeeks(onOrAfter, 1))
            setOnOrBefore(addWeeks(onOrBefore, 1))
          }}
        >
          <SvgIcon name="right" color={theme.colors.Grey60} />
        </Button>
      </Row>
      <Text variant="h5" mb={theme.space.xs}>
        Scheduled workers
      </Text>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <WorkersOnScheduleTable
          workers={scheduledWorkers}
          onClickWorker={onClickWorker}
          getStylesForShiftRequest={getStylesForShiftRequest}
        />
      )}
      <Text variant="h5" mt={theme.space.lg} mb={theme.space.xs}>
        Replacement workers
      </Text>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <WorkersOnScheduleTable
          workers={replacementWorkers}
          onClickWorker={onClickWorker}
          getStylesForShiftRequest={getStylesForShiftRequest}
        />
      )}
    </>
  )
}
