import { CreateLineItemDto, EditLineItemDto, Invoice } from '@traba/types'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export function useInvoiceMutations(invoiceId: string) {
  const voidInvoiceMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    void,
    unknown
  >(() => trabaApi.put(`/invoices/${invoiceId}/void`))

  const deleteInvoiceMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    void,
    unknown
  >(() => trabaApi.delete(`/invoices/${invoiceId}`))

  const finalizeInvoiceMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    void,
    unknown
  >(() => trabaApi.put(`/invoices/${invoiceId}/finalize`))

  const resyncInvoiceMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    void,
    unknown
  >(() => trabaApi.post(`/invoices/${invoiceId}/resync`))

  const regenerateInvoiceMutation = useMutation<Invoice, AxiosError, boolean>(
    async (regenerateLineItems: boolean) => {
      const res = await trabaApi.put(
        `/invoices/${invoiceId}/revise?shouldRegenerateLineItems=${regenerateLineItems}`,
      )
      return res.data
    },
  )

  const addLineItemMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    CreateLineItemDto
  >((lineItem: CreateLineItemDto) =>
    trabaApi.post(`/invoices/${invoiceId}/line-items`, {
      lineItems: [lineItem],
    }),
  )

  const editLineItemMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    { stripeItemId: string; updates: EditLineItemDto }
  >(
    ({
      stripeItemId,
      updates,
    }: {
      stripeItemId: string
      updates: EditLineItemDto
    }) =>
      trabaApi.put(
        `/invoices/${invoiceId}/line-items?stripeItemId=${stripeItemId}`,
        updates,
      ),
  )

  const deleteLineItemMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    string
  >((stripeItemId: string) =>
    trabaApi.delete(`/invoices/${invoiceId}/line-items/${stripeItemId}`),
  )

  const resendInvoiceEmailMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    void,
    unknown
  >(() => trabaApi.post(`/invoices/${invoiceId}/send-email`))

  const updateInvoiceMemoMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    string
  >((newMemo: string) =>
    trabaApi.patch(`/invoices/${invoiceId}`, {
      stripeMemo: newMemo,
    }),
  )

  return {
    voidInvoice: voidInvoiceMutation.mutateAsync,
    deleteInvoice: deleteInvoiceMutation.mutateAsync,
    finalizeInvoice: finalizeInvoiceMutation.mutateAsync,
    regenerateInvoice: regenerateInvoiceMutation.mutateAsync,
    resyncInvoice: resyncInvoiceMutation.mutateAsync,
    addLineItemToInvoice: addLineItemMutation.mutateAsync,
    editLineItemOnInvoice: editLineItemMutation.mutateAsync,
    deleteLineItemFromInvoice: deleteLineItemMutation.mutateAsync,
    resendInvoiceEmail: resendInvoiceEmailMutation.mutateAsync,
    updateMemoOnInvoice: updateInvoiceMemoMutation.mutateAsync,
  }
}
