import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  ListItemText,
  Checkbox,
} from '@mui/material'
import { InputStatus } from '@traba/types'
import React, { useState, useMemo } from 'react'
import { Row, Button } from 'src/components/base'
import { ButtonVariant } from '../Button/types'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import { IMenuItem } from '../Select/Select'
import * as S from './SearchSelect.styles'

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

export interface SearchSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  inputStatus?: InputStatus
  options: Array<IMenuItem>
  selectItem?: IMenuItem
  selectedItems?: IMenuItem[]
  handleSelect?: (value: IMenuItem | undefined) => void
  handleSelectMultiple?: (value: IMenuItem[]) => void
  errorMessage?: string
  multiple?: boolean
  onlyShowLabel?: boolean
  width?: number | string
  isLoading?: boolean
  shouldAlsoSearchSecondaryLabel?: boolean
  multipleNoneSelectedLabel?: string
  showClearButton?: boolean
  selectedOnTop?: boolean
}

export const SearchSelect: React.FC<SearchSelectProps> = (
  props: SearchSelectProps,
) => {
  const {
    options,
    multiple,
    selectItem,
    selectedItems,
    handleSelect,
    handleSelectMultiple,
    onlyShowLabel,
    width,
    isLoading,
    shouldAlsoSearchSecondaryLabel = false,
    multipleNoneSelectedLabel,
    showClearButton = false,
    style,
    selectedOnTop,
  } = props

  const [searchText, setSearchText] = useState('')
  const displayedOptions = useMemo(() => {
    // First, filter options according to the search text
    const filteredOptions = options.filter((option) => {
      const labelHasText = containsText(option.label, searchText)
      let secondaryLabelHasText = false
      if (shouldAlsoSearchSecondaryLabel && option.secondaryLabel) {
        secondaryLabelHasText = containsText(option.secondaryLabel, searchText)
      }
      return labelHasText || secondaryLabelHasText
    })

    // If multiple selection is enabled and there are selected items, sort them to the top
    if (
      multiple &&
      selectedItems &&
      selectedItems.length > 0 &&
      selectedOnTop
    ) {
      filteredOptions.sort((a, b) => {
        const aIsSelected = selectedItems.some((item) => item.value === a.value)
        const bIsSelected = selectedItems.some((item) => item.value === b.value)
        if (aIsSelected && !bIsSelected) {
          return -1
        } else if (!aIsSelected && bIsSelected) {
          return 1
        }
        return 0 // Keep original order if both are selected or not selected
      })
    }

    return filteredOptions
  }, [
    searchText,
    options,
    shouldAlsoSearchSecondaryLabel,
    multiple,
    selectedItems,
    selectedOnTop,
  ])

  const hasError = props.inputStatus === InputStatus.error
  const onSelect = (e: SelectChangeEvent<string | string[]>) => {
    // Logic for multiple selection
    if (multiple && handleSelectMultiple) {
      const updatedValues = e.target.value as string[]
      const updatedSelectedItems = updatedValues
        .map((v) => options.find((o) => o.value === v))
        .filter((o) => typeof o !== 'undefined') as IMenuItem[]
      return handleSelectMultiple(updatedSelectedItems)
    }

    // Logic for simple selection
    const updatedSelectedItem = options.find((o) => o.value === e.target.value)
    if (handleSelect && updatedSelectedItem) {
      handleSelect(updatedSelectedItem)
    }
  }

  const renderValue = () => {
    if (multiple) {
      if (!selectedItems || selectedItems?.length === 0) {
        return multipleNoneSelectedLabel
      }

      return selectedItems?.map((item) => item.label).join(', ')
    }
    return selectItem?.label || selectItem?.value || '-'
  }

  return (
    <>
      <S.SearchSelectContainer style={{ width, ...style }}>
        <S.SearchBoxStyling />
        <FormControl style={{ borderColor: '#8000ff' }} fullWidth>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={
              multiple
                ? selectedItems?.map((item) => item.value as string)
                : selectItem
                  ? (selectItem?.value as string)
                  : '-'
            }
            onChange={onSelect}
            onClose={() => setSearchText('')}
            renderValue={renderValue}
            displayEmpty={!!(multiple && multipleNoneSelectedLabel)}
            multiple={multiple}
            disabled={isLoading}
            sx={{ width }}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                id="search-text-field"
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  margin: '8px 0px',
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
            {showClearButton ? (
              <Row justifyEnd>
                <Button
                  slim
                  variant={ButtonVariant.TEXT}
                  onClick={() =>
                    multiple && handleSelectMultiple
                      ? handleSelectMultiple([])
                      : handleSelect
                        ? handleSelect(undefined)
                        : undefined
                  }
                >
                  Clear
                </Button>
              </Row>
            ) : null}
            {displayedOptions.map((option: IMenuItem) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ fontFamily: 'Poppins' }}
              >
                {multiple && (
                  <Checkbox
                    checked={
                      Array.isArray(selectedItems) &&
                      !!selectedItems?.find((s) => s.value === option.value)
                    }
                  />
                )}
                <ListItemText
                  primary={option.label}
                  secondary={
                    onlyShowLabel ? '' : (option.secondaryLabel ?? option.value)
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!props.label && <S.Label>{props.label}</S.Label>}
      </S.SearchSelectContainer>
      {hasError ? (
        <InputErrorMessage>
          <InputErrorIcon>
            {/* <AlertIcon /> TODO_BRUNNO: Add Alert Icon */}
          </InputErrorIcon>
          {props.errorMessage}
        </InputErrorMessage>
      ) : null}
    </>
  )
}
