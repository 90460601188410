import { Alert, AlertTitle } from '@mui/material'
import Fade from '@mui/material/Fade'
import { useAlert } from '@traba/context'
import { Z_INDEXES } from '@traba/theme'
import { useEffect } from 'react'

export const Toast = () => {
  const { state, cancelAlert } = useAlert()
  const { active, severity, message, title, durationInMs } = state

  useEffect(() => {
    if (active) {
      const timer = setTimeout(() => {
        cancelAlert()
      }, durationInMs)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [active, cancelAlert, durationInMs])

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 100,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        zIndex: Z_INDEXES.ALERT,
      }}
    >
      <Fade in={active}>
        <Alert
          severity={severity}
          style={{
            minWidth: 600,
            justifyContent: 'center',
          }}
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Fade>
    </div>
  )
}
