import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { ShiftRequest } from '@traba/types'

export const createShiftRequestEdits = async (shiftRequestEdit: {
  edit: Partial<ShiftRequest> & {
    shiftRequestId: string
    editType: string
    cancellationSource?: string
    originalStartTime: Date
  }
}) => {
  try {
    const response = await trabaApi.post(
      `shift-request-edits`,
      shiftRequestEdit,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}
