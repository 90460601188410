import { WorkerSearchParams, WorkerSearchParamsRaw } from '@traba/types'
import { Worker } from '@traba/types'
import { AxiosRequestConfig } from 'axios'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'
import { PaginationParams } from './usePagination'
import { makeSearchParameters } from './utils/searchUtils'

export type WorkerSearchQuery = {
  withCount?: boolean
  parameters: any
  includes?: WorkerSearchRelations
  select?: SelectFields
}

export type WorkerSearchRelations = {
  accountStatus?: boolean
  payment?: boolean
  backgroundCheck?: boolean
  workAvailability?: boolean
  schedulePreferences?: boolean
  workPreferences?: boolean
  workerProfile?: boolean
  workerShifts?: boolean
  workerMetric?: boolean
  certifications?: boolean
  connections?: boolean
}

export type WorkerSearchRawQuery = {
  withCount?: boolean
  parameters: any
  select?: WorkerSearchRawSelect
}

export type WorkerSearchRawSelect = {
  worker?: string[]
  accountStatus?: string[]
  payment?: string[]
  backgroundCheck?: string[]
  workAvailability?: string[]
  schedulePreferences?: string[]
  workPreferences?: string[]
  workerProfile?: string[]
  workerShifts?: string[]
  workerMetric?: string[]
  certifications?: string[]
  connections?: string[]
}

export async function searchWorkersApi(
  searchType: 'explore' | 'query',
  searchData: Partial<Worker>,
  config?: AxiosRequestConfig,
): Promise<Worker[]> {
  const res = await trabaApi.post(
    `workers/${
      searchType === 'explore'
        ? 'explore?fetchExtraDetails=true'
        : 'query?fetchAccountStatus=true'
    }`,
    searchData,
    config,
  )

  if (searchType === 'explore') {
    return res.data.availableWorkers
  }
  return res.data
}

export enum OrFields {
  firstName = 'firstName',
  lastName = 'lastName',
  id = 'id',
  phoneNumber = 'phoneNumber',
}

export type SelectFields = {
  [key: string]: string[]
}

export async function workerSearch({
  parameters,
  paginationParams,
  includes,
  activeOrFields,
  fullTextSearchParam,
  select,
  config,
}: {
  parameters: WorkerSearchParams
  paginationParams: PaginationParams
  includes?: WorkerSearchRelations
  activeOrFields?: OrFields[]
  select?: SelectFields
  config?: AxiosRequestConfig
  fullTextSearchParam?: string
}): Promise<{ workers: PopulatedWorker[]; count: number }> {
  const payload: WorkerSearchQuery = {
    parameters: makeSearchParameters(
      parameters,
      activeOrFields,
      fullTextSearchParam,
    ),
    includes,
    select,
    withCount: true,
  }
  const { limit, offset, sortBy, sortOrder } = paginationParams
  const URL = `workers/search?startAt=${offset}&limit=${limit}${
    sortBy ? `&orderBy=${String(sortBy)}` : ''
  }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
  const res = await trabaApi.post(URL, payload, config)
  return res.data
}

export async function workerSearchRaw(
  parameters: WorkerSearchParamsRaw,
  paginationParams: PaginationParams,
  select?: WorkerSearchRawSelect,
  config?: AxiosRequestConfig,
): Promise<{ workers: PopulatedWorker[]; count: number }> {
  const payload: WorkerSearchRawQuery = {
    parameters,
    select,
    withCount: true,
  }
  const { limit, offset, sortBy, sortOrder } = paginationParams
  const URL = `workers/search-raw?startAt=${offset}&limit=${limit}${
    sortBy ? `&orderBy=${String(sortBy)}` : ''
  }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
  const res = await trabaApi.post(URL, payload, config)
  return res.data
}

export function useSearchSingleWorker(
  parameters: WorkerSearchParams,
  paginationParams: PaginationParams,
  activeOrFields?: OrFields[],
  select?: SelectFields,
  config?: AxiosRequestConfig,
) {
  const { isLoading, isError, data, error, isFetched, refetch } = useQuery<{
    workers: PopulatedWorker[]
    count: number
  }>(
    `single_worker/search/${parameters.id}`,
    () =>
      workerSearch({
        parameters,
        paginationParams: { ...paginationParams, limit: 1 },
        activeOrFields,
        select,
        config,
      }),
    {
      cacheTime: FIVE_MINUTES_IN_MS,
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    worker: data?.workers[0],
    error,
    isFetched,
    refetch,
  }
}
