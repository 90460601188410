import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { isBefore } from 'date-fns'
import { useState } from 'react'
import { Col, Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import {
  SliderButton,
  SliderButtonOption,
} from 'src/components/base/SliderButton'
import { ShiftTile } from 'src/components/ShiftTile'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import { JobStatus } from 'src/types'
import { ManageWorkerModalContent } from './ManageWorkerModalContent'

type ManageWorkerModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
}

export const DEFAULT_MANAGE_WORKER_BUTTON_OPTIONS: SliderButtonOption[] = [
  { label: 'Cancel' },
  { label: 'Cancel Many' },
  { label: 'Reject' },
  { label: 'Appease' },
  { label: 'No Show' },
  { label: 'To Do' },
  { label: 'Abandon' },
  { label: 'Complete' },
  { label: 'Forgive Incidents' },
  { label: 'Connection' },
  { label: 'Suspend' },
  { label: 'Ban' },
]

export const ManageWorkerModal = (props: ManageWorkerModalProps) => {
  const { handleClose, isOpen, workerShift } = props
  const [currentButton, setCurrentButton] = useState<number>(0)
  const manageConnectionAccess = usePermissions([
    UserRolePermission.ManageConnections,
  ])

  function setDisabledOptions(buttonOptions: SliderButtonOption[]) {
    return buttonOptions.map((bo) => {
      const isCanceled = workerShift.jobStatus === JobStatus.Canceled
      const isConnection =
        bo.label === 'Connection' ||
        bo.label === 'Suspend' ||
        bo.label === 'Ban'
      if (
        isCanceled &&
        bo.label !== 'Cancel' &&
        bo.label !== 'Cancel Many' &&
        bo.label !== 'Forgive Incidents' &&
        !isConnection
      ) {
        return {
          ...bo,
          disabled: true,
          info: 'You cannot do this on a canceled shift. For non Biz cancellations you can re-add the worker to the shift',
        }
      }

      if (bo.label === 'Complete') {
        const isDisabled =
          isBefore(new Date(), new Date(workerShift.shiftInfo.endTime)) ||
          workerShift.jobStatus !== JobStatus.ToDo
        return {
          ...bo,
          disabled: isDisabled,
          info: isCanceled
            ? 'Can not complete a worker on a canceled shift'
            : isDisabled
              ? 'This option is only available for past shifts AND workers that are in TO_DO'
              : undefined,
        }
      } else if (isConnection && !manageConnectionAccess) {
        return {
          ...bo,
          disabled: true,
        }
      }

      return bo
    })
  }

  const manageWorkerButtonOptions = setDisabledOptions(
    DEFAULT_MANAGE_WORKER_BUTTON_OPTIONS,
  )

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={`Manage ${workerShift.worker?.firstName} ${workerShift.worker?.lastName}`}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Row flexCol fullWidth style={{ justifyContent: 'flex-start' }}>
          <Row flexCol mt={16}>
            <Row style={{ width: '400px' }}>
              <ShiftTile shiftId={workerShift.shiftId} />
            </Row>
            <Row center>
              <SliderButton
                buttonOptions={manageWorkerButtonOptions}
                currentButton={currentButton}
                setCurrentButton={setCurrentButton}
              />
            </Row>
          </Row>
        </Row>
        <ManageWorkerModalContent
          workerShift={workerShift}
          currentButton={currentButton}
          handleClose={handleClose}
        />
      </Col>
    </Modal>
  )
}
