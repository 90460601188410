import { BrandColors, otherColors } from '@traba/theme'
import styled, { css } from 'styled-components'

interface IColorAndBackgroundColor {
  color: string
  backgroundColor: string
  borderWidth?: string
  borderStyle?: string
}

type BadgeContainerProps = {
  round?: boolean
  dismissible?: boolean
  variant: string
  sm?: boolean
  short?: boolean
  clickable?: boolean
  disableUppercase?: boolean
}

export const colorVariants: { [key: string]: IColorAndBackgroundColor } = {
  info: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  brand: {
    color: BrandColors.Violet,
    backgroundColor: otherColors.Violet10,
  },
  disabled: {
    color: otherColors.Grey60,
    backgroundColor: otherColors.Grey10,
  },
  success: {
    color: otherColors.Green80,
    backgroundColor: otherColors.Green10,
  },
  warning: {
    color: otherColors.Yellow70,
    backgroundColor: otherColors.Yellow10,
  },
  additional: {
    color: otherColors.Violet70,
    backgroundColor: otherColors.Violet10,
  },
  business: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  danger: {
    color: otherColors.Red60,
    backgroundColor: otherColors.Red10,
  },
  opaqueRed: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Red40,
  },
  certification: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  pink: {
    color: otherColors.Red80,
    backgroundColor: '#FFC0CB',
  },
  orange: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Orange60,
  },
  blue: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Blue50,
  },
  outlineViolet: {
    color: BrandColors.Violet,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  outlineGreen: {
    color: otherColors.Green80,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  outlineRed: {
    color: otherColors.Red80,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  outlineYellow: {
    color: otherColors.Yellow70,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
}

export const BadgeContainer = styled.div<BadgeContainerProps>`
  border-radius: 4px;
  font-size: 10px;
  padding: 6px 12px;
  background-color: ${({ variant }) => colorVariants[variant].backgroundColor};
  color: ${({ variant }) => colorVariants[variant].color};
  border-width: ${({ variant }) => colorVariants[variant].borderWidth};
  border-style: ${({ variant }) => colorVariants[variant].borderStyle};
  border-color: ${({ variant }) => colorVariants[variant].color};
  font-weight: bold;
  text-transform: ${({ disableUppercase }) =>
    disableUppercase ? 'none' : 'uppercase'};
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    `};

  ${({ round }) =>
    round &&
    css`
      border-radius: 100%;
      padding: 0;
      width: 24px;
      height: 24px;
    `};

  ${({ short }) =>
    short &&
    css`
      display: block;
      max-width: 125px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `};

  ${({ dismissible }) =>
    dismissible &&
    css`
      border-radius: 8px;
      font-size: 12px;
      text-transform: inherit;
      padding: 8px 12px;
    `};

  ${({ sm }) =>
    sm &&
    css`
      margin-right: 4px;
      margin-bottom: 4px;
      font-size: 8px;
      padding: 4px 6px;
    `};
`
