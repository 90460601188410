import FormControlLabel from '@mui/material/FormControlLabel'
import MuiRadio from '@mui/material/Radio'
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup'
import React from 'react'

export type RadioGroupProps = {
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  options?: RadioGroupOptions
  row?: boolean
  style?: MuiRadioGroupProps['style']
}

export type RadioGroupOptions =
  | {
      label: string | React.ReactNode
      value: any
      disabled?: boolean
    }[]
  | string[]

export default function RadioGroup({
  options = [],
  value,
  onChange,
  row,
  style,
}: RadioGroupProps) {
  return (
    <MuiRadioGroup row={row} value={value} onChange={onChange} style={style}>
      {options.map((option, i) => (
        <FormControlLabel
          key={
            typeof option === 'string'
              ? option
              : typeof option.label === 'string'
                ? option.label
                : typeof option.value === 'string'
                  ? option.value
                  : i
          }
          value={typeof option === 'string' ? option : option.value}
          label={typeof option === 'string' ? option : option.label}
          disabled={typeof option === 'string' ? false : option.disabled}
          control={<MuiRadio />}
        />
      ))}
    </MuiRadioGroup>
  )
}
