import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ForwardFillRenotificationMethod } from '@traba/types'
import {
  WorkerShiftSignupNotification,
  WorkerShiftSignupNotificationStatus,
} from '@traba/types'
import { TierLevel } from '@traba/types'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Anchor, Button, CopyTextIcon, Row, Td, Tr } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { truncateString, formatPhoneNumber } from 'src/utils/stringUtils'
import FFListFeedbackModal from './FFListFeedbackModal'
import NotificationsTableEligibility from './NotificationsTableEligibility'

export type NotificationsTableRowProps = {
  notification: WorkerShiftSignupNotification
  worker: Worker
  timezone?: string
  workerTierLevel: TierLevel
  forwardFillRenotificationMethod?: ForwardFillRenotificationMethod
  handlePressCancelNotification: (notificationId: string) => void
  handlePressSendNotification: (notificationId: string) => void
}

export default function NotificationsTableRow({
  worker,
  timezone,
  workerTierLevel,
  notification,
  forwardFillRenotificationMethod,
  handlePressCancelNotification,
  handlePressSendNotification,
}: NotificationsTableRowProps) {
  const [isLoadingCancelButton, setIsLoadingCancelButton] = useState(false)
  const [isLoadingSendButton, setIsLoadingSendButton] = useState(false)
  const v1FeedbackModal = useModal()
  const attributeFeedbackModal = useModal()
  const [showV1Thumb, setShowV1Thumb] = useState<boolean>()
  const [showAttributeThumb, setShowAttributeThumb] = useState<boolean>()
  const tz = useTimezonedDates(timezone)

  const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)
  let attributeListIndexColor
  if (
    notification.attributeListIndex !== undefined &&
    notification.attributeListIndex < notification.immutableIndex
  ) {
    attributeListIndexColor = theme.colors.Green70
  }
  if (
    notification.attributeListIndex !== undefined &&
    notification.attributeListIndex > notification.immutableIndex
  ) {
    attributeListIndexColor = theme.colors.Red70
  }

  return (
    <Tr>
      <Td
        onClick={() => v1FeedbackModal.open()}
        onMouseEnter={() => setShowV1Thumb(true)}
        onMouseLeave={() => setShowV1Thumb(false)}
        style={{ cursor: 'pointer' }}
      >
        {notification.immutableIndex}
        {showV1Thumb ? ' 👎' : undefined}
      </Td>
      <Td
        style={{ color: attributeListIndexColor, cursor: 'pointer' }}
        onClick={() => attributeFeedbackModal.open()}
        onMouseEnter={() => setShowAttributeThumb(true)}
        onMouseLeave={() => setShowAttributeThumb(false)}
      >
        <Text color={attributeListIndexColor}>
          {notification.attributeListIndex}
          {showAttributeThumb ? ' 👎' : undefined}
        </Text>
      </Td>
      <Td>
        <Link to={`/workers/${worker.uid || worker.workerId}`} target="_blank">
          <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
            {worker.firstName} {worker.lastName}
          </Button>
        </Link>
      </Td>
      <Td style={{ fontFamily: 'monospace' }}>
        {truncateString(worker?.uid ?? '')}{' '}
        <CopyTextIcon textToCopy={worker?.uid} />
      </Td>
      <Td>{workerTierLevel?.replace('_', ' ')}</Td>
      <Td>
        <Anchor href={`tel:${phoneNumber}`}>{phoneNumber}</Anchor>
        <CopyTextIcon textToCopy={worker.phoneNumber} />
      </Td>
      <Td>
        <NotificationsTableEligibility notification={notification} />
      </Td>
      <Td>{tz.getDateTime(notification?.sentAt) || '-'}</Td>
      <Td>{tz.getDateTime(notification?.lastSendAttemptedAt) || '-'}</Td>
      {forwardFillRenotificationMethod ===
      ForwardFillRenotificationMethod.SMS ? (
        <Td>{tz.getDateTime(notification?.renotifiedBySmsAt) || '-'}</Td>
      ) : null}
      <Td>
        <Row>
          <Button
            slim
            disabled={
              notification.status === WorkerShiftSignupNotificationStatus.Sent
            }
            loading={isLoadingCancelButton}
            variant={ButtonVariant.OUTLINED}
            onClick={async () => {
              setIsLoadingCancelButton(true)
              await handlePressCancelNotification(
                notification.workerShiftSignupNotificationId,
              )
              setIsLoadingCancelButton(false)
            }}
          >
            Cancel
          </Button>
          <Button
            slim
            loading={isLoadingSendButton}
            disabled={
              notification.status === WorkerShiftSignupNotificationStatus.Sent
            }
            variant={ButtonVariant.FILLED}
            style={{ marginLeft: 10 }}
            onClick={async () => {
              setIsLoadingSendButton(true)
              await handlePressSendNotification(
                notification.workerShiftSignupNotificationId,
              )
              setIsLoadingSendButton(false)
            }}
          >
            Send
          </Button>
        </Row>
      </Td>
      <FFListFeedbackModal
        {...v1FeedbackModal}
        notification={notification}
        isForAttributeList={true}
      />
      <FFListFeedbackModal
        {...attributeFeedbackModal}
        notification={notification}
        isForAttributeList={true}
      />
    </Tr>
  )
}
