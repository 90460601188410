import { Worker } from '@traba/types'
import { Anchor, CopyTextIcon, DataTable } from 'src/components/base'
import { truncateString, formatPhoneNumber } from 'src/utils/stringUtils'

export type BulkActionWorkerTableProps = {
  workers?: Worker[]
}

export default function BulkActionWorkerTable({
  workers,
}: BulkActionWorkerTableProps) {
  if (!workers) {
    return null
  }

  return (
    <DataTable
      headers={[{ label: 'Name', sortable: true }, 'Worker ID', 'Phone']}
      rows={workers?.map((worker) => {
        const workerId = worker.workerId || worker.uid
        const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)

        return {
          key: workerId,
          cells: [
            {
              sortKey: `${worker.firstName}-${worker.lastName}`,
              renderFn: () => (
                <div>
                  {worker.firstName} {worker.lastName}
                </div>
              ),
            },
            {
              renderFn: () => (
                <>
                  {truncateString(worker.workerId || worker.uid)}
                  <CopyTextIcon
                    textToCopy={worker.workerId || worker.uid}
                  />{' '}
                </>
              ),
            },
            {
              sortKey: '',
              renderFn: () => {
                return (
                  <>
                    <Anchor href={`tel:${phoneNumber}`}>{phoneNumber}</Anchor>
                    <CopyTextIcon textToCopy={worker.phoneNumber} />
                  </>
                )
              },
            },
          ],
        }
      })}
    />
  )
}
