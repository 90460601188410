import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AddRecurringWorkerShiftResponse,
  ExperienceLevel,
  GenericAcceptShiftBypasses,
  InitialAcceptShiftBypasses,
  Shift,
  ShiftRequest,
  WorkerShiftEligibility,
} from '@traba/types'
import { truncateString } from '@traba/utils'
import { useState } from 'react'
import { Anchor, CopyTextIcon, DataTable } from 'src/components/base'
import AcceptShiftBypass from 'src/modals/AcceptShiftModal/components/AcceptShiftBypass'
import MissingCriteria from 'src/modals/AcceptShiftModal/components/MissingCriteria'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { formatPhoneNumber, toPercentString } from 'src/utils/stringUtils'
import { addWorkerToRecurringWorkerShift } from '../../../hooks/useRecurringWorkerShift'
import { AddWorkerToScheduleResults } from './AddWorkerToScheduleResults'

interface Props {
  eligibilities?: WorkerShiftEligibility[]
  checkedWorkers: PopulatedWorker[]
  selectedShiftRequest: ShiftRequest | undefined
  shiftRequestIdWithOneShift: Map<string, Shift>
  onBack: () => void
  onDone: () => void
}

export function AddWorkerToScheduleConfirm(props: Props) {
  const {
    checkedWorkers,
    eligibilities,
    shiftRequestIdWithOneShift,
    selectedShiftRequest,
    onBack,
    onDone,
  } = props
  const [isConfirming, setIsConfirming] = useState(false)
  const { showError } = useAlert()
  const { hotSettings, isLoading } = useHotSettings()
  const [bypasses, setBypasses] = useState<GenericAcceptShiftBypasses>({})
  const [responses, setResponses] =
    useState<AddRecurringWorkerShiftResponse[]>()
  if (!eligibilities || !selectedShiftRequest) {
    return null
  }
  const { shiftRequestId } = selectedShiftRequest
  const shift = shiftRequestIdWithOneShift.get(
    selectedShiftRequest.shiftRequestId,
  )

  if (!shift) {
    return null
  }
  if (isLoading) {
    return <LoadingSpinner />
  }

  const bypassSettings =
    hotSettings?.acceptShiftBypasses &&
    hotSettings?.acceptShiftBypasses.length > 0
      ? hotSettings?.acceptShiftBypasses
      : InitialAcceptShiftBypasses

  const onConfirmAddToSchedule = async () => {
    try {
      setIsConfirming(true)
      const addToSchedulePromises = checkedWorkers.map((worker) =>
        addWorkerToRecurringWorkerShift(
          worker.id ?? worker.workerId,
          shiftRequestId,
          shift.startTime,
          bypasses,
        ),
      )
      const responses = await Promise.all(addToSchedulePromises)
      setResponses(responses)
    } catch (error) {
      Sentry.captureException(error)
      showError('Failed to add workers to schedule, please try again')
    } finally {
      setIsConfirming(false)
    }
  }

  if (responses) {
    return (
      <AddWorkerToScheduleResults
        responses={responses}
        checkedWorkers={checkedWorkers}
        onDone={onDone}
      />
    )
  }

  return (
    <>
      <Text variant="h4">Eligibility results</Text>
      <Text variant="body2">
        Review workers' eligibilities and confirm. If you wish to add a worker
        to specific shifts, please go to field monitor or shift details page.
      </Text>
      <DataTable
        headers={[
          'Name',
          'Worker ID',
          'Phone',
          'Tier',
          'Reliability',
          'Proven',
          'Missing Criteria',
        ]}
        rows={eligibilities?.map((eligibility) => {
          const worker = checkedWorkers.find(
            (cw) => cw.id === eligibility.workerId,
          )
          if (!worker) {
            throw new Error('Worker not found from eligibility list')
          }
          const workerId = worker.id || worker.uid
          const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)

          return {
            key: workerId,
            cells: [
              {
                renderFn: () => (
                  <Text variant="body1">
                    {worker.firstName} {worker.lastName}
                  </Text>
                ),
              },
              {
                renderFn: () => (
                  <>
                    {truncateString(workerId)}
                    <CopyTextIcon textToCopy={workerId} />{' '}
                  </>
                ),
              },
              {
                renderFn: () => {
                  return (
                    <>
                      <Anchor href={`tel:${phoneNumber}`}>{phoneNumber}</Anchor>
                      <CopyTextIcon textToCopy={worker.phoneNumber} />
                    </>
                  )
                },
              },
              {
                renderFn: () => eligibility?.workerTierLevel,
              },
              {
                renderFn: () =>
                  eligibility
                    ? `${toPercentString(eligibility?.reliability)}%`
                    : '-',
              },
              {
                renderFn: () =>
                  eligibility?.experienceLevel === ExperienceLevel.Proven
                    ? 'Yes'
                    : 'No',
              },
              {
                renderFn: () => (
                  <MissingCriteria
                    eligibility={eligibility}
                    bypasses={bypasses}
                    acceptShiftBypassSettings={bypassSettings}
                  />
                ),
              },
            ],
          }
        })}
      />
      <AcceptShiftBypass
        shifts={[shift]}
        bypasses={bypasses}
        setBypasses={setBypasses}
        eligibilityData={eligibilities}
        acceptShiftBypassSettings={bypassSettings}
        showPaidBackupsBypass={false} //no paid backups for schedules
      />
      <Row justifyBetween my={theme.space.sm}>
        <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
          Back
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          onClick={onConfirmAddToSchedule}
          loading={isConfirming}
        >
          Confirm add to schedule
        </Button>
      </Row>
    </>
  )
}
