import styled from 'styled-components'
import Row from '../Row'
import { MODAL_SIZE } from './types'

type Props = {
  size?: MODAL_SIZE
}

export const ModalPaddingContainer = styled.div`
  padding: ${({ theme }) => theme.space.lg}px;
  width: 100%;
`

export const ModalContainer = styled('div')<Props>`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 12px;
  overflow-y: auto;
  max-height: ${(props) => {
    switch (props.size) {
      case MODAL_SIZE.LARGE_LOCKED:
        return '80%'
      case MODAL_SIZE.MEDIUM_LOCKED:
        return '50%'
      default:
        return '90%'
    }
  }};
  min-height: ${(props) => {
    switch (props.size) {
      case MODAL_SIZE.DIALOG:
        return '40%'
      case MODAL_SIZE.EXTRA_SMALL:
        return '60%'
      case MODAL_SIZE.MEDIUM_LOCKED:
        return '50%'
      case MODAL_SIZE.LARGE_LOCKED:
        return '60%'
      default:
        return 'calc(100% - 300px)'
    }
  }};
  width: ${(props) => {
    switch (props.size) {
      case MODAL_SIZE.DIALOG:
        return '60%'
      case MODAL_SIZE.SMALL:
        return '70%'
      case MODAL_SIZE.MEDIUM:
        return '75%'
      case MODAL_SIZE.EXTRA_LARGE:
        return '85%'
      default:
        return '80%'
    }
  }};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  position: absolute;
`

export const HeaderRow = styled(Row)`
  border-bottom: 1px solid #e2e6e9;
  justify-content: space-between;
  align-items: center;
  margin: 12px -12px;
  padding: 0 24px 15px;
`

export const ModalInnerContainer = styled('div')<Props>`
  padding: 12px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
