export enum BugleBlastStatus {
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum BugleBlastCreator {
  OPS = 'OPS',
}

export enum BugleMessageType {
  SMS = 'SMS',
  PUSH = 'PUSH',
}

export type BugleMessageOptions = {
  body: string
  type: BugleMessageType
}

export type BugleBlast = {
  bugleBlastId: string
  createdAt: Date
  message: BugleMessageOptions
  scheduledStart: Date
  searchParamsId: string
  shiftId: string
  status: BugleBlastStatus
  updatedAt?: Date
  workerCount?: number
  opsSender?: string
}

export type ShiftBugleStatisticsResponse = {
  aggregatedStatistics: BugleStatistics
  shiftId: string
  bugleBlasts: {
    [bugleBlastId: string]: {
      bugleBlastId: string
      messageBody: string
      statistics: BugleStatistics
    }
  }
}

export type BugleStatistics = {
  signedForShift: number
  unresponsive: number
  unsubscribed: number
  undelivered: number
  messagesSent: number
  messagesCost: number
}

export enum BugleStatisticsType {
  SIGNED_FOR_SHIFT = 'SIGNED_FOR_SHIFT',
  UNRESPONSIVE = 'UNRESPONSIVE',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  UNDELIVERED = 'UNDELIVERED',
}

export type WorkerMessageForStatistics = {
  createdAt: Date
  workerId: string
  toNumber: string
  status: string
  messageSid: string | null
  price: number | null
  priceUnit: string | null
  errorCode: number | null
  errorMessage: string | null
  signedForShift: boolean
}

export enum WorkerCommunicationMedium {
  SMS = 'SMS',
  PUSH = 'PUSH',
  ROBOCALL = 'ROBOCALL',
}

export enum SearchParamsType {
  CUSTOM = 'CUSTOM',
  PRESET = 'PRESET',
}

export enum SearchParamsStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type WorkerSearchParams = {
  id?: string
  workerId?: string
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  deviceId?: string
  referralCode?: string
  referredBy?: string
  regionId?: string
  zipCode?: string
  accountStatus?: string
  accountStatuses?: string[]
  activeRegion?: string
  approvedAt?: Date
  setupStatus?: string
  setupStatuses?: string
  instantPayEligibilityStatus?: string
  instantPayEnabled?: boolean
  backgroundCheckStatus?: string
  dob?: string
  last4SSN?: string
  fullTextSearchParam?: string
  savedSearchId?: string
  regionIds?: string[]
  hasWorkedWithCompanyId?: string
  orFields?: {
    firstName?: string
    lastName?: string
    id?: string
  }
}

export type WorkerSearchParamsRaw = WorkerSearchParams & {
  hasValidConnectionsWithCompanyId?: string
  isAvailableForShiftId?: string
  isOnWaitlistForShiftId?: string
  isOnWaitlistOnSameDayOfShiftId?: string
  isOnShiftId?: string
  isInvitedToShiftId?: string
  targetShiftId?: string
  last24HoursBugleSMSLimit?: number
  hasNotBlockedInternalPhoneNumbers?: boolean
}

export type SearchParams = {
  searchParamsId: string
  createdAt: Date
  parameters: WorkerSearchParamsRaw | WorkerSearchParams
  type: SearchParamsType
  status: SearchParamsStatus
  creator: string
  presetTitle?: string
  presetDescription?: string
  presetQuery?: string // not exposed to the endpoint
}
