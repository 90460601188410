import { TextField, Chip, AutocompleteRenderInputParams } from '@mui/material'
import {
  FilterOptionsState,
  Autocomplete as MuiAutocomplete,
  AutocompleteGetTagProps,
} from '@mui/material'
import { theme } from '@traba/theme'
import React, { useEffect, useState } from 'react'

import { Icon } from 'src/components/base'
import {
  InputErrorIcon,
  InputErrorMessage,
} from 'src/components/base/Input/Input.styles'

import * as S from './Autocomplete.styles'

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().includes(searchText.toLowerCase())

export interface AutocompleteProps {
  label: string
  options: string[]
  value: string[]
  errorMessage?: string
  onChangeValues: (
    event: React.SyntheticEvent<Element, Event>,
    value: string[],
  ) => void
  validateInput: (str: string) => string | undefined
}

export const Autocomplete: React.FC<AutocompleteProps> = (
  props: AutocompleteProps,
) => {
  const [input, setInput] = useState('')
  const [inputValid, setInputValid] = useState(true)
  const [showError, setShowError] = useState(false)
  const { options, value, label, errorMessage, onChangeValues, validateInput } =
    props

  useEffect(() => {
    try {
      validateInput(input)
      setInputValid(true)
    } catch (e) {
      setInputValid(false)
    }
  }, [input, validateInput, setInputValid])

  const validate = (value?: string) => {
    try {
      validateInput(input)
      setInputValid(true)
      value !== undefined && setInput(value)
    } catch (e) {
      setInputValid(false)
      setShowError(true)
      value !== undefined && setInput(input)
    }
  }

  const onFocus = () => {
    if (!inputValid) {
      setShowError(false)
    }
  }

  const onBlur = (e: React.SyntheticEvent<Element, Event>) => {
    if (!inputValid) {
      setShowError(true)
    } else if (!!input && inputValid) {
      onChangeValues(e, [...value, input])
      setInput('')
    }
  }

  const filterOptions = (
    options: string[],
    state: FilterOptionsState<string>,
  ) =>
    options.filter(
      (o) => !value.includes(o) && containsText(o, state.inputValue),
    )

  const renderTags = (value: string[], getTagProps: AutocompleteGetTagProps) =>
    value.map((option: string, index: number) => (
      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
    ))

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={label} />
  )

  const onInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    setShowError(false)
    if (event) {
      if (event.type === 'keydown') {
        const nativeEvent = event.nativeEvent as KeyboardEvent
        if (nativeEvent.key === 'Enter') {
          validate(value)
        }
      } else {
        setInput(value)
      }
    }
  }

  return (
    <S.SearchBox>
      <MuiAutocomplete
        onFocus={onFocus}
        onBlur={onBlur}
        fullWidth
        multiple
        freeSolo
        filterSelectedOptions
        options={options}
        value={value}
        inputValue={input}
        clearText={input && inputValid ? 'Add' : 'Clear'}
        onInputChange={onInputChange}
        onChange={onChangeValues}
        filterOptions={filterOptions}
        renderTags={renderTags}
        renderInput={renderInput}
      />
      {!inputValid && showError && errorMessage ? (
        <InputErrorMessage style={{ display: 'flex', alignItems: 'center' }}>
          <InputErrorIcon>
            <Icon name="alert" color={theme.colors.Red60} />
          </InputErrorIcon>
          {errorMessage}
        </InputErrorMessage>
      ) : null}
    </S.SearchBox>
  )
}
