import { Text } from '@traba/react-components'
import { User } from '@traba/types'
import { Anchor, CopyTextIcon, Row } from 'src/components/base'
import { DotText } from 'src/components/base/DotText'
import { formatPhoneNumber } from 'src/utils/stringUtils'

export const SupervisorInfo = ({ supervisor }: { supervisor?: User }) => {
  if (!supervisor) {
    return null
  }
  return (
    <Row alignCenter>
      <Text>
        {supervisor.firstName} {supervisor.lastName}
      </Text>
      <DotText />
      <Anchor style={{ fontSize: 12 }} href={`tel:${supervisor.phoneNumber}`}>
        {formatPhoneNumber(supervisor.phoneNumber || '', true)}
      </Anchor>
      <CopyTextIcon textToCopy={supervisor.phoneNumber} />
      <DotText />
      <Text variant="caption">{supervisor.uid}</Text>
      <CopyTextIcon textToCopy={supervisor.uid} />
    </Row>
  )
}
