import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Row } from '../base'

interface Props {
  label: string
  content: string | JSX.Element
  onEdit?: () => void
}

export const ScheduleListItem = (props: Props) => {
  const { label, content, onEdit } = props

  return (
    <Row flexCol style={{ width: '30%' }}>
      <Text variant="caption">{label}</Text>
      <Row gap={theme.space.xxs} mt={theme.space.xxs}>
        {typeof content === 'string' ? (
          <Text variant="body2" color={theme.colors.MidnightBlue}>
            {content}
          </Text>
        ) : (
          content
        )}
        {onEdit && <SvgIcon name="edit" onClick={onEdit} />}
      </Row>
    </Row>
  )
}
