import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Role } from '@traba/types'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Modal, Row } from 'src/components/base'
import { ModalProps } from 'src/components/base/Modal/Modal'
import Toggle from 'src/components/base/Toggle'
import { useActiveQueries } from 'src/hooks/useActiveQueries'

export type RoleSettingsModalProps = {
  role: Role
  roleSettingsModal: Omit<ModalProps, 'children'>
}

export default function RoleSettingsModal({
  role,
  roleSettingsModal,
}: RoleSettingsModalProps) {
  const { refetchActiveQueries } = useActiveQueries()
  const [hasPreShiftConfirmationRobocall, setHasPreShiftConfirmationRobocall] =
    useState(role.hasPreShiftConfirmationRobocall ?? true)

  const [isLoadingApi, setIsLoadingApi] = useState(false)
  const { showError } = useAlert()

  async function handleTogglePreShiftConfirmationRobocall() {
    const updatedFlagValue = !hasPreShiftConfirmationRobocall
    try {
      setIsLoadingApi(true)
      setHasPreShiftConfirmationRobocall(updatedFlagValue)

      await trabaApi.patch(`companies/${role.companyId}/roles/${role.roleId}`, {
        hasPreShiftConfirmationRobocall: updatedFlagValue,
      })
      await refetchActiveQueries()
    } catch (err: any) {
      showError(err.message, 'Error')
      setHasPreShiftConfirmationRobocall(!updatedFlagValue)
    } finally {
      setIsLoadingApi(false)
    }
  }

  return (
    <Modal {...roleSettingsModal} title={`Role Settings for ${role.roleName}`}>
      <Row>
        <Toggle
          label={'Pre-shift confirmation Robocalls'}
          buttonState={hasPreShiftConfirmationRobocall}
          runOnChange={handleTogglePreShiftConfirmationRobocall}
        />

        {isLoadingApi && (
          <CircularProgress
            size={'20px'}
            style={{ marginTop: 12, marginLeft: 6 }}
          />
        )}
      </Row>
    </Modal>
  )
}
