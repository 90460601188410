import { InternalUser } from '@traba/types'
import { AssigneeStatus, ShiftAssignmentResponse } from '@traba/types'
import { useShiftAssignments } from 'src/hooks/useAssignments'
import { Button, Icon, Row } from '..'
import { useActiveQueries } from '../../../hooks/useActiveQueries'
import { ButtonVariant } from '../Button/types'
import Badge from '.'

export type ShiftAssignmentBadgeProps = {
  shiftAssignment: ShiftAssignmentResponse
  internalUser: InternalUser
  includeDelete?: boolean
  refetchAssignments?: () => Promise<void>
}

export default function ShiftAssignmentBadge({
  shiftAssignment: shiftAssignmentResponse,
  internalUser,
  includeDelete = true,
  refetchAssignments,
}: ShiftAssignmentBadgeProps) {
  const { updateShiftAssignment, updateShiftAssignmentLoading } =
    useShiftAssignments()
  const { refetchActiveQueries } = useActiveQueries()

  const handleDeleteAssignment = async () => {
    try {
      await updateShiftAssignment({
        shiftId: shiftAssignmentResponse.shiftId,
        internalUserId: internalUser.id,
        assigneeStatus: AssigneeStatus.UnAssigned,
      })
    } finally {
      refetchAssignments ? await refetchAssignments() : refetchActiveQueries()
    }
  }

  return (
    <Row style={{ height: '28px' }} alignCenter>
      <Badge title={internalUser.email} variant={'brand'} />
      {includeDelete && (
        <Button
          variant={ButtonVariant.TEXT}
          loading={updateShiftAssignmentLoading}
          onClick={handleDeleteAssignment}
        >
          <Icon name="trash" />
        </Button>
      )}
    </Row>
  )
}
