import {
  BugleStatisticsType,
  ShiftBugleStatisticsResponse,
  WorkerCommunicationMedium,
  WorkerMessageForStatistics,
} from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from '../../../api/helpers'
import { ONE_MINUTE_IN_MS } from '../../../libs/constants'

async function getBugleStatisticsByShiftId(
  shiftId: string,
  medium: WorkerCommunicationMedium,
): Promise<ShiftBugleStatisticsResponse> {
  const url = `communication/bugle/${shiftId}/statistics`
  const res = await trabaApi.post(url, { medium })
  return res.data
}

async function getWorkerCommunicationsForBugleStatisticsType(
  bugleBlastId: string,
  shiftId: string,
  medium: WorkerCommunicationMedium,
  bugleStatisticsType: BugleStatisticsType,
): Promise<WorkerMessageForStatistics[]> {
  const url = `communication/bugle/${bugleBlastId}/worker-communications`
  const res = await trabaApi.post(url, { medium, shiftId, bugleStatisticsType })
  return res.data
}

export function useBugleStatisticsByShiftId(shiftId: string) {
  return useQuery(
    [`bugle_statistics_${shiftId}`, shiftId],
    () => getBugleStatisticsByShiftId(shiftId, WorkerCommunicationMedium.SMS),
    {
      staleTime: ONE_MINUTE_IN_MS,
      enabled: !!shiftId,
    },
  )
}

export function useBugleStatisticsTypeWorkersComms(
  bugleBlastId: string,
  shiftId: string,
  type: BugleStatisticsType,
  isExpanded: boolean,
) {
  return useQuery(
    ['BUGLE_STATS_COMM_QUERY_KEY', { type, bugleBlastId, isExpanded }],
    () =>
      getWorkerCommunicationsForBugleStatisticsType(
        bugleBlastId,
        shiftId,
        WorkerCommunicationMedium.SMS,
        type,
      ),
    {
      staleTime: ONE_MINUTE_IN_MS,
      enabled: isExpanded,
    },
  )
}
