import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import {
  WorkerJobPlacementAction,
  WorkerJobPlacementResponseByCompanyId,
  WorkerJobPlacementResponseByWorkerId,
  WorkerJobPlacementStatus,
} from '@traba/types'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getQueryParams } from './useApi'

type JobPlacementResponse =
  | WorkerJobPlacementResponseByWorkerId[]
  | WorkerJobPlacementResponseByCompanyId[]
  | undefined

const COMPANY_JOB_PLACEMENTS_QUERY_KEY = 'COMPANY_JOB_PLACEMENTS_QUERY_KEY'
const WORKER_JOB_PLACEMENTS_QUERY_KEY = 'WORKER_JOB_PLACEMENTS_QUERY_KEY'

// Using workerId
async function getWorkerJobPlacements(
  workerId: string,
  status: WorkerJobPlacementStatus,
): Promise<WorkerJobPlacementResponseByWorkerId[] | undefined> {
  const queryString = getQueryParams([['status', status]])
  const { data } = await trabaApi.get(
    `worker-job-placements/worker/${workerId}${queryString}`,
  )
  return data
}

// Create job placement
async function createJobPlacement({
  workerId,
  companyId,
  initiatedBy,
  startDate,
  reason,
}: {
  workerId: string
  companyId: string
  initiatedBy: string
  startDate: Date
  reason: string
}) {
  const { data } = await trabaApi.post(`/worker-job-placements/`, {
    workerId,
    companyId,
    initiatedBy,
    startDate,
    reason,
  })
  return data
}

// Using companyId
async function getCompanyJobPlacements(
  companyId: string,
  status: WorkerJobPlacementStatus,
): Promise<WorkerJobPlacementResponseByCompanyId[] | undefined> {
  const queryString = getQueryParams([['status', status]])
  const res = await trabaApi.get(
    `worker-job-placements/company/${companyId}${queryString}`,
  )
  return res.data
}

// Update job placement
async function updateJobPlacement({
  workerId,
  companyId,
  initiatedBy,
  endDate,
  reason,
}: {
  workerId: string
  companyId: string
  initiatedBy: string
  endDate?: Date
  reason: string
}) {
  const queryString = getQueryParams([
    ['action', WorkerJobPlacementAction.TERMINATE_WORKER],
  ])
  const { data } = await trabaApi.patch(
    `/worker-job-placements/${queryString}`,
    {
      workerId,
      companyId,
      initiatedBy,
      endDate,
      reason,
    },
  )
  return data
}

export function useWorkerJobPlacements(
  workerId: string,
  status: WorkerJobPlacementStatus,
) {
  const fetchFunction = () => getWorkerJobPlacements(workerId, status)

  const { showError } = useAlert()

  const {
    isLoading,
    isError,
    data: jobPlacements,
    error,
    isFetched,
    refetch,
  } = useQuery<JobPlacementResponse, Error>({
    queryKey: [WORKER_JOB_PLACEMENTS_QUERY_KEY, { workerId, status }],
    queryFn: fetchFunction,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    const errorMessage = getErrorMessage(error)
    Sentry.captureException(error)
    showError(errorMessage, 'Error Fetching Job Placements')
  }

  return {
    isLoading,
    isError,
    jobPlacements,
    error,
    isFetched,
    refetch,
  }
}

export function useCompanyJobPlacements(
  companyId: string,
  status: WorkerJobPlacementStatus,
) {
  const fetchFunction = () => getCompanyJobPlacements(companyId, status)

  const { showError } = useAlert()

  const {
    isLoading,
    isError,
    data: jobPlacements,
    error,
    isFetched,
    refetch,
  } = useQuery<JobPlacementResponse, Error>({
    queryKey: [COMPANY_JOB_PLACEMENTS_QUERY_KEY, { companyId, status }],
    queryFn: fetchFunction,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    const errorMessage = getErrorMessage(error)
    Sentry.captureException(error)
    showError(errorMessage, 'Error Fetching Job Placements')
  }

  return {
    isLoading,
    isError,
    jobPlacements,
    error,
    isFetched,
    refetch,
  }
}

export function useCreateJobPlacement() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  return useMutation(createJobPlacement, {
    onSuccess: (data) => {
      if (data && data.isEnded === false) {
        const queriesToInvalidate = [
          COMPANY_JOB_PLACEMENTS_QUERY_KEY,
          WORKER_JOB_PLACEMENTS_QUERY_KEY,
        ]

        queriesToInvalidate.forEach((queryKey) =>
          queryClient.invalidateQueries([queryKey]),
        )
        showSuccess('Worker Job Placement created successfully')
      }
    },
    onError: (e: unknown) => {
      showError(getErrorMessage(e), 'Error Creating Worker Job Placement')
    },
  })
}

export function useUpdateJobPlacement() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  return useMutation(updateJobPlacement, {
    onSuccess: (data) => {
      if (data) {
        const queriesToInvalidate = [
          COMPANY_JOB_PLACEMENTS_QUERY_KEY,
          WORKER_JOB_PLACEMENTS_QUERY_KEY,
        ]

        queriesToInvalidate.forEach((queryKey) =>
          queryClient.invalidateQueries(queryKey),
        )
        showSuccess('Worker Job Placement updated successfully')
      }
    },
    onError: (e: unknown) => {
      showError(getErrorMessage(e), 'Error Updating Worker Job Placement')
    },
  })
}
