import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

async function getKioskPin(companyId: string): Promise<string | undefined> {
  try {
    const res = await trabaApi.get(`companies/${companyId}/kiosk-pin`)
    return res.data.pin
  } catch (error) {
    console.error('useKioskPin -> getKioskPin() ERROR', error)
  }
}

async function updateKioskPin({
  companyId,
  kioskPin,
}: {
  companyId: string
  kioskPin: string
}) {
  try {
    const res = await trabaApi.put(`/companies/${companyId}/kiosk-pin`, {
      kioskPin,
    })
    return res.data
  } catch (error) {
    console.error('useKioskPin -> updateKioskPin() ERROR', error)
    throw error
  }
}

export function useKioskPin(companyId: string) {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: kioskPin,
    error,
    isFetched,
    refetch,
  } = useQuery<string | undefined, Error>(
    `company_${companyId}_kiosk_pin`,
    () => getKioskPin(companyId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const mutation = useMutation<any, AxiosError, any>(updateKioskPin, {
    onSuccess: (data) => {
      queryClient.setQueryData(`company_${companyId}_kiosk_pin`, data.pin)
    },
  })

  return {
    isLoading,
    isError,
    kioskPin,
    error,
    isFetched,
    refetch,
    updateKioskPin: mutation.mutateAsync,
  }
}
