import { Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { Shift, WorkerScores } from '@traba/types'

export default function WorkerCompletions({
  workerScores,
  shift,
}: {
  workerScores: WorkerScores | undefined
  shift: Shift
}) {
  const totalShiftsCompleted = workerScores?.numberOfCompletions ?? 0
  const shiftsCompletedForRole =
    workerScores?.roleCounts?.find(
      (roleCount) => roleCount.roleId === shift.roleId,
    )?.count ?? 0

  return (
    <Tooltip title="Shifts completed (by role/total)">
      <Text>{`${shiftsCompletedForRole}/${totalShiftsCompleted}`}</Text>
    </Tooltip>
  )
}
