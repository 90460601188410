import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ExpandedRosterRow } from 'src/components/Rosters/ExpandedRosterRow'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { RosterInfo, RosterMap } from './types'

interface SearchAddWorkersViewProps {
  rosters: RosterInfo[]
  rosterMap: RosterMap
  selectedRosterIdToSelectedWorkers: Record<
    string,
    Record<string, PopulatedWorker>
  >
  setSelectedRosterIdToSelectedWorkers: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, PopulatedWorker>>>
  >
  selectedWorkerIdsFromGeneralPool: PopulatedWorker[]
  setSelectedWorkerIdsFromGeneralPool: React.Dispatch<
    React.SetStateAction<PopulatedWorker[]>
  >
}

export const SearchAddWorkersView: React.FC<SearchAddWorkersViewProps> = (
  props,
) => {
  const {
    rosters,
    rosterMap,
    selectedRosterIdToSelectedWorkers,
    setSelectedRosterIdToSelectedWorkers,
    selectedWorkerIdsFromGeneralPool,
    setSelectedWorkerIdsFromGeneralPool,
  } = props

  /**
   * When workers are selected or deselected from any given roster, we don't
   * want to just update that roster. Other rosters may have these workers too;
   * we want *all* rows for these workers to be reflected as it's a more
   * intuitive UI for a user.
   */
  const handleWorkersSelection = (
    workers: PopulatedWorker[],
    operation: 'add' | 'remove',
  ) => {
    setSelectedRosterIdToSelectedWorkers((prevState) => {
      const newSelectedRosterIdToSelectedWorkers = { ...prevState }

      workers.forEach((worker) => {
        Object.keys(rosterMap).forEach((rosterId) => {
          if (
            // rosterMap has the master list of all of the business's
            // rosters/favorites list to all workers in those lists, but we
            // only want the rosters actually selected, hence this check below
            !Object.prototype.hasOwnProperty.call(
              newSelectedRosterIdToSelectedWorkers,
              rosterId,
            ) ||
            // If the worker in question isn't even in this roster, skip this
            // roster
            !rosterMap[rosterId].workers
              .map((rw) => rw.id || rw.uid)
              .includes(worker.id || worker.uid)
          ) {
            return
          }

          if (operation === 'add') {
            newSelectedRosterIdToSelectedWorkers[rosterId][
              worker.id || worker.uid
            ] = worker
          } else {
            delete newSelectedRosterIdToSelectedWorkers[rosterId][
              worker.id || worker.uid
            ]
          }
        })
      })

      return newSelectedRosterIdToSelectedWorkers
    })
  }

  return (
    <div style={{ minHeight: 724 }}>
      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        Find workers from a roster or from the larger worker pool
      </Text>
      <SearchWorkers
        checkedWorkers={selectedWorkerIdsFromGeneralPool}
        setCheckedWorkers={setSelectedWorkerIdsFromGeneralPool}
        hideInstructionText={true}
        hideApprovedOnlyToggle={true}
        openInline={true}
      />
      <div style={{ marginTop: theme.space.xxs }}>
        {rosters.map((roster) => {
          return (
            <ExpandedRosterRow
              key={roster.id}
              id={roster.id}
              name={roster.name}
              numWorkers={roster.numWorkers}
              workers={rosterMap[roster.id].workers}
              handleWorkersSelection={handleWorkersSelection}
              rosterToSelectedWorkers={selectedRosterIdToSelectedWorkers}
            />
          )
        })}
      </div>
    </div>
  )
}
