import { Time } from '@internationalized/date'
import { CircularProgress } from '@mui/material'
import { useDeepEffect } from '@traba/hooks'
import { useInternalUsers } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import { AssigneeDisplay, InternalUserRole, ShiftStatus } from '@traba/types'
import { InternalUserStatus } from '@traba/types'
import { CompanyResponse } from '@traba/types'
import { Region } from '@traba/types'
import { ForwardFillMax } from '@traba/types'
import { addDays, isAfter, isBefore, isEqual, subDays } from 'date-fns'
import { compact, debounce } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { Button, Icon, Input, Row, Select } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { CollapsibleShiftTable } from 'src/components/CollapsibleShiftTable'
import { useSentinelContext } from 'src/context/sentinelContext/SentinelContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useAttributes } from 'src/hooks/useAttributes'
import { useSentinelNotificationsForShifts } from 'src/hooks/useSentinelNotifications'
import { useSearchShifts } from 'src/hooks/useShifts'
import { SHIFT_CODE_LEN } from 'src/libs/constants'
import BulkCancelShiftsModal from 'src/modals/CancelShiftModal/BulkCancelShiftsModal'
import { strContainsNonBase64Char } from 'src/utils/shiftCodeUtils'
import Pagination from '../../components/base/Pagination/Pagination'
import ShiftAssignmentModal from '../../components/ShiftAssigmentModal/ShiftAssigmentModal'
import { createShiftAssignmentMaps } from '../../hooks/useAssignments'
import { SortOrder } from '../../hooks/usePagination'
import { getExpectedWorkerShifts } from '../../utils/workerShiftUtils'
import { AddWorkerToShiftsModal } from './components/AddWorkers/AddWorkerToShiftsModal'
import { AssignmentsDrawer } from './components/AssignmentsDrawer'
import FieldMonitorFilters, {
  FieldMonitorFiltersType,
} from './components/FieldMonitorFilters/FieldMonitorFilters'
import * as S from './styles'

const defaultSelectFields = {
  shift: [
    'id',
    'status',
    'createdAt',
    'startTime',
    'endTime',
    'regionId',
    'timezone',
    'slotsRequested',
    'slotsFilled',
    'overbookSlotsRequested',
    'paidBackupSlotsRequested',
    'paidBackupSlotsFilled',
    'reliabilityAverage',
    'shiftRequestId',
    'tags',
    'forwardFillMax',
    'companyId',
  ],
  company: ['id', 'category', 'employerName'],
  role: ['roleName'],
  location: ['shortLocation'],
  workerShifts: [
    'earningsSummary',
    'workerShiftTimeToDestination',
    'jobStatus',
    'isShiftConfirmed',
    'clockInTime',
    'clockOutTime',
    'reliability',
  ],
  worker: ['id', 'firstName', 'lastName'],
  shiftAssignment: ['id', 'shiftId', 'assignees', 'status'],
}
const shiftLimitOptions: IMenuItem[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '400', value: '400' },
  { label: '600', value: '600' },
]

const DEFAULT_PAGE_SIZE_FM = 400

interface FieldMonitorProps {
  companies: CompanyResponse[]
  regions: Region[]
}

export type WorkersOnTheWay = {
  count: number
  shiftId: string
}

export type WorkerShiftTimeToDestinationResponse = {
  workersOnTheWay: WorkersOnTheWay[]
}

export default function FieldMonitorPG(props: FieldMonitorProps) {
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([])
  const [assignedTo, setAssignedTo] = useState<IMenuItem[]>([])
  const [assignmentsOpen, setAssignmentsOpen] = useState<boolean>(false)
  // TODO replace with proper search
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [shiftId, setShiftId] = useState<string>('')
  const [shiftCode, setShiftCode] = useState<string>('')
  const [magicSearchText, setMagicSearchText] = useState<string>('')
  const [debouncedMagicSearchText, setDebouncedMagicSearchText] =
    useState<string>('')
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_FM)

  const { companies, regions } = props

  const { state } = useUserContext()
  const sentinelContext = useSentinelContext()

  /* Formatting for search boxes */
  let companyOptions: IMenuItem[] = companies.map((company) => {
    return { label: company.employerName, value: company.id }
  })
  // `value` in this case is the companyId, and some companies with bogus data might not have that field.
  companyOptions = companyOptions.filter((c) => !!c.value)

  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })

  /*
    FILTERS
  */
  const [fieldMonitorFilters, setFieldMonitorFilters] =
    useState<FieldMonitorFiltersType>({
      showCanceledShiftsFilter: false,
      showLateClockInShiftsFilter: false,
      showLateClockOutShiftsFilter: false,
      showNotConfirmedFilter: false,
      showNotPaidFilter: false,
      showSlotsNotFilledFilter: false,
      showLowReliabilityShifts: false,
      showUnassignedShiftsMarketOps: false,
      showUnassignedShiftsScaleOps: false,
      showAssignedShifts: false,
    })

  const handleToggleFilter = (filter: Partial<FieldMonitorFiltersType>) => {
    setFieldMonitorFilters({ ...fieldMonitorFilters, ...filter })
  }

  const activeFilters = Object.entries(fieldMonitorFilters).filter(
    (fieldMonitorFilter) => fieldMonitorFilter[1],
  )

  const activeFilterCount = activeFilters.length

  const clearSelectedShiftIds = () => {
    setSelectedShiftIds([])
    return setFieldMonitorFilters({
      ...fieldMonitorFilters,
    })
  }

  /* Input States */
  /** The params here are stored in the URI so it can be shared */
  const [search, setSearch] = useSearchParams()

  const begOfToday = new Date(new Date().setHours(0, 0, 0, 0))
  const begOfTomorrow = addDays(begOfToday, 1)
  const afterDefault = search.get('after') || begOfToday
  const beforeDefault = search.get('before') || begOfTomorrow

  const [afterDate, setAfterDate] = useState<Date>(new Date(afterDefault))
  const [beforeDate, setBeforeDate] = useState<Date>(new Date(beforeDefault))

  /* Debounce when programmatically changing date inputs to avoid changing
      start date or end date while the user is still typing */
  const debouncedAdjustAfterDate = useCallback(
    debounce((newDate) => {
      if (
        newDate &&
        afterDate &&
        (isBefore(newDate, afterDate) || isEqual(newDate, afterDate))
      ) {
        setAfterDate(subDays(newDate, 1))
      }
    }, 750),
    [afterDate, setAfterDate],
  )
  const debouncedAdjustBeforeDate = useCallback(
    debounce((newDate) => {
      if (
        newDate &&
        beforeDate &&
        (isAfter(newDate, beforeDate) || isEqual(newDate, beforeDate))
      ) {
        setBeforeDate(addDays(newDate, 1))
      }
    }, 750),
    [beforeDate, setBeforeDate],
  )

  function onChangeAfterDate(newDate: Date | null, isFourDigitYear?: boolean) {
    if (!newDate) {
      return
    }
    setAfterDate(newDate)
    if (isFourDigitYear) {
      debouncedAdjustBeforeDate(newDate)
    }
  }

  function onChangeBeforeDate(newDate: Date | null, isFourDigitYear?: boolean) {
    if (!newDate) {
      return
    }
    setBeforeDate(newDate)
    if (isFourDigitYear) {
      debouncedAdjustAfterDate(newDate)
    }
  }

  const [dateRange, setDateRange] = useState([afterDate, beforeDate])
  const debouncedSetDateRange = useCallback(
    debounce((newAfterDate, newBeforeDate) => {
      setDateRange([newAfterDate, newBeforeDate])
    }, 750),
    [],
  )

  useEffect(() => {
    // Handle a clear date action immediately, otherwise debounce input
    if (afterDate === null || beforeDate === null) {
      setDateRange([afterDate, beforeDate])
    }
    debouncedSetDateRange(afterDate, beforeDate)
    return () => {
      debouncedSetDateRange.cancel()
    }
  }, [afterDate, beforeDate, debouncedSetDateRange])

  const companyDefaults = search.get('companyNames')
  const regionIdsDefault = search.get('regionIds')

  const companyNamesDefaultArray = companyDefaults?.split(',') ?? []
  const companyDefaultSelectedItems = companyNamesDefaultArray
    .map((company) => companyOptions.find((o) => o.value === company))
    .filter((r) => r !== undefined) as IMenuItem[]

  const [companyNames, setCompanyNames] = useState<IMenuItem[]>(
    companyDefaultSelectedItems,
  )

  const regionIdsDefaultArray = regionIdsDefault?.split(',') ?? []
  const regionDefaultSelectedItems = regionIdsDefaultArray
    .map((regionId) => regionsOptions.find((o) => o.value === regionId)?.value)
    .filter((r) => r !== undefined) as string[]

  const [regionIds, setRegionIds] = useState<string[]>(
    regionDefaultSelectedItems,
  )

  const ffMaxOptions: IMenuItem[] = Object.values(ForwardFillMax).map(
    (ffMax) => ({
      label: ffMax,
      value: ffMax,
    }),
  )
  const [ffMax, setFfMax] = useState<IMenuItem[]>([])

  /**
   * The following assignee display options defines what would be shown as "bubbles"
   * next to each row of the "assignments" -- Market Ops (green) and Scaled Ops (purple)
   * This logic is to select the dropdown box on the top of the field monitor to selectively display
   * the market ops and scaled ops.
   */
  const assigneeDisplayOptions: IMenuItem[] = Object.keys(AssigneeDisplay).map(
    (assigneeDisplay) => ({
      label: assigneeDisplay,
      value: assigneeDisplay,
    }),
  )
  const { state: userState } = useUserContext()
  const [assigneeDisplay, setAssigneeDisplay] = useState<IMenuItem[]>(() => {
    switch (userState.userProfile?.internalUser?.role) {
      case InternalUserRole.MARKET_OPS:
        return [
          {
            value: AssigneeDisplay.MARKET_OPS,
            label: AssigneeDisplay.MARKET_OPS,
          },
        ]
      case InternalUserRole.SCALED_OPS:
        return [
          {
            value: AssigneeDisplay.SCALED_OPS,
            label: AssigneeDisplay.SCALED_OPS,
          },
        ]
      default:
        return []
    }
  })

  /** Store the search params in the URI */
  const searchParams = useMemo(() => {
    return {
      before: dateRange[1] ? dateRange[1].toISOString() : '',
      after: dateRange[0] ? dateRange[0].toISOString() : '',
      companyNames: companyNames
        ? companyNames.map((c) => c.value as string).join(',')
        : [],
      regionIds: regionIds.join(','),
    }
  }, [dateRange, companyNames, regionIds])

  useEffect(() => {
    setSearch(searchParams)
  }, [searchParams, setSearch])

  /** Build the search params for the backend request */
  const activeSearchParams = useMemo(() => {
    if (shiftId) {
      return { id: shiftId }
    }
    const statuses = fieldMonitorFilters.showCanceledShiftsFilter
      ? [ShiftStatus.CANCELED, ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
      : [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
    const baseParams = {
      // If we are searching using the shiftCode, we only want to search into the future since users that has completed their shift will have all needed information to help ops user pinpoint their shifts
      startTimeBefore: dateRange[1] && !shiftCode ? dateRange[1] : undefined,
      startTimeAfter: dateRange[0] ? dateRange[0] : undefined,
      companyIds: companyNames?.length
        ? companyNames.map((c) => c.value)
        : undefined,
      regionIds: regionIds.length ? regionIds : undefined,
      isCompanyApproved: true,
      statuses,
    }
    return baseParams
  }, [
    shiftId,
    companyNames,
    dateRange,
    regionIds,
    fieldMonitorFilters.showCanceledShiftsFilter,
    shiftCode,
  ])

  useDebounce(() => setDebouncedMagicSearchText(magicSearchText), 500, [
    magicSearchText,
  ])

  const {
    shifts,
    totalFound,
    isLoading: isLoadingShifts,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  } = useSearchShifts({
    params: activeSearchParams,
    paginationParams: {
      limit: pageSize,
      offset: 0,
      sortBy: 'startTime',
      sortOrder: SortOrder.asc,
    },
    select: defaultSelectFields,
    magicSearchText: debouncedMagicSearchText,
    internalUserId: state.userProfile?.internalUser?.id,
    shiftCode: shiftCode,
  })

  const { data: sentinelNotifications } = useSentinelNotificationsForShifts({
    shiftIds: shifts?.map((shift) => shift.id) || [],
    limit: shifts.length,
    enabled: !!shifts.length,
    internalUserId: state.userProfile?.internalUser?.id,
  })

  // Set list of shiftIds into sentinel context
  useDeepEffect(() => {
    sentinelContext.dispatch({
      type: 'SET_SHIFT_IDS',
      payload: shifts?.map((shift) => shift.id) || [],
    })
  }, [shifts, sentinelContext])

  const { userOptions, isLoadingInternalUsers } = useInternalUsers({
    statuses: [InternalUserStatus.Active],
  })

  const { attributes: roleAttributes, isLoading: isLoadingRoleAttributes } =
    useAttributes()

  const { refetchLoading, lastUpdated, refetchActiveQueries } =
    useActiveQueries()

  const handleRefetch = () => {
    setCurrentPage(0)
    refetchActiveQueries()
  }

  const handleReset = () => {
    setFirstName('')
    setLastName('')
    setMagicSearchText('')
    handleRefetch()
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [searchParams, magicSearchText])

  const addWorkersToShiftsModal = useModal()
  const shiftAssignmentModal = useModal()
  const bulkCancelShiftsModal = useModal()

  const isLoadingAdditionalData =
    isLoadingRoleAttributes || isLoadingInternalUsers
  const isLoadingOrRefetch = isLoadingShifts || refetchLoading
  const disabledShiftActions = isLoadingOrRefetch || !selectedShiftIds.length

  const selectedShiftsList = useMemo(() => {
    return shifts?.filter((s) => selectedShiftIds.includes(s.id)) || []
  }, [shifts, selectedShiftIds])

  const pageCounters = useMemo(() => {
    if (isLoadingShifts) {
      return ''
    }
    const slotsRequested = shifts?.reduce(
      (acc, curr) => acc + curr.slotsRequested,
      0,
    )
    const obSlotsRequested = shifts?.reduce(
      (acc, curr) => acc + (curr.overbookSlotsRequested || curr.slotsRequested),
      0,
    )

    const validWorkerShifts = shifts
      .map((shift) => getExpectedWorkerShifts(shift.workerShifts))
      .flat()

    return `Filled: ${validWorkerShifts.length} | Requested: ${slotsRequested} | OB: ${obSlotsRequested}`
  }, [isLoadingShifts, shifts])

  const { shiftAssignmentsMapByShiftId, shiftAssignmentsMapByInternalUser } =
    useMemo(() => {
      const shiftAssignments =
        compact(shifts?.flatMap((shift) => shift.shiftAssignment)) || []
      return createShiftAssignmentMaps(shiftAssignments)
    }, [shifts])

  const setShiftIdentifier = (shiftIdentifier: string) => {
    if (
      shiftIdentifier.length === SHIFT_CODE_LEN &&
      !strContainsNonBase64Char(shiftIdentifier)
    ) {
      setShiftCode(shiftIdentifier)
      setShiftId('')
    } else {
      setShiftId(shiftIdentifier)
      if (shiftId) {
        setShiftCode('')
      }
    }
  }

  return (
    <>
      <Text variant="h4">Ops Field Monitor</Text>
      <Row>
        <S.FieldMonitorInputsWrapper>
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={onChangeAfterDate}
            isClearable={true}
            inlineLabel={true}
            label="After"
            width={280}
            date={afterDate}
            defaultTime={new Time(0, 0)}
          />
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={onChangeBeforeDate}
            isClearable={true}
            inlineLabel={true}
            label="Before"
            width={280}
            date={beforeDate}
            defaultTime={new Time(0, 0)}
          />
          <SearchSelect
            multiple
            options={companyOptions}
            selectedItems={companyNames}
            handleSelectMultiple={setCompanyNames}
            label={`Business${regionIds.length > 1 ? 'es' : ''}`}
            width={260}
            selectedOnTop
            showClearButton
          />
          <StateSearchSelect
            multiple
            options={regionsOptions}
            selectedItems={regionIds}
            handleSelectMultiple={setRegionIds}
            label={`Region${regionIds.length > 1 ? 's' : ''}`}
            width={260}
          />
          <Input
            label="Shift ID / Full Shift Code"
            value={shiftId || shiftCode}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setShiftIdentifier(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <Input
            label="First Name"
            value={firstName}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setFirstName(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <Input
            label="Last Name"
            value={lastName}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setLastName(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <SearchSelect
            multiple
            options={userOptions}
            selectedItems={assignedTo}
            handleSelectMultiple={setAssignedTo}
            label={`Assignees`}
            width={260}
            isLoading={isLoadingInternalUsers}
          />
          <Input
            label="Magic Search"
            value={magicSearchText}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setMagicSearchText(ev.target.value)
            }
            style={{ height: theme.space.lg }}
            width="260px"
          />
          <SearchSelect
            multiple
            options={ffMaxOptions}
            selectedItems={ffMax}
            handleSelectMultiple={setFfMax}
            label={`FF Max`}
            width={260}
          />
          <SearchSelect
            multiple
            options={assigneeDisplayOptions}
            selectedItems={assigneeDisplay}
            handleSelectMultiple={setAssigneeDisplay}
            label={'Display Assignee'}
          />
        </S.FieldMonitorInputsWrapper>
      </Row>
      <Row
        mb={theme.space.med}
        justifyBetween
        wrap
        style={{
          position: 'sticky',
          top: 50,
          backgroundColor: theme.colors.Grey10,
          border: `1px solid ${theme.colors.Grey20}`,
          borderRadius: theme.border.radius,
          zIndex: Z_INDEXES.DROPDOWN_LIST_CONTAINER,
          padding: theme.space.xs,
        }}
      >
        {isLoadingAdditionalData && (
          <Row justifyCenter alignCenter>
            <CircularProgress size={18} />
            <Text ml={theme.space.xs}>Loading additional data</Text>
          </Row>
        )}
        {!isLoadingAdditionalData && (
          <Row>
            {selectedShiftIds.length > 0 && (
              <S.ShiftActionButton
                leftIcon={<Icon name="cancel" />}
                variant={ButtonVariant.OUTLINED}
                onClick={clearSelectedShiftIds}
              >
                {`Clear (${selectedShiftIds.length})`}
              </S.ShiftActionButton>
            )}
            <S.ShiftActionButton
              id="add-workers-button"
              onClick={addWorkersToShiftsModal.open}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              disabled={disabledShiftActions}
              ml={theme.space.xxs}
              slim
            >
              Add Workers
            </S.ShiftActionButton>
            <S.ShiftActionButton
              id="assign-shifts-button"
              onClick={shiftAssignmentModal.open}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              disabled={disabledShiftActions}
              ml={theme.space.xxs}
              slim
            >
              Assign Shifts
            </S.ShiftActionButton>
            <S.ShiftActionButton
              id="cancel-shifts-button"
              onClick={bulkCancelShiftsModal.open}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingOrRefetch}
              disabled={disabledShiftActions}
              ml={theme.space.xxs}
              slim
            >
              Cancel Shifts
            </S.ShiftActionButton>
          </Row>
        )}
        <Row alignEnd wrap>
          <Text variant="caption" mb={theme.space.xxs}>
            Last updated: {lastUpdated}
          </Text>
          <Button
            leftIcon={<Icon name="undo" />}
            onClick={() => handleReset()}
            variant={ButtonVariant.OUTLINED}
            loading={isLoadingOrRefetch}
            style={{ width: 120 }}
            ml={theme.space.xxs}
          >
            Reset
          </Button>
          <Button
            leftIcon={<Icon name="search" />}
            onClick={() => handleRefetch()}
            variant={ButtonVariant.FILLED}
            loading={isLoadingOrRefetch}
            style={{ width: 220 }}
            ml={theme.space.xxs}
          >
            Search
          </Button>
        </Row>
      </Row>

      <FieldMonitorFilters // TODO assigned to me
        activeFilterCount={activeFilterCount}
        fieldMonitorFilters={fieldMonitorFilters}
        handleToggleFilter={handleToggleFilter}
      />
      <Row justifyBetween mt={theme.space.med}>
        <Text variant="h6">
          {isLoadingShifts ? `...` : `${totalFound} shifts found`}
        </Text>
        <Text variant="h6">{pageCounters}</Text>
        <Row alignCenter>
          <Pagination
            dataSize={shifts.length}
            onPageLeft={goToPreviousPage}
            onPageRight={goToNextPage}
            page={currentPage}
            pageSize={pageSize}
            totalFound={totalFound}
          />
          <Select
            label="Limit"
            value={pageSize.toString()}
            handleSelect={(v) => setPageSize(parseInt(v))}
            menuItems={shiftLimitOptions}
            containerStyle={{ marginLeft: theme.space.xs }}
          />
        </Row>
      </Row>
      <Row mt={theme.space.xs}>
        {isLoadingOrRefetch ? (
          <CircularProgress
            size={36}
            sx={{
              left: '50%',
            }}
          />
        ) : (
          <CollapsibleShiftTable
            shifts={shifts?.map((s) => ({ ...s, shiftId: s.id })) || []}
            sentinelNotifications={sentinelNotifications?.shifts ?? []}
            fieldMonitorFilters={fieldMonitorFilters}
            workerFirstName={firstName}
            workerLastName={lastName}
            roleAttributes={roleAttributes}
            assignedTo={assignedTo}
            activeFilterCount={activeFilterCount}
            selectedShiftIds={selectedShiftIds}
            setSelectedShiftIds={setSelectedShiftIds}
            showSelect
            ffMax={ffMax}
            assigneeDisplay={assigneeDisplay}
            uncollapseRow={shifts?.length === 1 ? true : false}
          />
        )}
      </Row>
      <AddWorkerToShiftsModal
        handleClose={addWorkersToShiftsModal.handleClose}
        isOpen={addWorkersToShiftsModal.isOpen}
        shiftsList={selectedShiftsList}
      />
      <ShiftAssignmentModal
        handleClose={shiftAssignmentModal.handleClose}
        isOpen={shiftAssignmentModal.isOpen}
        shiftsList={selectedShiftsList}
        refetchAssignments={handleRefetch}
        shiftAssignmentsMapByShiftId={shiftAssignmentsMapByShiftId}
      />
      <BulkCancelShiftsModal
        handleClose={bulkCancelShiftsModal.handleClose}
        isOpen={bulkCancelShiftsModal.isOpen}
        shiftsList={selectedShiftsList}
      />
      <AssignmentsDrawer
        isOpen={assignmentsOpen}
        shiftsList={shifts}
        setIsOpen={setAssignmentsOpen}
        shiftAssignmentsMapByInternalUser={shiftAssignmentsMapByInternalUser}
      />
    </>
  )
}
