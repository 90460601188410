import { CircularProgress, List } from '@mui/material'
import { theme } from '@traba/theme'
import { WorkerListItem } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/WorkerListItem'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { Row } from '../base'

type RostersTableProps = {
  rosterId: string
  isLoadingWorkers: boolean
  rosterWorkers: PopulatedWorker[]
}

export const RosterWorkersTable = (props: RostersTableProps) => {
  const { rosterId, isLoadingWorkers, rosterWorkers } = props

  if (isLoadingWorkers) {
    return (
      <Row justifyCenter alignCenter my={theme.space.sm}>
        <CircularProgress
          size={48}
          sx={{
            left: '50%',
          }}
        />
      </Row>
    )
  }

  return (
    <List
      sx={{
        maxHeight: 500,
        overflow: 'auto',
        padding: 0,
        marginTop: 2,
      }}
    >
      {(rosterWorkers ?? []).map((worker: PopulatedWorker, i: number) => (
        <WorkerListItem
          key={`${rosterId}-${worker.id}`}
          worker={worker}
          index={i}
        />
      ))}
    </List>
  )
}
