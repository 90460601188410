import { Checkbox } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AssigneeDisplay,
  InternalUserRole,
  SentinelNotification,
} from '@traba/types'
import { RoleAttribute } from '@traba/types'
import { JobStatus, PaymentStatus } from '@traba/types'
import { addMinutes, isAfter, subHours } from 'date-fns'
import { compact } from 'lodash'
import { MouseEvent, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { FieldMonitorFiltersType as FieldMonitorFilters } from 'src/screens/FieldMonitorScreen/components/FieldMonitorFilters/FieldMonitorFilters'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import {
  getTagEmojiString,
  shouldShowOverbookWarning,
  shouldShowInvitationTag,
  shouldShowRmsaTag,
  calculateShiftReliabilityAverage,
} from 'src/utils/shiftUtils'
import { normalizeString, toPercentString } from 'src/utils/stringUtils'
import { getExpectedWorkerShifts } from '../../utils/workerShiftUtils'
import { Badge, Button, Icon, IconButton, Row, Td, Tr } from '../base'
import { ButtonVariant } from '../base/Button/types'
import { IMenuItem } from '../base/Select/Select'
import { UnreadSentinelNotificationLabel } from '../Sentinel/components/UnreadSentinelNotificationLabel'
import { SentinelShiftNotificationsDropdown } from '../Sentinel/SentinelShiftDropdown'
import { ShiftAndCompanyNoteDrawer } from '../ShiftAndCompanyNoteDrawer'
import { ShiftAssignmentLabel } from '../ShiftAssigneeLabel'
import { ShiftDetails } from '../ShiftDetails/ShiftDetails'
import { EarlyArrivalBufferBadge } from './components/EarlyArrivalBufferBadge'

export interface CollapsibleShiftRowProps {
  shift: OpsExtendedShift
  fieldMonitorFilters?: FieldMonitorFilters
  activeFilterCount?: number
  workerFirstName?: string
  workerLastName?: string
  ffMax?: IMenuItem[]
  assigneeDisplay?: IMenuItem[]
  disableCollapsible?: boolean
  roleAttributes?: RoleAttribute[]
  onSelectShift?: (shiftId: string) => void
  isSelected?: boolean
  showSelect?: boolean
  assignedTo?: IMenuItem[]
  isSentinelNotificationsMuted?: boolean
  sentinelNotifications?: SentinelNotification[]
  uncollapseRow?: boolean
}

const LOW_RELIABILITY_THRESHOLD = 0.8

// This logic is consistent with the logic in the CompanyShiftsTable/CompanyShiftsTable.tsx populateSlotsFilled(). Please update both if changing this.
const formatStringOrCheck = (
  first: number,
  second: number,
  options?: {
    shouldShowWarning?: boolean
    warningBackground?: boolean
    warningBackgroundColor?: string
    textSuffix?: string
    newLine?: string
  },
) => {
  const shouldShowRedText =
    typeof options?.shouldShowWarning !== 'undefined'
      ? options?.shouldShowWarning
      : first > second
  // If it is ever greater want them to see how much greater
  // e.a. see how much they are overbooking by
  return (
    <Td
      style={
        options?.warningBackground
          ? {
              backgroundColor:
                options?.warningBackgroundColor ?? theme.colors.Red10,
            }
          : {}
      }
    >
      <Row style={{ display: 'inline' }}>
        {first > 0 && first === second && (
          <>
            <Icon name="greenCheck" />{' '}
          </>
        )}
        <Text
          style={{
            display: 'inline',
            color: shouldShowRedText ? theme.colors.Red80 : '',
          }}
        >
          {first}/{second}
        </Text>
      </Row>
      {options && options.textSuffix}
      {options && options.newLine && <Row>{options.newLine}</Row>}
    </Td>
  )
}

export const CollapsibleShiftRow = (props: CollapsibleShiftRowProps) => {
  const [expanded, setExpanded] = useState<boolean>(
    props.disableCollapsible || props.uncollapseRow ? true : false,
  )
  const [showSentinelNotifications, setShowSentinelNotifications] =
    useState<boolean>(false)
  const [isLoadingSentinelNotifications, setIsLoadingSentinelNotifications] =
    useState<boolean>(false)
  const [isNoteDrawerOpen, setIsNoteDrawerOpen] = useState<boolean>(false)
  const { showSuccess } = useAlert()

  const {
    shift,
    workerFirstName,
    workerLastName,
    roleAttributes,
    fieldMonitorFilters,
    activeFilterCount,
    onSelectShift,
    isSelected,
    showSelect,
    assignedTo,
    isSentinelNotificationsMuted,
    sentinelNotifications,
    ffMax,
    assigneeDisplay,
  } = props
  const isCanceled = !!shift.canceledAt

  const { state } = useUserContext()
  const { hotSettings } = useHotSettings()

  const tz = useTimezonedDates(shift.timezone)

  const assignedToMarketOps = useMemo(
    () =>
      !!shift.shiftAssignment?.assignees.find(
        (assignee) =>
          assignee.internalUser.role === InternalUserRole.MARKET_OPS,
      ),
    [shift.shiftAssignment?.assignees],
  )

  const assignedToScaledOps = useMemo(
    () =>
      !!shift.shiftAssignment?.assignees.find(
        (assignee) =>
          assignee.internalUser.role === InternalUserRole.SCALED_OPS,
      ),
    [shift.shiftAssignment?.assignees],
  )

  // // Responsive collapse
  // useEffect(() => {
  //   setExpanded(props.disableCollapsible ?? false)
  // }, [props.disableCollapsible])

  /* Parse worker shifts for required info */
  const expectedToWork = getExpectedWorkerShifts(shift.workerShifts)
  const confirmedWS = expectedToWork?.filter((ws) => ws.isShiftConfirmed)
  const clockedInWS = expectedToWork?.filter((ws) => ws.clockInTime)
  const clockedOutWS = expectedToWork?.filter((ws) => ws.clockOutTime)

  const reliabilityAverage = calculateShiftReliabilityAverage(
    shift.workerShifts,
  )

  /*
   * Determine if shift has a buffer
   */
  const earlyArrivalBuffer = getEarlyArrivalTimeBufferInMinutes({
    shiftStartTime: shift.startTime,
    businessStartTime: shift.businessStartTime,
  })

  /*
   *  Normalize worker names for filtering
   */
  const normalizedWorkerNames = compact(
    shift.workerShifts?.map((ws) => {
      if (ws.jobStatus !== JobStatus.Canceled) {
        return {
          normalizedFirstName: normalizeString(ws.worker?.firstName || ''),
          normalizedLastName: normalizeString(ws.worker?.lastName || ''),
        }
      }
      return undefined
    }),
  )

  const firstNameFilter = workerFirstName
    ? normalizeString(workerFirstName)
    : ''
  const lastNameFilter = workerLastName ? normalizeString(workerLastName) : ''

  const includesFirstName = firstNameFilter
    ? normalizedWorkerNames.some((workerName) =>
        workerName.normalizedFirstName.includes(firstNameFilter),
      )
    : true
  const includesLastName = lastNameFilter
    ? normalizedWorkerNames.some((workerName) =>
        workerName.normalizedLastName.includes(lastNameFilter),
      )
    : true

  // TODO move this to be a backend filter
  const assignedToSet = new Set(
    assignedTo?.map((item: IMenuItem) => item.value),
  )

  /** Check if there are shifts assigned the selected internal user Ids */
  const includesAssignedTo =
    assignedToSet.size > 0
      ? shift.shiftAssignment?.assignees.some((assignee) =>
          assignedToSet.has(assignee.internalUserId),
        )
      : true

  const includesFFMax =
    ffMax && ffMax.length > 0
      ? ffMax.some((item) => item.value === shift.forwardFillMax)
      : true

  const internalUser = state.userProfile?.internalUser

  const showSentinelNotificationLabel = useMemo(
    () =>
      !!internalUser &&
      !props.disableCollapsible &&
      hotSettings?.enableSentinelNotificationUI &&
      !!sentinelNotifications?.length,
    [
      internalUser,
      props.disableCollapsible,
      hotSettings?.enableSentinelNotificationUI,
      sentinelNotifications?.length,
    ],
  )

  // bubble icon logic
  const memoizedAssignees = useMemo(() => {
    return shift.shiftAssignment?.assignees.filter((assignee) => {
      const assigneeDisplaySet = new Set(
        assigneeDisplay?.map((item) => item.value),
      )

      if (
        assigneeDisplaySet.has(AssigneeDisplay.MARKET_OPS) &&
        assigneeDisplaySet.has(AssigneeDisplay.SCALED_OPS)
      ) {
        return (
          assignee?.internalUser.role === InternalUserRole.MARKET_OPS ||
          assignee?.internalUser.role === InternalUserRole.SCALED_OPS
        )
      } else if (assigneeDisplaySet.has(AssigneeDisplay.MARKET_OPS)) {
        return assignee?.internalUser.role === InternalUserRole.MARKET_OPS
      } else if (assigneeDisplaySet.has(AssigneeDisplay.SCALED_OPS)) {
        return assignee?.internalUser.role === InternalUserRole.SCALED_OPS
      }
    })
  }, [shift.shiftAssignment?.assignees, assigneeDisplay])

  //Check if this shift is assigned to logged in user
  const assignedToUser = useMemo(
    () =>
      !!shift.shiftAssignment?.assignees.find(
        (assignee) => assignee.internalUserId === internalUser?.id,
      ),
    [internalUser?.id, shift.shiftAssignment?.assignees],
  )

  // If the filter is present and worker names do not match then hide row
  // TODO move all these to be filtered at the backend query
  if (
    !includesFirstName ||
    !includesLastName ||
    !includesAssignedTo ||
    !includesFFMax
  ) {
    return null
  }

  /*
  Check whether shift is paid
   */
  let workerShiftsPaid = 0
  let workerShiftsOwed = 0

  shift.workerShifts?.forEach((ws) => {
    const earningsSummary = ws.earningsSummary
    if (earningsSummary && earningsSummary.totalOwed > 0) {
      if (
        earningsSummary.paymentStatus === PaymentStatus.Paid ||
        earningsSummary.paymentStatus === PaymentStatus.ManualComplete
      ) {
        workerShiftsPaid++
      }
      workerShiftsOwed++
    }
  })

  /*
   * Logic for showing warnings
   */
  const curDate = new Date()
  const twoHoursBeforeShift = subHours(new Date(shift.startTime), 2)
  const thirtyMinsAfterShift = addMinutes(new Date(shift.endTime), 30)

  const warnLowReliability =
    reliabilityAverage !== null &&
    reliabilityAverage < LOW_RELIABILITY_THRESHOLD

  const warnConfirm =
    isAfter(curDate, twoHoursBeforeShift) &&
    confirmedWS &&
    confirmedWS.length < shift.slotsRequested

  const warnClockIn =
    isAfter(curDate, new Date(shift.startTime)) &&
    clockedInWS &&
    clockedInWS.length < shift.slotsRequested

  const warnClockOut =
    isAfter(curDate, thirtyMinsAfterShift) &&
    clockedOutWS &&
    expectedToWork &&
    clockedOutWS.length < expectedToWork.length

  const warnPaid = workerShiftsPaid < workerShiftsOwed

  const warnSlotsFilled =
    shift.slotsFilled < (shift.overbookSlotsRequested ?? shift.slotsRequested)

  const hasAssignments = !!shift.shiftAssignment?.assignees?.length

  /*
   * Logic for showing filteredShifts
   * FIXME: Do NOT add anymore logic here. We need to revamp. This will lead to more unituitive side-effects as the UI becomes more complex.
   * https://linear.app/traba/issue/ENG-10522
   */
  if (fieldMonitorFilters) {
    if (activeFilterCount && activeFilterCount > 0) {
      if (!warnLowReliability && fieldMonitorFilters.showLowReliabilityShifts) {
        return null
      }
      if (!warnConfirm && fieldMonitorFilters.showNotConfirmedFilter) {
        return null
      }
      if (!warnClockIn && fieldMonitorFilters.showLateClockInShiftsFilter) {
        return null
      }
      if (!warnClockOut && fieldMonitorFilters.showLateClockOutShiftsFilter) {
        return null
      }
      if (!warnPaid && fieldMonitorFilters.showNotPaidFilter) {
        return null
      }
      if (!warnSlotsFilled && fieldMonitorFilters.showSlotsNotFilledFilter) {
        return null
      }
      if (!assignedToUser && fieldMonitorFilters.showAssignedShifts) {
        return null
      }

      // FIXME: Do NOT add any more logic here. @see https://linear.app/traba/issue/ENG-10522
      if (
        fieldMonitorFilters.showUnassignedShiftsMarketOps &&
        fieldMonitorFilters.showUnassignedShiftsScaleOps
      ) {
        // both are on, then we should filter out assigned ones so it shows all unassigned
        if (hasAssignments) {
          return null
        }
      } else {
        if (
          fieldMonitorFilters.showUnassignedShiftsMarketOps &&
          assignedToMarketOps
        ) {
          return null
        }
        if (
          fieldMonitorFilters.showUnassignedShiftsScaleOps &&
          assignedToScaledOps
        ) {
          return null
        }
      }
    }
  }

  const handleCheckboxChange = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    onSelectShift && onSelectShift(shift.id)
  }

  const handleRowClick = (ev: MouseEvent<HTMLTableRowElement>) => {
    if (ev.altKey) {
      navigator.clipboard.writeText(shift.id)
      showSuccess('', `Shift ID copied to clipboard: ${shift.id}`, 2000)
    } else {
      setExpanded(!expanded)
    }
  }

  const roleName = shift.shiftRole || shift.role?.roleName

  function getSlotsFilledTextSuffixAndNewLine(shift: OpsExtendedShift) {
    const {
      overbookSlotsRequested,
      paidBackupSlotsRequested,
      paidBackupSlotsFilled,
    } = shift

    let textSuffix = ''
    if (overbookSlotsRequested) {
      textSuffix += `/OB${overbookSlotsRequested}`
    }

    let newLine = ''
    if (paidBackupSlotsRequested || paidBackupSlotsFilled) {
      newLine += `${paidBackupSlotsFilled ?? 0}/PB${
        paidBackupSlotsRequested ?? 0
      }`
    }
    return { textSuffix, newLine }
  }

  return (
    <>
      <Tr
        onClick={handleRowClick}
        key={`shiftRow_${shift.id}`}
        style={{
          backgroundColor:
            expanded && !props.disableCollapsible
              ? theme.colors.Grey10
              : theme.colors.White,
          cursor: props.disableCollapsible ? 'auto' : 'pointer',
        }}
      >
        {!!showSelect && (
          <Td>
            <Row alignCenter justifyCenter>
              <Checkbox checked={isSelected} onClick={handleCheckboxChange} />
            </Row>
          </Td>
        )}

        <Td>
          <Row alignCenter justifyBetween>
            {getTagEmojiString(
              shift.tags || [],
              shouldShowInvitationTag(shift),
              shouldShowRmsaTag(shift),
            )}
            {shift.employerName || shift.company.employerName}
            <Row>
              {isCanceled && (
                <Badge
                  style={{ marginLeft: theme.space.xxs }}
                  variant="opaqueRed"
                  title="Canceled"
                />
              )}
              {shift.shiftAssignment &&
                memoizedAssignees?.map((assignee) => {
                  return (
                    <span
                      style={{ marginLeft: theme.space.xxxs }}
                      key={`${assignee.id}_${shift.id}`}
                    >
                      <ShiftAssignmentLabel
                        internalUser={assignee.internalUser}
                      />
                    </span>
                  )
                })}

              {showSentinelNotificationLabel && (
                <UnreadSentinelNotificationLabel
                  sentinelNotificationCount={sentinelNotifications?.length ?? 0}
                  onClickLabel={() =>
                    setShowSentinelNotifications((prevValue) => !prevValue)
                  }
                  isMuted={!!isSentinelNotificationsMuted}
                  isLoading={isLoadingSentinelNotifications}
                />
              )}
            </Row>
          </Row>
        </Td>
        <Td>
          <Row alignCenter>
            <Text>{shift.location?.shortLocation}</Text>
            <Badge
              style={{ marginLeft: theme.space.xxs }}
              variant="info"
              title={shift.regionId}
            />
          </Row>
        </Td>
        <Td>{roleName}</Td>
        <Td>
          {tz.getShiftDate(shift.startTime, shift.endTime, { year: undefined })}
        </Td>
        <Td>
          <Row alignCenter>
            <Text>{tz.getTime(shift.startTime)}</Text>
            {earlyArrivalBuffer ? (
              <EarlyArrivalBufferBadge
                buffer={earlyArrivalBuffer}
                style={{ marginLeft: theme.space.xxs }}
              />
            ) : null}
          </Row>
        </Td>
        <Td>{tz.getTime(shift.endTime)}</Td>
        {/* RELIABILITY */}
        <Td
          style={
            warnLowReliability
              ? {
                  backgroundColor: theme.colors.Red10,
                }
              : {}
          }
        >
          <Row style={{ display: 'inline' }}>
            <Text
              style={{
                display: 'inline',
              }}
            >
              {reliabilityAverage !== null
                ? `${toPercentString(reliabilityAverage)}%`
                : '-'}
            </Text>
          </Row>
        </Td>
        <Td>
          {shift.workersMovingOrArrived} / {shift.slotsRequested}
        </Td>
        {/* WORKERS CONFIRMED */}
        {confirmedWS &&
          expectedToWork &&
          formatStringOrCheck(confirmedWS.length, expectedToWork.length, {
            warningBackground: warnConfirm,
          })}

        {/* WORKERS CLOCKED IN */}
        {clockedInWS &&
          expectedToWork &&
          formatStringOrCheck(clockedInWS.length, expectedToWork.length, {
            warningBackground: warnClockIn,
          })}

        {/* WORKERS CLOCKED OUT */}
        {(clockedOutWS && clockedOutWS.length > 0) ||
        isAfter(curDate, thirtyMinsAfterShift) ? (
          clockedOutWS &&
          expectedToWork &&
          formatStringOrCheck(clockedOutWS.length, expectedToWork.length, {
            warningBackground: warnClockOut,
          })
        ) : (
          <Td>-</Td>
        )}

        {/* WORKER SLOTS FILLED */}
        {formatStringOrCheck(shift.slotsFilled, shift.slotsRequested, {
          shouldShowWarning: shouldShowOverbookWarning(shift),
          warningBackground: warnSlotsFilled,
          ...(shift.slotsFilled < shift.slotsRequested
            ? { warningBackgroundColor: theme.colors.Red40 }
            : {}),
          ...getSlotsFilledTextSuffixAndNewLine(shift),
        })}

        {/* WORKERS PAID */}
        {workerShiftsOwed > 0 ? (
          formatStringOrCheck(workerShiftsPaid, workerShiftsOwed, {
            warningBackground: warnPaid,
          })
        ) : (
          <Td>-</Td>
        )}
        <Td>
          <IconButton
            iconName="message"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setIsNoteDrawerOpen((isOpen) => !isOpen)
            }}
          />
        </Td>
        <Td>
          {!props.disableCollapsible && (
            <Icon
              name={expanded ? 'chevronUp' : 'chevronDown'}
              style={{
                height: 12,
                width: 12,
                cursor: 'pointer',
              }}
            />
          )}
        </Td>
      </Tr>
      {!!internalUser && showSentinelNotifications && (
        <Tr style={{ width: '100%' }}>
          <Td colSpan={14}>
            <SentinelShiftNotificationsDropdown
              shiftId={shift.id}
              timezone={shift.timezone}
              internalUser={internalUser}
              onClose={() => setShowSentinelNotifications(false)}
              setIsLoadingShiftNotifications={setIsLoadingSentinelNotifications}
            />
          </Td>
        </Tr>
      )}
      {expanded ? (
        <Tr style={{ width: '100%' }}>
          <Td colSpan={12} style={{ padding: 0, margin: 0 }}>
            <div style={{ marginTop: theme.space.xs }}>
              <ShiftDetails shift={shift} roleAttributes={roleAttributes} />
            </div>
            {!props.disableCollapsible && (
              <Link to={`/field-monitor/${shift.id}`}>
                <Button
                  variant={ButtonVariant.PURPLEGRADIENT}
                  style={{ width: '100%', marginBottom: '8px' }}
                >
                  Check Details
                </Button>
              </Link>
            )}
          </Td>
        </Tr>
      ) : null}
      {isNoteDrawerOpen && (
        <ShiftAndCompanyNoteDrawer
          shiftId={shift.id}
          companyId={shift.companyId}
          hideFAB={true}
          isOpen={isNoteDrawerOpen}
          setIsOpen={setIsNoteDrawerOpen}
        />
      )}
    </>
  )
}
