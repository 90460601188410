import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  ListItemText,
  Checkbox,
} from '@mui/material'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { containsText } from '@traba/utils'
import React, { useState, useMemo } from 'react'
import { Row, Button } from 'src/components/base'
import { ButtonVariant } from '../Button/types'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import { IMenuItem } from '../Select/Select'
import * as S from './SearchSelect.styles'

export interface StateSearchSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  inputStatus?: InputStatus
  options: IMenuItem[]
  handleSelectMultiple?: (value: string[]) => void
  selectedItems: string[]
  errorMessage?: string
  multiple?: boolean
  onlyShowLabel?: boolean
  width?: number | string
  isLoading?: boolean
  multipleNoneSelectedLabel?: string
  showClearButton?: boolean
}

const createMenuItem = ({
  option,
  isChild,
  multiple,
  checked,
  onlyShowLabel,
  handleCityChange,
  handleStateChange,
}: {
  option: IMenuItem
  isChild?: boolean
  multiple?: boolean | undefined
  checked: boolean
  onlyShowLabel?: boolean
  handleCityChange?: (value: string) => void
  handleStateChange?: (value: string) => void
}) => {
  return (
    <MenuItem
      key={option.value}
      value={option.value}
      sx={{
        fontFamily: 'Poppins',
        ...(isChild ? { 'padding-left': theme.space.med } : {}),
      }}
      onClick={() =>
        isChild
          ? handleCityChange?.(option.value)
          : handleStateChange?.(option.value)
      }
    >
      {multiple && <Checkbox checked={checked} />}
      <ListItemText
        primary={option.label}
        secondary={
          onlyShowLabel || !isChild
            ? ''
            : (option.secondaryLabel ?? option.value)
        }
      />
    </MenuItem>
  )
}

export const StateSearchSelect: React.FC<StateSearchSelectProps> = (
  props: StateSearchSelectProps,
) => {
  const {
    options,
    multiple,
    handleSelectMultiple,
    selectedItems,
    width,
    isLoading,
    multipleNoneSelectedLabel,
    showClearButton = false,
    style,
  } = props

  const [searchText, setSearchText] = useState('')
  const [selectedStates, setSelectedStates] = useState<string[]>([])
  const [selectedCities, setSelectedCities] = useState<string[]>(selectedItems)

  // creating regions such that cities are nested within state as children
  const stateCityMap = useMemo(() => {
    const regionsOptions: IMenuItem[] = Object.entries(
      options.reduce(
        (acc, region) => {
          const state = region.value.split('-').pop()?.toUpperCase() || ''
          if (!acc[state]) {
            acc[state] = []
          }
          acc[state].push({
            label: region.label,
            value: region.value,
          })
          return acc
        },
        {} as {
          [key: string]: {
            label: string
            value: string
          }[]
        },
      ),
    )
      .map(([state, regions]) => ({
        value: state,
        label: state,
        children: regions.sort((a, b) => a.label.localeCompare(b.label)),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    return regionsOptions
  }, [options])

  const displayedOptions = useMemo(() => {
    const filteredOptions = stateCityMap.filter((option) => {
      const labelHasText = containsText(option.label, searchText)
      const secondaryLabelHasText = false

      const childrenMatch = option.children?.some((child) =>
        containsText(child.label, searchText),
      )

      return labelHasText || secondaryLabelHasText || childrenMatch
    })

    if (multiple && selectedStates.length > 0) {
      filteredOptions.sort((a, b) => {
        const aIsSelected = selectedStates.includes(a.value)
        const bIsSelected = selectedStates.includes(b.value)
        if (aIsSelected && !bIsSelected) {
          return -1
        } else if (!aIsSelected && bIsSelected) {
          return 1
        }
        return a.value.localeCompare(b.value)
      })
    }

    return filteredOptions
  }, [searchText, stateCityMap, multiple, selectedStates])

  const handleStateChange = (state: string) => {
    const updatedStates = isStateSelected(state)
      ? selectedStates.filter((selectedState) => selectedState !== state)
      : [...selectedStates, state]

    setSelectedStates(updatedStates)

    const stateCities =
      stateCityMap
        .find((option) => option.value === state)
        ?.children?.map((city) => city.value) || []

    const updatedCities = isStateSelected(state)
      ? selectedCities.filter((city) => !stateCities.includes(city))
      : [...selectedCities, ...stateCities]

    setSelectedCities(updatedCities)
    handleSelectMultiple?.(updatedCities)
  }

  const handleCityChange = (city: string) => {
    const updatedCities = selectedCities.includes(city)
      ? selectedCities.filter((selectedCity) => selectedCity !== city)
      : [...selectedCities, city]

    setSelectedCities(updatedCities)
    handleSelectMultiple?.(updatedCities)
  }

  const isStateSelected = (stateLabel: string): boolean => {
    const stateOption = stateCityMap.find(
      (option) => option.value === stateLabel,
    )

    // handle check to see if all cities of a state are selected in which case
    if (stateOption && stateOption.children) {
      return stateOption.children.every((city) =>
        selectedCities.includes(city.value),
      )
    }
    // there should always be a return value above
    return false
  }

  const isCitySelected = (cityLabel: string): boolean => {
    return selectedCities.some((city) => city === cityLabel)
  }

  const hasError = props.inputStatus === InputStatus.error

  const renderValue = () => {
    if (multiple) {
      const allSelectedItems = [...selectedStates, ...selectedCities]
      if (allSelectedItems.length === 0) {
        return multipleNoneSelectedLabel
      }
      return allSelectedItems.join(', ')
    }
  }

  return (
    <>
      <S.SearchSelectContainer style={{ width, ...style }}>
        <S.SearchBoxStyling />
        <FormControl style={{ borderColor: theme.colors.Violet }} fullWidth>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="state-search-select-label"
            id="state-search-select"
            value={[...selectedStates, ...selectedCities].map((item) => item)}
            onClose={() => setSearchText('')}
            renderValue={renderValue}
            displayEmpty={!!(multiple && multipleNoneSelectedLabel)}
            multiple={multiple}
            disabled={isLoading}
            sx={{ width }}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                id="search-text-field"
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  margin: '8px 0px',
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
            {showClearButton ? (
              <Row justifyEnd>
                <Button
                  slim
                  variant={ButtonVariant.TEXT}
                  onClick={() => {
                    setSelectedStates([])
                    setSelectedCities([])
                    handleSelectMultiple?.([])
                  }}
                >
                  Clear
                </Button>
              </Row>
            ) : null}
            {displayedOptions.flatMap((state) => [
              createMenuItem({
                option: state,
                isChild: false,
                multiple,
                checked: isStateSelected(state.value),
                handleStateChange: () => handleStateChange(state.value),
              }),
              ...(state.children?.map((city) =>
                createMenuItem({
                  option: city,
                  isChild: true,
                  multiple,
                  checked: isCitySelected(city.value),
                  handleCityChange: () => handleCityChange(city.value),
                }),
              ) ?? []),
            ])}
          </Select>
        </FormControl>
        {!!props.label && <S.Label>{props.label}</S.Label>}
      </S.SearchSelectContainer>
      {hasError ? (
        <InputErrorMessage>
          <InputErrorIcon></InputErrorIcon>
          {props.errorMessage}
        </InputErrorMessage>
      ) : null}
    </>
  )
}
