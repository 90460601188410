import * as Sentry from '@sentry/react'
import { theme } from '@traba/theme'
import { WorkerShiftEligibility } from '@traba/types'
import React, { useState } from 'react'
import { trabaApi } from '../../api/helpers'
import { Button, Input, Row } from '../base'
import EligibilityIcon from '../base/EligibilityIcon/EligibilityIcon'

function WorkerEligibility({ shiftId }: { shiftId: string }) {
  const [workerId, setWorkerId] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [workersEligibility, setWorkersEligibility] =
    useState<WorkerShiftEligibility>()

  const onWorkerIdChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setWorkerId(ev.currentTarget.value)
  }

  const getWorkerEligibilities = async () => {
    try {
      setIsLoading(true)
      const res = await trabaApi.get<WorkerShiftEligibility>(
        `/shifts/${shiftId}/worker-shifts/${workerId}/check-eligibility`,
      )
      if (res.data) {
        setWorkersEligibility(res.data)
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  const validWorkerIdLength = workerId && workerId.length >= 24

  return (
    <Row fullWidth mt={theme.space.sm}>
      <Input
        label="Worker Eligibility"
        value={workerId}
        onChange={onWorkerIdChange}
        disabled={isLoading}
        width="250px"
        containerStyle={{ width: 250, marginRight: theme.space.xs }}
        placeholder="Enter a full worker ID"
      />
      <Button
        onClick={getWorkerEligibilities}
        disabled={isLoading || !validWorkerIdLength}
        style={{ width: '200px', height: '50px' }}
        mr={theme.space.xs}
      >
        Get Eligibility
      </Button>

      {validWorkerIdLength && workersEligibility && (
        <EligibilityIcon
          eligibility={workersEligibility}
          size="large"
          placement="right"
        />
      )}
    </Row>
  )
}

export default WorkerEligibility
