import { CalendarDate, fromDate, now } from '@internationalized/date'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ClearIcon from '@mui/icons-material/Clear'
import { DateValue, TimeValue } from '@react-types/datepicker'
import { theme } from '@traba/theme'
import { useRef, useState } from 'react'
import { AriaDatePickerProps, useDatePicker } from 'react-aria'
import { useDatePickerState } from 'react-stately'
import { AlertIcon } from 'src/assets/svg/icons/AlertIcon'
import { getLocalTimezone } from 'src/utils/dateUtils'
import styled from 'styled-components'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import Row from '../Row'
import { FieldButton } from './Button'
import Calendar from './Calendar'
import DateField from './DateField'
import Dialog from './Dialog'
import Popover from './Popover'
import * as S from './styles'
import TimeField from './TimeField'

interface DatePickerProps extends AriaDatePickerProps<DateValue> {
  showTimeFieldInPopover?: boolean
  shouldCloseOnSelect?: boolean
  isClearable?: boolean
  inlineLabel?: boolean
  defaultTime?: TimeValue
  setDate?: (newDate: Date | null, isFourDigitYear?: boolean) => void
  date?: Date | null
  defaultDate?: Date | null
  minDate?: Date
  maxDate?: Date
  width?: number
  timezone?: string
}

const DateFieldRow = styled(Row)<{
  width?: number
  isInvalid: boolean
  isDisabled: boolean
}>`
  display: flex;
  border: 1px solid;
  background-color: ${({ isDisabled }) =>
    isDisabled ? theme.colors.Grey20 : 'transparent'};
  ${({ isDisabled }) => (isDisabled ? 'pointer-events: none;' : '')}
  border-radius: 5px;
  border-color: ${({ isInvalid, isDisabled }) =>
    isDisabled
      ? theme.colors.Grey30
      : isInvalid
        ? theme.colors.red
        : theme.colors.Grey30};
  &:hover {
    border-color: ${({ isInvalid, isDisabled }) =>
      isDisabled
        ? theme.colors.Grey30
        : isInvalid
          ? theme.colors.red
          : theme.colors.Grey50};
  }
  &:focus-within {
    border-color: ${({ isInvalid, isDisabled }) =>
      isDisabled
        ? theme.colors.Grey30
        : isInvalid
          ? theme.colors.red
          : theme.colors.Violet};
  }
  ${({ width }) => {
    return width ? `width: ${width}px;` : ''
  }}
`

const THREE_DIGIT_YEAR = 999

export default function DatePicker(props: DatePickerProps) {
  const timezone = props.timezone || getLocalTimezone()
  const [hasTypedInField, setHasTypedInField] = useState<boolean>(false)

  const validateDate = (date: DateValue) => {
    /* When a user clears a single field in the date, the date is set to null
        but is still considered valid by react-aria. This overrides the behavior
        to display a validation error in that case. */
    if (hasTypedInField && date == null) {
      return 'Incomplete date or time'
    } else if (date?.year && date.year <= THREE_DIGIT_YEAR) {
      return 'Year must be 4 digits'
    } else {
      return undefined
    }
  }

  const handleDateChange = (date: DateValue) => {
    const isFourDigitYear = date?.year > THREE_DIGIT_YEAR
    props.setDate &&
      props.setDate(date?.toDate(timezone) || null, isFourDigitYear)
  }

  const modifiedProps = {
    ...props,
    minValue: props.minDate
      ? fromDate(props.minDate, timezone)
      : (props.minValue ?? new CalendarDate(1900, 1, 1)),
    maxValue: props.maxDate ? fromDate(props.maxDate, timezone) : undefined,
    defaultValue: props.defaultDate
      ? fromDate(props.defaultDate, timezone)
      : undefined,
    validate: validateDate,
    onChange: handleDateChange,
    value:
      props.date !== undefined
        ? props.date !== null
          ? fromDate(new Date(props.date), timezone)
          : null
        : undefined, // Value controlled by parent component passed in as js Date()
    placeholderValue: now(timezone),
  }

  const state = useDatePickerState(modifiedProps)

  /* Set the default time value if required & state has no current timeValue. */
  if (!!props.defaultTime && state.hasTime && !state.timeValue) {
    state.setTimeValue(props.defaultTime)
  }

  const ref = useRef(null)
  const {
    groupProps,
    labelProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(modifiedProps, state, ref)

  const [dateFieldKey, setDateFieldKey] = useState<number>(0)
  const handleClearButtonClick = () => {
    state.setValue(null)
    setHasTypedInField(false)
    // Force a re-render of datefield to clear all the fields on clear button click
    setDateFieldKey((prev) => prev + 1)
  }

  return (
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
      }}
    >
      <S.Label {...labelProps} inlineLabel={props.inlineLabel ?? false}>
        {props.label}
      </S.Label>
      <div
        {...groupProps}
        ref={ref}
        className="group"
        style={props.isDisabled ? { cursor: 'not-allowed' } : {}}
      >
        <DateFieldRow
          justifyBetween={true}
          alignCenter
          width={props.width}
          isInvalid={state.isInvalid}
          isDisabled={props.isDisabled ?? false}
        >
          <DateField
            key={dateFieldKey}
            {...fieldProps}
            handleStartTyping={() => {
              if (!hasTypedInField) {
                setHasTypedInField(true)
              }
            }}
          />
          <div style={{ display: 'flex' }}>
            <FieldButton {...buttonProps}>
              <CalendarMonthIcon fontSize="small" color="action" />
            </FieldButton>
            {props.isClearable && (
              <S.ClearIconContainer onClick={handleClearButtonClick}>
                <ClearIcon fontSize="small" />
              </S.ClearIconContainer>
            )}
          </div>
        </DateFieldRow>
      </div>
      {state.isInvalid &&
        state.displayValidation.validationErrors.map((errorMessage, index) => (
          <InputErrorMessage key={index}>
            <InputErrorIcon>
              <AlertIcon />
            </InputErrorIcon>
            {errorMessage}
          </InputErrorMessage>
        ))}
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start">
          <Dialog {...dialogProps}>
            <Calendar {...calendarProps} timezone={timezone} />
            {props.showTimeFieldInPopover && (
              <TimeField
                label="Time"
                value={state.timeValue}
                onChange={state.setTimeValue}
              />
            )}
          </Dialog>
        </Popover>
      )}
    </div>
  )
}
