import { DEFAULT_FENCE_RADIUS_METERS } from '@traba/consts'
import { GeofenceType, UpdateShiftAreaFenceDto } from '@traba/types'
import { OpsCreateLocationDto } from '@traba/types'
import { useState } from 'react'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { updateShiftAreaFence } from 'src/hooks/useGeofences'
import { useHotSettings } from 'src/hooks/useHotSettings'
import {
  LocationModal,
  LocationModalForm,
} from '../LocationModal/LocationModal'

export type CreateLocationModalProps = {
  companyId: string
  handleClose: () => void
  isOpen: boolean
}

export const CreateLocationModal = (props: CreateLocationModalProps) => {
  const { isOpen, handleClose, companyId } = props
  const { createLocationAsync } = useLocations(companyId)

  const [mediaFiles, setMediaFiles] = useState<string[]>([])
  const { hotSettings } = useHotSettings()

  const DEFAULT_COORDS = {
    latitude: 25.765523,
    longitude: -80.1951192,
  }

  const emptyLocation: OpsCreateLocationDto = {
    name: '',
    address: {
      city: '',
      street1: '',
      street2: '',
      postalCode: '',
      state: '',
    },
    coords: {
      latitude: DEFAULT_COORDS.latitude,
      longitude: DEFAULT_COORDS.longitude,
    },
    shortLocation: '',
    locationInstructions: '',
    opsLocationDetails: '',
    disableLeftFencePromptOverride: false,
    enableLeftFencePromptOverride: false,
  }

  const emptyShiftAreaFence = {
    latitude: DEFAULT_COORDS.latitude.toString(),
    longitude: DEFAULT_COORDS.longitude.toString(),
    radius_metres:
      hotSettings?.defaultTrackingRadiusMetres ?? DEFAULT_FENCE_RADIUS_METERS,
  }

  async function handleFormSubmit(values: LocationModalForm) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { shiftAreaFence, opsOverride, coords, ...otherLocationValues } =
      values

    const responseData = await createLocationAsync({
      companyId,
      newLocation: {
        ...otherLocationValues,
        coords: {
          latitude: parseFloat(coords.latitude),
          longitude: parseFloat(coords.longitude),
        },
      },
    })

    const updateFenceDto: UpdateShiftAreaFenceDto = {
      type: GeofenceType.CIRCLE,
      circleCoordinates: {
        longitude: parseFloat(shiftAreaFence.longitude),
        latitude: parseFloat(shiftAreaFence.latitude),
      },
      circleRadiusMeters: shiftAreaFence.radius_metres,
    }

    await updateShiftAreaFence({
      locationId: responseData.locationId,
      updatedFence: updateFenceDto,
    })
  }

  return (
    <LocationModal
      location={emptyLocation}
      isOpen={isOpen}
      handleClose={handleClose}
      handleFormSubmit={handleFormSubmit}
      hideOpsOverride={true}
      mediaFiles={mediaFiles}
      setMediaFiles={setMediaFiles}
      shiftAreaFence={emptyShiftAreaFence}
      title={'Create Location'}
      companyId={companyId}
    />
  )
}
