import * as Sentry from '@sentry/react'
import { Timesheet } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { ONE_HOUR_IN_MS } from 'src/libs/constants'

async function getTimesheetForShift(
  shiftId: string,
): Promise<Timesheet | undefined> {
  try {
    const response = await trabaApi.get(`/timesheets/${shiftId}`, {
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 404
      },
    })
    if (response.status === 404) {
      return
    }

    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useTimesheetForShift = (shiftId: string) => {
  const {
    isLoading,
    isError,
    data: timesheet,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<Timesheet | undefined, Error>(
    `timesheet_${shiftId}`,
    () => getTimesheetForShift(shiftId),
    {
      staleTime: ONE_HOUR_IN_MS,
    },
  )

  return {
    isLoading,
    isFetching,
    isError,
    error,
    isFetched,
    timesheet,
    refetch,
  }
}
