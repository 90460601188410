import { Switch } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { IMenuItem, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AccountApprovalStatus,
  AccountStatus,
  ApprovalStatusReason,
} from '@traba/types'
import { BreakType } from '@traba/types'
import { ConnectionType } from '@traba/types'
import {
  CancellationBusinessChargeType,
  CancellationSource,
  ShiftCancellationCostResponse,
} from '@traba/types'
import { JobStatus } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { Worker } from '@traba/types'
import { WorkerJobPlacementAction } from '@traba/types'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { trabaApi } from 'src/api/helpers'
import { Button, Col, Input, Row, Select } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import Toggle from 'src/components/base/Toggle'
import { BreakAdjuster } from 'src/components/BreakAdjuster/BreakAdjuster'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useApi } from 'src/hooks/useApi'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { hasPermissions } from 'src/hooks/usePermissions'
import {
  useCreateJobPlacement,
  useUpdateJobPlacement,
} from 'src/hooks/useWorkerJobPlacements'
import { isWorkerEligibleForInstantPay } from 'src/screens/WorkerDetailsScreen/WorkerDetailsScreen'
import { getMinDate } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getEnumOptions } from 'src/utils/inputUtils'
import { getTotalBreakTime } from 'src/utils/moneyUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import Checkbox from '../../components/base/Checkbox'
import { useAnalytics } from '../../hooks/useAnalytics'
import CancellationCosts from '../CancelShiftModal/CancellationCosts'
import { CancelManyContent } from './components/CancelManyContent'
import CompleteShiftContent from './components/CompleteShiftContent/CompleteShiftContent'
import { ManageWorkerModalContentProps } from './ManageWorkerModalContentProps'

type ConnectionProps = {
  workerId: string
  companyId: string
  handleClose: () => void
}

type WorkerJobPlacementProps = {
  workerId: string
  companyId: string
  action:
    | WorkerJobPlacementAction.CREATE_WORKER
    | WorkerJobPlacementAction.TERMINATE_WORKER
  handleClose: () => void
}

const CUSTOM_APPEASEMENT_AMOUNTS_IN_CENTS = [
  2000, 3000, 3500, 4000, 5000, 6000, 7000,
]

export const ManageWorkerModalContent = (
  props: ManageWorkerModalContentProps & { currentButton: number },
) => {
  const { currentButton } = props
  const disableButtonWhenShiftIsCanceled =
    props.workerShift.jobStatus === JobStatus.Canceled
  switch (currentButton) {
    case 0:
      return <CancelContent {...props} />
    case 1:
      return <CancelManyContent {...props} />
    case 2:
      return (
        <RejectContent {...props} disable={disableButtonWhenShiftIsCanceled} />
      )
    case 3:
      return (
        <AppeaseContent {...props} disable={disableButtonWhenShiftIsCanceled} />
      )
    case 4:
      return (
        <NoShowContent {...props} disable={disableButtonWhenShiftIsCanceled} />
      )
    case 5:
      return (
        <ToDoContent {...props} disable={disableButtonWhenShiftIsCanceled} />
      )
    case 6:
      return (
        <AbandonContent {...props} disable={disableButtonWhenShiftIsCanceled} />
      )
    case 7:
      return <CompleteShiftContent {...props} />
    case 8:
      return <ForgiveIncidentContent {...props} />
    case 9:
      return (
        <ConnectionContent
          handleClose={props.handleClose}
          workerId={props.workerShift.workerId}
          companyId={props.workerShift.shiftInfo.companyId}
        />
      )
    case 10:
      return (
        <AccountStatusContent
          {...props}
          statusChange={AccountApprovalStatus.Suspended}
        />
      )
    case 11:
      return (
        <AccountStatusContent
          {...props}
          statusChange={AccountApprovalStatus.Banned}
        />
      )
    default:
      return <Text variant="h5">Coming soon...</Text>
  }
}

export const ModalButtons = (props: {
  handleClose: () => void
  handleConfirm: () => Promise<void>
  loading: boolean
  disableSubmit?: boolean
}) => {
  const { handleClose, handleConfirm, loading, disableSubmit } = props
  return (
    <Row
      mt={16}
      fullWidth
      style={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}
    >
      <Button
        variant={ButtonVariant.OUTLINED}
        style={{ width: '200px' }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        style={{ width: '200px' }}
        onClick={handleConfirm}
        loading={loading}
        disabled={disableSubmit}
      >
        Confirm
      </Button>
    </Row>
  )
}

const RejectContent = (props: ManageWorkerModalContentProps) => {
  const { workerShift, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()
  const { hotSettings } = useHotSettings()

  let rejectionItems = [
    { value: 'late to shift', label: 'Too late' },
    { value: 'wrong attire', label: 'Wrong attire' },
    { value: 'shift is full', label: 'Shift Full' },
    { value: `doesn't meet requirements`, label: `Doesn't meet requirements` },
  ]

  rejectionItems = rejectionItems.concat(
    hotSettings?.attributes.map((attribute) => {
      return { value: attribute.type, label: `No ${attribute.en.displayName}` }
    }) || [],
  )

  const [rejectionReason, setRejectionReason] = useState<string>(
    rejectionItems[0].value,
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [shouldNotifyWorker, setShouldNotifyWorker] = useState<boolean>(false)
  const [shouldOpenSlot, setShouldOpenSlot] = useState<boolean>(false)

  const handleReject = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/reject`,
        {
          rejectionReason,
          shouldNotifyWorker,
          includeInSlotsFilledCount: !shouldOpenSlot,
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Rejecting Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  const toggleShouldOpenSlot = useCallback(
    () => setShouldOpenSlot((oldValue) => !oldValue),
    [],
  )

  function toggleShouldNotifyWorker() {
    // ensure that it toggles the state by taking in the old state value
    setShouldNotifyWorker((oldValue) => !oldValue)
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Reject Worker
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            Business rejected the worker before the shift started and removes
            applicable attributes. This can only be done if the worker is on the
            shift.
          </Text>
          <Select
            fullWidth
            label="Rejection Reason"
            menuItems={rejectionItems}
            value={rejectionReason}
            handleSelect={setRejectionReason}
          />
        </Col>
      </Row>
      <Toggle
        label="Should Open Slot?"
        buttonState={shouldOpenSlot}
        runOnChange={toggleShouldOpenSlot}
      />
      <Toggle
        containerStyle={{
          marginTop: theme.space.xs,
          marginBottom: theme.space.sm,
        }}
        label="Notify Worker? (will push/SMS worker that they were turned away)"
        buttonState={shouldNotifyWorker}
        runOnChange={toggleShouldNotifyWorker}
      />
      <ModalButtons
        {...props}
        handleConfirm={handleReject}
        loading={loading}
        disableSubmit={props.disable}
      />
    </>
  )
}

const AppeaseContent = (props: ManageWorkerModalContentProps) => {
  const { workerShift, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()
  const [shouldInstantPay, setShouldInstantPay] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  // Standard appeasement amount is $35
  const [amountSelected, setAmountSelected] = useState<number>(3500)
  const [shouldOpenSlot, setShouldOpenSlot] = useState<boolean>(false)
  const { hotSettings } = useHotSettings()

  const { data: worker, isLoading: isLoadingWorker } = useApi<
    Worker & AccountStatus
  >(`/workers/${workerShift.workerId}/worker-and-profile`)

  const toggleShouldOpenSlot = useCallback(
    () => setShouldOpenSlot((oldValue) => !oldValue),
    [],
  )

  const appeasementItems: IMenuItem[] = hotSettings?.appeasementItems
    ? Object.values(hotSettings.appeasementItems).map(
        (appeasementItemInfo) => ({
          value: appeasementItemInfo['value'],
          label: appeasementItemInfo['label'],
        }),
      )
    : []

  // Bad Overbook most common appeasement item
  const defaultAppeasementItem =
    hotSettings?.appeasementItems?.badOverbook?.value || ''

  const [appeasementId, setAppeasementId] = useState<string>(
    defaultAppeasementItem ||
      (appeasementItems.length > 0 ? appeasementItems[0].value : ''),
  )

  const createAmountItems = (amounts: number[]): IMenuItem[] => {
    return amounts.map((amount) => ({
      value: amount.toString(),
      label: getMoneyString(amount / 100, true),
    }))
  }

  const amountItems = createAmountItems(CUSTOM_APPEASEMENT_AMOUNTS_IN_CENTS)

  const workerCanGetInstantPay =
    isWorkerEligibleForInstantPay(worker) && worker?.payment?.instantPayEnabled

  const handleAppease = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/appease?incentiveId=${appeasementId}&shouldInstantPay=${shouldInstantPay}`,
        {
          appeasementAmount: {
            amount: amountSelected,
            currency: 'USD',
          },
          includeInSlotsFilledCount: !shouldOpenSlot,
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Appeasing Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Appease Worker
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            Worker is no longer needed, but was on time and prepared for the
            shift. This can only be done if the worker is on the shift.
          </Text>
          <Row style={{ marginBottom: theme.space.sm }} alignCenter>
            <Select
              label="Appeasement Reason"
              menuItems={appeasementItems}
              value={appeasementId}
              handleSelect={setAppeasementId}
              containerStyle={{ width: '50%', marginRight: theme.space.sm }}
            />
            {isLoadingWorker && (
              <Text variant="body2">loading instant pay status</Text>
            )}
            {!isLoadingWorker && workerCanGetInstantPay && (
              <Checkbox
                checked={shouldInstantPay}
                label="Use instant Pay"
                onChange={() => setShouldInstantPay(!shouldInstantPay)}
              />
            )}
            {!isLoadingWorker && !workerCanGetInstantPay && (
              <Text>Instant pay not available</Text>
            )}
          </Row>
          <Row mb={theme.space.sm} alignCenter>
            <Select
              label="Amount"
              menuItems={amountItems}
              value={amountSelected.toString()}
              handleSelect={(value) => setAmountSelected(Number(value))}
              containerStyle={{ width: '50%', marginRight: theme.space.sm }}
            />
          </Row>
          <Row>
            <Toggle
              label="Should Open Slot?"
              buttonState={shouldOpenSlot}
              runOnChange={toggleShouldOpenSlot}
            />
          </Row>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleAppease}
        loading={isLoadingWorker || loading}
        disableSubmit={props.disable}
      />
    </>
  )
}

export const NoShowContent = (props: ManageWorkerModalContentProps) => {
  const { workerShift, handleClose, sentinelNotificationToUserId } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()
  const { trackAnalytics } = useAnalytics()

  const [loading, setLoading] = useState<boolean>(false)

  const handleNoShow = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/no-show`,
        { sentinelNotificationToUserId },
      )
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken NoShow', {
          sentinelNotificationToUserId,
          workerId: workerShift.workerId,
          shiftId: workerShift.shiftId,
        })
      }
      showSuccess(
        `Successfully marked worker ${workerShift.workerId} as no-show`,
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error No Showing Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            No Show Worker
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            Worker did not show up for the shift. This can only be done if the
            worker is on the shift.
          </Text>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleNoShow}
        loading={loading}
        disableSubmit={props.disable}
      />
    </>
  )
}

const ToDoContent = (props: ManageWorkerModalContentProps) => {
  const { workerShift, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const [loading, setLoading] = useState<boolean>(false)

  const handleToDo = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/todo`,
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Reverting Shift to To-Do')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Revert a Worker to To Do
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            Reset the worker shift to To Do status. This can only be done if the
            worker is on the shift.
          </Text>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleToDo}
        loading={loading}
        disableSubmit={props.disable}
      />
    </>
  )
}

const AbandonContent = (props: ManageWorkerModalContentProps) => {
  const { workerShift, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const defaultClockIn = workerShift?.clockInTime
    ? new Date(workerShift.clockInTime)
    : new Date(workerShift.shiftInfo.startTime)

  const defaultClockOut = workerShift?.clockOutTime
    ? new Date(workerShift.clockOutTime)
    : getMinDate(
        new Date(workerShift.shiftInfo.endTime),
        new Date(new Date().setSeconds(0, 0)),
      )

  const [clockInTime, setClockInTime] = useState<Date>(defaultClockIn)
  const [clockOutTime, setClockOutTime] = useState<Date>(defaultClockOut)
  const [breakType, setBreakType] = useState<BreakType>(
    workerShift.shiftInfo.breakType,
  )
  const [breaks, setBreaks] = useState<ShiftTime[]>(workerShift?.breaks ?? [])

  const breakTime = getTotalBreakTime(
    breaks,
    breakType,
    workerShift.shiftInfo.scheduledBreaks,
  )

  const [loading, setLoading] = useState<boolean>(false)

  const handleAbandon = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/abandon`,
        {
          clockOutTime,
          clockInTime,
          breaks,
          breakType,
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Abandoning Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Set worker to abandoned
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            The worker left the shift without being dismissed by the business.
            This can only be done if the worker is on the shift.
          </Text>
          <Row fullWidth alignStart justifyStart>
            <Col>
              <Text style={{ marginBottom: theme.space.sm }} variant="caption">
                SHIFT LOCAL TIME
              </Text>
              <div style={{ display: 'flex', width: '50%' }}>
                <DatePicker
                  showTimeFieldInPopover={true}
                  setDate={(clockInTime) => {
                    if (clockInTime) {
                      setClockInTime(clockInTime)
                    }
                  }}
                  isClearable={false}
                  inlineLabel={true}
                  label="Clock In Time"
                  date={clockInTime}
                  timezone={workerShift.shiftInfo.timezone}
                />
              </div>
            </Col>
          </Row>
          <Row fullWidth justifyStart alignStart mt={theme.space.sm}>
            <div style={{ display: 'flex', width: '50%' }}>
              <DatePicker
                showTimeFieldInPopover={true}
                setDate={(clockOutTime) => {
                  if (clockOutTime) {
                    setClockOutTime(clockOutTime)
                  }
                }}
                isClearable={false}
                inlineLabel={true}
                label="Clock Out Time"
                date={clockOutTime}
                timezone={workerShift.shiftInfo.timezone}
              />
            </div>
          </Row>
          <Row fullWidth mt={theme.space.sm}>
            <Select
              label="Break Type"
              menuItems={getEnumOptions(BreakType)}
              value={breakType}
              handleSelect={(breakType) => setBreakType(breakType as BreakType)}
            />
          </Row>
          <Row mt={theme.space.sm} mb={theme.space.sm} fullWidth alignCenter>
            <Text variant="h5" mr={theme.space.xs}>
              Breaks
            </Text>{' '}
            <Text variant="body2">
              Worker Shift Break Time: {breakTime} minutes
            </Text>
          </Row>
          <Col style={{ flex: 1, marginBottom: theme.space.sm }}>
            <BreakAdjuster
              breaks={breaks}
              setBreaks={setBreaks}
              timezone={workerShift.shiftInfo.timezone}
              defaultBreakTime={workerShift?.shiftInfo.startTime ?? new Date()}
            />
          </Col>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleAbandon}
        loading={loading}
        disableSubmit={props.disable}
      />
    </>
  )
}

const ForgiveIncidentContent = (props: ManageWorkerModalContentProps) => {
  const { workerShift, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const [loading, setLoading] = useState<boolean>(false)

  const handleForgiveIncident = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/forgive-incidents`,
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Forgiving Incidents')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Forgive incidents on this shift
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            Forgive incidents on this shift so they don't affect this worker's
            OTA, or Reliability metric. Will also not count towards rejected,
            abandoned, canceled, or no show shifts.
          </Text>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleForgiveIncident}
        loading={loading}
      />
    </>
  )
}

export const ConnectionContent = (props: ConnectionProps) => {
  const { workerId, companyId, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showSuccess, showError } = useAlert()
  const { state } = useUserContext()
  const [loading, setLoading] = useState<boolean>(false)

  /*
   States
   */
  const connectionOptions = [
    { value: ConnectionType.BLOCK, label: 'Block' },
    { value: ConnectionType.INELIGIBLE, label: 'Ineligible' },
    { value: ConnectionType.FAVORITE, label: 'Favorite' },
  ]

  const blockReasons = [
    {
      value: 'Cannot perform required task',
      label: 'Cannot perform required task',
    },
    { value: 'Does not want to work', label: 'Does not want to work' },
    {
      value: 'Does not follow instructions',
      label: 'Does not follow instructions',
    },
    {
      value: 'Worker actively avoids working',
      label: 'Worker actively avoids working',
    },
    {
      value: 'Cannot work well with others',
      label: 'Cannot work well with others',
    },
    {
      value: 'Creates disruptive work environment',
      label: 'Creates disruptive work environment',
    },
    { value: 'Abandoned Shift', label: 'Abandoned Shift' },
    { value: 'Abusive behavior', label: 'Abusive behavior' },
    { value: 'Under the influence', label: 'Under the influence' },
    { value: 'Consistently late', label: 'Consistently late' },
    { value: 'No Show at Shift', label: 'No Show at Shift' },
  ]
  const [connectionType, setConnectionType] = useState<string>(
    connectionOptions[0].value,
  )
  const [blockReason, setBlockReason] = useState<string>(blockReasons[0].value)
  const [connectionReason, setConnectionReason] = useState<string>('')

  const handleConnection = async () => {
    setLoading(true)
    try {
      const response = await trabaApi.post(
        `/connections/company/${companyId}`,
        {
          workerId,
          connectionType,
          initiatedBy: state.userProfile?.email || 'OPS',
          note:
            connectionType === ConnectionType.BLOCK
              ? blockReason
              : connectionReason
                ? connectionReason
                : 'N/A',
          bypassRestrictions: true,
        },
      )
      if (response.data.connectionType === ConnectionType.SCHEDULED_BLOCK) {
        showSuccess(
          'The block will occur 1 hour after they clock out (or 6 hours after shift end time if they forget to clock out). They will not be informed of the block or removed from future shifts until then.',
          'Worker is currently on a shift, Block Scheduled.',
        )
      }
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Creating Connection')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Create a connection in place of the company
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            You can set the connection type of the worker. If you are blocking a
            worker add a block reason.
          </Text>
          <Select
            fullWidth
            label="Connection Type"
            menuItems={connectionOptions}
            value={connectionType}
            handleSelect={setConnectionType}
          />
          <Row mt={16}>
            {connectionType === ConnectionType.BLOCK ? (
              <Select
                fullWidth
                label="Block Reason"
                menuItems={blockReasons}
                value={blockReason}
                handleSelect={setBlockReason}
              />
            ) : (
              <Input
                full
                label="Connection Reason (Optional)"
                value={connectionReason}
                onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  setConnectionReason(ev.target.value)
                }
              />
            )}
          </Row>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleClose={handleClose}
        handleConfirm={handleConnection}
        loading={loading}
      />
    </>
  )
}

export const WorkerJobPlacementContent = (props: WorkerJobPlacementProps) => {
  const { workerId, companyId, action, handleClose } = props
  const [startDate, setHireDate] = useState<Date>(new Date())
  const [terminationDate, setTerminationDate] = useState<Date | undefined>()
  const [reason, setReason] = useState<string>('')

  const { state } = useUserContext()

  const { mutateAsync: createJobPlacement, isLoading: isCreating } =
    useCreateJobPlacement()
  const { mutateAsync: updateJobPlacement, isLoading: isUpdating } =
    useUpdateJobPlacement()

  const handleWorkerJobPlacementCreate = async (): Promise<void> => {
    await createJobPlacement(
      {
        workerId,
        companyId,
        initiatedBy: state.userProfile?.email || 'OPS',
        startDate,
        reason,
      },
      {
        onSettled: () => {
          handleClose()
        },
      },
    )
  }

  const handleWorkerJobPlacementUpdate = async (): Promise<void> => {
    await updateJobPlacement(
      {
        workerId,
        companyId,
        initiatedBy: state.userProfile?.email || 'OPS',
        endDate: terminationDate,
        reason,
      },
      {
        onSettled: () => {
          handleClose()
        },
      },
    )
  }
  const setDateHandler = (date: Date | null) => {
    if (date) {
      if (action === WorkerJobPlacementAction.CREATE_WORKER) {
        setHireDate(date)
      } else {
        setTerminationDate(date)
      }
    }
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            {action === WorkerJobPlacementAction.CREATE_WORKER
              ? 'Date of Hire'
              : 'Date of Termination (Optional)'}
          </Text>
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={setDateHandler}
            isClearable={
              action === WorkerJobPlacementAction.CREATE_WORKER ? false : true
            }
            inlineLabel={true}
            label={
              action === WorkerJobPlacementAction.CREATE_WORKER
                ? 'Hire Date'
                : 'Termination Date'
            }
            date={
              action === WorkerJobPlacementAction.CREATE_WORKER
                ? startDate
                : terminationDate
            }
          />
          <Text
            variant="h5"
            style={{
              marginTop: theme.space.sm,
              marginBottom: theme.space.xxs,
            }}
          >
            Reason (Optional)
          </Text>
          <Row mt={theme.space.sm}>
            <Input
              full
              type="textarea"
              label="Reason (Optional)"
              value={reason}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setReason(ev.target.value)
              }
            />
          </Row>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleClose={handleClose}
        handleConfirm={
          action === WorkerJobPlacementAction.CREATE_WORKER
            ? handleWorkerJobPlacementCreate
            : handleWorkerJobPlacementUpdate
        }
        loading={isCreating || isUpdating}
      />
    </>
  )
}

const AccountStatusContent = (
  props: ManageWorkerModalContentProps & {
    statusChange: AccountApprovalStatus
  },
) => {
  const { workerShift, handleClose, statusChange } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const behaviorReasons = [
    { value: ApprovalStatusReason.WorkerCommittedFraud, label: 'Fraud' },
    { value: ApprovalStatusReason.WorkerCommittedTheft, label: 'Theft' },
    {
      value: ApprovalStatusReason.OffPlatformActivity,
      label: 'Off-platform Activity',
    },
    { value: ApprovalStatusReason.Violence, label: 'Violence' },
    {
      value: ApprovalStatusReason.UnprofessionalBehavior,
      label: 'Unprofessional Behavior',
    },
    {
      value: ApprovalStatusReason.CriminalActivity,
      label: 'Criminal Activity',
    },
    {
      value: ApprovalStatusReason.UnderTheInfluence,
      label: 'Under The Influence',
    },
    {
      value: ApprovalStatusReason.AbuseOrHarassment,
      label: 'Abuse/Harassment',
    },
    { value: ApprovalStatusReason.NoShowAtShift, label: 'No Show at Shift' },
    { value: ApprovalStatusReason.LateToShift, label: 'Late to Shift' },
    { value: ApprovalStatusReason.AbandonedShift, label: 'Abandoned Shift' },
  ]

  const [approvalStatusReason, setApprovalStatusReason] = useState<string>(
    behaviorReasons[0].value,
  )

  const [loading, setLoading] = useState<boolean>(false)

  const handleAccountStatusChange = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(`/workers/${workerShift.workerId}/approval-status`, {
        accountStatus: statusChange,
        approvalStatusReason,
      })
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Updating Account Status')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Set {workerShift.worker?.firstName} {workerShift.worker?.lastName}{' '}
            to {statusChange}
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            The worker exhibited behavior that does not align with our company
            guidelines
          </Text>
          <Select
            fullWidth
            label="Reason"
            menuItems={behaviorReasons}
            value={approvalStatusReason}
            handleSelect={setApprovalStatusReason}
          />
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleAccountStatusChange}
        loading={loading}
      />
    </>
  )
}

const CancelContent = (props: ManageWorkerModalContentProps) => {
  const userContext = useUserContext()
  const { workerShift, handleClose } = props
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const cancellationSources = [
    { value: CancellationSource.Ops, label: 'Ops Canceled' },
    { value: CancellationSource.Worker, label: 'Worker Canceled' },
    {
      value: CancellationSource.Business,
      label: 'Business Canceled',
      disabled: !hasPermissions(userContext.state.userProfile, [
        UserRolePermission.BusinessCancelShift,
      ]),
    },
    {
      value: CancellationSource.System,
      label: 'System Canceled',
      disabled: !hasPermissions(userContext.state.userProfile, [
        UserRolePermission.SystemCancelShift,
      ]),
    },
  ]

  const [cancellationSource, setCancellationSource] = useState<string>(
    cancellationSources[0].value,
  )
  const [cancellationReason, setCancellationReason] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [shouldNotifyWorker, setShouldNotifyWorker] = useState<boolean>(false)

  const { data: cancellationData } = useApi<ShiftCancellationCostResponse>(
    `companies/${workerShift.shiftInfo.companyId}/shifts/${workerShift.shiftId}/cancellation-costs`,
  )
  const isPaidCancellation = useMemo(() => {
    const chargedCancellations = cancellationData?.cancellations.filter(
      (c) => c.totalCharge && c.totalCharge.amount > 0,
    )
    return !!chargedCancellations && chargedCancellations.length > 0
  }, [cancellationData])

  const calculatedShiftCancellationData = useMemo(() => {
    const shiftCancellationData = !!cancellationData && cancellationData
    const {
      cancellations = [],
      cancellationSettings,
      cancellationChargeSummary,
    } = shiftCancellationData || {}
    const {
      cancellationBusinessTimeWindow = 18,
      cancellationBusinessChargeType = CancellationBusinessChargeType.Hourly,
      shouldWaiveCancellationFees = false,
    } = cancellationSettings || {}
    const baseWorkerCancellation = cancellations.find(
      (cancellation) => cancellation.workerId === workerShift.workerId,
    )
    const baseWorkerPay =
      baseWorkerCancellation && baseWorkerCancellation.grossPay
        ? baseWorkerCancellation.grossPay.amount / 100
        : 0
    const baseBusinessCharge =
      baseWorkerCancellation && baseWorkerCancellation.totalCharge
        ? baseWorkerCancellation.totalCharge.amount / 100
        : 0
    const baseBusinessHoursCharged =
      baseWorkerCancellation && baseWorkerCancellation.totalChargedTime
        ? Math.round(10 * (baseWorkerCancellation.totalChargedTime / 60)) / 10
        : 0 // hrs
    const numberOfWorkersToChargeBusinessFor = cancellations.filter(
      (c) => c.totalCharge && c.totalCharge.amount > 0,
    ).length
    return {
      baseWorkerPay,
      baseBusinessCharge,
      cancellationBusinessTimeWindow,
      cancellationBusinessChargeType,
      cancellationChargeSummary,
      baseBusinessHoursCharged,
      shouldWaiveCancellationFees,
      numberOfWorkersToChargeBusinessFor,
    }
  }, [cancellationData, workerShift.workerId])

  const handleCancelWorkerShift = async () => {
    setLoading(true)

    try {
      await trabaApi.patch(
        `/workers/${workerShift?.workerId}/worker-shifts/${workerShift.shiftId}/cancel-shift`,
        {
          cancellationSource,
          cancellationReason,
          bypassCancellationSourceCheck: true,
          sendPushNotificationToWorkerOnOpsCancel:
            workerShift?.workerId && shouldNotifyWorker,
        },
        {
          params: {
            shouldInstantPay: true,
          },
        },
      )
    } catch (e: unknown) {
      Sentry.captureException(
        `ManageWorkerModalContent -> handleCancelWorkerShift -> Error canceling shift: ${e}`,
      )
      showError(getErrorMessage(e), 'Error Canceling Shift')
    }

    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
            Cancel the worker shift
          </Text>
          <Text variant="body2" style={{ marginBottom: theme.space.sm }}>
            You can cancel the worker's shift on their behalf
          </Text>
          <Select
            fullWidth
            label="Cancellation Source"
            menuItems={cancellationSources}
            value={cancellationSource}
            handleSelect={setCancellationSource}
          />
          <Row mt={theme.space.xxs}>
            <Input
              full
              label="Cancellation Reason (Optional)"
              value={cancellationReason}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setCancellationReason(ev.target.value)
              }
            />
          </Row>
          {cancellationSource === CancellationSource.Business && (
            <CancellationCosts
              {...calculatedShiftCancellationData}
              numberWorkersToAppease={1}
              isPaidCancellation={isPaidCancellation}
              employerName={workerShift.shiftInfo.employerName}
            />
          )}
          <Row alignCenter style={{ marginTop: theme.space.sm }}>
            <Text variant="h7">Notify worker</Text>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={shouldNotifyWorker}
              onClick={() => setShouldNotifyWorker((prev) => !prev)}
            />
          </Row>
        </Col>
      </Row>
      <ModalButtons
        {...props}
        handleConfirm={handleCancelWorkerShift}
        loading={loading}
      />
    </>
  )
}
