import { theme } from '@traba/theme'
import { ShiftPayType } from '@traba/types'
import { JobStatus, WorkerShiftAsTimesheetRow } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { addMinutes } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { TimeValue } from 'react-aria'
import { assetNameType } from 'src/assets/svg/SvgIconProps'
import {
  parseDateString,
  parseDateTimeString,
  parseTimeString,
} from 'src/utils/dateUtils'
import { TableCell, TableRow, TimesheetAdjustment } from './timesheet.types'

const FIFTEEN_MINUTES_IN_MS: number = 15 * 60 * 1000
const MINUTE_IN_MS: number = 60 * 1000

const SIGNIFICANT_FIELDS = new Set(['jobStatus', 'payRate'])
const DATE_FIELDS = new Set([
  'clockInDate',
  'clockInTime',
  'clockOutDate',
  'clockOutTime',
])
const NUMBER_FIELDS = new Set(['unitsWorked', 'breakTime', 'minimumPaidTime'])

export const FIELDS_TO_CHECK = [
  ...DATE_FIELDS,
  ...NUMBER_FIELDS,
  ...SIGNIFICANT_FIELDS,
]

function convertDateOrTimeStringToDateTime(d: string): Date | null {
  const date = parseDateString(d)
  if (date && !isNaN(date.getTime())) {
    return date
  }
  // if we have a time, we have to use a dummy date to calculate the difference between the times
  const time = parseTimeString(d)
  if (time && !isNaN(time.getTime())) {
    return time
  }
  return null
}

export function isSignificantDifference(
  key: keyof TableRow,
  tableCell: TableCell,
): boolean {
  if (SIGNIFICANT_FIELDS.has(key)) {
    return true
  }

  const { parsedWorkerShiftValue, originalWorkerShiftValue } = tableCell
  if (typeof parsedWorkerShiftValue !== typeof originalWorkerShiftValue) {
    console.error('parsedValue and originalValue must be of the same type')
    return true
  }

  if (
    DATE_FIELDS.has(key) &&
    typeof parsedWorkerShiftValue === 'string' &&
    typeof originalWorkerShiftValue === 'string'
  ) {
    const parsedDate = convertDateOrTimeStringToDateTime(parsedWorkerShiftValue)
    const originalDate = convertDateOrTimeStringToDateTime(
      originalWorkerShiftValue,
    )
    // now that we've coerced the values into DateTimes, we can calculate the difference
    if (parsedDate && originalDate) {
      return (
        Math.abs(parsedDate.getTime() - originalDate.getTime()) >=
        FIFTEEN_MINUTES_IN_MS
      )
    }
  }

  if (NUMBER_FIELDS.has(key)) {
    const parsedNumber = Number(parsedWorkerShiftValue)
    const originalNumber = Number(originalWorkerShiftValue)
    if (!isNaN(parsedNumber) && !isNaN(originalNumber)) {
      // this means we have a number value
      return Math.abs(parsedNumber - originalNumber) >= 15
    }
  }

  return false
}

export function hasDiff(tableRow: TableRow) {
  return Object.values(tableRow).some((cell) => cell.isDiff)
}

export function checkAndSetDiff(row: TableRow, key: keyof TableRow) {
  const tableCell = row[key]
  if (
    DATE_FIELDS.has(key) &&
    typeof tableCell.parsedWorkerShiftValue === 'string' &&
    typeof tableCell.originalWorkerShiftValue === 'string'
  ) {
    const parsedDate = convertDateOrTimeStringToDateTime(
      tableCell.parsedWorkerShiftValue,
    )
    const originalDate = convertDateOrTimeStringToDateTime(
      tableCell.originalWorkerShiftValue,
    )
    // now that we've coerced the values into DateTimes, we can check the difference
    if (
      parsedDate &&
      originalDate &&
      parsedDate.getTime() !== originalDate.getTime()
    ) {
      tableCell.isDiff = true
      tableCell.isSignificantDiff = isSignificantDifference(key, tableCell)
    } else if ((parsedDate && !originalDate) || (!parsedDate && originalDate)) {
      tableCell.isDiff = true
      tableCell.isSignificantDiff = true
    }
  } else if (
    tableCell.parsedWorkerShiftValue !== tableCell.originalWorkerShiftValue
  ) {
    tableCell.isDiff = true
    tableCell.isSignificantDiff = isSignificantDifference(key, tableCell)
  }
}

function hasSignificantDifference(tableRow: TableRow): boolean {
  return FIELDS_TO_CHECK.some((field) => {
    const key = field as keyof TableRow
    return tableRow[key].isSignificantDiff
  })
}

export function determineIconName(tableRow: TableRow): assetNameType {
  if (hasDiff(tableRow)) {
    if (hasSignificantDifference(tableRow)) {
      return 'error'
    }
    return 'caution'
  }
  return 'greenCheck'
}

export function getStyle(tableCell: TableCell, isOldValue?: boolean) {
  const { isDiff, isSignificantDiff } = tableCell
  return {
    ...(isDiff && {
      ...(isOldValue && { textDecoration: 'line-through' }),
      ...(isSignificantDiff && {
        backgroundColor: theme.colors.Yellow10,
        borderColor: theme.colors.Yellow70,
        borderWidth: '2px',
        borderStyle: 'solid',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
      }),
    }),
  }
}

export function tableRowToTimesheetAdjustment(
  row: TableRow,
  workerShift: WorkerShiftAsTimesheetRow,
): TimesheetAdjustment {
  const clockInDateTime = new Date(
    `${row.clockInDate.parsedWorkerShiftValue} ${row.clockInTime.parsedWorkerShiftValue}`,
  )
  const clockOutDateTime = new Date(
    `${row.clockOutDate.parsedWorkerShiftValue} ${row.clockOutTime.parsedWorkerShiftValue}`,
  )

  const breaks: ShiftTime[] = row.breakTime.isDiff
    ? [
        {
          startTime: new Date(workerShift.startTime),
          endTime: addMinutes(
            new Date(workerShift.startTime),
            Number(row.breakTime.parsedWorkerShiftValue),
          ),
        },
      ]
    : workerShift.breaks

  return {
    workerName: workerShift.workerName,
    shiftName: workerShift.shiftName,
    source: 'OPS',
    adjustmentReason: 'timesheet_bulk_adjustment',
    clockInTime: zonedTimeToUtc(clockInDateTime, workerShift.timezone),
    clockOutTime: zonedTimeToUtc(clockOutDateTime, workerShift.timezone),
    ...(row.jobStatus.isDiff && {
      jobStatus: row.jobStatus.parsedWorkerShiftValue.toString(),
    }),
    breakTime: row.breakTime.parsedWorkerShiftValue,
    unitsWorked: Number(row.unitsWorked.parsedWorkerShiftValue),
    workerId: workerShift.workerId,
    shiftId: workerShift.shiftId,
    breaks,
    accountStatus: {
      payment: {
        instantPayEnabled:
          workerShift.accountStatus?.payment?.instantPayEnabled ?? null,
        instantPayEligibility: {
          status:
            workerShift.accountStatus?.payment?.instantPayEligibility?.status ??
            null,
        },
      },
    },
    shiftInfo: {
      payRate:
        Number(row.payRate.parsedWorkerShiftValue) ?? workerShift.payRate,
      companyId: workerShift.companyId,
      minimumPaidTime: row.minimumPaidTime.isDiff
        ? Number(row.minimumPaidTime.parsedWorkerShiftValue)
        : (workerShift.minimumPaidTime ?? 0),
      breakType: workerShift.breakType,
      payType: workerShift.payType ?? ShiftPayType.HOURLY,
      timezone: workerShift.timezone,
      startTime: workerShift.startTime,
      endTime: workerShift.endTime,
      slotsRequested: Number(workerShift.slotsRequested),
      numberOfUnits: Number(workerShift.numberOfUnits),
    },
  }
}

export function buildBaseTableRow(
  parsedWorkerShift: WorkerShiftAsTimesheetRow,
  workerShift: WorkerShiftAsTimesheetRow,
): TableRow {
  return {
    workerName: {
      parsedWorkerShiftValue: parsedWorkerShift.workerName,
      originalWorkerShiftValue: workerShift.workerName,
    },
    shiftName: {
      parsedWorkerShiftValue: parsedWorkerShift.shiftName,
      originalWorkerShiftValue: workerShift.shiftName,
    },
    clockInDate: {
      parsedWorkerShiftValue: parsedWorkerShift.clockInDate ?? '',
      originalWorkerShiftValue: workerShift.clockInDate ?? '',
    },
    clockInTime: {
      parsedWorkerShiftValue: parsedWorkerShift.clockInTime ?? '',
      originalWorkerShiftValue: workerShift.clockInTime ?? '',
    },
    clockOutDate: {
      parsedWorkerShiftValue: parsedWorkerShift.clockOutDate ?? '',
      originalWorkerShiftValue: workerShift.clockOutDate ?? '',
    },
    clockOutTime: {
      parsedWorkerShiftValue: parsedWorkerShift.clockOutTime ?? '',
      originalWorkerShiftValue: workerShift.clockOutTime ?? '',
    },
    jobStatus: {
      parsedWorkerShiftValue: parsedWorkerShift.jobStatus,
      originalWorkerShiftValue: workerShift.jobStatus,
    },
    breakTime: {
      parsedWorkerShiftValue:
        parsedWorkerShift.breakTime ?? workerShift.breakTime,
      originalWorkerShiftValue: workerShift.breakTime,
    },
    payRate: {
      parsedWorkerShiftValue: parsedWorkerShift.payRate ?? workerShift.payRate,
      originalWorkerShiftValue: workerShift.payRate,
    },
    unitsWorked: {
      parsedWorkerShiftValue: parsedWorkerShift.unitsWorked ?? 0,
      originalWorkerShiftValue: workerShift.unitsWorked ?? 0,
    },
    minimumPaidTime: {
      parsedWorkerShiftValue:
        parsedWorkerShift.minimumPaidTime ?? workerShift.minimumPaidTime ?? 240,
      originalWorkerShiftValue: workerShift.minimumPaidTime ?? 240,
    },
    workerId: {
      parsedWorkerShiftValue: parsedWorkerShift.workerId,
      originalWorkerShiftValue: workerShift.workerId,
    },
    shiftId: {
      parsedWorkerShiftValue: parsedWorkerShift.shiftId,
      originalWorkerShiftValue: workerShift.shiftId,
    },
    companyId: {
      parsedWorkerShiftValue: parsedWorkerShift.companyId,
      originalWorkerShiftValue: workerShift.companyId,
    },
    numberOfUnits: {
      parsedWorkerShiftValue: parsedWorkerShift.numberOfUnits ?? '',
      originalWorkerShiftValue: workerShift.numberOfUnits ?? '',
    },
  }
}

function getDurationInMinutes({
  clockInTime,
  clockOutTime,
}: {
  clockInTime: Date
  clockOutTime: Date
}): number {
  if (isNaN(clockOutTime.getTime()) || isNaN(clockInTime.getTime())) {
    console.error(
      "Encountered row without a valid clockInTime or clockOutTime - this may be because the workerShift doesn't have a clockInTime or clockOutTime or clockOutTime",
    )
    return 0
  }
  return (clockOutTime.getTime() - clockInTime.getTime()) / MINUTE_IN_MS
}

export function calculateTotalMinutesWorked(rows: TableRow[]): {
  newMinutesWorked: number
  prevMinutesWorked: number
} {
  const prevMinutesWorked = rows.reduce((acc, row) => {
    const clockInTime = parseDateTimeString(
      row.clockInDate.originalWorkerShiftValue.toString(),
      row.clockInTime.originalWorkerShiftValue.toString(),
    )
    const clockOutTime = parseDateTimeString(
      row.clockOutDate.originalWorkerShiftValue.toString(),
      row.clockOutTime.originalWorkerShiftValue.toString(),
    )
    if (!clockInTime || !clockOutTime) {
      console.error(
        `[prevMinutesWorked] encountered unknown date format:\nclockIn: ${row.clockInDate.originalWorkerShiftValue} ${row.clockInTime.originalWorkerShiftValue}\nclockOut: ${row.clockOutDate.originalWorkerShiftValue} ${row.clockOutTime.originalWorkerShiftValue}`,
      )
      return acc
    }
    const durationInMinutes = getDurationInMinutes({
      clockInTime,
      clockOutTime,
    })
    return acc + durationInMinutes
  }, 0)

  const newMinutesWorked = rows.reduce((acc, row) => {
    const clockInTime = parseDateTimeString(
      row.clockInDate.parsedWorkerShiftValue.toString(),
      row.clockInTime.parsedWorkerShiftValue.toString(),
    )
    const clockOutTime = parseDateTimeString(
      row.clockOutDate.parsedWorkerShiftValue.toString(),
      row.clockOutTime.parsedWorkerShiftValue.toString(),
    )
    if (!clockInTime || !clockOutTime) {
      console.error(
        `[newMinutesWorked] encountered unknown date format:\nclockIn: ${row.clockInDate.parsedWorkerShiftValue} ${row.clockInTime.parsedWorkerShiftValue}\nclockOut: ${row.clockOutDate.parsedWorkerShiftValue} ${row.clockOutTime.parsedWorkerShiftValue}`,
      )
      return acc
    }
    const durationInMinutes = getDurationInMinutes({
      clockInTime,
      clockOutTime,
    })
    return acc + durationInMinutes
  }, 0)

  return { newMinutesWorked, prevMinutesWorked }
}

export function calculateTotalPay(rows: TableRow[]): {
  newPay: number
  prevPay: number
} {
  const prevPay = rows.reduce((acc, row) => {
    const clockInTime = parseDateTimeString(
      row.clockInDate.originalWorkerShiftValue.toString(),
      row.clockInTime.originalWorkerShiftValue.toString(),
    )
    const clockOutTime = parseDateTimeString(
      row.clockOutDate.originalWorkerShiftValue.toString(),
      row.clockOutTime.originalWorkerShiftValue.toString(),
    )
    if (!clockInTime || !clockOutTime) {
      console.error(
        `[prevPay] encountered unknown date format:\nclockIn: ${row.clockInDate.originalWorkerShiftValue} ${row.clockInTime.originalWorkerShiftValue}\nclockOut: ${row.clockOutDate.originalWorkerShiftValue} ${row.clockOutTime.originalWorkerShiftValue}`,
      )
      return acc
    }
    const durationInHours =
      getDurationInMinutes({ clockInTime, clockOutTime }) / 60
    const totalPay =
      Number(row.payRate.originalWorkerShiftValue) * durationInHours
    return acc + totalPay
  }, 0)

  const newPay = rows.reduce((acc, row) => {
    const clockInTime = parseDateTimeString(
      row.clockInDate.parsedWorkerShiftValue.toString(),
      row.clockInTime.parsedWorkerShiftValue.toString(),
    )
    const clockOutTime = parseDateTimeString(
      row.clockOutDate.parsedWorkerShiftValue.toString(),
      row.clockOutTime.parsedWorkerShiftValue.toString(),
    )
    if (!clockInTime || !clockOutTime) {
      console.error(
        `[newPay] encountered unknown date format:\nclockIn: ${row.clockInDate.parsedWorkerShiftValue} ${row.clockInTime.parsedWorkerShiftValue}\nclockOut: ${row.clockOutDate.parsedWorkerShiftValue} ${row.clockOutTime.parsedWorkerShiftValue}`,
      )
      return acc
    }
    const durationInHours =
      getDurationInMinutes({ clockInTime, clockOutTime }) / 60
    const totalPay =
      Number(row.payRate.parsedWorkerShiftValue) * durationInHours
    return acc + totalPay
  }, 0)

  return { newPay, prevPay }
}

export function buildErrorMessage(
  failedWorkerIdsAndReasons: [string, string | object][],
): string {
  return failedWorkerIdsAndReasons
    .filter(Boolean)
    .map(
      ([workerId, reason]) =>
        `${workerId}: ${
          reason && typeof reason === 'object' && 'message' in reason
            ? reason.message
            : reason
        }`,
    )
    .join('\n')
}

export function updateParsedData(
  parsedData: WorkerShiftAsTimesheetRow[],
  row: TableRow,
  field: keyof WorkerShiftAsTimesheetRow,
  value?: Date | TimeValue | string | number | null,
) {
  const updatedData = [...parsedData]
  const index = updatedData.findIndex(
    (d) =>
      d.shiftId === row.shiftId.parsedWorkerShiftValue &&
      d.workerId === row.workerId.parsedWorkerShiftValue,
  )
  if (index === -1) {
    console.error(
      `Could not find parsedData with shiftId: ${row.shiftId.parsedWorkerShiftValue} and workerId: ${row.workerId.parsedWorkerShiftValue}`,
    )
    return updatedData
  }

  if (field === 'clockInDate' || field === 'clockOutDate') {
    updatedData[index][field] = value
      ? (value as Date).toLocaleDateString()
      : undefined
  } else if (field === 'clockInTime' || field === 'clockOutTime') {
    updatedData[index][field] = value
      ? `${(value as TimeValue).hour}:${(value as TimeValue).minute}`
      : undefined
  } else if (
    field === 'payRate' ||
    field === 'unitsWorked' ||
    field === 'minimumPaidTime' ||
    field === 'breakTime'
  ) {
    updatedData[index][field] = Number(value ?? 0)
  } else if (field === 'jobStatus') {
    updatedData[index][field] = value as JobStatus
  } else {
    console.error(`Unknown field: ${field}`)
  }
  return updatedData
}
