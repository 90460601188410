import { CountryCode } from './base'
import { TierLevel } from './worker-tiers'

export interface Region {
  regionId: string
  displayName: string
  regionCode: number
  countryCode: CountryCode
  postalCodes: string[]
  acceptingSignups: boolean
  status: RegionStatus
  launchDate?: Date
  createdAt?: string
  updatedAt?: Date
  backgroundCheckFlow: BackgroundCheckFlow
  unprovenWorkerThreshold?: number
  minimumAcceptedTier?: TierLevel
  overbookMultiplier?: number
  showSearch?: boolean
  activateSearch?: boolean
}

export enum RegionStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export enum BackgroundCheckFlow {
  early = 'EARLY',
  late = 'LATE',
}

export enum OperationType {
  REMOVE = 'REMOVE',
  ADD = 'ADD',
}
