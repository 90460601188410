import { InputStatus } from '@traba/types'
import styled, { css } from 'styled-components'
import { RequiredProps } from '../Required'
import { InputProps } from './types'

export const InputContainer = styled.div`
  width: 100%;
`

const inputStyling = css<InputProps>`
  /* Basic Styles */
  border: 1px solid #e2e6e9;
  border-radius: 8px;
  padding: ${(props) => (props.sm ? '8px 12px' : '12px 16px')};
  line-height: 22px;
  outline: none;
  font-weight: 300;
  transition: border 0.3s;

  /* Full Width */
  ${({ full }) => (full ? 'width: 100%;' : '')}

  /* Transitions */
  &.valid,
  :-webkit-autofill,
  :-webkit-autofill::first-line,
  :-webkit-autofill:active {
    border: 1px solid #e2e6e9;
    font-family: Poppins;
    font-weight: 300;
  }

  // Focused Input
  :focus,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: 1px solid #8000ff;
  }

  // Label for focused input
  :focus ~ label,
  :-webkit-autofill:hover ~ label,
  :-webkit-autofill:focus ~ label {
    top: -9px;
    font-size: 12px;
    color: #8000ff;
    font-weight: 500;
  }

  // Label for valid input
  &.valid ~ label,
  :-webkit-autofill ~ label,
  :-webkit-autofill:active ~ label {
    top: -9px;
    font-size: 12px;
    font-weight: 500;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
    }
  }};

  border-color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
    }
  }};

  ${({ placeholder }) =>
    placeholder
      ? css`
          ~ label {
            top: -9px;
            font-size: 12px;
            font-weight: 500;
          }
        `
      : ''}
`

export const Label = styled.label<RequiredProps>`
  top: ${({ sm }) => (sm ? '10px' : '12px')};
  left: 12px;
  position: absolute;
  background-color: white;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
  font-size: 14px;
  font-weight: 300;

  color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
      default:
        return '#7A8A99'
    }
  }};
`
export const Input = styled.input<InputProps>`
  ${inputStyling}
`

export const MultiLineInput = styled.textarea<InputProps>`
  ${inputStyling}
`
export const InputErrorIcon = styled.div`
  color: ${({ theme }) => theme?.error.border};
  padding-right: 8px;
`
export const InformationIcon = styled.div`
  color: '#E5E5E5';
  padding-right: 8px;
  top: 1px;
`

export const InputErrorMessage = styled.div<InputProps>`
  color: ${({ theme }) => theme?.error.border};
  font-size: 12px;

  margin-top: 8px;
  display: flex;
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 12px;
  flex-direction: row;
  ${(props) => props.errorStyle};
`
export const IconWrapper = styled.div`
  position: absolute;
  top: 36px;
  right: 17px;
  cursor: pointer;
`
