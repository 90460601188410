import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { ShiftBadge, ShiftBadgeStatus } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getQueryParams } from './useApi'

async function getAllShiftBadges(
  queryString?: string,
): Promise<ShiftBadge[] | undefined> {
  const res = await trabaApi.get(
    queryString ? `shift-badge/${queryString}` : `shift-badge`,
  )
  return res.data
}

const SHIFT_BADGE_QUERY_KEY = 'shift-badges'

export function useCustomBadges(query?: {
  companyId?: string
  roleId?: string
  shiftId?: string
}) {
  const queryString = getQueryParams([
    ['companyId', query?.companyId],
    ['roleId', query?.roleId],
    ['shiftId', query?.shiftId],
  ])

  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: shiftBadges,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftBadge[] | undefined, Error>(
    `${SHIFT_BADGE_QUERY_KEY}-${queryString}`,
    () => getAllShiftBadges(queryString),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCustomBadges ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    Sentry.captureException(fullErrorMessage)
    showError(fullErrorMessage, 'Error Fetching Shift Badges')
  }

  return {
    isLoading,
    isError,
    shiftBadges,
    activeBadges: shiftBadges?.filter(
      (badge) => badge.status === ShiftBadgeStatus.ACTIVE,
    ),
    archivedBadges: shiftBadges?.filter(
      (badge) => badge.status === ShiftBadgeStatus.ARCHIVED,
    ),
    error,
    isFetched,
    refetch,
  }
}
