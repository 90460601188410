import { Switch } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ChangeEvent, useCallback } from 'react'
import { Button, Col, Icon, Input, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'

export enum HiddenFilterTypes {
  PAYMENT_STATUS_OPTIONS = 'PAYMENT_STATUS_OPTIONS',
  WARDEN_FLAG_ONLY = 'WARDEN_FLAG_ONLY',
  WORKER_EDITED_ONLY = 'WORKER_EDITED_ONLY',
}

export interface FilterProps {
  shiftId: string
  setShiftId: (shiftId: string) => void
  workerId: string
  setWorkerId: (workerId: string) => void
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
  companyIds: IMenuItem[]
  setCompanyIds: (companyIds: IMenuItem[]) => void
  regionIds: string[]
  setRegionIds: (regionIds: string[]) => void
  excludeCompanies: boolean
  setExcludeCompanies: (excludeCompanies: boolean) => void
  paymentStatuses: IMenuItem[]
  setPaymentStatuses: (paymentStatuses: IMenuItem[]) => void
  wardenHalted: boolean
  setWardenHalted: (wardenHalted: boolean) => void
  workerEditedOnly: boolean
  setWorkerEditedOnly: (workerEditedOnly: boolean) => void
  companyOptions: IMenuItem[]
  regionsOptions: IMenuItem[]
  paymentStatusOptions: IMenuItem[]
}

type FiltersSectionProps = {
  isLoading: boolean
  onClickSearch: () => void
  hiddenFilters?: HiddenFilterTypes[]
  filterProps: FilterProps
}

export function FiltersSection(props: FiltersSectionProps) {
  const { onClickSearch, isLoading, hiddenFilters, filterProps } = props

  const {
    shiftId,
    setShiftId,
    workerId,
    setWorkerId,
    dateRange,
    setDateRange,
    companyIds,
    setCompanyIds,
    regionIds,
    setRegionIds,
    excludeCompanies,
    setExcludeCompanies,
    paymentStatuses,
    setPaymentStatuses,
    wardenHalted,
    setWardenHalted,
    workerEditedOnly,
    setWorkerEditedOnly,
    companyOptions,
    regionsOptions,
    paymentStatusOptions,
  } = filterProps

  const toggleExcludedCompanies = useCallback(() => {
    setExcludeCompanies(!excludeCompanies)
  }, [setExcludeCompanies, excludeCompanies])

  const toggleWardenHalted = useCallback(() => {
    setWardenHalted(!wardenHalted)
  }, [setWardenHalted, wardenHalted])

  const toggleWorkerEditedOnly = useCallback(() => {
    setWorkerEditedOnly(!workerEditedOnly)
  }, [setWorkerEditedOnly, workerEditedOnly])

  const handleSetWorkerId = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setWorkerId(ev.target.value)
    },
    [setWorkerId],
  )

  const handleSetShiftId = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setShiftId(ev.target.value)
    },
    [setShiftId],
  )

  return (
    <>
      <Row mt={theme.space.sm} alignCenter justifyBetween>
        <Col mr={theme.space.xs}>
          <DateRangePicker
            label="Date range"
            inlineLabel={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
            granularity="day"
          />
        </Col>
        <Col mr={theme.space.xxs}>
          <SearchSelect
            options={companyOptions}
            selectedItems={companyIds}
            handleSelectMultiple={setCompanyIds}
            label="Businesses"
            multiple
            showClearButton
          />
        </Col>
        <Row alignCenter mr={theme.space.xs}>
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={excludeCompanies}
            onClick={toggleExcludedCompanies}
          />
          <Text variant="caption">Exclude Companies</Text>
        </Row>
        <Col mr={theme.space.xs} style={{ maxWidth: 600 }}>
          <StateSearchSelect
            multiple
            options={regionsOptions}
            handleSelectMultiple={setRegionIds}
            selectedItems={regionIds}
            label="Region"
            showClearButton
          />
        </Col>
        {!hiddenFilters?.includes(HiddenFilterTypes.WARDEN_FLAG_ONLY) && (
          <Row alignCenter mr={theme.space.xs}>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={wardenHalted}
              onClick={toggleWardenHalted}
            />
            <Text variant="caption">Warden Flagged Only</Text>
          </Row>
        )}
      </Row>
      <Row mt={theme.space.xs} alignCenter>
        <Col mr={theme.space.xs}>
          <Input
            full
            label="Shift ID"
            value={shiftId}
            onChange={handleSetShiftId}
          />
        </Col>
        <Col mr={theme.space.xs}>
          <Input
            full
            label="Worker ID"
            value={workerId}
            onChange={handleSetWorkerId}
          />
        </Col>
        {!hiddenFilters?.includes(HiddenFilterTypes.PAYMENT_STATUS_OPTIONS) && (
          <Col mr={theme.space.xs}>
            <SearchSelect
              multiple
              options={paymentStatusOptions}
              selectedItems={paymentStatuses}
              handleSelectMultiple={setPaymentStatuses}
              label="Payment Status"
              onlyShowLabel
            />
          </Col>
        )}
        <Col>
          <Button
            full
            leftIcon={<Icon name="search" />}
            onClick={onClickSearch}
            variant={ButtonVariant.FILLED}
            loading={isLoading}
          >
            Search
          </Button>
        </Col>
      </Row>
      {!hiddenFilters?.includes(HiddenFilterTypes.WORKER_EDITED_ONLY) && (
        <Row>
          <Row alignCenter>
            <Switch
              size="medium"
              inputProps={{ 'aria-label': 'controlled' }}
              checked={workerEditedOnly}
              onClick={toggleWorkerEditedOnly}
            />
            <Text variant="caption">Worker Edited Only</Text>
          </Row>
        </Row>
      )}
    </>
  )
}
