import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Incentive,
  IncentiveRules,
  IncentiveTypeIds,
  RuleLine,
} from '@traba/types'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  Badge,
  Button,
  Col,
  CopyTextIcon,
  Icon,
  IncentiveStatusBadge,
  Row,
} from '../../components/base'
import { CircularProgress } from '../../components/base/CircularProgress/CircularProgress'
import Divider from '../../components/base/Divider'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import HeaderWithDetailItem from '../../components/ShiftDetails/ShiftDetailsItem'
import WorkerIncentivesTable from '../../components/WorkerIncentives/WorkerIncentivesTable'
import { useIncentives } from '../../hooks/useIncentives'
import useTimezonedDates from '../../hooks/useTimezonedDates'
import { truncateString } from '../../utils/stringUtils'
import { SearchWorkers } from '../ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from '../WorkerSearchScreen/worker-search.types'

const shiftIncentiveTypes = [
  IncentiveTypeIds.shift_cash,
  IncentiveTypeIds.shift_hrly,
]
export const isShiftIncentive = (incentive: Incentive) =>
  !!incentive?.shiftId && shiftIncentiveTypes.includes(incentive.typeId)

const getSecondaryFieldForRuleType = (
  rule: RuleLine,
): {
  description: string
  value?: string | string[]
} => {
  switch (rule.type) {
    case IncentiveRules.WorkerReferral:
      return {
        description:
          'Worker has a valid referrer Id and never earned another referee incentive',
      }
    case IncentiveRules.NewAccount:
      return {
        description: 'Account approved in the last 60 days',
      }
    case IncentiveRules.CertificationRequired:
      return {
        description:
          'Worker has the required certification for the shift completed',
        value: `Certification type ${rule.certificationType}`,
      }
    case IncentiveRules.CompanyExclusive:
      return {
        description: 'Only shifts from this company will be considered',
        value: `Company Id ${rule.companyId}`,
      }
    case IncentiveRules.HasCompletedIncentive:
      return {
        description: 'Worker has completed another incentive',
        value: `Incentive Id ${rule.incentiveId}`,
      }
    case IncentiveRules.HasCompletedShift:
      return {
        description: 'Worker has completed another shift',
        value: `Shift Id ${rule.shiftId}`,
      }
    case IncentiveRules.LifeTimeShiftCompletions:
      return {
        description: 'Worker has completed a certain number of shifts',
        value: `Minimum: ${rule.minimumCompletions}, Maximum: ${rule.maximumCompletions}`,
      }
    case IncentiveRules.ProgressOnly:
      return {
        description:
          'Only workers we granted the initial incentive can progress',
      }
    case IncentiveRules.ShiftGroup:
      return {
        description: 'Only shifts from this group will be considered',
        value: rule.shiftIds,
      }
    case IncentiveRules.ShiftsCompletionRequired:
      return {
        description: 'Worker has completed a certain number of shifts',
        value: `Complete ${rule.count} shifts`,
      }
    default:
      return { description: rule.type }
  }
}

const RuleLineRow = ({ rule }: { rule: RuleLine }) => {
  const info = getSecondaryFieldForRuleType(rule)
  return (
    <Row pl={theme.space.xxs} pb={theme.space.xxs} alignCenter fullWidth>
      <Text variant="h6" style={{ width: 250 }}>
        {rule.type}
      </Text>
      <Text style={{ width: 600 }}>{info.description}</Text>
      {info.value && (
        <Text>
          {info.value instanceof Array ? info.value.join(', ') : info.value}
        </Text>
      )}
    </Row>
  )
}

const ResyncWorker = ({
  resync,
  isProcessing,
  disabled,
}: {
  resync: () => void
  isProcessing: boolean
  disabled: boolean
}) => {
  return (
    <Button
      variant={ButtonVariant.OUTLINED}
      ml={theme.space.xxs}
      onClick={resync}
      loading={isProcessing}
      disabled={disabled}
    >
      Resync Worker Incentive
    </Button>
  )
}
function IncentiveDetailsScreen() {
  const [processingResync, setProcessingResync] = useState<boolean>(false)
  const [selectedWorkers, setSelectedWorkers] = useState<PopulatedWorker[]>([])
  const { incentiveId = '' } = useParams<{ incentiveId: string }>()

  const navigate = useNavigate()

  const tz = useTimezonedDates()

  const {
    useIncentiveById,
    resyncWorkerIncentive,
    useGetWorkerIncentivesForIncentiveId,
  } = useIncentives()

  const { data: incentive, isLoading: isLoadingIncentives } =
    useIncentiveById(incentiveId)
  const {
    data: workerIncentives,
    isLoading: isLoadingWorkerIncentives,
    refetch,
  } = useGetWorkerIncentivesForIncentiveId(incentiveId)

  const handleResync = async () => {
    const workerId = selectedWorkers[0].workerId || selectedWorkers[0].id
    if (processingResync || !selectedWorkers?.length || !workerId) {
      return
    }
    setProcessingResync(true)

    const resyncRes = await resyncWorkerIncentive(
      workerId,
      incentiveId,
      incentive?.shiftId,
    )
    setProcessingResync(false)

    window.alert(
      `Finished resync worker incentive:\n${resyncRes[0].progress || ''}\n${
        resyncRes[0].status || ''
      }\n${resyncRes[0].message || ''}`,
    )
  }

  if (isLoadingIncentives) {
    return (
      <MainLayout title="Incentive Details">
        <Row>
          <CircularProgress size="medium" color={theme.colors.brand} />
        </Row>
      </MainLayout>
    )
  }

  if (!incentive) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <Text variant="h4">No incentive found with id: {incentiveId}</Text>
        </Row>
      </MainLayout>
    )
  }

  const canResync =
    incentive?.typeId === IncentiveTypeIds.dXgY || isShiftIncentive(incentive)

  return (
    <MainLayout title="Incentive Details">
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Go back
      </Button>
      <Divider />

      <Col mt={theme.space.sm} mb={theme.space.sm}>
        <Row justifyBetween alignCenter>
          <Row alignCenter>
            <Text variant="h4" style={{ marginRight: theme.space.xs }}>
              {incentive.title}
            </Text>
            <IncentiveStatusBadge incentiveStatus={incentive.status} />
          </Row>
          <Button
            variant={ButtonVariant.OUTLINED}
            disabled
            tooltipText="Coming soon"
          >
            Edit Incentive
          </Button>
        </Row>
      </Col>
      {/* Info Fields */}
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <HeaderWithDetailItem title="Incentive Id">
            {incentive.id && truncateString(incentive.id, 12)}{' '}
            <CopyTextIcon textToCopy={incentive.id} />
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Description">
            {incentive.description}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Internal Memo">
            {incentive.internalMemo || 'No internal memo'}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Regions">
            {incentive.regionIds.length ? (
              incentive.regionIds?.map((regionId) => (
                <Badge
                  title={regionId}
                  key={regionId}
                  style={{ marginRight: theme.space.xxs }}
                />
              ))
            ) : (
              <Badge
                title={'GLOBAL'}
                style={{ marginRight: theme.space.xxs }}
              />
            )}
          </HeaderWithDetailItem>
        </Col>
        <Col>
          <HeaderWithDetailItem title="Start time">
            {tz.getDateTime(incentive.startTime)}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="End time">
            {tz.getDateTime(incentive.endTime)}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem
            title="Shift Id"
            tooltipText="Only incentives tied to a shift will have a shiftId"
          >
            {incentive.shiftId}
          </HeaderWithDetailItem>
        </Col>
        <Col>
          <HeaderWithDetailItem
            title="Type Id"
            tooltipText="This is the type used internally to control incentive progress"
          >
            {incentive.typeId}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Category">
            {incentive.category}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Category Id">
            {incentive.categoryId}
          </HeaderWithDetailItem>
        </Col>
      </Row>
      <Row fullWidth justifyStart flexCol mb={theme.space.xs}>
        <Text variant="caption" my={theme.space.xxs}>
          RULES
        </Text>
        {incentive.typeId === IncentiveTypeIds.dXgY &&
        incentive.rules.length ? (
          incentive.rules.map((rule, index) => (
            <RuleLineRow key={index} rule={rule} />
          ))
        ) : isShiftIncentive(incentive) ? (
          <Text pl={theme.space.xxs} pb={theme.space.xxs} variant="h6">
            This incentive only requires a specific shift completion
          </Text>
        ) : (
          <Text pl={theme.space.xxs} pb={theme.space.xxs} variant="h6">
            This incentive needs to be granted by Ops
          </Text>
        )}
      </Row>
      <Divider />
      <Col my={theme.space.xs}>
        {canResync && (
          <>
            <Text variant="h5">Resync incentive progress</Text>
            <Row style={{ alignItems: 'end' }} alignStart>
              <SearchWorkers
                checkedWorkers={selectedWorkers}
                setCheckedWorkers={setSelectedWorkers}
                selectOneOnly
                hideInstructionText
                actionButton={
                  <ResyncWorker
                    resync={handleResync}
                    isProcessing={processingResync}
                    disabled={!selectedWorkers.length}
                  />
                }
              />
            </Row>
          </>
        )}
      </Col>
      <Col>
        {/* Worker Incentives */}
        <Divider />
        <Text variant="h5" my={theme.space.xs}>
          Worker Incentives
        </Text>
        <Row fullWidth justifyStart flexCol mb={theme.space.xs}>
          <WorkerIncentivesTable
            workerIncentives={workerIncentives}
            isLoading={isLoadingWorkerIncentives}
            incentiveDetails
            refetch={refetch}
          />
        </Row>
      </Col>
    </MainLayout>
  )
}

export default IncentiveDetailsScreen
