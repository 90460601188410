import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Roster } from '@traba/types'
import { memo, useState } from 'react'
import { Icon, Col, Row, IconButton, CopyTextIcon } from 'src/components/base'
import { useWorkersWithDetails } from 'src/hooks/useWorkers'
import { downloadArrayAsCSV } from 'src/screens/WorkerSearchScreen/components/ExportDrawer/utils'
import { truncateString } from 'src/utils/stringUtils'
import { RosterWorkersTable } from './RosterWorkersTable'
import { getWorkersPlurality } from './utils/utils'

interface ViewRosterRowProps {
  roster: Roster
  isVirtualRoster?: boolean
  onClickEditRoster: React.Dispatch<React.SetStateAction<Roster | undefined>>
  onClickDeleteRoster: React.Dispatch<React.SetStateAction<Roster | undefined>>
}

export const ViewRosterRow: React.FC<ViewRosterRowProps> = memo((props) => {
  const { roster, isVirtualRoster } = props
  const { id, rosterName, workers } = roster
  const numWorkers = workers.length
  const [expanded, setExpanded] = useState<boolean>(false)

  const { workersWithDetails: rosterWorkers, isLoading: isLoadingWorkers } =
    useWorkersWithDetails(workers.map((worker) => worker.workerId))

  const handleCsvExport = () => {
    const csvData = (rosterWorkers ?? []).map((worker) => {
      return {
        Name: `${worker.firstName} ${worker.lastName}`,
        ID: worker.id,
        Email: worker.email,
        'Phone Number': worker.phoneNumber,
        'Region Id': worker.regionId,
        Tier: worker.workerMetric?.tierLevel,
        'Account Status': worker.accountStatus.accountStatus,
        'Completed Shifts': worker.workerMetric?.metrics?.find(
          (metric) => metric.type === 'ALL_TIME',
        )?.completedShifts,
      }
    })

    downloadArrayAsCSV(csvData, `${id}`)
  }

  return (
    <Card
      key={roster.id}
      style={{
        padding: theme.space.xs,
        marginBottom: theme.space.xxs,
      }}
    >
      <Row alignCenter fullWidth>
        <IconButton
          mr={theme.space.xxs}
          style={{
            width: theme.space.lg,
            height: theme.space.lg,
          }}
          onClick={() => setExpanded((prevState) => !prevState)}
          iconName={expanded ? 'chevronUp' : 'chevronDown'}
        />
        <Row alignCenter fullWidth style={{ marginRight: theme.space.xxs }}>
          <Row
            alignCenter
            justifyCenter
            style={{
              width: theme.space.lg,
              height: theme.space.lg,
              borderRadius: theme.border.radius,
              border: `2px solid ${theme.colors.Grey20}`,
            }}
          >
            <Icon name="workers" />
          </Row>
          <Col
            style={{
              marginLeft: theme.space.xs,
              bottom: 3,
            }}
          >
            <Row alignCenter>
              <Text
                color={theme.colors.MidnightBlue}
                variant="h6"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {rosterName}
              </Text>
              <Text variant="caption" style={{ marginLeft: theme.space.xxs }}>
                {truncateString(roster.id)}
                <CopyTextIcon textToCopy={roster.id} />
              </Text>
            </Row>
            <Text
              variant="body3"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${numWorkers} ${getWorkersPlurality(numWorkers)}`}
            </Text>
          </Col>
        </Row>
        <DotMenu
          type="rosters"
          dotMenuKey={id}
          menuItems={[
            ...(isVirtualRoster
              ? []
              : [
                  {
                    title: 'Edit Roster',
                    onClick: () => {
                      props.onClickEditRoster(roster)
                    },
                  },
                  {
                    title: 'Delete Roster',
                    onClick: () => {
                      props.onClickDeleteRoster(roster)
                    },
                  },
                ]),
            {
              title: 'Export CSV',
              onClick: handleCsvExport,
            },
          ]}
        />
      </Row>
      {expanded && (
        <RosterWorkersTable
          rosterId={id}
          rosterWorkers={rosterWorkers ?? []}
          isLoadingWorkers={isLoadingWorkers}
        />
      )}
    </Card>
  )
})
