import { CircularProgress } from '@mui/material'
import { useInternalUsers } from '@traba/hooks'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InternalUserRole, InternalUserStatus } from '@traba/types'
import { ShiftAssignmentResponse } from '@traba/types'
import { useState } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import { useShiftAssignments } from 'src/hooks/useAssignments'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { Col, Modal, Row, Select } from '../base'
import { MODAL_SIZE } from '../base/Modal/types'
import { SearchSelect } from '../base/SearchSelect/SearchSelect'
import { IMenuItem } from '../base/Select/Select'
import { ShiftAssignmentList } from './components/ShiftAssignmentList'

type ShiftAssignmentModalProps = {
  handleClose: () => void
  isOpen: boolean
  shiftsList: OpsExtendedShift[]
  refetchAssignments: any
  shiftAssignmentsMapByShiftId: Map<string, ShiftAssignmentResponse>
}

const internalUserTeamOptions = [
  { label: 'All', value: '' },
  { label: 'Scaled Ops', value: InternalUserRole.SCALED_OPS },
  { label: 'Market Ops', value: InternalUserRole.MARKET_OPS },
]

export default function ShiftAssignmentModal(props: ShiftAssignmentModalProps) {
  if (!props.isOpen) {
    return null
  }
  return <ShiftAssignmentModalComponent {...props} />
}

function ShiftAssignmentModalComponent({
  isOpen,
  handleClose,
  shiftsList,
  refetchAssignments,
  shiftAssignmentsMapByShiftId,
}: ShiftAssignmentModalProps) {
  const shiftIds = shiftsList.map((shift) => shift.id)
  const { state } = useUserContext()
  const loggedInUserRole = state.userProfile?.internalUser?.role
  const [selectedRole, setSelectedRole] = useState<InternalUserRole>(
    loggedInUserRole || InternalUserRole.SCALED_OPS,
  )

  const { isLoadingInternalUsers, userOptions } = useInternalUsers({
    statuses: [InternalUserStatus.Active],
    roles: selectedRole
      ? [selectedRole]
      : [
          InternalUserRole.OPS,
          InternalUserRole.SCALED_OPS,
          InternalUserRole.MARKET_OPS,
        ],
  })

  const { createShiftAssignments, createShiftAssignmentLoading } =
    useShiftAssignments()

  const [selectedUsers, setSelectedUsers] = useState<IMenuItem[]>([])

  if (isLoadingInternalUsers) {
    return <CircularProgress size={theme.space.xxl} />
  }

  const handleCreateAssignments = async () => {
    try {
      await createShiftAssignments({
        assignedBy: state.userProfile?.email || 'OPS',
        shiftIds: shiftIds,
        internalUserIds: selectedUsers.map((user) => user.value),
      })
    } finally {
      await refetchAssignments()
      handleClose()
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={'Assign Shifts'}
    >
      <Row justifyStart flexCol>
        <Col mb={theme.space.xs} width={'50%'}>
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            Select the Ops team to assign shifts
          </Text>
          <Select
            label="Ops Team"
            menuItems={internalUserTeamOptions}
            value={selectedRole}
            handleSelect={(val) => setSelectedRole(val as InternalUserRole)}
          />
        </Col>
        <Col>
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            Select Users to Assign to Selected Shifts
          </Text>
          <SearchSelect
            multiple
            options={userOptions}
            selectedItems={selectedUsers}
            handleSelectMultiple={setSelectedUsers}
            label={`Users`}
            width={500}
          />
        </Col>
        <Col>
          <Text
            variant="h5"
            style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
          >
            Selected Shifts
          </Text>
          <ShiftAssignmentList
            shiftsList={shiftsList}
            shiftAssignmentsMapByShiftId={shiftAssignmentsMapByShiftId}
            refetchAssignments={refetchAssignments}
          />
        </Col>
        <ModalButtons
          handleClose={handleClose}
          loading={createShiftAssignmentLoading}
          handleConfirm={handleCreateAssignments}
        />
      </Row>
    </Modal>
  )
}
