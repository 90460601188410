import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Card,
  LoadingSpinner,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  ParentInvoiceGroup,
  Shift,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { useModal } from 'src/components/base/Modal/Modal'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import { createShiftRequestEdits } from 'src/hooks/useShiftRequestEdits'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { CreateOrEditInvoiceGroupModal } from 'src/screens/CompanyDetailsScreen/components/CreateOrEditInvoiceGroupModal'
import { InvoiceSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/InvoiceSection'
import { LocationSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/LocationSection'
import { RolesAndWorkersSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/RolesAndWorkersSection'
import { getShiftRequestForEditOrAdd } from 'src/screens/PostShiftScreen/components/PostShiftForm/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface Props {
  company: Company
  shifts?: Shift[]
  firstRecurringShiftRequest: ShiftRequest
  selectedShiftRequest: ShiftRequest
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onBack: () => void
  onSuccess: () => void
}

export const EditScheduleRoleDetails = ({
  company,
  firstRecurringShiftRequest,
  shiftRequestParent,
  selectedShiftRequest,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const [selectedShift, setSelectedShift] = useState<Shift>()
  const [createShiftRequests, setCreateShiftRequests] = useState<
    CreateShiftRequest[]
  >([getShiftRequestForEditOrAdd(shiftRequestParent, selectedShiftRequest)])
  const shiftsList = shifts?.filter(
    (s) =>
      s.shiftRequestId === firstRecurringShiftRequest.shiftRequestId && !!s.id,
  )
  const { companyId } = company
  const { roles, isLoading: isLoadingRoles } = useRoles(companyId)
  const { locations } = useLocations(companyId)
  const { showError, showSuccess } = useAlert()
  const { companyUsers, isLoading: isLoadingUsers } = useCompanyUsers(companyId)
  const { rosters, isLoading: isLoadingRosters } = useRosters(companyId)
  const { hotSettings, isLoading: isLoadingHotsettings } = useHotSettings()
  const { activeInvoiceGroups, refetch: refetchInvoiceGroups } =
    useInvoiceGroups(companyId)
  const createOrEditInvoiceGroupModal = useModal()
  const originalShiftRequest = getShiftRequestForEditOrAdd(
    shiftRequestParent,
    selectedShiftRequest,
  )
  const handleCreateInvoiceGroupModalClose = async (
    newGroup?: ParentInvoiceGroup,
  ) => {
    await refetchInvoiceGroups()
    if (newGroup) {
      setCreateShiftRequests((prev) => {
        return prev.map((shiftRequest) => {
          return {
            ...shiftRequest,
            parentInvoiceGroupId: newGroup.id,
          }
        })
      })
    }
    createOrEditInvoiceGroupModal.handleClose()
  }

  const minHourlyPayRate = useMemo(() => {
    return getPayRate({
      companyMin: company.minHourlyPayRate,
      hotSettingMin: hotSettings?.platformMinHourlyPayRate ?? 0,
    })
  }, [company.minHourlyPayRate, hotSettings?.platformMinHourlyPayRate])

  const onConfirmUpdates = async () => {
    try {
      if (!selectedShift) {
        return showError(
          'Please select a date that your change will take effect',
        )
      }
      const differences = _.omitBy(createShiftRequests[0], (value, key) =>
        // @ts-ignore - this is a hack to get the differences between the two objects
        _.isEqual(value, originalShiftRequest[key]),
      )
      await createShiftRequestEdits({
        edit: {
          ...differences,
          editType: ShiftRequestEditType.ALL_FUTURE,
          shiftRequestId: selectedShiftRequest.shiftRequestId,
          originalStartTime: selectedShift.startTime,
        },
      })
      onSuccess()
      showSuccess('Role edited successfully')
    } catch (error) {
      showError('Error updating shift request')
      console.error(error)
    }
  }

  if (
    isLoadingUsers ||
    isLoadingHotsettings ||
    isLoadingRoles ||
    isLoadingRosters
  ) {
    return <LoadingSpinner />
  }

  if (!shiftsList || shiftsList.length === 0) {
    return (
      <Text variant="error">
        There's no shifts in upcoming 3 weeks for this schedule and role
      </Text>
    )
  }

  const onClickShift = (shift: Shift) => {
    setSelectedShift(shift)
  }

  return (
    <>
      <Text mb={theme.space.xs}>
        You are in edit mode for a schedule, shift type and schedule recurring
        pattern are not editable.
      </Text>
      <Card style={{ marginBottom: theme.space.sm }}>
        <Text variant="h5">
          Select when should your changes below start taking effect?
        </Text>
        <Text variant="body2" mb={theme.space.xs}>
          For example, if you want changes to take effect starting from
          Jun.30th, select Jun.30th
        </Text>
        <Divider />
        {shiftsList?.map((shift: Shift) => (
          <Row
            alignCenter
            gap={theme.space.xxs}
            mt={theme.space.xxs}
            onClick={() => onClickShift(shift)}
            key={shift.shiftId}
          >
            <RadioButton selected={selectedShift?.id === shift.id} size={20} />
            <Text>
              {getShiftDateString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
              {', '}
              {getShiftTimeString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
            </Text>
          </Row>
        ))}
      </Card>
      <LocationSection
        setCreateShiftRequests={setCreateShiftRequests}
        createShiftRequests={createShiftRequests}
        locations={locations}
        timezone={shiftsList[0].timezone}
      />
      <RolesAndWorkersSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        roles={roles}
        companyId={companyId}
        companyUsers={companyUsers}
        minHourlyPayRate={minHourlyPayRate}
        businessStartTime={shiftsList[0].businessStartTime ?? null}
        workersToInvite={workersToInvite}
        setWorkersToInvite={setWorkersToInvite}
        rosters={rosters}
      />
      <InvoiceSection
        createOrEditInvoiceGroupModal={createOrEditInvoiceGroupModal}
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        activeInvoiceGroups={activeInvoiceGroups}
      />
      <Row alignCenter justifyBetween>
        <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
          Back
        </Button>
        <Button onClick={onConfirmUpdates}>Confirm updates</Button>
      </Row>
      <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={companyId}
      />
    </>
  )
}
