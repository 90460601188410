import { Text } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { theme } from '@traba/theme'
import { compact } from 'lodash'
import { useMemo, useState } from 'react'
import {
  ExtendedWardenExemption,
  WardenExemptionType,
  wardenExemptionsInfoMap,
} from 'src/types/warden'
import { Button, Link, Row } from './base'
import { ButtonVariant } from './base/Button/types'
import { SearchSelect } from './base/SearchSelect/SearchSelect'
import { IMenuItem } from './base/Select/Select'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from './base/Table/DataTable'

type WardenExemptionsTableProps = {
  wardenExemptions: ExtendedWardenExemption[]
  onArchiveClick: (exemption: ExtendedWardenExemption) => void
  hideCompany?: boolean
  hideWorker?: boolean
  hideRole?: boolean
  hideActions?: boolean
}

function getFilterOptionsFromWardenExemptions(
  wardenExemptions: ExtendedWardenExemption[],
): {
  companyOptions: IMenuItem[]
  workerOptions: IMenuItem[]
  roleOptions: IMenuItem[]
} {
  const uniqueCompanyIds = new Set()
  const companyOptions: IMenuItem[] = compact(
    wardenExemptions
      .filter((exemption) => exemption.company && exemption.companyId)
      .map((exemption) => {
        if (uniqueCompanyIds.has(exemption.companyId)) {
          return undefined
        }
        uniqueCompanyIds.add(exemption.companyId)
        return {
          label: exemption.company!.employerName as string,
          value: exemption.companyId as string,
        }
      }),
  )

  const uniqueWorkerIds = new Set()
  const workerOptions: IMenuItem[] = compact(
    wardenExemptions
      .filter((exemption) => exemption.worker && exemption.workerId)
      .map((exemption) => {
        if (uniqueWorkerIds.has(exemption.workerId)) {
          return undefined
        }
        uniqueWorkerIds.add(exemption.workerId)
        return {
          label: `${exemption.worker!.firstName} ${exemption.worker!.lastName}`,
          value: exemption.workerId as string,
        }
      }),
  )

  const uniqueRoleIds = new Set()
  const roleOptions: IMenuItem[] = compact(
    wardenExemptions
      .filter((exemption) => exemption.role && exemption.roleId)
      .map((exemption) => {
        if (uniqueRoleIds.has(exemption.roleId)) {
          return undefined
        }
        uniqueRoleIds.add(exemption.roleId)
        return {
          label: exemption.role!.roleName as string,
          value: exemption.roleId as string,
        }
      }),
  )

  return { companyOptions, workerOptions, roleOptions }
}

export const WardenExemptionsTable = ({
  wardenExemptions,
  onArchiveClick,
  hideCompany,
  hideWorker,
  hideRole,
  hideActions,
}: WardenExemptionsTableProps) => {
  const [selectedExemptionTypes, setSelectedExemptionTypes] = useState<
    IMenuItem[]
  >([])
  const [selectedCompanies, setSelectedCompanies] = useState<IMenuItem[]>([])
  const [selectedWorkers, setSelectedWorkers] = useState<IMenuItem[]>([])
  const [selectedRoles, setSelectedRoles] = useState<IMenuItem[]>([])

  const wardenExemptionTypeOptions = useMemo(() => {
    return Object.values(WardenExemptionType).map((exemption) => ({
      label: wardenExemptionsInfoMap[exemption].title,
      value: exemption,
    }))
  }, [wardenExemptionsInfoMap])
  const { companyOptions, workerOptions, roleOptions } = useMemo(
    () => getFilterOptionsFromWardenExemptions(wardenExemptions),
    [wardenExemptions],
  )

  const onFilterChange = (values: IMenuItem[], field: string) => {
    switch (field) {
      case 'exemptionTypes':
        setSelectedExemptionTypes(values)
        break
      case 'companies':
        setSelectedCompanies(values)
        break
      case 'workers':
        setSelectedWorkers(values)
        break
      case 'roles':
        setSelectedRoles(values)
        break
      default:
        break
    }
  }

  const headers: (DataTableHeader | undefined)[] = useMemo(
    () => [
      {
        key: 'bypass',
        label: 'Bypass Type',
        sortable: true,
      },
      hideWorker
        ? undefined
        : {
            key: 'workerName',
            label: 'Worker',
            sortable: true,
          },
      hideCompany
        ? undefined
        : {
            key: 'companyName',
            label: 'Company',
            sortable: true,
          },
      hideRole
        ? undefined
        : {
            key: 'roleName',
            label: 'Role',
            sortable: true,
          },
      {
        key: 'actions',
        label: '',
      },
    ],
    [hideCompany, hideRole, hideWorker],
  )

  const filteredWardenExemptions = useMemo(
    () =>
      wardenExemptions.filter((exemption) => {
        if (
          selectedExemptionTypes.length > 0 &&
          !selectedExemptionTypes.some(
            (exemptionType) => exemptionType.value === exemption.exemptionType,
          )
        ) {
          return false
        }

        if (
          selectedCompanies.length > 0 &&
          !selectedCompanies.some(
            (company) => company.value === exemption.companyId,
          )
        ) {
          return false
        }

        if (
          selectedWorkers.length > 0 &&
          !selectedWorkers.some((worker) => worker.value === exemption.workerId)
        ) {
          return false
        }

        if (
          selectedRoles.length > 0 &&
          !selectedRoles.some((role) => role.value === exemption.roleId)
        ) {
          return false
        }

        return true
      }),
    [
      selectedExemptionTypes,
      selectedCompanies,
      selectedWorkers,
      selectedRoles,
      wardenExemptions,
    ],
  )

  const rows: TableRow[] = useMemo(
    () =>
      filteredWardenExemptions.map((exemption) => {
        const cells: (TableCell | undefined)[] = [
          {
            sortKey: exemption.exemptionType,
            renderFn: () =>
              wardenExemptionsInfoMap[exemption.exemptionType].title,
          },
          hideWorker
            ? undefined
            : {
                sortKey:
                  `${exemption.worker?.firstName}_${exemption.worker?.lastName}` ||
                  'All',
                renderFn: () =>
                  exemption.worker ? (
                    <Link to={`/workers/${exemption.workerId}`} target="_blank">
                      <Button
                        style={{ padding: 0 }}
                        variant={ButtonVariant.TEXT}
                      >
                        <Text
                          variant="body1"
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                            textAlign: 'left',
                          }}
                        >
                          {exemption.worker!.firstName}{' '}
                          {exemption.worker!.lastName}
                        </Text>
                      </Button>
                    </Link>
                  ) : (
                    'All'
                  ),
              },
          hideCompany
            ? undefined
            : {
                sortKey: exemption.company?.employerName || 'All',
                renderFn: () =>
                  exemption.company ? (
                    <Link
                      to={`/companies/${exemption.companyId}`}
                      target="_blank"
                    >
                      <Button
                        style={{ padding: 0 }}
                        variant={ButtonVariant.TEXT}
                      >
                        <Text
                          variant="body1"
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                            textAlign: 'left',
                          }}
                        >
                          {exemption.company.employerName}
                        </Text>
                      </Button>
                    </Link>
                  ) : (
                    'All'
                  ),
              },
          hideRole
            ? undefined
            : {
                sortKey: exemption.role?.roleName || 'All',
                renderFn: () => exemption.role?.roleName || 'All',
              },
          {
            renderFn: () =>
              hideActions ? null : (
                <Row justifyEnd mr={theme.space.xxs}>
                  <DotMenu
                    type="warden-exemption-actions"
                    dotMenuKey={exemption.id}
                    menuItems={[
                      {
                        title: 'Archive Exemption',
                        onClick: () => onArchiveClick(exemption),
                      },
                    ]}
                  />
                </Row>
              ),
          },
        ]

        return {
          key: exemption.id,
          cells: compact(cells),
        }
      }),
    [
      filteredWardenExemptions,
      onArchiveClick,
      hideActions,
      hideCompany,
      hideRole,
      hideWorker,
    ],
  )

  return (
    <>
      <Row pb={theme.space.sm}>
        <SearchSelect
          style={{ minWidth: 200, marginRight: theme.space.sm }}
          multiple
          onlyShowLabel
          options={wardenExemptionTypeOptions}
          selectedItems={selectedExemptionTypes}
          handleSelectMultiple={(values) =>
            onFilterChange(values, 'exemptionTypes')
          }
          label="Exemption Type"
        />
        {!hideCompany && (
          <SearchSelect
            style={{ minWidth: 200, marginRight: theme.space.sm }}
            multiple
            onlyShowLabel
            options={companyOptions}
            selectedItems={selectedCompanies}
            handleSelectMultiple={(values) =>
              onFilterChange(values, 'companies')
            }
            label="Company"
          />
        )}
        {!hideWorker && (
          <SearchSelect
            style={{ minWidth: 200, marginRight: theme.space.sm }}
            multiple
            onlyShowLabel
            options={workerOptions}
            selectedItems={selectedWorkers}
            handleSelectMultiple={(values) => onFilterChange(values, 'workers')}
            label="Worker"
          />
        )}
        {!hideRole && (
          <SearchSelect
            style={{ minWidth: 200 }}
            multiple
            onlyShowLabel
            options={roleOptions}
            selectedItems={selectedRoles}
            handleSelectMultiple={(values) => onFilterChange(values, 'roles')}
            label="Role"
          />
        )}
      </Row>
      <DataTable
        headers={compact(headers)}
        rows={rows}
        initialSortByColumnIndex={0} // sort by name column
      />
    </>
  )
}
