import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { JobStatus } from '@traba/types'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import { ConfirmShiftModal, FraudulentModal } from 'src/modals'
import AdjustBreakModal from 'src/modals/AdjustBreakModal/AdjustBreakModal'
import MakeRobocallModal from 'src/modals/MakeRobocallModal/MakeRobocallModal'
import { ManageWorkerModal } from 'src/modals/ManageWorkerModal/ManageWorkerModal'
import { openInNewTab } from 'src/utils/helperUtils'
import { ButtonVariant } from '../base/Button/types'
import { Dropdown, DropdownElement } from '../base/Dropdown'
import { useModal } from '../base/Modal/Modal'

type WorkerActionProps = {
  workerShift: WorkerShift
  openClockInModal: (workerShift: WorkerShift) => void
  openClockOutModal: (workerShift: WorkerShift) => void
  openAdjustmentModal: (workerShift: WorkerShift) => void
}

export const WorkerAction = (props: WorkerActionProps) => {
  const {
    workerShift,
    openClockInModal,
    openClockOutModal,
    openAdjustmentModal,
  } = props

  const confirmShiftModal = useModal()
  const manageWorkerModal = useModal()
  const adjustBreakModal = useModal()
  const makeRobocallModal = useModal()
  const fraudulentModal = useModal()

  const stripeAccess = usePermissions([UserRolePermission.StripeAccess])
  const flagShiftAccess = usePermissions([UserRolePermission.FlagShift])

  const workerActions: DropdownElement[] = [
    { label: 'Confirm Shift', handleClick: () => confirmShiftModal.open() },
    {
      label: 'Clock Worker In',
      handleClick: () => openClockInModal(workerShift),
    },
    {
      label: 'Clock Worker Out',
      handleClick: () => openClockOutModal(workerShift),
    },
    { label: 'Manage Worker', handleClick: () => manageWorkerModal.open() },
    {
      label: 'Adjust Shift',
      handleClick: () => openAdjustmentModal(workerShift),
    },
    {
      label: 'Open Stripe',
      handleClick: () =>
        openInNewTab(
          `${
            import.meta.env.VITE_STRIPE_URL ||
            'https://dashboard.stripe.com/test'
          }/connect/accounts/${workerShift.worker?.stripeAccountId}/activity`,
        ),
      disabled: !stripeAccess,
    },
    {
      label: 'Retool Worker Profile',
      handleClick: () =>
        openInNewTab(
          `https://traba.retool.com/apps/5b7d54ea-b120-11ec-a560-a355ea6c44fd/Profile%20Information?=#workerUid=${workerShift.workerId}`,
        ),
    },
    {
      label: 'Robocalls',
      handleClick: () => makeRobocallModal.open(),
    },
    {
      label: 'Set Flag On Shift',
      handleClick: () => fraudulentModal.open(),
      disabled: !flagShiftAccess,
    },
  ]

  if (import.meta.env.VITE_ENV !== 'production') {
    workerActions.push({
      label: 'Open Worker Shift Doc',
      handleClick: () =>
        openInNewTab(
          `https://console.firebase.google.com/u/1/project/${
            import.meta.env.VITE_FBPROJ
          }/firestore/data/~2Fworkers~2F${
            workerShift.workerId
          }~2Fworker_shift~2F${workerShift.shiftId}`,
        ),
    })
  }

  if (
    workerShift.jobStatus === JobStatus.InProgress ||
    workerShift.jobStatus === JobStatus.OnBreak
  ) {
    workerActions.push({
      label: 'Adjust breaks',
      handleClick: () => adjustBreakModal.open(),
    })
  }

  return (
    <>
      <Dropdown
        dropdownOptions={workerActions}
        orientation={'right'}
        buttonVariant={ButtonVariant.OUTLINED}
        buttonStyling={{
          padding: '11px 12px 6px',
          marginBottom: -8,
          marginTop: -8,
        }}
      />
      <ConfirmShiftModal {...confirmShiftModal} workerShift={workerShift} />
      <AdjustBreakModal {...adjustBreakModal} workerShift={workerShift} />
      <ManageWorkerModal {...manageWorkerModal} workerShift={workerShift} />
      <MakeRobocallModal {...makeRobocallModal} workerShift={workerShift} />
      <FraudulentModal {...fraudulentModal} workerShift={workerShift} />
    </>
  )
}
