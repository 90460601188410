import { Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import { truncateString } from 'src/utils/stringUtils'

interface WorkerNameWithIconsProps {
  name: string
  addMargin?: boolean
  maxLength?: number
  isInvitedWorker?: boolean
  isFavoriteWorker?: boolean
  isFirstShiftWithCompany?: boolean
  isFirstShiftWithTraba?: boolean
}

export function WorkerNameWithIcons({
  name,
  addMargin = true,
  maxLength,
  isInvitedWorker = false,
  isFavoriteWorker = false,
  isFirstShiftWithCompany = false,
  isFirstShiftWithTraba = false,
}: WorkerNameWithIconsProps) {
  const style = {
    marginRight: addMargin ? theme.space.xxxs : undefined,
  }
  return (
    <div>
      {isInvitedWorker && (
        <Tooltip title={'Worker has been invited'}>
          <span
            role="img"
            aria-label="Worker invited by business"
            style={style}
          >
            💌
          </span>
        </Tooltip>
      )}
      {isFirstShiftWithTraba && (
        <Tooltip title="Worker's first shift with Traba">
          <span role="img" aria-label="First shift with Traba" style={style}>
            🐣
          </span>
        </Tooltip>
      )}
      {!isFirstShiftWithTraba && isFirstShiftWithCompany && (
        <Tooltip title="Worker's first shift with this business">
          <span
            role="img"
            aria-label="First shift with this business"
            style={style}
          >
            👶
          </span>
        </Tooltip>
      )}
      {isFavoriteWorker && (
        <Tooltip title="Favorite worker for this business">
          <span
            role="img"
            aria-label="Favorite worker for this business"
            style={style}
          >
            ❤️
          </span>
        </Tooltip>
      )}
      {maxLength ? truncateString(`${name}`, maxLength) : name}
    </div>
  )
}
