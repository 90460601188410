import {
  Incentive,
  IncentiveRules,
  IncentiveStatus,
  IncentiveTypeIds,
  RuleLine,
  SourceType,
  TargetType,
  ValueType,
  incentiveTypeFromTypeId,
} from '@traba/types'
import { WorkerCertificationType } from '@traba/types'
import { addDays } from 'date-fns'
import { FieldType, IncentiveCategory, RuleOption } from './types'

export const incentiveRuleOptions: RuleOption[] = [
  ...(Object.values(IncentiveRules)
    .map((rule) => {
      switch (rule) {
        case IncentiveRules.ShiftsCompletionRequired:
          return {
            key: rule,
            label: 'Shifts Completion Count',
            keyFields: [
              {
                fieldKey: 'count',
                fieldLabel: 'Count',
                fieldType: FieldType.int,
                fieldDescription:
                  'The number of shifts the worker needs to complete to earn the incentive',
              },
            ],
            description:
              "This rule will evaluate as true if the worker has completed the required number of shifts within the incentive's timeframe.",
          }
        case IncentiveRules.HasCompletedIncentive:
          return {
            key: rule,
            label: 'Has Completed Incentive',
            keyFields: [
              {
                fieldKey: 'incentiveId',
                fieldLabel: 'Incentive ID',
                fieldType: FieldType.input,
                fieldDescription:
                  "This is the id of an incentive that the worker needs to have already earned in order to progress on the incentive you're creating.",
              },
            ],
            description:
              'This rule will evaluate as true if the worker has previously completed the listed incentive.',
          }
        case IncentiveRules.HasCompletedShift:
          return {
            key: rule,
            label: 'Has Completed Shift',
            keyFields: [
              {
                fieldKey: 'shiftId',
                fieldLabel: 'Shift ID',
                fieldType: FieldType.input,
                fieldDescription:
                  'The ID of the shift the worker needs to have completed',
              },
            ],
            description:
              'This rule will evaluate as true if the worker has completed a specific shift.',
          }
        case IncentiveRules.NewAccount:
          return {
            key: rule,
            label: 'New Account',
            keyFields: [],
            description:
              'This rule will evaluate as true if the worker has an account that has been approved within 30 days of earning this incentive.',
          }
        case IncentiveRules.WorkerReferral:
          return {
            key: rule,
            label: 'Worker Referral',
            keyFields: [],
            description:
              'This rule will evaluate as true if the worker was referred by another worker.',
          }
        case IncentiveRules.ProgressOnly:
          return {
            key: rule,
            label: 'Progress Only',
            keyFields: [],
            description:
              'This rule will prevent all workers from automatically earning this incentive, regardless if all other rules are true. For workers to earn credit for this incentive, they must be granted the incentive manually by Ops.',
          }
        case IncentiveRules.LifeTimeShiftCompletions:
          return {
            key: rule,
            label: 'Lifetime Shift Completions',
            keyFields: [
              {
                fieldKey: 'minimumCompletions',
                fieldLabel: 'Minimum Lifetime Completions',
                fieldType: FieldType.int,
                fieldDescription:
                  'The minimum number of shifts the worker needs to have completed in their lifetime.',
              },
              {
                fieldKey: 'maximumCompletions',
                fieldLabel: 'Maximum Lifetime Completions',
                fieldType: FieldType.int,
                fieldDescription:
                  'The maximum number of shifts the worker can have completed in their lifetime.',
              },
            ],
            description:
              'This rule will evaluate as true if the worker has completed a minimum number of shifts in their lifetime. You can use this to target a range of completions, for example 11-20 shifts',
          }
        case IncentiveRules.CertificationRequired:
          return {
            key: rule,
            label: 'Certification Required',
            keyFields: [
              {
                fieldKey: 'certificationType',
                fieldLabel: 'Certification Type',
                fieldType: FieldType.select,
                fieldOptions: Object.values(WorkerCertificationType).map(
                  (certification) => ({
                    value: certification,
                    label: certification,
                  }),
                ),
                fieldDescription:
                  'The certification type the worker needs to have',
              },
            ],
            description:
              'This rule will evaluate as true if the worker has a specific approved certification when they complete a shift.',
          }
        case IncentiveRules.CompanyExclusive:
          return {
            key: rule,
            label: 'Company Exclusive',
            keyFields: [
              {
                fieldKey: 'companyId',
                fieldLabel: 'Company ID',
                fieldType: FieldType.input,
                fieldDescription:
                  'The ID of the company of the Shift the worker needs to work for',
              },
            ],
            description:
              'This rule will evaluate as true if the worker has completed a shift at a specific company.',
          }
        case IncentiveRules.ShiftGroup:
          return {
            key: rule,
            label: 'Shift IDs',
            keyFields: [
              {
                fieldKey: 'shiftIds',
                fieldLabel: 'Shift IDs',
                fieldType: FieldType.multiSelect, // TODO(joey) add multiple string input capability
                fieldDescription:
                  'The IDs of the shifts the worker needs to have completed',
              },
            ],
            description:
              'This rule will evaluate as true if the worker has completed a shift at a specific company.',
          }
        default:
          // eslint-disable-next-line no-case-declarations
          const exhaustiveCheck: never = rule
          console.error(`Unhandled incentive rule: ${exhaustiveCheck}`)
          return {}
      }
    })
    .filter(Boolean) as RuleOption[]),
]

export const incentiveCategoryOptions: IncentiveCategory[] = [
  {
    key: 'EWI-ENG-DXGY',
    label: 'Existing Worker Incentive - Engagement - Do X Get Y',
    typeId: IncentiveTypeIds.dXgY,
    valueType: ValueType.Cash,
    requiredRules: [IncentiveRules.ShiftsCompletionRequired],
    allowsRuleSelection: true,
    isOpsGranted: false,
  },
  {
    key: 'NWI-REF',
    label: 'New Worker Incentives - Referral',
    typeId: IncentiveTypeIds.dXgY,
    valueType: ValueType.Cash,
    requiredRules: [
      IncentiveRules.ShiftsCompletionRequired,
      IncentiveRules.WorkerReferral,
    ],
    allowsRuleSelection: true,
    isOpsGranted: false,
  },
  {
    key: 'EWI-APP-BO',
    label: 'Existing Worker Incentive - Appeasement - Bad Overbook',
    typeId: IncentiveTypeIds.appeasement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-APP-BFILL',
    label: 'Existing Worker Incentive - Appeasement - Bad Backfill',
    typeId: IncentiveTypeIds.appeasement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-APP-BH',
    label: 'Existing Worker Incentive - Appeasement - Business Behavior',
    typeId: IncentiveTypeIds.appeasement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-APP-PB',
    label: 'Existing Worker Incentive - Appeasement - Paid Backup',
    typeId: IncentiveTypeIds.appeasement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-APP-PC',
    label: 'Existing Worker Incentive - Appeasement - Paid Cancellation',
    typeId: IncentiveTypeIds.appeasement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-ENG-OT',
    label: 'Existing Worker Incentive - Engagement - Overtime',
    typeId: IncentiveTypeIds.engagement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-ENG-CAR',
    label: 'Existing Worker Incentive - Engagement - Transportation',
    typeId: IncentiveTypeIds.engagement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-ENG-EQUIP',
    label: 'Existing Worker Incentive - Engagement - Equipment',
    typeId: IncentiveTypeIds.engagement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-ENG-BONUS', // NOTE: this incentive will be the free-form 'catch all'
    label: 'Existing Worker Incentive - Engagement - Performance Bonus',
    typeId: IncentiveTypeIds.engagement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-ENG-DT',
    label: 'Existing Worker Incentive - Engagement - Drug Tests',
    typeId: IncentiveTypeIds.engagement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
  {
    key: 'EWI-ENG-TU',
    label: 'Existing Worker Incentive - Engagement - Top Up',
    typeId: IncentiveTypeIds.engagement,
    valueType: ValueType.Voucher,
    allowsRuleSelection: false,
    isOpsGranted: true,
  },
]

export const INITIAL_INCENTIVE: Omit<Incentive, 'incentiveId'> = {
  status: IncentiveStatus.Active,
  title: '',
  description: '',
  internalMemo: '',
  regionIds: [],
  valueType: incentiveCategoryOptions[0].valueType,
  category: incentiveCategoryOptions[0].label,
  categoryId: incentiveCategoryOptions[0].key,
  type: incentiveTypeFromTypeId(incentiveCategoryOptions[0].typeId),
  typeId: incentiveCategoryOptions[0].typeId,
  campaignId: 'TRABA',
  companyId: 'TRABA',
  source: SourceType.Traba,
  target: TargetType.Worker,
  rules: [],
  startTime: new Date(new Date().setHours(0, 0, 0, 0)),
  endTime: addDays(new Date(), 14), // Default to 2 weeks out
}

export function validateCreateIncentive(
  incentive: Omit<Incentive, 'incentiveId'>,
  selectedRules: Set<RuleOption>,
): string | null {
  const ruleValidation = validateIncentiveRules(incentive, selectedRules)
  if (ruleValidation) {
    return ruleValidation
  }
  if (!validatePay(incentive)) {
    return 'Amount is required'
  }
  if (!incentive.title) {
    return 'Title is required'
  }
  if (!incentive.description) {
    return 'Description is required'
  }
  if (!incentive.internalMemo) {
    return 'Internal Memo is required'
  }
  if (
    !incentive.regionIds.length &&
    incentive.valueType !== ValueType.Voucher
  ) {
    return 'At least one region is required for non Voucher incentives'
  }
  if (incentive.startTime > incentive.endTime) {
    return 'Start Time cannot be later than End Time'
  }
  return null
}

export function validatePay(incentive: Omit<Incentive, 'incentiveId'>) {
  if (incentive.valueType === ValueType.Cash) {
    return !!incentive.total?.amount
  } else {
    return true
  }
}

export function validateIncentiveRules(
  incentive: Omit<Incentive, 'incentiveId'>,
  selectedRules: Set<RuleOption>,
): string | null {
  // Check that, given the selected form rules, the required fields for each rule are populated on the rules list on the incentive object.
  for (const selectedRule of Array.from(selectedRules)) {
    for (const keyField of selectedRule.keyFields) {
      const correspondingRule = incentive.rules.find(
        (rule) => rule.type === (selectedRule.key as IncentiveRules),
      )
      const ruleValue =
        correspondingRule &&
        correspondingRule[keyField.fieldKey as keyof RuleLine]
      if (!ruleValue || (Array.isArray(ruleValue) && ruleValue.length === 0)) {
        return `Rule field: "${keyField.fieldLabel}" is required`
      }
    }
  }
  return null
}
