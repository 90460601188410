import { useAlert } from '@traba/context'
import { ConnectionType } from '@traba/types'
import { useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import {
  Anchor,
  Button,
  CopyTextIcon,
  IconButton,
  Link,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import {
  deleteConnection,
  useCompanyConnections,
} from 'src/hooks/useConnections'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import { CompanyConnectionModal } from './CompanyConnectionModal'

interface CompanyConnectionsProps {
  companyId: string
}

export default function CompanyConnections({
  companyId,
}: CompanyConnectionsProps) {
  const connectionOptions: IMenuItem[] = Object.keys(ConnectionType).map(
    (connectionType) => {
      return { label: connectionType, value: connectionType }
    },
  )
  const [connectionFilters, setConnectionFilters] =
    useState<IMenuItem[]>(connectionOptions)

  const { connections, isLoading, refetch } = useCompanyConnections(companyId)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { showSuccess, showError } = useAlert()
  const createCompanyConnectionModal = useModal()

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  const filteredConnections = connections?.filter((connection) =>
    connectionFilters.some(
      (filter) => filter.value === connection.connectionType,
    ),
  )

  const handleDeleteConnection = async (
    workerId: string,
    companyId: string,
    workerName: string,
  ) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this connection with ${workerName}?`,
      )
      if (!isConfirmed) {
        return
      }
      setDeleteLoading(true)
      await deleteConnection(workerId, companyId)
      showSuccess('Connection deleted successfully')
      refetch()
    } catch (error) {
      showError(getErrorMessage(error), 'Error deleting connection')
    } finally {
      setDeleteLoading(false)
    }
  }

  const tableRows: TableRow[] =
    filteredConnections?.map((connection) => {
      const {
        id,
        createdAt,
        connectionReason = '',
        connectionReasons = [],
        connectionType,
        source,
        initiatedBy,
        note,
        worker,
      } = connection

      const { firstName, lastName, regionId, zipCode } = worker

      const workerId = worker.workerId || worker.uid
      const name = `${firstName} ${lastName}`
      const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)

      const connReason = connectionReasons.length
        ? connectionReasons.join(', ')
        : connectionReason
      const createdAtDate = new Date(createdAt)

      return {
        key: id,
        cells: [
          {
            renderFn: () => (
              <Row justifyBetween>
                <Link to={`/workers/${workerId}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {name}
                  </Button>
                </Link>
              </Row>
            ),
            sortKey: name,
          },
          {
            renderFn: () => (
              <>
                {truncateString(workerId, 6)}
                <CopyTextIcon textToCopy={workerId} />
              </>
            ),
            sortKey: workerId,
          },
          {
            renderFn: () => {
              return (
                <>
                  <Anchor href={`tel:${phoneNumber}`}>{phoneNumber}</Anchor>
                  <CopyTextIcon textToCopy={phoneNumber} />
                </>
              )
            },
          },
          {
            renderFn: () => regionId,
            sortKey: regionId,
          },
          {
            renderFn: () => zipCode,
            sortKey: zipCode,
          },
          {
            renderFn: () => connectionType,
            sortKey: connectionType,
          },
          {
            renderFn: () => source,
            sortKey: source,
          },
          {
            renderFn: () => initiatedBy,
            sortKey: initiatedBy,
          },
          {
            renderFn: () => connReason,
            sortKey: connReason,
          },
          {
            renderFn: () => note,
            sortKey: note,
          },
          {
            renderFn: () => formatDateTimeWithTimezone(createdAtDate),
            sortKey: Date.parse(createdAt),
          },
          {
            renderFn: () => (
              <IconButton
                iconName="trash"
                onClick={() =>
                  handleDeleteConnection(workerId, companyId, name)
                }
                loading={deleteLoading}
              />
            ),
          },
        ],
      }
    }) ?? []

  return (
    <>
      <Row pb={20} fullWidth justifyBetween>
        <SearchSelect
          multiple
          onlyShowLabel
          options={connectionOptions}
          selectedItems={connectionFilters}
          handleSelectMultiple={setConnectionFilters}
          label="Connection Type"
        />
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={createCompanyConnectionModal.open}
        >
          Create Connection
        </Button>
      </Row>
      <DataTable
        initialSortByColumnIndex={0} // sort by name column
        rows={tableRows}
        headers={[
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'workerId',
            label: 'Worker ID',
            sortable: true,
          },
          {
            key: 'phoneNumber',
            label: 'Phone Number',
          },
          {
            key: 'regionId',
            label: 'Region ID',
            sortable: true,
          },
          {
            key: 'zipCode',
            label: 'Zipcode',
            sortable: true,
          },
          {
            key: 'connectionType',
            label: 'Connection Type',
            sortable: true,
          },
          {
            key: 'source',
            label: 'Source',
            sortable: true,
          },
          {
            key: 'initiatedBy',
            label: 'Initiated By',
            sortable: true,
          },
          {
            key: 'connReason',
            label: 'Connection Reason',
            sortable: true,
          },
          {
            key: 'note',
            label: 'Note',
            sortable: true,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            sortable: true,
          },
          {
            key: 'deleteConnection',
            label: 'Delete',
            sortable: false,
          },
        ]}
      />
      <CompanyConnectionModal
        companyId={companyId}
        isOpen={createCompanyConnectionModal.isOpen}
        handleClose={createCompanyConnectionModal.handleClose}
      />
    </>
  )
}
