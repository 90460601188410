import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeLevelType,
  AttributeSkillLevels,
  OpsAttributeLevel,
} from '@traba/types'
import {
  isAttributeLevelUnreported,
  isAttributeLevelRevoked,
} from 'src/utils/attributeUtils'

export interface AttributePillLevelProps {
  attributeName: string
  attributeLevel: OpsAttributeLevel
  levelType: AttributeLevelType
}

function textForAttributeSkillLevel(attributeLevel: AttributeSkillLevels) {
  switch (attributeLevel) {
    case AttributeSkillLevels.HIGH:
      return '3'
    case AttributeSkillLevels.MEDIUM:
      return '2'
    case AttributeSkillLevels.LOW:
      return '1'
    default:
      return
  }
}

export function AttributePillLevel(props: AttributePillLevelProps) {
  const { attributeLevel, levelType } = props

  if (isAttributeLevelUnreported(attributeLevel)) {
    return null
  }

  if (isAttributeLevelRevoked(attributeLevel)) {
    return <SvgIcon name="block" color={theme.colors.Red80} />
  }

  switch (levelType) {
    case AttributeLevelType.SKILL_LEVELING: {
      return (
        <Text
          color={theme.colors.White}
          variant="h6"
          style={{ whiteSpace: 'nowrap' }}
        >
          {textForAttributeSkillLevel(attributeLevel as AttributeSkillLevels)}
        </Text>
      )
    }
    case AttributeLevelType.BASE_LEVELING: {
      return (
        <Text
          color={theme.colors.White}
          variant="h6"
          style={{ whiteSpace: 'nowrap' }}
        >
          {attributeLevel}
        </Text>
      )
    }
    default: {
      return null
    }
  }
}
