import { ModalButtons } from '@traba/react-components'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { Row } from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { ShiftRequestSelector } from 'src/components/ShiftRequestSelector'
import { useAttributes } from 'src/hooks/useAttributes'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useRoles } from 'src/hooks/useRoles'
import { EditShiftForm } from './EditShiftForm'
import { FormWrapper } from './style'

interface EditShiftModalProps {
  handleClose: () => void
  isOpen: boolean
  shift: Shift
  minHourlyPayRate: number
  allowSelectionFromShiftRequest?: boolean
  sentinelNotificationToUserId?: string
}

export const EditShiftModalSteps = {
  SELECT_SHIFTS: 1,
  EDIT_SHIFTS: 2,
}

export const EditShiftModal = (props: EditShiftModalProps) => {
  const {
    isOpen,
    handleClose,
    shift,
    minHourlyPayRate,
    allowSelectionFromShiftRequest = true,
    sentinelNotificationToUserId,
  } = props
  const [step, setStep] = useState(allowSelectionFromShiftRequest ? 1 : 2)
  const [selectedShiftsForRequest, setSelectedShiftsForRequest] = useState<
    Record<string, string[]>
  >({ [shift.shiftRequestId]: [shift.id] })
  const { locations } = useLocations(shift.companyId)
  const { roles } = useRoles(shift.companyId)
  const { attributes } = useAttributes()
  const { companyUsers } = useCompanyUsers(shift.companyId)

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={
        step === EditShiftModalSteps.SELECT_SHIFTS
          ? 'Select shifts'
          : 'Make edits'
      }
    >
      <FormWrapper>
        {step === EditShiftModalSteps.SELECT_SHIFTS && (
          <>
            <ShiftRequestSelector
              shiftRequestId={shift.shiftRequestId}
              setSelectedShifts={setSelectedShiftsForRequest}
              selectedShifts={selectedShiftsForRequest}
            />
            <Row justifyEnd>
              <ModalButtons
                handleClose={handleClose}
                handleConfirm={() => setStep(EditShiftModalSteps.EDIT_SHIFTS)}
                confirmText={`Confirm ${
                  selectedShiftsForRequest[shift.shiftRequestId]?.length || 0
                } shifts`}
              />
            </Row>
          </>
        )}
        {step === EditShiftModalSteps.EDIT_SHIFTS && (
          <EditShiftForm
            shift={shift}
            roles={roles}
            locations={locations}
            companyUsers={companyUsers}
            attributes={attributes}
            shiftList={selectedShiftsForRequest[shift.shiftRequestId] || []}
            onCancel={() => {
              if (allowSelectionFromShiftRequest) {
                setStep(EditShiftModalSteps.SELECT_SHIFTS)
              } else {
                handleClose()
              }
            }}
            handleClose={handleClose}
            minHourlyPayRate={minHourlyPayRate}
            allowSelectionFromShiftRequest={allowSelectionFromShiftRequest}
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        )}
      </FormWrapper>
    </Modal>
  )
}
