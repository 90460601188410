import { CircularProgress } from '@mui/material'
import { theme } from '@traba/theme'
import { useRef } from 'react'
import { useShiftRequestsWithShifts } from 'src/screens/FieldMonitorScreen/components/AddWorkers/hooks/useShiftRequestWithShifts'
import ShiftRequestCard from 'src/screens/FieldMonitorScreen/components/AddWorkers/ShiftRequestCard'
import { Row } from './base'

type ShiftRequestSelectorProps = {
  shiftRequestId: string
  setSelectedShifts: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >
  selectedShifts: Record<string, string[]>
}

export const ShiftRequestSelector = (props: ShiftRequestSelectorProps) => {
  const { shiftRequestId, setSelectedShifts, selectedShifts } = props

  const { current: shiftRequestIdArray } = useRef([shiftRequestId])

  const { shifts, shiftRequests, isLoading } =
    useShiftRequestsWithShifts(shiftRequestIdArray)

  if (isLoading) {
    return (
      <Row center>
        <CircularProgress size={theme.space.xxl} />
      </Row>
    )
  }

  const shiftRequestWithShifts = { ...shiftRequests[0], shifts }

  return (
    <ShiftRequestCard
      key={`${shiftRequestId}_request_card`}
      shiftRequest={shiftRequestWithShifts}
      selectedShifts={selectedShifts}
      setSelectedShifts={setSelectedShifts}
      conflictingShiftIds={[]}
    />
  )
}
