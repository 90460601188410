import { Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { ChangeEvent, useMemo } from 'react'
import { DatePicker, Input, Row, Select } from 'src/components/base'
import { Field, FieldType } from 'src/hooks/useSearchInputItems'
import { InputsWrapper } from '../../screens/WorkerSearchScreen/components/WorkerSearch.styles'
import Checkbox from '../base/Checkbox'
import { SearchSelect } from '../base/SearchSelect/SearchSelect'

interface InputMapperProps {
  inputFields: Field[]
  values: { [key: string]: any }
  onChange: (field: string, value: any) => void
}

type ChangeType = ChangeEvent<HTMLInputElement>

const WithToolTip = (props: {
  children: React.ReactNode
  tooltip?: string
}) => (
  <Tooltip title={props.tooltip || ''} leaveDelay={100} arrow>
    <span>{props.children}</span>
  </Tooltip>
)

export default function InputMapper({
  inputFields,
  values,
  onChange,
}: InputMapperProps) {
  // Map the inputs into sections based on the field type
  const inputSections = useMemo(() => {
    const sectionsObj: { [key in FieldType]?: Field[] } = {}
    inputFields.forEach((field) => {
      if (!sectionsObj[field.type]) {
        sectionsObj[field.type] = []
      }
      sectionsObj[field.type]?.push(field)
    })
    const sections: Field[][] = Object.values(sectionsObj)
    return sections
  }, [inputFields])

  return (
    <>
      {inputSections.map((section, index) => (
        <InputsWrapper key={`input-section-${index}`}>
          {section.map((fieldConfig) => {
            const { field, type, label, tooltip } = fieldConfig
            const currentValue = values[field]

            if (type === FieldType.input) {
              return (
                <Input
                  key={label}
                  full
                  label={label}
                  value={currentValue || ''}
                  style={{ height: 40 }}
                  onChange={(ev: ChangeType) =>
                    onChange(field, ev.target.value)
                  }
                  placeholder={fieldConfig.placeholder}
                />
              )
            }

            if (type === FieldType.checkbox) {
              return (
                <Row alignCenter justifyStart key={label}>
                  <WithToolTip tooltip={tooltip}>
                    <Checkbox
                      label={label}
                      checked={currentValue || false}
                      onChange={(ev: ChangeType) =>
                        onChange(field, ev.target.checked)
                      }
                    />
                  </WithToolTip>
                </Row>
              )
            }

            if (type === FieldType.float || type === FieldType.int) {
              return (
                <Row alignCenter key={label}>
                  <Input
                    containerStyle={{
                      marginTop: 0,
                      marginRight: 12,
                      width: 80,
                      height: 50,
                    }}
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                    }}
                    width="100%"
                    type="number"
                    placeholder={type === FieldType.float ? 'e.g. 0.1' : ''}
                    value={currentValue >= 0 ? `${currentValue}` : ''}
                    onChange={({ target: { value } }: ChangeType) => {
                      if (type === FieldType.float) {
                        return onChange(
                          field,
                          parseInt(value, 10) > 1 ? '1' : value.trim(),
                        )
                      }
                      return onChange(field, value.trim())
                    }}
                    step={type === FieldType.float ? '0.1' : '1'}
                    min={0}
                  />
                  <Text
                    variant="label"
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    {label}
                  </Text>
                </Row>
              )
            }

            if (type === FieldType.date) {
              return (
                <DatePicker
                  key={label}
                  date={currentValue}
                  setDate={(date: Date | null) => onChange(field, date)}
                  label={label}
                  showTimeFieldInPopover
                  granularity="minute"
                  isClearable={true}
                  width={280}
                />
              )
            }

            if (type === FieldType.select && fieldConfig.items) {
              return (
                <Select
                  key={label}
                  fullWidth
                  label={label}
                  menuItems={fieldConfig.items || []}
                  value={currentValue || ''}
                  handleSelect={(val) => onChange(field, val)}
                  showEmptyOption
                />
              )
            }

            if (type === FieldType.multiSelect) {
              return (
                <SearchSelect
                  options={
                    fieldConfig.items?.map((option) => ({
                      label: typeof option === 'string' ? option : option.label,
                      value: typeof option === 'string' ? option : option.value,
                    })) || []
                  }
                  selectedItems={
                    currentValue && currentValue.length > 0 ? currentValue : []
                  }
                  handleSelectMultiple={(items) => onChange(field, items)}
                  label={label}
                  onlyShowLabel
                  multiple
                />
              )
            }

            return null
          })}
        </InputsWrapper>
      ))}
    </>
  )
}
