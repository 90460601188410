import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Money } from '@traba/types'
import {
  PayoutMethod,
  PayoutStatus,
  TransactionDocumentType,
  TransactionType,
  TransferStatus,
} from '@traba/types'
import { WorkerIncentiveStatus } from '@traba/types'
import { WorkerEarning } from '@traba/types'
import { useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { trabaApi } from 'src/api/helpers'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import {
  TransactionIconWrapper,
  TransactionTypeTd,
} from 'src/screens/ShiftDetailsScreen/components/TransactionsTable/TransactionsTable.styles'
import { formatDateTimeWithTimezone, formatDuration } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { openInNewTab } from 'src/utils/helperUtils'
import {
  getChargedUnitsString,
  getMoneyString,
  getPayRateString,
} from 'src/utils/stringUtils'
import { Row, Table, Td, Tr, Col, Icon, Button } from '../base'
import { ButtonVariant } from '../base/Button/types'
import TransferStatusBadge from '../TransferStatusBadge'

type WorkerPaymentTableProps = {
  workerEarning: WorkerEarning
  totalPaid: number
  refetchWorkerEarnings: () => Promise<
    QueryObserverResult<WorkerEarning[] | WorkerEarning | undefined, Error>
  >
}

type AmountOwedRow = {
  date?: Date
  description: string
  notes: string
  amount: number
  totalCell?: JSX.Element
  total: number
}

type AmountPaidRow = {
  date: Date
  type: TransactionType | 'REVERSAL'
  status: TransferStatus | PayoutStatus
  transactionType: TransactionDocumentType
  link: string
  id: string
  amount: number
  workerIncentiveId?: string
  method?: PayoutMethod
  reversedTransferId?: string
  relatedTransferIds?: Array<string>
}

function getStripeUrl(
  transactionType: TransactionType | 'REVERSAL',
  acctId: string,
  transactionId: string,
) {
  if (transactionType === TransactionType.PAYOUT) {
    return `https://dashboard.stripe.com/${acctId}${
      import.meta.env.VITE_STRIPE_URL === 'https://dashboard.stripe.com'
        ? ''
        : '/test'
    }/payouts/${transactionId}`
  } else {
    return `${
      import.meta.env.VITE_STRIPE_URL || 'https://dashboard.stripe.com/test'
    }/connect/transfers/${transactionId}`
  }
}

function getCurrentTotal(rows: AmountOwedRow[], amount: number) {
  return rows.length > 0 ? rows[rows.length - 1].total + amount : amount
}

function getTotalCell(
  id: number,
  total: number,
  grossPay: Money,
  netPay: Money,
  totalTrustAndSafetyFee?: Money,
  instantPayFee?: Money,
) {
  return (
    <Td data-tooltip-id={`amountOwedToolTip${id}`}>
      <Text variant="h7" style={{ fontSize: 12 }}>
        {getMoneyString(total / 100)}
      </Text>
      <ReactTooltip
        place="top"
        id={`amountOwedToolTip${id}`}
        style={{ zIndex: 2 }}
      >
        <p style={{ lineHeight: '24px' }}>Gross: {getMoneyString(grossPay)}</p>
        {totalTrustAndSafetyFee && (
          <p style={{ lineHeight: '24px' }}>
            OAI: {getMoneyString(totalTrustAndSafetyFee)}
          </p>
        )}
        <p style={{ lineHeight: '24px' }}>Net: {getMoneyString(netPay)}</p>
        {instantPayFee && (
          <p style={{ lineHeight: '24px' }}>
            IP Fee: {getMoneyString(instantPayFee)}
          </p>
        )}
      </ReactTooltip>
    </Td>
  )
}

export const WorkerPaymentTable = (props: WorkerPaymentTableProps) => {
  const { workerEarning, totalPaid, refetchWorkerEarnings } = props

  const stripeAccess = usePermissions([UserRolePermission.StripeAccess])
  const revertPaymentAccess = usePermissions([
    UserRolePermission.RevertPayments,
  ])

  //Get stripe account Id either from the transactions on the worker earning or from the worker incentive
  const stripeAcctId =
    workerEarning.transactions && workerEarning.transactions.length
      ? workerEarning.transactions[0].stripeId
      : workerEarning.workerIncentives.length > 0 &&
          workerEarning.workerIncentives[0].transactions &&
          workerEarning.workerIncentives[0].transactions.length
        ? workerEarning.workerIncentives[0].transactions[0].stripeId
        : ''

  const amountOwedRows: AmountOwedRow[] = []
  const amountPaidRows: AmountPaidRow[] = []

  //************** Amount Owed *****************//
  /*
   * Shift Charge
   */
  if (workerEarning.grossPay && workerEarning.netPay) {
    const amount = workerEarning.netPay.amount
    const total = getCurrentTotal(amountOwedRows, amount)
    amountOwedRows.push({
      date: workerEarning.shiftInfo.endTime,
      description: 'Traba Shift Payment for Time Worked',
      notes: `${getPayRateString(
        workerEarning.shiftInfo.payType,
        workerEarning.shiftInfo.payRate,
      )} for ${formatDuration(workerEarning.timeWorked || 0)}`,
      amount,
      total,
      totalCell: getTotalCell(
        0,
        total,
        workerEarning.grossPay,
        workerEarning.netPay,
        workerEarning.totalTrustAndSafetyFee,
        workerEarning.instantPayFee,
      ),
    })
  }

  /*
   * Adjustments
   */
  if (workerEarning.adjustments && workerEarning.adjustments.length > 0) {
    const adjustments = workerEarning.adjustments
    for (let i = adjustments.length - 1; i >= 0; i--) {
      const adjustmentNumber = adjustments.length - i
      const amount =
        adjustments[i].netPay.amount -
        (i >= adjustments.length - 1
          ? (workerEarning.netPay?.amount ?? 0)
          : adjustments[i + 1].netPay.amount)
      const total = getCurrentTotal(amountOwedRows, amount)
      amountOwedRows.push({
        date: adjustments[i].createdAt,
        description: `Shift Adjustment ${adjustmentNumber}  - ${adjustments[i].adjustmentReason}`,
        notes: `${getPayRateString(
          adjustments[i].shiftInfo.payType,
          adjustments[i].shiftInfo.payRate,
        )} for ${getChargedUnitsString({
          payType: adjustments[i].shiftInfo.payType,
          timeWorkedInMinutes: adjustments[i].timeWorked,
          unitsWorked: adjustments[i].unitsWorked,
        })}`,
        amount,
        total,
        totalCell: getTotalCell(
          adjustmentNumber,
          total,
          adjustments[i].grossPay,
          adjustments[i].netPay,
          adjustments[i].totalTrustAndSafetyFee,
          adjustments[i].instantPayFee,
        ),
      })
    }
  }

  /*
   * Incentives
   */
  if (workerEarning.workerIncentives.length > 0) {
    for (const workerIncentive of workerEarning.workerIncentives) {
      if (
        workerIncentive.status === WorkerIncentiveStatus.Complete ||
        workerIncentive.status === WorkerIncentiveStatus.Reversed
      ) {
        const amount = workerIncentive.total?.amount || 0
        amountOwedRows.push({
          date: workerIncentive.earnedAt,
          description: workerIncentive.incentiveInfo.category,
          notes: `Worker Incentive Id: ${workerIncentive.id}`,
          amount,
          total: getCurrentTotal(amountOwedRows, amount),
        })
        if (workerIncentive.status === WorkerIncentiveStatus.Reversed) {
          const negAmount = -1 * amount
          amountOwedRows.push({
            description: `Reversed ${workerIncentive.incentiveInfo.category}`,
            notes: `Worker Incentive Id: ${workerIncentive.id}`,
            amount: negAmount,
            total: getCurrentTotal(amountOwedRows, negAmount),
          })
        }

        //Incentive Pay
        if (workerIncentive.transactions) {
          for (const transaction of workerIncentive.transactions) {
            amountPaidRows.push({
              date: transaction.created,
              type: transaction.type,
              status: transaction.reversed
                ? TransferStatus.REVERSED
                : transaction.status,
              transactionType: TransactionDocumentType.WORKER_INCENTIVE,
              workerIncentiveId: workerIncentive.id,
              link: getStripeUrl(
                transaction.type,
                stripeAcctId,
                transaction.id,
              ),
              id: transaction.id,
              amount: transaction.amount,
              ...(transaction.type === TransactionType.PAYOUT
                ? {
                    relatedTransferIds: transaction.relatedTransferIds,
                    method: transaction.method,
                  }
                : undefined),
            })
            if (transaction.reversals) {
              for (const reversal of transaction.reversals.data) {
                amountPaidRows.push({
                  date: reversal.created,
                  type: 'REVERSAL',
                  status: TransferStatus.SUCCEEDED,
                  transactionType: TransactionDocumentType.WORKER_INCENTIVE,
                  workerIncentiveId: workerIncentive.id,
                  link: getStripeUrl('REVERSAL', stripeAcctId, transaction.id),
                  id: reversal.id,
                  amount: -1 * reversal.amount,
                  reversedTransferId: transaction.id,
                })
              }
            }
          }
        }
      }
    }
  }

  //************** Amount Paid *****************//

  /*
   * Shift Transactions
   */
  if (workerEarning.transactions) {
    for (const transaction of workerEarning.transactions) {
      amountPaidRows.push({
        date: transaction.created,
        type: transaction.type,
        status: transaction.reversed
          ? TransferStatus.REVERSED
          : transaction.status,
        transactionType: TransactionDocumentType.WORKER_SHIFT,
        link: getStripeUrl(transaction.type, stripeAcctId, transaction.id),
        id: transaction.id,
        amount: transaction.amount,
        ...(transaction.type === TransactionType.PAYOUT
          ? {
              relatedTransferIds: transaction.relatedTransferIds,
              method: transaction.method,
            }
          : undefined),
      })
      if (transaction.reversals) {
        for (const reversal of transaction.reversals.data) {
          amountPaidRows.push({
            date: reversal.created,
            type: 'REVERSAL',
            status: TransferStatus.SUCCEEDED,
            transactionType: TransactionDocumentType.WORKER_SHIFT,
            link: getStripeUrl('REVERSAL', stripeAcctId, transaction.id),
            id: reversal.id,
            amount: -1 * reversal.amount,
            reversedTransferId: transaction.id,
          })
        }
      }
    }
  }

  /*
   * Sort paid rows
   */
  amountPaidRows.sort(function (a, b) {
    return +new Date(a.date) - +new Date(b.date)
  })

  /*
   * Reversal Logic
   */
  const { showError } = useAlert()
  const [loadingReversal, setLoadingReversal] = useState<boolean>(false)

  async function handleReverseTransfer(
    transferId: string,
    transactionType: TransactionDocumentType,
    workerIncentiveId?: string,
  ) {
    setLoadingReversal(true)
    try {
      const isConfirmed = window.confirm(`Confirm reversal of ${transferId}?`)
      if (!isConfirmed) {
        setLoadingReversal(false)
        return
      }

      await trabaApi.post(`/stripe/transfers/${transferId}/reversals`, {
        uniqueId: `${transferId}_reversal`,
        documentType: transactionType,
        workerId: workerEarning.workerId,
        shiftId: workerEarning.shiftId,
        ...(transactionType === TransactionDocumentType.WORKER_INCENTIVE && {
          workerIncentiveId: workerIncentiveId,
        }),
      })
      refetchWorkerEarnings()
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Reversing Transfer')
    }
    setLoadingReversal(false)
    await refetchWorkerEarnings()
  }

  return (
    <Row>
      <Col mt={theme.space.xs} mr={theme.space.xs}>
        <Text variant="h5">Owed</Text>
        <Row mt={theme.space.xs}>
          <Table
            headers={[
              'Date',
              'Description',
              'Notes',
              'Item Amount',
              'Total Owed',
            ]}
          >
            {amountOwedRows.map((row) => {
              return (
                <Tr>
                  <Td>
                    <Text variant="caption">
                      {row.date
                        ? formatDateTimeWithTimezone(
                            row.date,
                            workerEarning.shiftInfo.timezone,
                          )
                        : ''}
                    </Text>
                  </Td>
                  <Td>
                    <Text variant="caption">{row.description}</Text>
                  </Td>
                  <Td>
                    <Text variant="caption">{row.notes}</Text>
                  </Td>
                  <Td>
                    <Text
                      variant="h7"
                      style={{
                        fontSize: 12,
                        color:
                          row.amount >= 0
                            ? theme.colors.Green70
                            : theme.colors.Red70,
                      }}
                    >
                      {row.amount >= 0 ? '+ ' : '- '}
                      {getMoneyString(Math.abs(row.amount) / 100)}
                    </Text>
                  </Td>
                  {row.totalCell ? (
                    row.totalCell
                  ) : (
                    <Td>
                      <Text variant="h7" style={{ fontSize: 12 }}>
                        {getMoneyString(row.total / 100)}
                      </Text>
                    </Td>
                  )}
                </Tr>
              )
            })}
            <Tr>
              <Td>
                <Text variant="h5">TOTAL</Text>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>
                <Text variant="h5">
                  {getMoneyString(getCurrentTotal(amountOwedRows, 0) / 100)}
                </Text>
              </Td>
            </Tr>
          </Table>
        </Row>
      </Col>
      <Col mt={theme.space.xs}>
        <Text variant="h5">Paid</Text>
        <Row mt={theme.space.xs}>
          <Table
            headers={[
              'Date',
              'Type',
              'Id',
              'Paid For',
              'Status',
              'Stripe',
              'Item Amount',
              '',
            ]}
          >
            {amountPaidRows.map((row) => {
              return (
                <>
                  <Tr>
                    <Td>
                      <Text variant="caption">
                        {formatDateTimeWithTimezone(
                          row.date,
                          workerEarning.shiftInfo.timezone,
                        )}
                      </Text>
                    </Td>
                    <TransactionTypeTd>
                      <TransactionIconWrapper>
                        {row.type === TransactionType.TRANSFER
                          ? '💸'
                          : row.type === 'REVERSAL'
                            ? '↩️'
                            : '🏦'}
                      </TransactionIconWrapper>
                      <Text variant="caption">{row.type.toLowerCase()}</Text>
                    </TransactionTypeTd>
                    <Td>
                      <Text variant="caption">{row.id}</Text>
                    </Td>
                    <Td>
                      <Text variant="caption">
                        {row.transactionType ===
                        TransactionDocumentType.WORKER_INCENTIVE
                          ? `Incentive: ${row.workerIncentiveId}`
                          : 'Shift'}
                      </Text>
                    </Td>
                    <Td>
                      <Row alignCenter>
                        <TransferStatusBadge
                          transferStatus={
                            row.status === TransferStatus.REVERSED
                              ? TransferStatus.REVERSED
                              : row.status
                          }
                        />
                        {row.method &&
                          row.method === PayoutMethod.INSTANT_PAYOUT && (
                            <Text
                              variant="caption"
                              style={{
                                marginLeft: theme.space.xxxs,
                                backgroundColor: theme.colors.Grey20,
                                padding: theme.space.xxxs,
                                borderRadius: theme.space.xxs,
                              }}
                            >
                              INSTANT
                            </Text>
                          )}
                      </Row>
                    </Td>
                    <Td>
                      <Text variant="caption">
                        {stripeAccess ? (
                          <Icon
                            name="link"
                            style={{ cursor: 'pointer' }}
                            onClick={() => openInNewTab(row.link)}
                          />
                        ) : (
                          <Icon name="link" />
                        )}
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        variant="h7"
                        style={{
                          fontSize: 12,
                          color:
                            row.type !== TransactionType.PAYOUT
                              ? row.amount >= 0
                                ? theme.colors.Green70
                                : theme.colors.Red70
                              : theme.colors.TrabaBlue,
                        }}
                      >
                        {row.type !== TransactionType.PAYOUT
                          ? row.amount >= 0
                            ? '+ '
                            : '- '
                          : ''}
                        {getMoneyString(Math.abs(row.amount) / 100)}
                      </Text>
                    </Td>
                    <Td>
                      {row.type === TransactionType.TRANSFER &&
                        row.status !== TransferStatus.REVERSED && (
                          <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={() =>
                              handleReverseTransfer(
                                row.id,
                                row.transactionType,
                                row.workerIncentiveId,
                              )
                            }
                            style={{ padding: 8 }}
                            loading={loadingReversal}
                            disabled={!revertPaymentAccess}
                          >
                            <span role="img" aria-label="reverse">
                              ↩️
                            </span>
                          </Button>
                        )}
                    </Td>
                  </Tr>
                  {(row.relatedTransferIds || row.reversedTransferId) && (
                    <Tr>
                      <Td colSpan={8}>
                        <RelatedTransferRows
                          acctId={stripeAcctId}
                          relatedTransferIds={
                            row.reversedTransferId
                              ? [row.reversedTransferId]
                              : row.relatedTransferIds || []
                          }
                        />
                      </Td>
                    </Tr>
                  )}
                </>
              )
            })}
            <Tr>
              <Td>
                <Text variant="h5">TOTAL PAID</Text>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>

              <Td>
                <Text variant="h5">{getMoneyString(totalPaid / 100)}</Text>
              </Td>
            </Tr>
          </Table>
        </Row>
      </Col>
    </Row>
  )
}

type RelatedTransferRowsProps = {
  acctId: string
  relatedTransferIds: Array<string>
}

const RelatedTransferRows = (props: RelatedTransferRowsProps) => {
  const { relatedTransferIds, acctId } = props
  return (
    <Col
      style={{
        display: 'flex',
        width: '100%',
        padding: theme.space.xs,
        borderRadius: theme.space.xs,
        backgroundColor: theme.colors.Violet10,
        margin: '8px 8px 8px 8px',
      }}
    >
      <Text
        variant="caption"
        style={{
          color: theme.colors.MidnightBlue,
        }}
      >
        RELATED TRANSFERS:
      </Text>
      {relatedTransferIds.map((trId) => {
        return (
          <Row>
            <Text variant="caption" style={{ marginLeft: theme.space.med }}>
              {trId}
            </Text>
            <Icon
              name="link"
              style={{ cursor: 'pointer', marginLeft: theme.space.xxs }}
              onClick={() =>
                openInNewTab(
                  getStripeUrl(TransactionType.TRANSFER, acctId, trId),
                )
              }
            />
          </Row>
        )
      })}
    </Col>
  )
}
