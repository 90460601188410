import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftAsTimesheetRow } from '@traba/types'
import { Dispatch, useMemo, useState } from 'react'
import { Button, Col, DataTable, Icon, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { IMenuItem } from 'src/components/base/Select/Select'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { JobStatus } from 'src/types'
import { useAddWorkersByShift } from '../hooks'
import { TableRow } from '../timesheet.types'

const headers = [
  { label: 'Worker Name', sortable: true },
  'Shift Name',
  'Clock In Date',
  'Clock In Time',
  'Clock Out Date',
  'Clock Out Time',
  'Break Time',
  'Pay Rate',
  'Units Worked',
  'Job Status',
  'ShiftId',
  'WorkerId',
]

function isAcceptedValue(value: string | number): boolean {
  if (value === 0) {
    return true
  }
  return Boolean(value)
}
function setNewWorkerValues(
  oldData: WorkerShiftAsTimesheetRow,
  newData: TableRow,
): WorkerShiftAsTimesheetRow {
  const updatedData = { ...oldData }

  updatedData.workerId = newData.workerId.parsedWorkerShiftValue as string
  updatedData.shiftId = newData.shiftId.parsedWorkerShiftValue as string
  updatedData.jobStatus =
    (newData.jobStatus.parsedWorkerShiftValue as string) || JobStatus.ToDo

  if (
    updatedData.payRate !== newData.payRate.parsedWorkerShiftValue &&
    isAcceptedValue(newData.payRate.parsedWorkerShiftValue)
  ) {
    updatedData.payRate = Number(newData.payRate.parsedWorkerShiftValue)
  }

  if (
    updatedData.breakTime !== newData.breakTime.parsedWorkerShiftValue &&
    isAcceptedValue(newData.breakTime.parsedWorkerShiftValue)
  ) {
    updatedData.breakTime = Number(newData.breakTime.parsedWorkerShiftValue)
  }

  if (
    updatedData.unitsWorked !== newData.unitsWorked.parsedWorkerShiftValue &&
    isAcceptedValue(newData.unitsWorked.parsedWorkerShiftValue)
  ) {
    updatedData.unitsWorked = Number(newData.unitsWorked.parsedWorkerShiftValue)
  }

  return updatedData
}

export default function NewWorkersTable({
  tableRows,
  shiftMenuItems,
  refetchWorkerShifts,
  setParsedData,
}: {
  tableRows: TableRow[]
  shiftMenuItems: IMenuItem[]
  refetchWorkerShifts: () => void
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
}) {
  const [checkedWorkers, setCheckedWorkers] = useState<PopulatedWorker[]>([])
  const [newWorkerShiftUpdates, setNewWorkerShiftUpdates] =
    useState<TableRow[]>(tableRows)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { handleAddWorkers } = useAddWorkersByShift({
    refetchWorkerShifts,
    setIsLoading,
  })

  const updateNewWorkerShifts = (
    rowIndex: number,
    updates: { shiftId?: string; workerId?: string; shiftName?: string },
  ) => {
    const { shiftId, workerId, shiftName } = updates
    setNewWorkerShiftUpdates((prevRows) => {
      const newRows = [...prevRows]
      if (shiftId) {
        newRows[rowIndex].shiftId.parsedWorkerShiftValue = shiftId
      }
      if (workerId) {
        newRows[rowIndex].workerId.parsedWorkerShiftValue = workerId
      }
      if (shiftName) {
        newRows[rowIndex].shiftName.parsedWorkerShiftValue = shiftName
      }
      return newRows
    })
  }

  const clearNewWorkerShiftUpdate = (
    rowIndex: number,
    updates: { shiftId?: boolean; workerId?: boolean },
  ) => {
    const { shiftId, workerId } = updates
    setNewWorkerShiftUpdates((prevRows) => {
      const newRows = [...prevRows]
      if (shiftId) {
        newRows[rowIndex].shiftId.parsedWorkerShiftValue = ''
      }
      if (workerId) {
        newRows[rowIndex].workerId.parsedWorkerShiftValue = ''
      }
      return newRows
    })
  }

  const updateParsedDataWithNewWorkers = () => {
    setParsedData((prevData) => {
      const updatedData = [...prevData]
      newWorkerShiftUpdates.forEach((updatedRow) => {
        // Find the index of the updated row in the previous data
        const index = prevData.findIndex(
          (prevRow) =>
            prevRow.workerName ===
              updatedRow.workerName.parsedWorkerShiftValue &&
            prevRow.clockInDate ===
              updatedRow.clockInDate.parsedWorkerShiftValue &&
            prevRow.clockOutDate ===
              updatedRow.clockOutDate.parsedWorkerShiftValue,
        )
        // If the updated row exists in the previous data, replace it
        if (index !== -1) {
          updatedData[index] = setNewWorkerValues(
            updatedData[index],
            updatedRow,
          )
        }
      })
      return updatedData
    })
  }

  const groupWorkerIdsByShiftId = () => {
    const groupedWorkers = newWorkerShiftUpdates.reduce<
      Record<string, string[]>
    >((acc, row) => {
      const shiftId = row.shiftId.parsedWorkerShiftValue as string
      const workerId = row.workerId.parsedWorkerShiftValue as string

      if (shiftId) {
        if (!acc[shiftId]) {
          acc[shiftId] = []
        }
        if (workerId) {
          acc[shiftId].push(workerId)
        }
      }
      return acc
    }, {})

    return groupedWorkers
  }

  const rows = useMemo(() => {
    return newWorkerShiftUpdates.map((row, index) => {
      return {
        key: index.toString(),
        cells: [
          {
            sortKey: row.workerName.parsedWorkerShiftValue,
            renderFn: () => (
              <Row justifyCenter>{row.workerName.parsedWorkerShiftValue}</Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter>{row.shiftName.parsedWorkerShiftValue}</Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.clockInDate.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.clockInTime.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.clockOutDate.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.clockOutTime.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.breakTime.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.payRate.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.unitsWorked.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.jobStatus.parsedWorkerShiftValue}
              </Row>
            ),
          },
          {
            renderFn: () => (
              <Row justifyCenter alignCenter>
                {row.shiftId.parsedWorkerShiftValue ? (
                  <Button
                    variant={ButtonVariant.TEXT}
                    rightIcon={<Icon name="edit_active" />}
                    style={{
                      padding: theme.space.xxs,
                      height: theme.space.sm,
                      marginLeft: theme.space.xxs,
                    }}
                    onClick={() =>
                      clearNewWorkerShiftUpdate(index, { shiftId: true })
                    }
                  >
                    {row.shiftId.parsedWorkerShiftValue}
                  </Button>
                ) : (
                  <Select
                    fullWidth
                    label="Shifts"
                    menuItems={shiftMenuItems}
                    value={''}
                    handleSelect={(shiftId) => {
                      const shiftName = shiftMenuItems.find(
                        ({ value }) => value === shiftId,
                      )?.label
                      updateNewWorkerShifts(index, { shiftId, shiftName })
                    }}
                  />
                )}
              </Row>
            ),
          },
          {
            renderFn: () => {
              return (
                <Row justifyCenter alignCenter>
                  {row.workerId.parsedWorkerShiftValue ? (
                    <Button
                      variant={ButtonVariant.TEXT}
                      rightIcon={<Icon name="edit_active" />}
                      style={{
                        padding: theme.space.xxs,
                        height: theme.space.sm,
                        marginLeft: theme.space.xxs,
                      }}
                      onClick={() =>
                        clearNewWorkerShiftUpdate(index, { workerId: true })
                      }
                    >
                      {row.workerId.parsedWorkerShiftValue}
                    </Button>
                  ) : row.shiftId.parsedWorkerShiftValue ? (
                    <SearchWorkers
                      selectOneOnly
                      hideInstructionText
                      checkedWorkers={checkedWorkers}
                      setCheckedWorkers={setCheckedWorkers}
                      actionButton={
                        <Button
                          onClick={() => {
                            const worker = checkedWorkers[0]
                            updateNewWorkerShifts(index, {
                              workerId:
                                worker.id || worker.workerId || worker.uid,
                            })
                            setCheckedWorkers([])
                          }}
                        >
                          Add Worker
                        </Button>
                      }
                    />
                  ) : (
                    <Text>-</Text>
                  )}
                </Row>
              )
            },
          },
        ],
      }
    })
  }, [newWorkerShiftUpdates, checkedWorkers, shiftMenuItems])

  return (
    <Col my={theme.space.lg}>
      <Row alignEnd my={theme.space.xs}>
        <Button
          loading={isLoading}
          onClick={() => {
            const groupedWorkers = groupWorkerIdsByShiftId()
            handleAddWorkers(groupedWorkers)
            updateParsedDataWithNewWorkers()
          }}
        >
          Add Workers to Shifts
        </Button>
      </Row>
      {/* TODO(gavin): make these selectable to mass-add shiftIds to shifts */}
      <DataTable headers={headers} rows={rows} />
    </Col>
  )
}
