import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Dialog, MODAL_SIZE, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequest } from '@traba/types'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { useRosters } from 'src/hooks/useRosters'
import { useScheduleInvitations } from 'src/hooks/useScheduleInvitations'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import { useWorkersWithDetails } from 'src/hooks/useWorkers'
import { SearchAddWorkersView } from 'src/screens/PostShiftScreen/components/SearchInviteWorkers/SearchAddWorkersView'
import { SelectRostersView } from 'src/screens/PostShiftScreen/components/SearchInviteWorkers/SelectRostersView'
import {
  createRosterMap,
  getRostersInOrder,
  getUniqueWorkerIds,
} from 'src/screens/PostShiftScreen/components/SearchInviteWorkers/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { Row } from '../base'
import Checkbox from '../base/Checkbox'

interface ScheduleInvitationModalProps {
  shiftRequestParentId: string
  companyId: string
  shiftRequests: ShiftRequest[] | undefined
  showInvitationModal: boolean
  setShowInvitationModal: Dispatch<SetStateAction<boolean>>
}

enum INVITE_STEP {
  SELECT_ROLES = 0,
  SELECT_ROSTERS = 1,
  SELECT_WORKERS = 2,
}

export const ScheduleDetailsInvitesModal = ({
  shiftRequests,
  showInvitationModal,
  setShowInvitationModal,
  shiftRequestParentId,
  companyId,
}: ScheduleInvitationModalProps) => {
  const [selectShiftRequestIds, setSelectShiftRequestIds] = useState(
    new Set<string>(),
  )
  const [isConfirming, setIsConfirming] = useState(false)
  const [currStep, setCurrentStep] = useState<INVITE_STEP>(
    INVITE_STEP.SELECT_ROLES,
  )
  const { showSuccess, showError } = useAlert()
  const [selectedRosters, setSelectedRosters] = useState<Set<string>>(new Set())
  const [
    selectedRosterIdToSelectedWorkers,
    setSelectedRosterIdToSelectedWorkers,
  ] = useState<Record<string, Record<string, PopulatedWorker>>>({})
  const [
    selectedWorkerIdsFromGeneralPool,
    setSelectedWorkerIdsFromGeneralPool,
  ] = useState<PopulatedWorker[]>([])
  const { refetch } = useScheduleInvitations(shiftRequestParentId)
  const { rosters: createdRosters } = useRosters(companyId)
  const { virtualRosters } = useVirtualRosters(companyId)
  const rosters = useMemo(
    () => virtualRosters.concat(createdRosters),
    [createdRosters, virtualRosters],
  )

  const { workersWithDetails: allWorkers, isLoading: isLoadingAllWorkers } =
    useWorkersWithDetails(getUniqueWorkerIds(rosters))

  const rosterMap = useMemo(() => {
    if (allWorkers && allWorkers?.length && !isLoadingAllWorkers) {
      return createRosterMap(rosters, allWorkers)
    }
    return {}
  }, [allWorkers, isLoadingAllWorkers, rosters])

  const onClickShiftRequest = (shiftRequestId: string) => {
    const newShiftRequestIds = new Set(selectShiftRequestIds)
    if (selectShiftRequestIds.has(shiftRequestId)) {
      newShiftRequestIds.delete(shiftRequestId)
    } else {
      newShiftRequestIds.add(shiftRequestId)
    }
    setSelectShiftRequestIds(newShiftRequestIds)
  }

  const onClose = () => {
    clearStates()
    setShowInvitationModal(false)
  }

  const clearStates = () => {
    setCurrentStep(INVITE_STEP.SELECT_ROLES)
    setSelectedRosters(new Set())
    setSelectedRosterIdToSelectedWorkers({})
    setSelectedWorkerIdsFromGeneralPool([])
  }

  const onConfirm = async () => {
    if (currStep === INVITE_STEP.SELECT_ROLES) {
      setCurrentStep(INVITE_STEP.SELECT_ROSTERS)
    } else if (currStep === INVITE_STEP.SELECT_ROSTERS) {
      setSelectedRosterIdToSelectedWorkers(
        Object.fromEntries(
          Array.from(selectedRosters).map((key) => [
            key,
            Object.fromEntries(
              rosterMap[key].workers
                .filter((worker) => !!worker.id)
                .map((worker) => [worker.id || worker.uid, worker]),
            ),
          ]),
        ),
      )
      setCurrentStep(INVITE_STEP.SELECT_WORKERS)
    } else if (currStep === INVITE_STEP.SELECT_WORKERS) {
      setIsConfirming(true)
      // Use a set to ensure no duplicate workers
      const allWorkerIds: Set<string> = new Set()

      // Gather all workers from the selected rosters
      Object.values(selectedRosterIdToSelectedWorkers).forEach(
        (workerIdToWorker) => {
          Object.values(workerIdToWorker).forEach((populatedWorker) => {
            if (!populatedWorker.id || allWorkerIds.has(populatedWorker.id)) {
              return
            }
            allWorkerIds.add(populatedWorker.id)
          })
        },
      )

      // Gather all workers selected from the general pool
      selectedWorkerIdsFromGeneralPool.reduce((allWorkerIds, worker) => {
        if (worker.id && !allWorkerIds.has(worker.id)) {
          allWorkerIds.add(worker.id)
        }
        return allWorkerIds
      }, allWorkerIds)

      try {
        await Promise.all(
          Array.from(selectShiftRequestIds).map((shiftRequestId) =>
            trabaApi.post(
              `companies/${companyId}/shift-request/${shiftRequestId}/invitations`,
              {
                workerIds: Array.from(allWorkerIds),
              },
            ),
          ),
        )

        setIsConfirming(false)
      } catch {
        showError('Something went wrong, please try again')
      } finally {
        refetch()
        showSuccess('Invitations sent successfully')
        onClose()
      }
    }
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      open={showInvitationModal}
      onClose={onClose}
      onConfirmCTA={
        currStep === INVITE_STEP.SELECT_WORKERS
          ? 'Confirm & Invite Worker'
          : 'Next'
      }
      dialogTitle={'Invite workers to this schedule '}
      confirmDisabled={selectShiftRequestIds.size === 0}
      formId="invite-worker"
      size={MODAL_SIZE.LARGE}
      onConfirm={onConfirm}
      confirming={isConfirming}
    >
      {currStep === INVITE_STEP.SELECT_ROLES && (
        <>
          <Text variant="h5" mb={theme.space.sm} mt={theme.space.xs}>
            Which roles you want to invite workers for?
          </Text>
          {shiftRequests?.map((sr) => (
            <Row
              style={{
                borderRadius: 10,
                border: `1px solid ${theme.colors.Grey20}`,
                backgroundColor: selectShiftRequestIds.has(sr.shiftRequestId)
                  ? theme.colors.Grey10
                  : theme.colors.White,
                padding: theme.space.sm,
              }}
              gap={theme.space.sm}
              mb={theme.space.sm}
            >
              <Checkbox
                checked={selectShiftRequestIds.has(sr.shiftRequestId)}
                onChange={() => onClickShiftRequest(sr.shiftRequestId)}
              />
              <Text>{sr.shiftRole}</Text>
            </Row>
          ))}
        </>
      )}
      {currStep === INVITE_STEP.SELECT_ROSTERS &&
        Object.keys(rosterMap).length > 0 && (
          <SelectRostersView
            rosters={getRostersInOrder(rosterMap)}
            selectedRosters={selectedRosters}
            setSelectedRosters={setSelectedRosters}
          />
        )}

      {currStep === INVITE_STEP.SELECT_WORKERS && (
        <SearchAddWorkersView
          rosters={getRostersInOrder(rosterMap).filter((rosterInfo) =>
            selectedRosters.has(rosterInfo.id),
          )}
          rosterMap={rosterMap}
          selectedRosterIdToSelectedWorkers={selectedRosterIdToSelectedWorkers}
          setSelectedRosterIdToSelectedWorkers={
            setSelectedRosterIdToSelectedWorkers
          }
          selectedWorkerIdsFromGeneralPool={selectedWorkerIdsFromGeneralPool}
          setSelectedWorkerIdsFromGeneralPool={
            setSelectedWorkerIdsFromGeneralPool
          }
        />
      )}
    </Dialog>
  )
}
