import { AccountApprovalStatus } from './account-status'
import { TestAccountType } from './base'
import { RecurringWorkerShiftWithSchedules } from './recurring-worker-shift'
import { TierLevel } from './worker-tiers'
export interface Worker {
  // Note: use uid for the id of worker, workerId has been deprecated and don't use it
  uid: string
  id?: string
  workerId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  zipCode: string
  password?: string
  photoUrl?: string
  regionId: string
  stripeAccountId?: string
  testAccountType?: TestAccountType
  referredBy?: string
  referralCode: string
  acquisitionSource?: string
  createdAt: Date
  zipCodeGeohash: string
  languages: string[]
  accessToVehicle?: boolean
  workPreferences?: WorkPreferences
  lastActiveAt?: Date
  reportedGender?: WorkerGender
  opsReportedGender?: WorkerGender
  isProfilePhotoValid?: boolean
}

export enum HoursPerWeek {
  MoreThanThirty = 'MORE_THAN_THIRTY',
  TwentyToThirty = 'TWENTY_TO_THIRTY',
  LessThanTwenty = 'LESS_THAN_TWENTY',
  NotSure = 'NOT_SURE',
}

export enum ShiftConsistency {
  OneOff = 'ONE_OFF',
  Consistent = 'CONSISTENT',
}

export enum Transportation {
  DRIVE = 'DRIVE',
  PUBLIC = 'PUBLIC',
  CARPOOL = 'CARPOOL',
  SHARE = 'SHARE',
}

export enum WorkerGender {
  Male = 'MALE',
  Female = 'FEMALE',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Other = 'OTHER',
}

export type WorkPreferences = {
  hoursPerWeek?: HoursPerWeek
  shiftConsistency?: ShiftConsistency
  transportation?: Transportation
}

export type RecurringEligibilityCriteria = {
  recurringSlotAvailable: boolean
  workerIsAvailable: boolean
  workerOnRecurringShift?: RecurringWorkerShiftWithSchedules
  conflictingRecurringShiftIds: {
    shiftRequestId: string
    originalStartTime: Date
  }[]
  conflictingShiftIds: string[]
}

export type WorkerShiftEligibility = {
  workerId: string
  shiftId: string
  reliability: number
  experienceLevel: ExperienceLevel
  workerTierLevel: TierLevel
  eligibilityCriteria: EligibilityCriteria
  conflictingShiftIds?: string[]
  workerAlreadySignedUpForShift: boolean
  workerAlreadySignedUpForPaidBackup: boolean
  recurringEligibilityCriteria?: RecurringEligibilityCriteria
  //TODO Add Improvement Actions if needed in the future
}

export enum ExperienceLevel {
  Unproven = 'UNPROVEN',
  Proven = 'PROVEN',
}

export type GenericEligibilityCriteria = Record<string, boolean>

export type EligibilityCriteria = {
  readonly shiftStatusOk: boolean
  readonly shiftAllowsSignups: boolean
  readonly accountStatusOk: boolean
  readonly workerIsCertified: boolean
  readonly workerHasAttributes: boolean
  readonly workerHasNoRevokedAttributes: boolean
  readonly shiftIsNotFull: boolean
  readonly workerIsNotBlocked: boolean
  readonly workerIsNotIneligible: boolean
  readonly workerPassesFavoritesOnly: boolean
  readonly workerIsAvailable: boolean
  readonly workerPassesResumeUploadRequired: boolean
  readonly workerPassesRegion: boolean
  readonly workerPassesFavoritesFirst: boolean
  readonly companyIsApproved: boolean
  readonly workerPassesUnprovenThreshold: boolean
  readonly workerPassesMinimumAgeRequirement: boolean
  readonly workerPassesUnprovenTodoLimit: boolean
  readonly workerPassesAcceptedTier: boolean
  readonly workerPassesExtraBGCRequirement: boolean
  readonly workerPassesForwardFill: boolean
  readonly workerPassesInvitedOnly: boolean
  readonly workerPassesInvitedFirst: boolean
  readonly shiftPassesNoBackfill: boolean
  readonly workerShiftIsNotBizCancelled: boolean
  readonly workerShiftIsNotOpsCancelled: boolean
}

export type BasicOpsWorkerDetails = {
  id: string
  firstName: string
  lastName: string
  phoneNumber: string
  photoUrl?: string
  zipCode?: string
  accountApprovalStatus?: AccountApprovalStatus
  tierLevel?: TierLevel
  completedShifts?: number
  transportation?: Transportation
  isFirstTimeWorkerShiftWithCompany?: boolean
}
