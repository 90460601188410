import {
  AttributeBaseLevels,
  AttributeSkillLevels,
  AttributeStarLevels,
  OpsAttributeLevel,
  ROLE_ATTRIBUTE_STAR_RATING_TYPE,
  WorkerProfile,
} from '@traba/types'

export function isAttributeLevelUnreported(
  attributeLevel: OpsAttributeLevel,
): boolean {
  return (
    attributeLevel === AttributeBaseLevels.UNREPORTED ||
    attributeLevel === AttributeSkillLevels.UNREPORTED
  )
}

export function isAttributeLevelRevoked(
  attributeLevel: OpsAttributeLevel,
): boolean {
  return (
    attributeLevel === AttributeBaseLevels.REVOKED ||
    attributeLevel === AttributeSkillLevels.REVOKED
  )
}

export function getLevelNumberFromStarAttributeLevel(
  level: AttributeStarLevels | undefined,
) {
  if (!level) {
    return 0
  }
  switch (level) {
    case AttributeStarLevels.ONE_STAR:
      return 1
    case AttributeStarLevels.TWO_STAR:
      return 2
    case AttributeStarLevels.THREE_STAR:
      return 3
    case AttributeStarLevels.FOUR_STAR:
      return 4
    case AttributeStarLevels.FIVE_STAR:
      return 5
    default:
      return 0
  }
}

export function getStarAttributeLevelFromNumber(
  number: number,
): AttributeStarLevels | undefined {
  switch (number) {
    case 1:
      return AttributeStarLevels.ONE_STAR
    case 2:
      return AttributeStarLevels.TWO_STAR
    case 3:
      return AttributeStarLevels.THREE_STAR
    case 4:
      return AttributeStarLevels.FOUR_STAR
    case 5:
      return AttributeStarLevels.FIVE_STAR
    default:
      return undefined
  }
}

export function getStarRatingFromWorkerProfileAttributes(
  workerProfileAttributes: WorkerProfile[] | undefined,
): AttributeStarLevels | undefined {
  return workerProfileAttributes?.find(
    (workerAttribute) =>
      workerAttribute.profileField === ROLE_ATTRIBUTE_STAR_RATING_TYPE,
  )?.opsLevel as AttributeStarLevels
}
