import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import { useMemo, useState } from 'react'
import { Button, Row } from 'src/components/base'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useShiftTags } from 'src/hooks/useShiftTags'
import { getErrorMessage } from 'src/utils/errorUtils'

interface TagSelectorProps {
  curTags: string[]
  onSave: (tags: string[]) => Promise<void>
}

export const TagSelector = (props: TagSelectorProps) => {
  const { curTags, onSave } = props
  const { showError } = useAlert()
  const { shiftTagMenuItems } = useShiftTags()
  const { refetchActiveQueries } = useActiveQueries()

  const curTagMenuItems: IMenuItem[] = curTags.map((tag) => {
    return { label: tag, value: tag }
  })

  const [tags, setTags] = useState<IMenuItem[]>(curTagMenuItems)
  const [loading, setLoading] = useState<boolean>(false)

  const noChangesMade = useMemo(
    () =>
      JSON.stringify(curTagMenuItems.sort()) === JSON.stringify(tags.sort()),
    [curTagMenuItems, tags],
  )

  async function handleTagsSave(newTags: IMenuItem[]) {
    try {
      setLoading(true)
      const tagsToSave = newTags.map((tag) => tag.value)
      await onSave(tagsToSave)
      refetchActiveQueries()
    } catch (err) {
      showError(getErrorMessage(err), 'Error Updating Tags')
    } finally {
      setLoading(false)
    }
  }
  return (
    <Row alignCenter gap={theme.space.xs}>
      <SearchSelect
        style={{ maxWidth: '200px' }}
        multiple
        options={shiftTagMenuItems}
        selectedItems={tags}
        handleSelectMultiple={setTags}
        label="Tags"
        onlyShowLabel
      />

      <Button
        loading={loading}
        disabled={noChangesMade}
        onClick={() => handleTagsSave(tags)}
      >
        Save
      </Button>
    </Row>
  )
}
