import { Money } from './money'
import { WorkerCertificationType } from './worker-certification'

export enum ValueType {
  Cash = 'CASH',
  HourlyRate = 'HOURLY_RATE',
  Rate = 'RATE',
  Voucher = 'VOUCHER',
}

export enum SourceType {
  Traba = 'TRABA',
  Company = 'COMPANY',
}

export enum TargetType {
  Worker = 'WORKER',
  Company = 'COMPANY',
}

export enum IncentiveRules {
  ShiftsCompletionRequired = 'shifts_completion_count',
  HasCompletedIncentive = 'has_completed_incentive',
  HasCompletedShift = 'has_completed_shift',
  NewAccount = 'new_account',
  WorkerReferral = 'worker_referral',
  CompanyExclusive = 'company_exclusive',
  ProgressOnly = 'progress_only',
  LifeTimeShiftCompletions = 'lifetime_shift_completions',
  CertificationRequired = 'certification_required',
  ShiftGroup = 'shift_group',
}

export type RuleLine = {
  type: IncentiveRules
  count?: number
  field?: string
  incentiveId?: string
  shiftId?: string
  companyId?: string
  regionIds?: string[]
  certificationType?: WorkerCertificationType
  minimumCompletions?: number
  maximumCompletions?: number
  shiftIds?: string[]
}

export type ProgressLine = {
  type: IncentiveRules
  count?: number
  target?: number
}

/**
 * ACTIVE: In between start and end times and can be earned
 * INACTIVE: In between start and end time but disabled for some reason or Before start time
 * EXPIRED: Past end time
 */
export enum IncentiveStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Expired = 'EXPIRED',
}

export interface Incentive {
  id?: string
  incentiveId: string
  shiftId?: string
  createdAt?: Date
  updatedAt?: Date
  status: IncentiveStatus
  title: string
  description: string
  internalMemo: string
  valueType: ValueType
  total?: Money
  totalAmount?: number
  totalCurrency?: string
  rate?: number
  category: string
  categoryId: string
  type: string
  typeId: IncentiveTypeIds
  campaignId: string
  companyId: string
  source: SourceType
  target: TargetType
  rules: RuleLine[]
  startTime: Date
  endTime: Date
  isFeatured?: boolean
  regionIds: string[]
}

export interface IncentiveInfo {
  createdAt?: Date
  title: string
  description: string
  valueType: ValueType
  total?: Money
  rate?: number
  source: SourceType
  target: TargetType
  type: string
  typeId: IncentiveTypeIds
  category: string
  categoryId: string
  campaignId: string
  shiftId?: string
  startTime: Date
  endTime: Date
  rules: RuleLine[]
}

export enum IncentiveTypeIds {
  dXgY = 'DXGY', // dXgY

  appeasement = 'APPEASEMENT', // appeasement
  app_leg = 'APP_LEG', // appeasement_legacy

  engagement = 'ENGAGEMENT',

  shift_hrly = 'SHIFT_HRLY', // shift_hourlyIncrease
  shift_cash = 'SHIFT_CASH', // shift_cashBonus
}

export function incentiveTypeFromTypeId(typeId: IncentiveTypeIds): string {
  switch (typeId) {
    case IncentiveTypeIds.dXgY:
      return 'do_x_get_y'
    case IncentiveTypeIds.appeasement:
      return 'appeasement'
    case IncentiveTypeIds.app_leg:
      return 'appeasement_legacy'
    case IncentiveTypeIds.engagement:
      return 'engagement'
    case IncentiveTypeIds.shift_hrly:
      return 'shift_hourly_bonus'
    case IncentiveTypeIds.shift_cash:
      return 'shift_cash_bonus'
    default:
      return 'unknown'
  }
}

export type ShiftIncentivesResponse = {
  incentives: Incentive[]
}
