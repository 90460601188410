import { InputAdornment, TextField } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AdjustmentLabel,
  BreakType,
  HandleAdjustmentParams,
  ShiftPayType,
  ShiftTime,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { getBreaksForWorkerShift } from '@traba/utils'
import { head } from 'fp-ts/lib/Array'
import { Option, match, none, some } from 'fp-ts/Option'
import { useEffect, useState } from 'react'
import { AdjustmentSummary } from 'src/components/AdjustmentForm/components/AdjustmentSummary'
import { Button, Col, Row, Select } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import Divider from 'src/components/base/Divider'
import { IMenuItem } from 'src/components/base/Select/Select'
import { BreakAdjuster } from 'src/components/BreakAdjuster/BreakAdjuster'
import { WorkerShiftHistory } from 'src/components/WorkerShiftHistory'
import { useWardenResult } from 'src/hooks/useWardenResults'
import BulkAdjustWorkerTable from 'src/modals/BulkAdjustmentModal/components/BulkAdjustWorkerTable'
import { isWorkerEligibleForInstantPay } from 'src/screens/WorkerDetailsScreen/WorkerDetailsScreen'
import { extractOptionalDate, toDate } from 'src/utils/dateUtils'
import { ButtonVariant } from '../base/Button/types'
import RadioGroup from '../base/RadioGroup'
import { AdjustDate } from './components/AdjustDate'
import { BulkAdjustSummary } from './components/BulkAdjustSummary'

type AdjustmentFormProps = {
  handleClose: () => void
  handleAdjustment: (params: HandleAdjustmentParams) => Promise<void>
  workerShifts: WorkerShift[]
  unitsPerWorker: number | undefined
}

/*
 Helper functions 
 */

const isSingleWorkerShift = (workerShifts: WorkerShift[]) => {
  return workerShifts.length === 1
}

const getClockInClockOutTimes: (workerShifts: WorkerShift[]) => {
  toDateClockOut: Date
  toDateClockIn: Date
} = (workerShifts: WorkerShift[]) => {
  const workerShift = head(workerShifts)
  return match<
    WorkerShift,
    {
      toDateClockOut: Date
      toDateClockIn: Date
    }
  >(
    () => {
      return { toDateClockOut: new Date(), toDateClockIn: new Date() }
    },
    (workerShift) => {
      if (isSingleWorkerShift(workerShifts)) {
        const clockOutOrShiftEnd =
          workerShift.clockOutTime ??
          workerShift.clockOutTimeBeforeWorkerEdit ??
          workerShift.shiftInfo.endTime
        const clockInOrShiftStart =
          workerShift.clockInTime ?? workerShift.shiftInfo.startTime
        return {
          toDateClockOut: toDate(clockOutOrShiftEnd),
          toDateClockIn: toDate(clockInOrShiftStart),
        }
      }
      return {
        toDateClockOut: toDate(workerShift.shiftInfo.endTime),
        toDateClockIn: toDate(workerShift.shiftInfo.startTime),
      }
    },
  )(workerShift)
}

export function AdjustmentForm({
  handleClose,
  handleAdjustment,
  workerShifts,
  unitsPerWorker,
}: AdjustmentFormProps) {
  const { hotSettings } = useHotSettings()
  const workerShift = workerShifts[0]
  const isBulkAdjustment = !isSingleWorkerShift(workerShifts)
  const timezone = workerShift
    ? workerShift.shiftInfo.timezone
    : workerShifts
      ? workerShifts[0].shiftInfo.timezone
      : ''
  const payType = workerShift
    ? workerShift.shiftInfo.payType
    : workerShifts
      ? workerShifts[0].shiftInfo.payType
      : undefined

  const { toDateClockOut, toDateClockIn } =
    getClockInClockOutTimes(workerShifts)

  const breakTypes = (
    Object.keys(BreakType) as Array<keyof typeof BreakType>
  ).map((bt) => {
    return { label: bt, value: bt }
  })

  const adjustmentReasons: IMenuItem[] = [
    ...(payType === ShiftPayType.UNIT
      ? [{ value: 'reporting_units_worked', label: 'Report Units Worked' }]
      : []),
    {
      value: 'invalid_time_worker_at_fault',
      label: 'Worker Logged Incorrect Times',
    },
    {
      value: 'invalid_time_business_at_fault',
      label: 'Business Logged Incorrect Times',
    },
    { value: 'invalid_time_disputed_timesheet', label: 'Disputed Timesheet' },
    {
      value: 'invalid_minpaidtime_internal',
      label: 'Change Worker Minimum Paid Time',
    },
    { value: 'invalid_payrate_internal', label: 'Change Pay Rate' },
  ]

  /*
  States
  */
  const [showSummary, setShowSummary] = useState<boolean>(false)

  const [clockInTime, setClockInTime] = useState<Option<Date>>(
    !isBulkAdjustment ? none : some(new Date(toDateClockIn)),
  )
  const [clockOutTime, setClockOutTime] = useState<Option<Date>>(
    !isBulkAdjustment ? none : some(new Date(toDateClockOut)),
  )
  const [shouldAdjustEndTime, setShouldAdjustEndTime] = useState<boolean>(true)
  const [shouldAdjustStartTime, setShouldAdjustStartTime] =
    useState<boolean>(true)
  const [payRate, setPayRate] = useState<number>(workerShift.shiftInfo.payRate)
  const [minPaidTime, setMinPaidTime] = useState<number>(
    workerShift?.shiftInfo.minimumPaidTime ?? 0,
  )
  const [unitsWorked, setUnitsWorked] = useState<number | undefined>(
    workerShift.unitsWorked !== null ? workerShift.unitsWorked : undefined,
  )

  const [breakType, setBreakType] = useState<string>(
    workerShift?.shiftInfo.breakType ?? '',
  )

  const [breaks, setBreaks] = useState<ShiftTime[]>(
    getBreaksForWorkerShift(workerShift, hotSettings?.enableWorkerEditTime),
  )

  const [adjustmentReason, setAdjustmentReason] = useState<string>(
    adjustmentReasons[0].value.toString(),
  )
  const [adjustmentLabel, setAdjustmentLabel] = useState<AdjustmentLabel>(
    AdjustmentLabel.UNDETERMINED,
  )
  const [shouldAdjustPayment, setShouldAdjustPayment] = useState<boolean>(false)
  const [shouldInstantPay, setShouldInstantPay] = useState<boolean>(false)

  const [showShiftHistoryDetails, setShowShiftHistoryDetails] =
    useState<boolean>(true)

  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const workerId = workerShifts[0].workerId
  const shiftId = workerShifts[0].shiftId

  /*
  Hooks
  */

  const { isLoading, isError, wardenResult } = useWardenResult(
    workerId,
    shiftId,
  )

  useEffect(() => {
    if (!isBulkAdjustment) {
      if (!isLoading && !isError && !!wardenResult) {
        const label = wardenResult?.adjustmentLabel
        if (
          label === AdjustmentLabel.SHOULD_FLAG ||
          label === AdjustmentLabel.SHOULD_NOT_FLAG ||
          label === AdjustmentLabel.TIMESHEET_ADJUSTMENT
        ) {
          setAdjustmentLabel(label)
        }
      }
    }
  }, [isBulkAdjustment, isLoading, isError, wardenResult])

  useEffect(() => {
    if (shouldAdjustEndTime === false) {
      setClockOutTime(none)
    }
  }, [shouldAdjustEndTime])

  useEffect(() => {
    if (shouldAdjustStartTime === false) {
      setClockInTime(none)
    }
  }, [shouldAdjustStartTime])

  /*
  Callback Functions
  */

  const onChangePayRate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const parsedRate = Math.abs(+ev.currentTarget.value)
    setPayRate(Number(parsedRate.toFixed(2)))
  }

  const onConfirm = async () => {
    setLoading(true)
    await handleAdjustment({
      shouldAdjustPayment,
      shouldInstantPay,
      adjustmentReason,
      clockInTime,
      clockOutTime,
      breaks,
      payRate,
      minPaidTime,
      breakType,
      unitsWorked,
      adjustmentLabel,
    })
    setLoading(false)
  }

  const onEndTimeAdjustmentChange = () => {
    setShouldAdjustEndTime(!shouldAdjustEndTime)
  }

  const onStartTimeAdjustmentChange = () => {
    setShouldAdjustStartTime(!shouldAdjustStartTime)
  }

  const clockInTimeValue = extractOptionalDate(
    clockInTime,
    new Date(toDateClockIn),
  )

  const clockOutTimeValue = extractOptionalDate(
    clockOutTime,
    new Date(toDateClockOut),
  )

  const adjustedStartTime = clockInTimeValue ?? toDateClockIn

  const adjustedEndTime = clockOutTimeValue ?? toDateClockOut

  /*
  Local subcomponents
  */

  const VisibleShiftInfo = () => {
    return !isBulkAdjustment ? (
      <>
        <Row
          style={{
            marginBottom: theme.space.med,
            alignItems: 'center',
          }}
          justifyBetween
        >
          <Text variant="h5">Shift History</Text>
          <Button
            variant={ButtonVariant.TEXT}
            onClick={() => setShowShiftHistoryDetails(!showShiftHistoryDetails)}
            style={{ color: theme.colors.Violet }}
          >
            {showShiftHistoryDetails ? 'Hide Details' : 'Show Details'}
          </Button>
        </Row>
        <Row>
          {showShiftHistoryDetails && !!workerShift ? (
            <WorkerShiftHistory workerShift={workerShift} />
          ) : null}
        </Row>
      </>
    ) : (
      <>
        <Row
          style={{
            marginBottom: theme.space.med,
            alignItems: 'center',
          }}
          justifyBetween
        >
          <BulkAdjustWorkerTable
            workerShifts={workerShifts}
            shouldDisplayAdjustedValues={showSummary}
            adjustedStartTime={adjustedStartTime}
            adjustedEndTime={adjustedEndTime}
            adjustedBreakType={breakType as BreakType}
            adjustedBreaks={breaks}
            adjustedPayRate={payRate}
            unitsPerWorker={unitsPerWorker}
            adjustedMinPaidTime={minPaidTime}
            adjustedUnitsWorked={unitsWorked}
            adjustmentReason={
              adjustmentReasons.find((ele) => ele.value === adjustmentReason)!
                .label
            }
          />
        </Row>
        {!showSummary && <Divider />}
      </>
    )
  }

  const WardenSelection = () => {
    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAdjustmentLabel(event.target.value as AdjustmentLabel)
    }

    const options = [
      {
        label: <Text variant="body2">Warden Should Have Flagged</Text>,
        value: AdjustmentLabel.SHOULD_FLAG,
      },
      {
        label: <Text variant="body2">Warden Should Not Have Flagged</Text>,
        value: AdjustmentLabel.SHOULD_NOT_FLAG,
      },
      {
        label: <Text variant="body2">Manual Pay Timesheet Adjustment</Text>,
        value: AdjustmentLabel.TIMESHEET_ADJUSTMENT,
      },
    ]

    return (
      <Row
        mb={theme.space.med}
        style={{
          backgroundColor: theme.colors.Grey10,
          borderRadius: theme.space.xxs,
          padding: theme.space.xs,
          gap: theme.space.med,
        }}
      >
        <Text variant="h7">Warden Label</Text>
        <RadioGroup
          options={options}
          value={adjustmentLabel}
          onChange={handleSelection}
          style={{
            color: theme.colors.Grey60,
          }}
        />
      </Row>
    )
  }

  const workerCanGetInstantPay = workerShifts.some(
    (ws) =>
      isWorkerEligibleForInstantPay(ws.accountStatus) &&
      ws.accountStatus?.payment?.instantPayEnabled,
  )

  const AdjustPayment = () => {
    return (
      <Row
        style={{
          backgroundColor: theme.colors.Grey10,
          borderRadius: theme.space.xxs,
          padding: theme.space.xs,
          gap: theme.space.med,
        }}
      >
        <Text variant="h7">Adjust Payment</Text>
        <Checkbox
          checked={shouldAdjustPayment}
          label={'Should Adjust Payment'}
          onChange={(e) => {
            const isChecked = e.target.checked
            setShouldAdjustPayment(isChecked)
            if (!isChecked) {
              setShouldInstantPay(false)
            }
          }}
        />
        <Checkbox
          checked={shouldInstantPay}
          label={'Should Instant Pay'}
          onChange={(e) => {
            setShouldInstantPay(e.target.checked)
          }}
          disabled={!shouldAdjustPayment || !workerCanGetInstantPay}
        />
      </Row>
    )
  }

  const EndCards = () => {
    return (
      <Col
        mt={theme.space.sm}
        mb={theme.space.sm}
        style={{
          alignContent: 'space-between',
          gap: theme.space.med,
        }}
      >
        <WardenSelection />
        <AdjustPayment />
      </Col>
    )
  }

  const SingleAdjustmentSummary = () => {
    return (
      <>
        <AdjustmentSummary
          workerShift={workerShift}
          adjustedStartTime={adjustedStartTime}
          adjustedEndTime={adjustedEndTime}
          adjustedBreakType={breakType as BreakType}
          adjustedBreaks={breaks}
          adjustedPayRate={payRate}
          adjustedMinPaidTime={minPaidTime}
          adjustedUnitsWorked={unitsWorked}
          adjustmentReason={
            adjustmentReasons.find((ele) => ele.value === adjustmentReason)!
              .label
          }
        />
        <EndCards />
      </>
    )
  }

  const BulkAdjustmentSummary = () => {
    return (
      <>
        <BulkAdjustSummary
          workerShifts={workerShifts}
          adjustedStartTime={adjustedStartTime}
          adjustedEndTime={adjustedEndTime}
          adjustedBreakType={breakType as BreakType}
          adjustedBreaks={breaks}
          adjustedPayRate={payRate}
          adjustedMinPaidTime={minPaidTime}
          adjustmentReason={
            adjustmentReasons.find((ele) => ele.value === adjustmentReason)!
              .label
          }
          unitsPerWorker={unitsPerWorker}
          adjustedUnitsWorked={unitsWorked}
        />
        <EndCards />
      </>
    )
  }

  const SummaryInfo = () => {
    return !isBulkAdjustment ? (
      <SingleAdjustmentSummary />
    ) : (
      <BulkAdjustmentSummary />
    )
  }

  const adjIsValue = adjustmentLabel !== AdjustmentLabel.UNDETERMINED

  const disabledAdjustmentConfirmation = showSummary && !adjIsValue

  return (
    <Row flexCol fullWidth fullHeight justifyBetween>
      <VisibleShiftInfo />
      {showSummary ? (
        <SummaryInfo />
      ) : (
        <>
          <Row
            style={{
              marginTop: theme.space.sm,
              marginBottom: theme.space.med,
            }}
          >
            <Text variant="h5">Start/End Time</Text>
          </Row>
          <Col style={{ marginBottom: theme.space.med }}>
            <AdjustDate
              isBulkAdjustment={isBulkAdjustment}
              shouldAdjustStartTime={shouldAdjustStartTime}
              shouldAdjustEndTime={shouldAdjustEndTime}
              onStartTimeAdjustmentChange={onStartTimeAdjustmentChange}
              onEndTimeAdjustmentChange={onEndTimeAdjustmentChange}
              adjustedStartTime={adjustedStartTime}
              setClockInTime={setClockInTime}
              setClockOutTime={setClockOutTime}
              timezone={timezone}
              adjustedEndTime={adjustedEndTime}
            />
          </Col>
          <Divider />
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => setShowMoreOptions(!showMoreOptions)}
              style={{ color: theme.colors.Violet }}
            >
              {showMoreOptions ? 'Hide More Options' : 'Show More Options'}
            </Button>
          </Row>
          {showMoreOptions ? (
            <>
              <Row style={{ marginBottom: theme.space.xs }}>
                <Text variant="h5">Pay Rate</Text>
              </Row>
              <Row style={{ marginBottom: theme.space.med }}>
                <TextField
                  margin="dense"
                  style={{ width: '100%' }}
                  placeholder="Pay rate"
                  label="Pay rate"
                  value={payRate}
                  onChange={onChangePayRate}
                  inputMode="decimal"
                  inputProps={{ step: 'any' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    type: 'number',
                  }}
                  variant="outlined"
                />
              </Row>
              <Divider />
              <Row
                style={{
                  marginTop: theme.space.med,
                  marginBottom: theme.space.xs,
                }}
              >
                <Text variant="h5">Min Paid Time</Text>
              </Row>
              <Row style={{ marginBottom: theme.space.med }}>
                <TextField
                  margin="dense"
                  style={{ width: '100%' }}
                  placeholder="Minimum Paid Time (Minutes)"
                  label="Minimum Paid Time (Minutes)"
                  value={minPaidTime}
                  onChange={(e) => setMinPaidTime(+e.target.value)}
                  inputMode="decimal"
                  inputProps={{ min: 0, step: 1 }}
                  InputProps={{
                    type: 'number',
                  }}
                  variant="outlined"
                />
              </Row>
              <Divider />
              {payType === ShiftPayType.UNIT && (
                <>
                  <Row
                    style={{
                      marginTop: theme.space.med,
                      marginBottom: theme.space.xs,
                    }}
                  >
                    <Text variant="h5">Units Worked</Text>
                  </Row>
                  <Row style={{ marginBottom: theme.space.med }}>
                    <TextField
                      margin="dense"
                      style={{ width: '100%' }}
                      placeholder="Units Worked"
                      label="Units Worked"
                      value={unitsWorked}
                      onChange={(e) => setUnitsWorked(+e.target.value)}
                      inputMode="decimal"
                      InputProps={{
                        type: 'number',
                      }}
                      variant="outlined"
                    />
                  </Row>
                </>
              )}
            </>
          ) : null}
          <Divider />
          <Row
            style={{
              marginTop: theme.space.med,
              marginBottom: theme.space.med,
            }}
          >
            <Text variant="h5">Breaks</Text>
            <Row ml={theme.space.xs} style={{ width: '25%' }}>
              <Select
                fullWidth
                label="Break Type"
                menuItems={breakTypes}
                value={breakType}
                handleSelect={setBreakType}
              />
            </Row>
          </Row>
          <Col style={{ flex: 1, marginBottom: theme.space.med }}>
            <BreakAdjuster
              breaks={breaks}
              setBreaks={setBreaks}
              timezone={timezone}
              defaultBreakTime={workerShift?.shiftInfo.startTime ?? new Date()}
            />
          </Col>
          <Divider />
          <Row
            style={{
              marginTop: theme.space.med,
              marginBottom: theme.space.med,
            }}
          >
            <Col>
              <Text variant="h5" style={{ marginBottom: theme.space.med }}>
                Adjustment Reason
              </Text>
              <Select
                fullWidth
                label="Adjustment Reason"
                menuItems={adjustmentReasons}
                value={adjustmentReason}
                handleSelect={setAdjustmentReason}
              />
            </Col>
          </Row>
        </>
      )}
      <Divider />
      <Row
        fullWidth
        style={{
          marginTop: theme.space.med,
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ width: '200px' }}
          onClick={() => (showSummary ? setShowSummary(false) : handleClose())}
        >
          {showSummary ? 'Back' : 'Cancel'}
        </Button>
        <Button
          style={{ width: '200px' }}
          onClick={() => (showSummary ? onConfirm() : setShowSummary(true))}
          loading={loading}
          disabled={disabledAdjustmentConfirmation}
        >
          {showSummary ? 'Confirm Adjustment' : 'Continue'}
        </Button>
      </Row>
    </Row>
  )
}
