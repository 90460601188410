import { theme } from '@traba/theme'
import { OpsAttributeLevel } from '@traba/types'
import {
  isAttributeLevelRevoked,
  isAttributeLevelUnreported,
} from 'src/utils/attributeUtils'
import { AttributePillColors } from './types'

const unreportedColors: AttributePillColors = {
  textColor: theme.colors.Grey60,
  color: theme.colors.White,
  hoverColor: theme.colors.Grey20,
  borderColor: theme.colors.Grey40,
  borderHoverColor: theme.colors.Grey90,
}

const revokedColors: AttributePillColors = {
  textColor: theme.colors.Red80,
  color: theme.colors.Red10,
  hoverColor: theme.colors.Red30,
  borderColor: theme.colors.Red80,
  borderHoverColor: theme.colors.Red80,
}

const reportedColors: AttributePillColors = {
  textColor: theme.colors.White,
  color: theme.colors.Violet70,
  hoverColor: theme.colors.Violet90,
  borderColor: theme.colors.Violet80,
  borderHoverColor: theme.colors.Violet90,
}

export function getPillColorsForAttributeLevel(
  level: OpsAttributeLevel,
): AttributePillColors {
  if (isAttributeLevelUnreported(level)) {
    return unreportedColors
  }

  if (isAttributeLevelRevoked(level)) {
    return revokedColors
  }

  return reportedColors
}
