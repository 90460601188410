import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { useState } from 'react'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import BusinessTimesheetUpload from './components/BusinessTimesheetUpload'
import TrabaTimesheetUpload from './components/TrabaTimesheetUpload'

export default function TimesheetScreen() {
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <MainLayout>
      <TabsContainer>
        <Tabs
          value={currentTab}
          onChange={(_, v) => {
            setCurrentTab(v)
          }}
        >
          <Tab label="Upload Traba Timesheet" />
          <Tab label="Upload Business Timesheet" />
        </Tabs>
      </TabsContainer>
      <TabPanel value={currentTab} index={0}>
        <TrabaTimesheetUpload />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <BusinessTimesheetUpload />
      </TabPanel>
    </MainLayout>
  )
}
