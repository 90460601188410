import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Col, Row } from 'src/components/base'
import Toggle from 'src/components/base/Toggle'
import styled from 'styled-components'

export type FieldMonitorFiltersType = {
  showCanceledShiftsFilter: boolean
  showLateClockInShiftsFilter: boolean
  showLateClockOutShiftsFilter: boolean
  showNotConfirmedFilter: boolean
  showNotPaidFilter: boolean
  showSlotsNotFilledFilter: boolean
  showAssignedShifts: boolean
  showUnassignedShiftsMarketOps: boolean
  showUnassignedShiftsScaleOps: boolean
  showLowReliabilityShifts: boolean
}

export type FieldMonitorFiltersProps = {
  activeFilterCount: number
  fieldMonitorFilters: FieldMonitorFiltersType
  handleToggleFilter: (filter: Partial<FieldMonitorFiltersType>) => void
  disabledFilters?: Partial<FieldMonitorFiltersType>
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); // 8 filters per row
  gap: ${theme.space.xs}px ${theme.space.xs}px;
`

const filterList: { name: keyof FieldMonitorFiltersType; label: string }[] = [
  {
    name: 'showLowReliabilityShifts',
    label: 'Low Reliability',
  },
  {
    name: 'showNotConfirmedFilter',
    label: 'Not Confirmed',
  },
  {
    name: 'showLateClockInShiftsFilter',
    label: 'Not Clocked In',
  },
  {
    name: 'showLateClockOutShiftsFilter',
    label: 'Not Clocked Out',
  },
  {
    name: 'showNotPaidFilter',
    label: 'Not Paid',
  },
  {
    name: 'showSlotsNotFilledFilter',
    label: 'Slots Not Filled',
  },
  {
    name: 'showCanceledShiftsFilter',
    label: 'Show Canceled',
  },
  {
    name: 'showAssignedShifts',
    label: 'Assigned to me',
  },
  {
    name: 'showUnassignedShiftsMarketOps',
    label: 'Mkt Ops Unasgn', // Unasgn == Unassigned (abbreviation for space)
  },
  {
    name: 'showUnassignedShiftsScaleOps',
    label: 'Scl Ops Unasgn', // Unasgn == Unassigned (abbreviation for space)
  },
]

export default function FieldMonitorFilters({
  activeFilterCount,
  fieldMonitorFilters,
  handleToggleFilter,
  disabledFilters,
}: FieldMonitorFiltersProps) {
  return (
    <Col>
      <Text variant="h5" mb={theme.space.xxs}>
        Shift filters{' '}
        {!!activeFilterCount && (
          <Text
            variant="brand"
            style={{
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            ({activeFilterCount})
          </Text>
        )}
      </Text>
      <GridContainer>
        {filterList.map((filter) => (
          <Row key={filter.name}>
            <Toggle
              label={filter.label}
              buttonState={fieldMonitorFilters[filter.name]}
              runOnChange={() =>
                handleToggleFilter({
                  [filter.name]: !fieldMonitorFilters[filter.name],
                })
              }
              disabled={!!disabledFilters?.[filter.name]}
              containerStyle={{ width: 200, justifyContent: 'center' }}
            />
          </Row>
        ))}
      </GridContainer>
    </Col>
  )
}
