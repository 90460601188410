import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material'
import { Text } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { memo } from 'react'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { CopyTextIcon } from '../../../../components/base'
import { getAccountStatusColor } from '../../../../utils/workerUtils'
import * as S from './styles'

type WorkerListItemProps = {
  worker: PopulatedWorker
  index: number
  onAvatarClick?: (worker: PopulatedWorker) => void
  handleSelection?: (worker: PopulatedWorker) => void
  onWorkerHover?: (worker?: PopulatedWorker) => void
  workerIsSelected?: boolean
  showIndex?: boolean
  actions?: React.ReactNode
  // A custom HTML id for the checkbox to prevent React from confusing two list
  // items with the same id.
  customKey?: string
}

const WorkerListItem = memo(
  ({
    worker,
    handleSelection,
    workerIsSelected,
    onAvatarClick,
    onWorkerHover,
    index,
    showIndex,
    actions,
    customKey,
  }: WorkerListItemProps) => {
    const shiftsCompleted = worker.workerMetric?.metrics?.find(
      (metric) => metric.type === 'ALL_TIME',
    )?.completedShifts
    return (
      <label htmlFor={customKey || `checkbox-${worker.id}`}>
        <ListItem
          disablePadding
          secondaryAction={
            handleSelection ? (
              <Checkbox
                id={customKey || `checkbox-${worker.id}`}
                checked={workerIsSelected}
                onChange={() => handleSelection(worker)}
              />
            ) : null
          }
          onMouseOver={() => (onWorkerHover ? onWorkerHover(worker) : null)}
          sx={{
            backgroundColor: index % 2 === 0 ? '#f3f3f3' : '#fff',
          }}
        >
          <ListItemButton
            sx={{ justifyContent: 'space-between' }}
            onClick={() =>
              !handleSelection
                ? window.open(
                    `${window.location.origin}/workers/${worker.id}`,
                    '_blank',
                  )
                : undefined
            }
          >
            {showIndex && (
              <S.CounterWrapper>
                <Text variant="body1" pr={theme.space.xs}>
                  {index + 1}
                </Text>
              </S.CounterWrapper>
            )}
            <ListItemAvatar>
              <S.AvatarWrapper>
                <Avatar
                  alt={`worker_${worker.id}_avatar`}
                  src={worker.photoUrl}
                  sizes="40px"
                  onClick={() =>
                    onAvatarClick ? onAvatarClick(worker) : undefined
                  }
                />
              </S.AvatarWrapper>
            </ListItemAvatar>
            <S.WorkerItemCard>
              {worker.firstName} {worker.lastName}
            </S.WorkerItemCard>
            <S.WorkerItemCard>
              {worker.phoneNumber}{' '}
              <CopyTextIcon textToCopy={worker.phoneNumber} />
            </S.WorkerItemCard>

            {worker.regionId && (
              <S.WorkerItemCard>{worker.regionId}</S.WorkerItemCard>
            )}
            {worker.workerMetric?.tierLevel && (
              <S.WorkerItemCard>
                {worker.workerMetric?.tierLevel}
              </S.WorkerItemCard>
            )}
            <S.WorkerItemCard
              style={{
                color: getAccountStatusColor(
                  worker.accountStatus.accountStatus,
                ),
              }}
            >
              {worker.accountStatus.accountStatus}
            </S.WorkerItemCard>
            {shiftsCompleted !== undefined && (
              <S.WorkerItemCard>
                {shiftsCompleted} shift{makePlural(shiftsCompleted)} worked
              </S.WorkerItemCard>
            )}
            {actions && <S.WorkerItemCard>{actions}</S.WorkerItemCard>}
          </ListItemButton>
        </ListItem>
      </label>
    )
  },
)

export { WorkerListItem }
