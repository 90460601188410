import { useAlert } from '@traba/context'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { getErrorMessage } from 'src/utils/errorUtils'
import { openInNewTab } from 'src/utils/helperUtils'

export function useDownloadShiftReport(shiftId: string) {
  const [isLoading, setIsLoading] = useState(false)
  const { showError } = useAlert()

  const getReport = async (reportType: string) => {
    try {
      setIsLoading(true)
      const url = `/shifts/${shiftId}/${reportType}-report`
      const response = await trabaApi.put(url)
      const link = response.data

      if (!link) {
        throw new Error('no-shifts')
      }
      openInNewTab(link)
      return response.data
    } catch (error) {
      showError(getErrorMessage(error), 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    getCsvReport: async () => getReport('csv'),
    getPdfReport: async () => getReport('pdf'),
    getTimesheetReport: async () => getReport('timesheet'),
  }
}
