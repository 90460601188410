import { Tooltip } from '@mui/material'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { format } from 'date-fns'
import { isValidNumber } from 'libphonenumber-js'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Badge, Button, Icon, Input, Row } from '../../components/base'
import Pagination from '../../components/base/Pagination/Pagination'
import { SearchSelect } from '../../components/base/SearchSelect/SearchSelect'
import { IMenuItem } from '../../components/base/Select/Select'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import { SortOrder } from '../../hooks/usePagination'
import { useRegions } from '../../hooks/useRegions'

import {
  useSearchWorkerVettingCampaigns,
  WorkerVettingCampaignParams,
  WorkerVettingCampaignResponse,
} from '../../hooks/useWorkerVetting'
import { WorkerVettingSessionStatus } from '../../types/worker-vetting'
import { trimParams } from '../../utils/helperUtils'
import { sanitizePhoneNumber } from '../../utils/stringUtils'

const getBadgeColor = (status: string) => {
  switch (status) {
    case 'EXPIRED':
      return 'warning'
    case 'INACTIVE':
      return 'disabled'
    case 'ACTIVE':
      return 'success'
    default:
      return 'info'
  }
}

const BaseRenderCell = ({ value }: { value: string }) => (
  <Row fullWidth justifyCenter>
    <Tooltip title={value}>
      <div>
        <Text
          style={{
            maxWidth: '120ch',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          variant="h7"
        >
          {value}
        </Text>
      </div>
    </Tooltip>
  </Row>
)

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 240,
    renderCell: (params) => (
      <Row justifyStart>
        <BaseRenderCell value={params.value} />
      </Row>
    ),
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
    renderCell: (params) => (
      <Badge
        variant={getBadgeColor(params.value)}
        title={params.value}
        style={{ minWidth: 80, height: 25, borderRadius: 8 }}
      />
    ),
    align: 'center',
  },
  {
    field: 'completed',
    headerName: 'Completed',
    width: 100,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
  },
  {
    field: 'qualified',
    headerName: 'Qualified',
    width: 100,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
  },
  {
    field: 'createdAt',
    headerName: 'Started at',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 200 }}>
        <Text>{format(new Date(params.value), 'MMM dd, yyyy hh:mm a')}</Text>
      </Row>
    ),
    minWidth: 200,
  },
  {
    field: 'deadline',
    headerName: 'Finished at',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 200 }}>
        <Text>{format(new Date(params.value), 'MMM dd, yyyy hh:mm a')}</Text>
      </Row>
    ),
    minWidth: 200,
  },
]

const makeRows = (campaign: WorkerVettingCampaignResponse) => {
  const sessionsFinished = campaign.workerVettingSessions?.filter(
    (s) => s.status !== WorkerVettingSessionStatus.active,
  )
  const candidatesQualified = campaign.workerVettingSessions?.filter(
    (s) => s.status === WorkerVettingSessionStatus.complete && s.score === 200,
  )
  return {
    id: campaign.id,
    title: campaign.title,
    status: campaign.status,
    completed: `${sessionsFinished?.length || 0}/${
      campaign.workerVettingSessions?.length || 0
    }`,
    qualified: candidatesQualified?.length || 0,
    createdAt: campaign.createdAt,
    deadline: campaign.deadline,
  }
}

export default function WorkerVettingCampaignList() {
  const { regions = [], isLoading: isLoadingRegions } = useRegions()
  const [form, setForm] = useState({
    shiftId: '',
    title: '',
    phoneNumber: '',
  })
  const [regionIds, setRegionIds] = useState<IMenuItem[]>([])
  const [searchParams, setSearchParams] = useState<WorkerVettingCampaignParams>(
    {
      regionIds: undefined,
      shiftId: undefined,
      title: undefined,
      phoneNumbers: undefined,
    },
  )
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const navigate = useNavigate()

  const PAGE_SIZE = 50

  const validatePhoneNumber = (phoneNumber: string) => {
    if (isValidNumber(phoneNumber, 'US')) {
      setPhoneNumberError('')
      return true
    } else {
      setPhoneNumberError('Invalid phone number')
      return false
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setPhoneNumberError('')
    if (name === 'phoneNumber' && value) {
      validatePhoneNumber(value)
    }
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }))
  }

  const {
    campaigns: campaignsFound,
    isLoading: isLoadingCampaigns,
    totalFound,
    goToPreviousPage,
    goToNextPage,
    currentPage,
  } = useSearchWorkerVettingCampaigns({
    params: trimParams(searchParams),
    paginationParams: {
      sortBy: 'createdAt',
      sortOrder: SortOrder.desc,
      limit: PAGE_SIZE,
      offset: 0,
    },
  })

  const handleSearch = () => {
    const sanitizedPhoneNumber = sanitizePhoneNumber(form.phoneNumber)
    setSearchParams({
      regionIds: regionIds?.length
        ? regionIds.map((r: { value: string }) => r.value)
        : undefined,
      shiftId: form.shiftId,
      title: form.title,
      phoneNumbers: sanitizedPhoneNumber ? [sanitizedPhoneNumber] : undefined,
    })
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    if (form.phoneNumber && !validatePhoneNumber(form.phoneNumber)) {
      return
    }
    handleSearch()
  }

  const isLoading = isLoadingRegions || isLoadingCampaigns

  const rows = useMemo(() => {
    return campaignsFound.map(makeRows)
  }, [campaignsFound])

  return (
    <MainLayout title="Worker Vetting Tool">
      <Row fullWidth alignCenter justifyBetween>
        <Text variant="h4">Worker Vetting Tool</Text>
        <Button
          variant={ButtonVariant.PURPLEGRADIENT}
          leftIcon={<Icon name="plus" />}
          onClick={() => navigate('/worker-vetting/campaigns/new')}
          ml={theme.space.xs}
          style={{ height: theme.space.xl, width: 200 }}
        >
          Create New
        </Button>
      </Row>
      <form onSubmit={handleFormSubmit}>
        <Row
          flexCol
          py={theme.space.xs}
          px={theme.space.xs}
          mt={theme.space.med}
          style={{
            backgroundColor: theme.colors.Grey10,
            borderRadius: theme.border.radius,
          }}
        >
          <Row alignCenter justifyBetween mb={theme.space.xs} fullWidth>
            <Row>
              <Input
                placeholder="Title"
                name="title"
                disabled={isLoading}
                onChange={handleInputChange}
                value={form.title}
                containerStyle={{ width: 420 }}
                width="95%"
              />
              <Input
                placeholder="Phone number"
                name="phoneNumber"
                disabled={isLoading}
                onChange={handleInputChange}
                value={form.phoneNumber}
                containerStyle={{ width: 420 }}
                width="95%"
                inputStatus={phoneNumberError ? InputStatus.error : undefined}
              />
            </Row>
            <Button
              variant={ButtonVariant.FILLED}
              leftIcon={<Icon name="search" />}
              onClick={handleSearch}
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 200 }}
              disabled={isLoading || (!!form.phoneNumber && !!phoneNumberError)}
            >
              Search
            </Button>
          </Row>
          <Row alignCenter justifyStart fullWidth>
            <Input
              placeholder="ShiftId"
              name="shiftId"
              disabled={isLoading}
              onChange={handleInputChange}
              value={form.shiftId}
              containerStyle={{ width: 420 }}
              width="95%"
            />
            <SearchSelect
              options={regions.map((r) => ({
                label: r.displayName,
                value: r.regionId,
              }))}
              selectedItems={regionIds}
              handleSelectMultiple={(items) => {
                setRegionIds(items)
              }}
              label={'Target regions'}
              onlyShowLabel
              multiple
              width={350}
              disabled={isLoading}
            />
          </Row>
        </Row>
      </form>

      <Row alignCenter fullWidth justifyBetween my={theme.space.med}>
        <Text variant="h6" mr={theme.space.xs}>
          {isLoading ? `...` : `${totalFound} found`}
        </Text>

        <Pagination
          dataSize={campaignsFound.length}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          page={currentPage}
          pageSize={PAGE_SIZE}
          totalFound={totalFound}
        />
      </Row>

      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        autoHeight
        hideFooterPagination
        disableColumnFilter
        sx={{
          '.MuiDataGrid-columnHeaders': {
            fontWeight: 600,
            color: theme.colors.MidnightBlue,
          },
          '.MuiDataGrid-row': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
        onRowClick={(params: GridRowParams) =>
          navigate(`/worker-vetting/campaigns/${params.id}`)
        }
      />
    </MainLayout>
  )
}
