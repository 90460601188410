import { Switch } from '@mui/material'
import { useAlert } from '@traba/context'
import { Button, ButtonVariant, Card, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  GenderPreference,
  RequiredMultiShiftType,
  Role,
  ForwardFillMax,
  PaymentType,
  ShiftNotificationStatus,
  ShiftPayType,
  TierLevel,
  User,
  ShiftSignupStatus,
  Roster,
} from '@traba/types'
import { differenceInMinutes } from 'date-fns'
import { compact } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import { Autocomplete } from 'src/components/Autocomplete/Autocomplete'
import { Row, Select } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import {
  calculateEstimatedPay,
  validatePayRate,
} from 'src/modals/EditShiftModal/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import {
  calculateBilledShiftTime,
  calculatePaidShiftTime,
  convertCentsToDollars,
  convertPayRateToCents,
  getScheduledBreakTotal,
} from 'src/utils/moneyUtils'
import {
  genderPreferenceOptions,
  paymentTypeOptions,
  minimumTierOptions,
  signupStatusOptions,
  forwardFillTypeOptions,
} from 'src/utils/shiftFormUtils'
import { validateEmail } from 'src/utils/stringUtils'

import { SearchInviteWorkersSection } from '../SearchInviteWorkers/SearchInviteWorkersSection'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'
import { PaymentSummary } from './PaymentSummary'
import { SlotsAndOverbook } from './SlotsAndOverbook'

interface Props {
  createShiftRequest: CreateShiftRequest
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  index: number
  roles: Role[] | undefined
  companyUsers: User[] | undefined
  minHourlyPayRate: number
  setWorkersToInvite: Dispatch<SetStateAction<PopulatedWorker[]>>
  workersToInvite: PopulatedWorker[]
  companyId: string
  rosters: Roster[] | undefined
  businessStartTime: Date | null
}

const payTypeOptions = [
  { value: ShiftPayType.HOURLY, label: 'Hourly' },
  { value: ShiftPayType.UNIT, label: 'Unit' },
]

const rmsaShiftOptions = [
  { value: RequiredMultiShiftType.None, label: 'None' },
  { value: RequiredMultiShiftType.ALL_IN_REQUEST, label: 'All In Request' },
]

export const IndividualRoleForm = ({
  createShiftRequest,
  createShiftRequests,
  setCreateShiftRequests,
  index,
  roles,
  companyUsers,
  minHourlyPayRate,
  setWorkersToInvite,
  workersToInvite,
  companyId,
  rosters,
  businessStartTime,
}: Props) => {
  const [collapsed, setCollapsed] = useState(false)
  const { showError } = useAlert()
  const { hotSettings } = useHotSettings()
  const { virtualRosters } = useVirtualRosters(
    companyId,
    createShiftRequest.locationId,
    createShiftRequest.roleId,
  )

  const totalShiftTime = differenceInMinutes(
    createShiftRequest.schedules[0].endTime,
    createShiftRequest.schedules[0].startTime,
  )

  const totalBreakTime = getScheduledBreakTotal(
    createShiftRequest.scheduledBreaks || [],
  )

  const totalBilledTime = calculateBilledShiftTime(
    businessStartTime,
    createShiftRequest.schedules[0].endTime,
    totalBreakTime,
    createShiftRequest.breakType,
  )

  const totalPaidTime = calculatePaidShiftTime(
    totalBreakTime,
    totalShiftTime,
    createShiftRequest.breakType,
  )
  const { payString: estimatedPay } = calculateEstimatedPay(
    createShiftRequest.payRate,
    createShiftRequest.payType,
    totalPaidTime,
    createShiftRequest.numberOfUnits || 0,
    createShiftRequest.slotsRequested,
  )

  const additionalEmailsOptions = (companyUsers: User[]) => {
    return compact(
      companyUsers
        .filter(
          (companyUser) => companyUser.uid !== createShiftRequest.supervisorId,
        )
        .map((companyUser) => companyUser.email),
    )
  }

  const supervisorOptions: IMenuItem[] =
    companyUsers?.map((user) => {
      return {
        label: `${user.firstName} ${user.lastName}`,
        value: user.uid,
        secondaryLabel: user.email,
      }
    }) || []

  const roleMap: Map<string, Role> = new Map()
  const roleOptions: IMenuItem[] =
    roles?.map((role) => {
      roleMap.set(role.roleId, role)
      return {
        label: role.roleName,
        value: role.roleId,
        secondaryLabel:
          role.roleDescription.length > 100
            ? `${role.roleDescription.slice(0, 100)}...`
            : role.roleDescription,
      }
    }) || []
  const selectedRole = roleMap.get(createShiftRequest.roleId)

  const handleRoleChange = (roleId: string) => {
    const newRole = roleMap.get(roleId)
    if (!newRole) {
      return
    }
    setCreateShiftRequests((prev) => {
      const newCreateShiftRequests = [...prev]
      newCreateShiftRequests[index] = {
        ...newCreateShiftRequests[index],
        roleId,
        payRate: newRole.defaultPayRate,
        hourlyRate: newRole.defaultPayRate,
        videoIds: newRole.videoIds ?? [],
        genderPreference: newRole.genderPreference,
      }
      return newCreateShiftRequests
    })
  }

  if (collapsed) {
    return (
      <>
        <Row justifyBetween pt={theme.space.xs} alignCenter>
          <Row alignCenter>
            <Text variant="h6">
              {roleMap.get(createShiftRequest.roleId)?.roleName ??
                'Unselected role'}
              {` \u2022 `}
              {`$${createShiftRequest.payRate} / hr`}
              {` \u2022 `}
              {createShiftRequest.slotsRequested} slots
            </Text>
            <Button
              variant={ButtonVariant.TEXT}
              style={{ color: theme.colors.Violet }}
              onClick={() => {
                if (createShiftRequests.length === 1) {
                  return showError("Can't remove the only role")
                }
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests.splice(index, 1)
                  return newCreateShiftRequests
                })
              }}
            >
              Remove
            </Button>
          </Row>
          <Button
            variant={ButtonVariant.TEXT}
            style={{ color: theme.colors.Violet }}
            onClick={() => {
              setCollapsed(false)
            }}
          >
            + Expand
          </Button>
        </Row>
        <Divider />
      </>
    )
  }

  return (
    <Card>
      <Button
        style={{ alignSelf: 'flex-end' }}
        variant={ButtonVariant.TEXT}
        onClick={() => {
          setCollapsed(true)
        }}
      >
        - Collapse
      </Button>
      <Row mb={theme.space.sm} gap={theme.space.sm} wrap>
        <ShiftPostingInputContainerSection
          label="Role"
          input={
            <SearchSelect
              options={roleOptions}
              handleSelect={(item) => {
                if (!item?.value) {
                  return
                }
                handleRoleChange(item?.value)
              }}
              selectItem={roleOptions.find(
                (ro) => ro.value === createShiftRequest.roleId,
              )}
              shouldAlsoSearchSecondaryLabel
              style={{ minWidth: '40%' }}
            />
          }
        />
        <ShiftPostingInputContainerSection
          label="Supervisor"
          input={
            <SearchSelect
              options={supervisorOptions}
              handleSelect={(item) => {
                if (!item?.value) {
                  return
                }
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    supervisorId: item.value,
                  }
                  return newCreateShiftRequests
                })
              }}
              selectItem={supervisorOptions.find(
                (so) => so.value === createShiftRequest.supervisorId,
              )}
              shouldAlsoSearchSecondaryLabel
              style={{ minWidth: '40%' }}
            />
          }
        />
      </Row>
      {createShiftRequest.supervisorId && (
        <Row mb={theme.space.xs} style={{ width: '50%' }}>
          <ShiftPostingInputContainerSection
            label="Additional team members (optional)"
            input={
              <Autocomplete
                label="Email addresses"
                value={createShiftRequest.additionalEmails || []}
                options={additionalEmailsOptions(companyUsers || [])}
                onChangeValues={(_, value) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      additionalEmails: value,
                    }
                    return newCreateShiftRequests
                  })
                }}
                errorMessage="Email address is invalid"
                validateInput={validateEmail}
              />
            }
          />
        </Row>
      )}
      <Divider />
      <Row my={theme.space.sm} wrap>
        <ShiftPostingInputContainerSection
          label="Pay Type"
          input={
            <Select
              menuItems={payTypeOptions}
              fullWidth
              handleSelect={(payType) => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    payType: payType as ShiftPayType,
                  }
                  return newCreateShiftRequests
                })
              }}
              value={createShiftRequest.payType}
            />
          }
        />

        <ShiftPostingInputContainerSection
          label={`Pay rate per ${
            createShiftRequest.payType === ShiftPayType.HOURLY ? 'Hour' : 'Unit'
          }`}
          input={
            <>
              <NumberInput
                customErrorMessage={
                  createShiftRequest.payType === ShiftPayType.HOURLY
                    ? 'To post a lower rate, adjust the min hourly rate company setting.'
                    : 'Make sure to set both Pay Per Unit and Total Units.'
                }
                isMoney
                value={createShiftRequest.payRate}
                setValue={(payRate) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      // if the pay rate is zero, we don't need to charge a trust and safety fee
                      ...(payRate === 0
                        ? {
                            trustAndSafetyFeeHourly: {
                              amount: 0,
                              currency: 'USD',
                            },
                          }
                        : {
                            trustAndSafetyFeeHourly: {
                              amount: 50,
                              currency: 'USD',
                            },
                          }),
                      payRate: payRate || 0,
                      hourlyRate: payRate || 0,
                    }
                    return newCreateShiftRequests
                  })
                }}
                step={0.01}
                required
                placeholder="Pay Rate"
                error={
                  !validatePayRate({
                    payType: createShiftRequest.payType,
                    payRate: createShiftRequest.payRate,
                    minHourlyPayRate: minHourlyPayRate,
                    numberOfUnits: createShiftRequest.numberOfUnits,
                  })
                }
              />
              {selectedRole?.defaultPayRate === createShiftRequest.payRate && (
                <Text variant="caption" style={{ marginLeft: theme.space.xs }}>
                  THIS IS THE DEFAULT PAY FOR THE ROLE
                </Text>
              )}
            </>
          }
        />

        {createShiftRequest.payType === ShiftPayType.UNIT && (
          <ShiftPostingInputContainerSection
            label={`Total Units`}
            input={
              <NumberInput
                value={createShiftRequest.numberOfUnits}
                setValue={(units) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      numberOfUnits: units,
                    }
                    return newCreateShiftRequests
                  })
                }}
                decimals={0}
                placeholder="Num Units"
                min={0}
                step={1}
                error={!createShiftRequest.numberOfUnits}
                customErrorMessage="This field is required"
              />
            }
          />
        )}

        <ShiftPostingInputContainerSection
          label="Payment Type (optional)"
          input={
            <Select
              menuItems={paymentTypeOptions}
              fullWidth
              handleSelect={(paymentType) => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    paymentType: paymentType as PaymentType,
                  }
                  return newCreateShiftRequests
                })
              }}
              showEmptyOption
              value={createShiftRequest.paymentType || ''}
            />
          }
        />
      </Row>
      <Divider />

      <SlotsAndOverbook
        createShiftRequest={createShiftRequest}
        setCreateShiftRequests={setCreateShiftRequests}
        index={index}
      />

      <Divider />
      {hotSettings?.paidBackupsEnabled && (
        <Row my={theme.space.sm} fullWidth justifyCenter wrap>
          <ShiftPostingInputContainerSection
            label="Paid Backups"
            input={
              <NumberInput
                label="Add Paid Backups?"
                value={createShiftRequest.paidBackupSlotsRequested}
                setValue={(numReq) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      paidBackupSlotsRequested: numReq,
                    }
                    return newCreateShiftRequests
                  })
                }}
                step={1}
                min={0}
              />
            }
          />
          <ShiftPostingInputContainerSection
            label="Paid Backup Pay"
            input={
              <NumberInput
                value={
                  createShiftRequest.paidBackupPayAmount ||
                  createShiftRequest.paidBackupPayAmount === 0
                    ? convertCentsToDollars(
                        createShiftRequest.paidBackupPayAmount,
                      )
                    : undefined
                }
                setValue={(numReq) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      paidBackupPayAmount:
                        numReq || numReq === 0
                          ? convertPayRateToCents(numReq)
                          : undefined,
                    }
                    return newCreateShiftRequests
                  })
                }}
                isMoney={true}
                step={0.01}
                min={createShiftRequest.paidBackupSlotsRequested ? 1 : 0}
                max={100}
              />
            }
          />
        </Row>
      )}
      <Divider />
      <Row my={theme.space.sm} alignCenter wrap>
        <ShiftPostingInputContainerSection
          label={`Shift Signup Status`}
          input={
            <Row
              style={{
                alignItems: 'center',
              }}
            >
              <Select
                menuItems={signupStatusOptions}
                fullWidth
                handleSelect={(signupStatus) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      signupStatus: signupStatus as ShiftSignupStatus,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={
                  createShiftRequest.signupStatus || ShiftSignupStatus.ALLOWED
                }
              />
            </Row>
          }
        />
        <ShiftPostingInputContainerSection
          label={`Forward Fill Type`}
          input={
            <Select
              menuItems={forwardFillTypeOptions}
              fullWidth
              handleSelect={(forwardFillMax) => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    forwardFillMax: forwardFillMax as ForwardFillMax,
                  }
                  return newCreateShiftRequests
                })
              }}
              value={createShiftRequest.forwardFillMax || ForwardFillMax.NONE}
            />
          }
        />
      </Row>
      <Divider />
      <Row style={{ justifyContent: 'flex-start' }} my={theme.space.sm} wrap>
        <ShiftPostingInputContainerSection
          label={`Minimum Tier`}
          input={
            <Row style={{ marginRight: theme.space.med }}>
              <Select
                menuItems={minimumTierOptions}
                fullWidth
                handleSelect={(tier) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      minimumAcceptedTier: tier as TierLevel,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={createShiftRequest.minimumAcceptedTier}
              />
            </Row>
          }
        />

        <ShiftPostingInputContainerSection
          label={`Unproven Threshold`}
          input={
            <Row style={{ marginRight: theme.space.med }}>
              <DeprecatedNumberInput
                value={createShiftRequest.unprovenWorkerThreshold}
                setter={(threshold) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      unprovenWorkerThreshold: threshold,
                    }
                    return newCreateShiftRequests
                  })
                }}
                step={0.01}
                decimals={2}
                placeholder="eg. 0.5"
                helperText="Must be between 0 and 1"
                error={
                  createShiftRequest.unprovenWorkerThreshold !== undefined &&
                  (createShiftRequest.unprovenWorkerThreshold < 0 ||
                    createShiftRequest.unprovenWorkerThreshold > 1)
                }
              />
            </Row>
          }
        />
        <ShiftPostingInputContainerSection
          label={`Show RMSA`}
          input={
            <Row style={{ marginRight: theme.space.med }}>
              <Select
                menuItems={rmsaShiftOptions}
                fullWidth
                handleSelect={(rmsaOption) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      requiredMultiShiftType:
                        rmsaOption as RequiredMultiShiftType,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={createShiftRequest.requiredMultiShiftType}
              />
            </Row>
          }
        />
        <ShiftPostingInputContainerSection
          label={`Gender Preference`}
          input={
            <Row style={{ marginRight: theme.space.med }}>
              <Select
                menuItems={genderPreferenceOptions}
                fullWidth
                showEmptyOption
                handleSelect={(genderPreference) => {
                  setCreateShiftRequests((prev) => {
                    const newCreateShiftRequests = [...prev]
                    newCreateShiftRequests[index] = {
                      ...newCreateShiftRequests[index],
                      genderPreference: genderPreference
                        ? (genderPreference as GenderPreference)
                        : undefined,
                    }
                    return newCreateShiftRequests
                  })
                }}
                value={createShiftRequest.genderPreference || ''}
              />
            </Row>
          }
        />
        <ShiftPostingInputContainerSection
          label="Forward Fill Notifications"
          input={
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={
                createShiftRequest.notificationStatus ===
                ShiftNotificationStatus.ALLOWED
              }
              onClick={() => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    notificationStatus:
                      createShiftRequest.notificationStatus ===
                      ShiftNotificationStatus.ALLOWED
                        ? ShiftNotificationStatus.DISALLOWED
                        : ShiftNotificationStatus.ALLOWED,
                  }
                  return newCreateShiftRequests
                })
              }}
            />
          }
          right
        />
      </Row>
      <Row
        style={{ justifyContent: 'flex-start' }}
        mt={theme.space.sm}
        mb={theme.space.xs}
        wrap
      >
        <ShiftPostingInputContainerSection
          label="Require W9 Authorization"
          input={
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={createShiftRequest.requireW9Authorization}
              onClick={() => {
                setCreateShiftRequests((prev) => {
                  const newCreateShiftRequests = [...prev]
                  newCreateShiftRequests[index] = {
                    ...newCreateShiftRequests[index],
                    requireW9Authorization:
                      !createShiftRequest.requireW9Authorization,
                  }
                  return newCreateShiftRequests
                })
              }}
            />
          }
          right
        />
      </Row>
      {(createShiftRequest.forwardFillMax === ForwardFillMax.INVITED_ONLY ||
        createShiftRequest.forwardFillMax === ForwardFillMax.INVITED_FIRST) && (
        <>
          <HorizontalRule marginTop={theme.space.med} />
          <SearchInviteWorkersSection
            rosters={virtualRosters.concat(rosters ?? []) || []}
            allWorkersToInvite={workersToInvite}
            setAllWorkersToInvite={setWorkersToInvite}
          />
        </>
      )}
      <PaymentSummary
        totalPaidTime={totalPaidTime}
        totalBreakTime={totalBreakTime}
        totalShiftTime={totalShiftTime}
        totalBilledTime={totalBilledTime}
        estimatedPay={estimatedPay}
        isHourly={createShiftRequest.payType === ShiftPayType.HOURLY}
      />
    </Card>
  )
}
