import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { differenceInHours } from 'date-fns'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Col, Modal, Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getErrorMessage } from 'src/utils/errorUtils'
import { useAnalytics } from '../../hooks/useAnalytics'

type ClockOutModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
  sentinelNotificationToUserId?: string
}

export default function ClockOutModal({
  isOpen,
  handleClose,
  workerShift,
  sentinelNotificationToUserId,
}: ClockOutModalProps) {
  const [clockOutTime, setClockOutTime] = useState<Date>(
    workerShift.clockOutTimeBeforeWorkerEdit ?? workerShift.shiftInfo.endTime,
  )
  const { refetchActiveQueries } = useActiveQueries()

  const [loading, setLoading] = useState<boolean>(false)
  const { trackAnalytics } = useAnalytics()

  const { showSuccess, showError } = useAlert()

  const handleEndShift = async () => {
    if (
      differenceInHours(
        new Date(clockOutTime),
        new Date(workerShift.clockInTime || ''),
      ) > 24
    ) {
      const isConfirmed = window.confirm(
        `You are about to clock this person out for over 24 hours. Is that what you want to do?`,
      )

      if (!isConfirmed) {
        return
      }
    }

    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/end-shift`,
        {
          clockOutTime: clockOutTime,
          sentinelNotificationToUserId,
        },
      )
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken ClockOut', {
          sentinelNotificationToUserId,
          clockOutTime,
          workerId: workerShift.workerId,
          shiftId: workerShift.shiftId,
        })
      }
      showSuccess(`Clocked out worker successfully`)
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Clocking Out Worker')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={'End Worker Shift'}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Text variant="h5">Clock Out Time</Text>
          <Row style={{ marginTop: theme.space.sm }}>
            <Col>
              <Text style={{ marginBottom: theme.space.sm }} variant="caption">
                SHIFT LOCAL TIME
              </Text>
              <div style={{ width: '50%' }}>
                <DatePicker
                  showTimeFieldInPopover={true}
                  setDate={(clockOutTime) => {
                    if (clockOutTime) {
                      setClockOutTime(clockOutTime)
                    }
                  }}
                  isClearable={false}
                  inlineLabel={true}
                  label="Time"
                  date={clockOutTime}
                  timezone={workerShift.shiftInfo.timezone}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <ModalButtons
          handleClose={handleClose}
          handleConfirm={handleEndShift}
          loading={loading}
        />
      </Col>
    </Modal>
  )
}
