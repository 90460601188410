import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Col,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CancellationSource,
  Shift,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import { getShiftDateString } from '@traba/utils'
import { useMemo, useState } from 'react'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import { createShiftRequestEdits } from 'src/hooks/useShiftRequestEdits'
import { EditScheduleOption } from './EditScheduleOption'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSuccess: () => void
  onBack: () => void
  shifts?: Shift[]
}

export const EditScheduleRemoveRoles = (props: Props) => {
  const { shiftRequestParent, onBack, shifts } = props
  const shiftRequests = shiftRequestParent.shiftRequests
  const [selectedShiftRequest, setSelectedShiftRequest] =
    useState<ShiftRequest>()
  const [selectedShiftId, setSelectedShiftId] = useState<string>()
  const { showError } = useAlert()
  const [originalStartTime, setOriginalStartTime] = useState<Date>()

  const onConfirm = async () => {
    try {
      if (!selectedShiftRequest) {
        return showError('Please select a role to remove')
      }
      if (!originalStartTime) {
        return showError('Please select a date to remove from')
      }
      await createShiftRequestEdits({
        edit: {
          shiftRequestId: selectedShiftRequest?.shiftRequestId,
          editType: ShiftRequestEditType.ALL_FUTURE,
          cancellationSource: CancellationSource.Ops,
          originalStartTime: new Date(originalStartTime),
        },
      })
    } catch (error) {
      showError('Failed to cancel, please try again')
    }
  }

  const futureShiftsUnderSelectedShiftRequest = useMemo(
    () =>
      shifts?.filter(
        (s) =>
          s.shiftRequestId === selectedShiftRequest?.shiftRequestId && !!s.id,
      ),
    [selectedShiftRequest?.shiftRequestId, shifts],
  )

  return (
    <>
      <>
        <Text variant="h5" mb={theme.space.xxs}>
          Select which role you'd like to remove from schedule
        </Text>
        <Col gap={theme.space.xs}>
          {shiftRequests.map((sr) => (
            <EditScheduleOption
              key={sr.shiftRequestId}
              selected={
                selectedShiftRequest?.shiftRequestId === sr.shiftRequestId
              }
              title={sr.shiftRole}
              onClick={() => {
                setSelectedShiftRequest(sr)
              }}
            />
          ))}
        </Col>
      </>
      {selectedShiftRequest && (
        <>
          <Text variant="h5" mt={theme.space.lg}>
            Select after which date this role should be removed
          </Text>
          <InlineBanner
            style={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.xs,
            }}
            severity="info"
            text="For example, if you want cancel ALL SHIFTS for this role on and after Jun.30th, select Jun.30th"
          />

          {futureShiftsUnderSelectedShiftRequest?.map((shift) => (
            <Row
              alignCenter
              gap={theme.space.xxs}
              mt={theme.space.xxs}
              onClick={() => {
                setSelectedShiftId(shift.id)
                setOriginalStartTime(shift.startTime)
              }}
            >
              <RadioButton selected={selectedShiftId === shift.id} size={20} />
              <Text>
                {getShiftDateString(
                  shift.startTime,
                  shift.endTime,
                  shift.timezone,
                )}
              </Text>
            </Row>
          ))}
        </>
      )}
      <Row justifyBetween mt={theme.space.lg}>
        <Button onClick={onBack} variant={ButtonVariant.OUTLINED}>
          Back
        </Button>
        <Button onClick={onConfirm}>Confirm cancel</Button>
      </Row>
    </>
  )
}
