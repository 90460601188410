import { Invoice } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

async function getInvoice(invoiceId: string): Promise<Invoice | undefined> {
  try {
    const res = await trabaApi.get(`invoices/${invoiceId}`)
    return res.data
  } catch (error: any) {
    console.error('useInvoices -> getInvoices', error.message ?? error)
    throw error
  }
}

export function useInvoice(invoiceId: string) {
  const {
    isFetching,
    isLoading,
    isError,
    data: invoice,
    error,
    isFetched,
    refetch,
  } = useQuery<Invoice | undefined, Error>(
    [`invoice_${invoiceId}`],
    () => getInvoice(invoiceId),
    {
      retry: 1,
      refetchOnWindowFocus: true,
    },
  )

  return {
    isFetching,
    isError,
    invoice,
    error,
    isFetched,
    refetch,
    isLoading,
  }
}
