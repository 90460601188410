import { ChatMessagesResponse, UpdateChatMessageRequest } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export const updateChatMessage = async (
  request: UpdateChatMessageRequest,
  messageId: string,
) => {
  const response = await trabaApi.patch(`/chats/messages/${messageId}`, request)
  return response.data
}

const getMessages = async (summaryId: string) => {
  try {
    const response = await trabaApi.get(`/chats/${summaryId}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useMessages = (summaryId: string) => {
  const {
    isLoading,
    isError,
    data: messages,
    refetch,
    error,
    isFetched,
  } = useQuery<ChatMessagesResponse, Error>(['messages', summaryId], () =>
    getMessages(summaryId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    refetch,
    messages: messages?.messages,
  }
}
