import { Switch } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentStatus } from '@traba/types'
import { useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { Dropdown } from 'src/components/base/Dropdown'
import EarningsSummaryItem from 'src/components/EarningsSummaryItem/EarningsSummaryItem'
import { useEarningsSummaries } from 'src/hooks/useEarningsSummary'
import {
  EarningsSummaryResponse,
  useEarnings,
} from 'src/hooks/useEarningsSummary'
import { WorkerShiftAdjustmentFromEarningSummaryWrapper } from 'src/modals/AdjustmentModal/WorkerShiftAdjustmentFromEarningSummaryWrapper'
import { FilterProps, FiltersSection } from '../components/FiltersSection'
import {
  ResponseDisplayDialog,
  ResponseDisplayResponseType,
} from '../components/ResponseDisplayDialog'
import { EmptySearchResultsMessageContainer } from '../components/styles'

interface ClearingHouseProps {
  filterProps: FilterProps
}

export const ClearingHouse = (props: ClearingHouseProps) => {
  const { filterProps } = props
  const {
    shiftId,
    workerId,
    dateRange,
    companyIds,
    regionIds,
    excludeCompanies,
    paymentStatuses,
    wardenHalted,
    workerEditedOnly,
  } = filterProps

  /* Get Earnings */
  const {
    earningsSummaries = [],
    refetch,
    isLoading: earningsLoading,
    isFetched: earningsFetched,
  } = useEarningsSummaries({
    shiftId,
    workerId,
    after: dateRange[0]?.toISOString() ?? '',
    before: dateRange[1]?.toISOString() ?? '',
    regionIds: regionIds,
    companyIds: companyIds.map((c) => c.value as string),
    paymentStatuses: paymentStatuses.map((ps) => ps.value as PaymentStatus),
    wardenHalted,
    workerEditedOnly,
    excludeCompanies,
  })

  const [selectedEarnings, setSelectedEarnings] = useState<
    EarningsSummaryResponse[]
  >([])
  const [shouldInstantPay, setShouldInstantPay] = useState<boolean>(true)

  const [
    selectedEarningsForWorkerShiftAdjustment,
    setSelectedEarningsForWorkerShiftAdjustment,
  ] = useState<EarningsSummaryResponse | undefined>(undefined)

  const {
    paymentLoading,
    handlePayout,
    patchLoading,
    handleEarningsSummaryBulkPatch,
  } = useEarnings()

  const handleSelectEarning = (
    earningSummary: EarningsSummaryResponse,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setSelectedEarnings([...selectedEarnings, earningSummary])
    } else {
      setSelectedEarnings(
        selectedEarnings.filter(
          (es) => es.workerShiftId !== earningSummary.workerShiftId,
        ),
      )
    }
  }

  const [payoutResponses, setPayoutResponses] = useState<
    ResponseDisplayResponseType | undefined
  >()
  const [responseOpen, setResponseOpen] = useState<boolean>(false)

  const handlePayment = async () => {
    const isConfirmed = window.confirm(
      `Do you want to payout these worker shifts?`,
    )
    if (!isConfirmed) {
      return
    }
    const responses = await handlePayout(selectedEarnings, shouldInstantPay)
    setPayoutResponses(responses)
    await refetch()
    setSelectedEarnings([])
  }

  const handleSetWorkerShiftsToDoneOrNeedsReview = async (
    option: PaymentStatus.ManualComplete | PaymentStatus.NeedsReview,
  ) => {
    const isConfirmed = window.confirm(
      `Do you want to set these worker shifts to ${option} (will not allow pay in the future)?`,
    )
    if (!isConfirmed) {
      return
    }
    const responses = await handleEarningsSummaryBulkPatch(
      selectedEarnings,
      option,
    )
    setPayoutResponses(responses)
    await refetch()
    setSelectedEarnings([])
  }

  const handleResetEarningsStatus = async () => {
    const isConfirmed = window.confirm(
      `Do you want to reset the statuses of these worker shifts?`,
    )
    if (!isConfirmed) {
      return
    }
    const responses = await handleEarningsSummaryBulkPatch(
      selectedEarnings,
      'RESET_STATUS',
    )
    setPayoutResponses(responses)
    await refetch()
    setSelectedEarnings([])
  }

  const MARK_AS_OPTIONS = [
    {
      label: 'Unresolve',
      handleClick: handleResetEarningsStatus,
      disabled: selectedEarnings.length < 1,
    },
  ]

  const shouldHaveMarkAsDoneOption = selectedEarnings.some(
    (es) => es.paymentStatus !== PaymentStatus.ManualComplete,
  )
  const shouldHaveMarkAsNeedsReviewOption = selectedEarnings.some(
    (es) => es.paymentStatus !== PaymentStatus.NeedsReview,
  )

  if (shouldHaveMarkAsDoneOption) {
    MARK_AS_OPTIONS.push({
      label: 'Mark as Done',
      handleClick: () =>
        handleSetWorkerShiftsToDoneOrNeedsReview(PaymentStatus.ManualComplete),
      disabled: selectedEarnings.length < 1,
    })
  }

  if (shouldHaveMarkAsNeedsReviewOption) {
    MARK_AS_OPTIONS.push({
      label: 'Mark as Needs Review',
      handleClick: () =>
        handleSetWorkerShiftsToDoneOrNeedsReview(PaymentStatus.NeedsReview),
      disabled: selectedEarnings.length < 1,
    })
  }

  const allSelected =
    earningsSummaries.length > 0 &&
    selectedEarnings.length === earningsSummaries.length

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedEarnings([])
    } else {
      setSelectedEarnings(earningsSummaries)
    }
  }

  const handleCloseDialog = () => {
    setResponseOpen(false)
  }

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
        minWidth: '1100px',
      }}
    >
      <Text variant="h4">Clearing House</Text>
      <FiltersSection
        isLoading={!earningsFetched}
        onClickSearch={refetch}
        filterProps={filterProps}
      />
      <Row mt={theme.space.med} justifyBetween alignCenter>
        <Row alignCenter>
          <Text variant="h5">Search Results ({earningsSummaries.length})</Text>
          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ marginLeft: theme.space.xs }}
            onClick={() => setResponseOpen(true)}
          >
            Response
          </Button>
        </Row>
        <Row alignCenter>
          <Text
            variant="caption"
            style={{ fontSize: 12, marginRight: theme.space.xs }}
          >
            {selectedEarnings.length} Selected
          </Text>

          <Button
            variant={ButtonVariant.OUTLINED}
            mr={theme.space.xs}
            onClick={handleSelectAll}
          >
            {allSelected ? 'Unselect' : 'Select'} All
          </Button>
          <div
            style={{
              backgroundColor: theme.colors.Grey20,
              width: '2px',
              height: '50px',
              marginRight: theme.space.xs,
            }}
          />
          <Dropdown
            title="Mark as..."
            dropdownOptions={MARK_AS_OPTIONS}
            buttonVariant={ButtonVariant.OUTLINED}
            containerStyling={{ width: '150px' }}
            loading={patchLoading}
          />
          <Row alignCenter mr={theme.space.xs}>
            <Text variant="caption">Standard</Text>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={shouldInstantPay}
              onClick={(event) => {
                event.stopPropagation()
                setShouldInstantPay(!shouldInstantPay)
              }}
            />
            <Text variant="caption">Instant</Text>
          </Row>
          <Button
            variant={ButtonVariant.PURPLEGRADIENT}
            onClick={handlePayment}
            loading={paymentLoading}
            disabled={selectedEarnings.length === 0}
          >
            Process Payment For Selected
          </Button>
          <ResponseDisplayDialog
            isOpen={responseOpen}
            onClose={handleCloseDialog}
            response={payoutResponses}
          />
        </Row>
      </Row>
      {earningsLoading ? (
        <CircularProgress size={'medium'} />
      ) : earningsSummaries.length > 0 ? (
        <Virtuoso
          data={earningsSummaries}
          style={{ height: 'calc(100vh - 435px)', marginTop: theme.space.xs }} // this is setting a dynamic height to take up the viewport
          // aside from the height of components above. 435px will need to change if the height of other components changes
          totalCount={earningsSummaries.length}
          itemContent={(_, es) => (
            <Row mt={theme.space.xs}>
              <EarningsSummaryItem
                earningsSummary={es}
                key={`${es.workerShiftId}_${es.totalOwed}_${es.totalPaid}`}
                onSelect={(es, isSelected) =>
                  handleSelectEarning(es, isSelected)
                }
                isSelected={selectedEarnings.includes(es)}
                shouldInstantPay={shouldInstantPay}
                setPayoutResponses={setPayoutResponses}
                setSelectedEarningsForWorkerShiftAdjustment={
                  setSelectedEarningsForWorkerShiftAdjustment
                }
                hideEarningSummary
              />
            </Row>
          )}
        />
      ) : (
        <EmptySearchResultsMessageContainer>
          Earnings Matching Your Criteria Will Show Up Here
        </EmptySearchResultsMessageContainer>
      )}
      {selectedEarningsForWorkerShiftAdjustment && (
        <WorkerShiftAdjustmentFromEarningSummaryWrapper
          isOpen={true}
          handleClose={() =>
            setSelectedEarningsForWorkerShiftAdjustment(undefined)
          }
          workerId={selectedEarningsForWorkerShiftAdjustment.workerId}
          shiftId={selectedEarningsForWorkerShiftAdjustment.shiftId}
        />
      )}
    </Col>
  )
}
