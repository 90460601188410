import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { useDeepEffect } from '@traba/hooks'
import { theme } from '@traba/theme'
import {
  Company,
  Role,
  Locations,
  ParentInvoiceGroup,
  Roster,
  Shift,
  ShiftRequest,
  Worker,
  User,
  ShiftInvitationDto,
} from '@traba/types'
import { combineTwoDatesForDateAndTime } from '@traba/utils'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { trabaApi } from 'src/api/helpers'
import { Col, Row } from 'src/components/base'
import { useModal } from 'src/components/base/Modal/Modal'

import { useUserContext } from 'src/context/user/UserContext'
import { getQueryParams } from 'src/hooks/useApi'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { getShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import {
  CreateShiftRequest,
  useShiftRequests,
} from 'src/hooks/useShiftRequests'
import { CreateOrEditInvoiceGroupModal } from 'src/screens/CompanyDetailsScreen/components/CreateOrEditInvoiceGroupModal'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getErrorMessage } from 'src/utils/errorUtils'

import { validateDynamicOverbookAllowed } from 'src/utils/shiftFormUtils'

import { ConfirmShiftRequestCreationDialog } from './ConfirmShiftRequestCreationDialog'
import { CreateShiftRequestButton } from './CreateShiftRequestButton'
import { DuplicateShiftSection } from './DuplicateShiftSection'
import { InvoiceSection } from './InvoiceSection'
import { LocationSection } from './LocationSection'
import { RolesAndWorkersSection } from './RolesAndWorkersSection'
import { ScheduleDetailsSection } from './ScheduleDetailsSection'
import {
  getExistingShiftRequestFromSchedule,
  getShiftRequestForEditOrAdd,
  initialShiftRequest,
  populateCreateShiftRequestFromShiftRequestAndShift,
  validateShiftCreationForm,
  validateShiftCreationSchedule,
} from './utils'

type PostShiftFormProps = {
  company: Company
  locations?: Locations[]
  roles?: Role[]
  companyUsers?: User[]
  rosters?: Roster[]
  workersWorkedWithCompany?: Record<string, Worker[]>
  minHourlyPayRate: number
}

export const PostShiftFormWithSchedules = (props: PostShiftFormProps) => {
  const { company, locations, roles, companyUsers, rosters, minHourlyPayRate } =
    props
  const navigate = useNavigate()
  const { state } = useUserContext()
  const [search, setSearch] = useSearchParams()
  const [isDuplicateShift, setIsDuplicateShift] = useState(false)
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { activeInvoiceGroups, refetch: refetchInvoiceGroups } =
    useInvoiceGroups(company.companyId)
  const { showError } = useAlert()
  const createOrEditInvoiceGroupModal = useModal()
  const [createShiftRequests, setCreateShiftRequests] = useState<
    CreateShiftRequest[]
  >([initialShiftRequest(company)])
  const [selectedSingleShiftDates, setSelectedSingleShiftDates] = useState<
    Date[] | null
  >([])
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const [businessStartTime, setBusinessStartTime] = useState<Date | null>(null)
  const [actualStartTime, setActualStartTime] = useState<Date | null>(null)
  const [buttonTitle, setButtonTitle] = useState<string>('Create Shift Request')
  const [isInvalidBuffer, setIsInvalidBuffer] = useState<boolean>(false)
  const [duplicateShiftIdInput, setDuplicateShiftIdInput] = useState<string>(
    search.get('duplicateShiftId') || '',
  )
  const selectedLocation = locations?.find(
    (loc) => loc.locationId === createShiftRequests[0].locationId,
  )
  /* <--- These are for adding one off shift to schedule --->
  How it works: 
  1. User comes here with a shiftRequestParentId, we know it's adding one-off shifts to schedule
  2. We need some unchangeable data like location, invoice from existing shift request in this schedule
  3. We allow user to set roles, workers etc
  4. We combine the data from existing shift request with the new data and create new shift requests
  */
  const shiftRequestParentId = search.get('shiftRequestParentId')
  const isForAddSingleShiftToSchedule = !!shiftRequestParentId
  const [existingShiftRequest, setExistingShiftRequest] = useState<
    ShiftRequest | undefined
  >()

  useEffect(() => {
    const populateForAddSingleShiftToSchedule = async () => {
      const shiftRequestParentId = search.get('shiftRequestParentId')
      if (shiftRequestParentId) {
        const existingShiftRequest =
          await getExistingShiftRequestFromSchedule(shiftRequestParentId)
        if (existingShiftRequest) {
          setExistingShiftRequest(existingShiftRequest)
          setCreateShiftRequests((prev) => [
            { ...prev[0], locationId: existingShiftRequest.locationId },
          ])
        }
      }
    }
    populateForAddSingleShiftToSchedule()
  }, [])
  // <---END--->

  useEffect(() => {
    if (search.get('duplicateShiftId')) {
      handleDuplicateShift()
    }
  }, [])

  /**
   * Handlers
   */
  const handleCreateInvoiceGroupModalClose = async (
    newGroup?: ParentInvoiceGroup,
  ) => {
    await refetchInvoiceGroups()
    if (newGroup) {
      setCreateShiftRequests((prev) => {
        return prev.map((createShiftRequest) => ({
          ...createShiftRequest,
          parentInvoiceGroupId: newGroup.id,
        }))
      })
    }
    createOrEditInvoiceGroupModal.handleClose()
  }

  const [duplicateLoading, setDuplicateLoading] = useState(false)

  const handleDuplicateShift = async () => {
    setDuplicateLoading(true)
    setIsDuplicateShift(false)

    try {
      const res = await trabaApi.get<Shift | undefined>(
        `shifts/${duplicateShiftIdInput}`,
      )
      const shiftToDuplicate = res.data
      if (shiftToDuplicate === undefined) {
        throw Error('shift-not-found')
      }
      if (shiftToDuplicate?.companyId !== company.companyId) {
        showError(
          `The selected shift belongs to a different company, please select one for ${company.employerName}`,
          'Shift Does Not Belong To Company',
        )
        throw Error('wrong-company')
      }
      const shiftRequestResponse = await trabaApi.get<ShiftRequest | undefined>(
        `shift-requests/${shiftToDuplicate.shiftRequestId}`,
      )
      if (!shiftRequestResponse.data) {
        showError('Shift request not found')
        throw Error('shift-request-not-found')
      }
      const shiftRequest = shiftRequestResponse.data
      const duplicatedShiftReq =
        populateCreateShiftRequestFromShiftRequestAndShift(
          shiftRequest,
          shiftToDuplicate,
          createShiftRequests[0],
        )
      setCreateShiftRequests([duplicatedShiftReq])
      if (shiftToDuplicate.businessStartTime) {
        setBusinessStartTime(shiftToDuplicate.businessStartTime)
      }
      if (shiftToDuplicate.startTime) {
        setActualStartTime(shiftToDuplicate.startTime)
      }
      //If there are invitations we must fetch the workers and set invitedWorkers
      if (
        shiftRequest.shiftInvitations &&
        shiftRequest.shiftInvitations.length > 0
      ) {
        const workerIds: string[] = shiftRequest.shiftInvitations.flatMap(
          (invitation) => invitation.workers.map((worker) => worker.workerId),
        )

        const workersResponse = await trabaApi.post<PopulatedWorker[]>(
          '/workers/query-workers-with-details',
          {
            workerIds,
          },
        )
        const invitedWorkers = workersResponse.data

        if (invitedWorkers.length !== workerIds.length) {
          showError('Error fetching invited workers')
        }
        setWorkersToInvite(invitedWorkers)
      }

      setSearch({ duplicateShiftId: duplicateShiftIdInput })
      setIsDuplicateShift(true)
      window.analytics?.track(`Ops User Duplicated Shift`, {
        shiftId: duplicateShiftIdInput,
        initiatedBy: state.userProfile?.email || 'OPS',
      })
    } catch (err: unknown) {
      if (err instanceof Error && err.message === 'wrong-company') {
        setDuplicateShiftIdInput('')
      }
      showError(getErrorMessage(err), 'Error duplicating shift')
    } finally {
      setDuplicateLoading(false)
    }
  }

  useDeepEffect(() => {
    if (!workersToInvite.length) {
      setCreateShiftRequests((prevCreateShiftRequests) => {
        return prevCreateShiftRequests.map((prevCreateShiftRequest) => ({
          ...prevCreateShiftRequest,
          shiftInvitations: undefined,
        }))
      })
      return
    }
    setCreateShiftRequests((prevCreateShiftRequests) => {
      return prevCreateShiftRequests.map((prevCreateShiftRequest) => ({
        ...prevCreateShiftRequest,
        shiftInvitations: [
          {
            batch: 1,
            workers: workersToInvite.map((worker, index) => {
              const shiftInvitation: ShiftInvitationDto = {
                workerId: worker.id || worker.uid,
                index: index + 1,
              }
              return shiftInvitation
            }),
          },
        ],
      }))
    })
  }, [workersToInvite])

  const {
    bulkCreateShiftRequests,
    createShiftReqLoading,
    createShiftRequest: submitShiftRequest,
  } = useShiftRequests()
  const [isLoadingEatBuffer, setIsLoadingEatBuffer] = useState(false)

  const timezone = selectedLocation?.timezone || 'America/New_York'

  const addEarlyArrivalBuffer = async (
    shiftRequestId: string,
    overwriteShiftUpdates?: {
      businessStartTime: Date
      startTime: Date
      endTime: Date
    },
  ) => {
    if (!businessStartTime) {
      return
    }
    setIsLoadingEatBuffer(true)
    setButtonTitle('Adding early arrival buffer...')
    try {
      const shiftsResponse = await trabaApi.get<Shift[]>(
        `/shift-requests/${shiftRequestId}/shifts`,
      )
      const shifts = shiftsResponse.data
      const shiftUpdates = overwriteShiftUpdates ?? {
        businessStartTime: new Date(businessStartTime),
        startTime: createShiftRequests[0].schedules[0].startTime,
        endTime: createShiftRequests[0].schedules[0].endTime,
      }
      const shiftIds = shifts.map((s) => s.shiftId).join(',')
      const queryParamsString = getQueryParams([['shiftIds', shiftIds]])
      const res = await trabaApi.patch(`shifts${queryParamsString}`, {
        shiftUpdates,
      })
      return res.data
    } catch (e) {
      showError('Error adding early arrival buffer')
    } finally {
      setIsLoadingEatBuffer(false)
    }
  }

  const handleSubmitShiftRequest = async (navigateToCompany: boolean) => {
    try {
      setButtonTitle('Creating Shift Request...')
      if (selectedSingleShiftDates?.length) {
        const finalShiftRequests = createShiftRequests.flatMap((sr) => {
          return selectedSingleShiftDates?.map((shiftDate) => {
            const newStartTimeWithTimePersisted = combineTwoDatesForDateAndTime(
              shiftDate,
              sr.schedules[0].startTime,
            )
            const newEndTimeWithTimePersisted = combineTwoDatesForDateAndTime(
              shiftDate,
              sr.schedules[0].endTime,
            )

            return {
              ...(existingShiftRequest &&
                getShiftRequestForEditOrAdd(undefined, existingShiftRequest)),
              ...sr,
              schedules: sr.schedules.map((schedule) => ({
                ...schedule,
                startTime: newStartTimeWithTimePersisted,
                endTime: newEndTimeWithTimePersisted,
              })),
            }
          })
        })
        let firstShiftId = ''
        for (const sr of finalShiftRequests) {
          const response = await submitShiftRequest(sr)
          if (!firstShiftId) {
            firstShiftId = response.firstShiftId
          }
          if (businessStartTime) {
            const buffer = differenceInMinutes(
              businessStartTime,
              createShiftRequests[0].schedules[0].startTime,
            )
            if (buffer > 120) {
              showError(
                'Failed to set buffer because it is greater than 120 minutes.',
              )
              return
            }
            if (buffer > 0) {
              await addEarlyArrivalBuffer(response.shiftRequestId, {
                businessStartTime: addMinutes(
                  sr.schedules[0].startTime,
                  buffer,
                ),
                startTime: sr.schedules[0].startTime,
                endTime: sr.schedules[0].endTime,
              })
            }
          }
        }
        setButtonTitle('Create Shift Request')
        if (existingShiftRequest) {
          return navigate(
            `/schedule/${existingShiftRequest.shiftRequestParentId}`,
          )
        } else if (navigateToCompany) {
          return navigate(`/field-monitor/${firstShiftId}`)
        }
      } else {
        const response = await bulkCreateShiftRequests(createShiftRequests)
        const shiftRequestParent = await getShiftRequestParent(
          response.shiftRequestParentId,
        )
        if (shiftRequestParent) {
          await Promise.all(
            shiftRequestParent.shiftRequests.map((sr: ShiftRequest) =>
              addEarlyArrivalBuffer(sr.shiftRequestId),
            ),
          )
        }
        return navigate(`/schedule/${response.shiftRequestParentId}`)
      }

      setButtonTitle('Create Shift Request')
    } catch (e) {
      setButtonTitle('Create Shift Request')
      showError('Error creating shift request')
    }
  }

  if (isLoadingHotSettings) {
    return (
      <Row center>
        <CircularProgress size={theme.space.xxl} />
      </Row>
    )
  }

  const disableButton =
    createShiftRequests.every(
      (createShiftRequest) =>
        !validateShiftCreationForm(
          createShiftRequest,
          minHourlyPayRate,
          hotSettings?.paidBackupPayAmountMax,
        ) ||
        !validateDynamicOverbookAllowed(
          createShiftRequest.slotsRequested,
          !!createShiftRequest.enableDynamicOverbooking,
        ) ||
        isInvalidBuffer,
    ) ||
    !validateShiftCreationSchedule(
      selectedSingleShiftDates,
      createShiftRequests,
    )

  return (
    <Col>
      {!isForAddSingleShiftToSchedule && (
        <>
          <DuplicateShiftSection
            handleDuplicateShift={handleDuplicateShift}
            duplicateShiftIdInput={duplicateShiftIdInput}
            setDuplicateShiftIdInput={setDuplicateShiftIdInput}
            duplicateLoading={duplicateLoading}
          />
          <LocationSection
            setCreateShiftRequests={setCreateShiftRequests}
            createShiftRequests={createShiftRequests}
            locations={locations}
            timezone={timezone}
          />
        </>
      )}
      <ScheduleDetailsSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        selectedSingleShiftDates={selectedSingleShiftDates}
        setSelectedSingleShiftDates={setSelectedSingleShiftDates}
        timezone={timezone}
        actualStartTime={actualStartTime}
        businessStartTime={businessStartTime}
        setBusinessStartTime={setBusinessStartTime}
        isDuplicateShift={isDuplicateShift}
        isInvalidBuffer={isInvalidBuffer}
        setIsInvalidBuffer={setIsInvalidBuffer}
        defaultBreaks={company.defaultBreaks}
        selectedLocation={selectedLocation}
        isForAddSingleShiftToSchedule={isForAddSingleShiftToSchedule}
      />
      <RolesAndWorkersSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        roles={roles}
        companyId={company.companyId}
        companyUsers={companyUsers}
        minHourlyPayRate={minHourlyPayRate}
        businessStartTime={businessStartTime}
        workersToInvite={workersToInvite}
        setWorkersToInvite={setWorkersToInvite}
        rosters={rosters}
      />
      {!isForAddSingleShiftToSchedule && (
        <InvoiceSection
          createOrEditInvoiceGroupModal={createOrEditInvoiceGroupModal}
          createShiftRequests={createShiftRequests}
          setCreateShiftRequests={setCreateShiftRequests}
          activeInvoiceGroups={activeInvoiceGroups}
        />
      )}
      <CreateShiftRequestButton
        onClick={() => setShowConfirmationDialog(true)}
        buttonTitle={buttonTitle}
        isLoadingButton={createShiftReqLoading || isLoadingEatBuffer}
        disabledButton={disableButton}
      />
      <ConfirmShiftRequestCreationDialog
        showConfirmationDialog={showConfirmationDialog}
        setShowConfirmationDialog={setShowConfirmationDialog}
        handleSubmitShiftRequest={handleSubmitShiftRequest}
        createShiftRequests={createShiftRequests}
        timezone={timezone}
        businessStartTime={businessStartTime}
        selectedLocation={selectedLocation}
        companyId={company.companyId}
        selectedSingleShiftDates={selectedSingleShiftDates}
      />
      <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={company.companyId}
      />
    </Col>
  )
}
