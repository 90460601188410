import { Tooltip } from '@mui/material'
import { InfoTooltip } from '@traba/react-components'
import { CancellationSource } from '@traba/types'
import { JobStatus } from '@traba/types'
import {
  formatDateTimeWithTimezone,
  getLocalTimezone,
} from 'src/utils/dateUtils'
import { Badge, Row } from './base'
import { BadgeProps } from './base/Badge'
export interface JobStatusBadgeProps {
  jobStatus: JobStatus
  cancellationSource?: CancellationSource
  lateCancellation?: boolean
  rejectionReason?: string
  cancellationReason?: string
  canceledAt?: Date
  additionalBadgeProps?: Partial<BadgeProps>
  badgeTooltipText?: string
}

const statusToBadgeTitle = {
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'IN PROGRESS',
  TO_DO: 'TODO',
  ON_BREAK: 'ON BREAK',
  CANCELED: 'CANCELED',
  NO_SHOW: 'NO SHOW',
  WAITLIST: 'WAITLIST',
  ABANDONED: 'ABANDONED',
  REJECTED: 'REJECTED',
  APPEASED: 'APPEASED',
}

const cancellationSourceToTitle = {
  BUSINESS: 'BIZ',
  WORKER: 'WKR',
  SYSTEM: 'SYS',
  OPS: 'OPS',
}

export function getBadgeTitle(
  jobStatus: JobStatus,
  cancellationSource?: CancellationSource,
  lateCancellation?: boolean,
) {
  let badgeTitle = statusToBadgeTitle[jobStatus]

  if (jobStatus === JobStatus.Canceled && cancellationSource) {
    if (cancellationSource === CancellationSource.Worker && lateCancellation) {
      badgeTitle = `LATE ${badgeTitle}`
    } else {
      badgeTitle = `${cancellationSourceToTitle[cancellationSource]} ${badgeTitle}`
    }
  }
  return badgeTitle
}

export default function JobStatusBadge(props: JobStatusBadgeProps) {
  const {
    jobStatus,
    cancellationSource,
    lateCancellation,
    additionalBadgeProps,
    rejectionReason,
    cancellationReason,
    canceledAt,
    badgeTooltipText,
  } = props

  const statusToBadgeColor = {
    COMPLETE: 'success',
    IN_PROGRESS: 'warning',
    TO_DO: 'info',
    ON_BREAK: 'additional',
    CANCELED: 'danger',
    NO_SHOW: 'danger',
    WAITLIST: 'info',
    ABANDONED: 'opaqueRed',
    REJECTED: 'opaqueRed',
    APPEASED: 'business',
  }

  const badgeTitle = getBadgeTitle(
    jobStatus,
    cancellationSource,
    lateCancellation,
  )
  const badgeColor = statusToBadgeColor[jobStatus]

  return (
    <Row>
      <Tooltip title={badgeTooltipText}>
        <div>
          <Badge
            title={badgeTitle}
            variant={badgeColor}
            {...additionalBadgeProps}
          />
        </div>
      </Tooltip>
      {jobStatus === JobStatus.Rejected && rejectionReason && (
        <InfoTooltip title={`Reason: ${rejectionReason}`} />
      )}
      {jobStatus === JobStatus.Canceled && (
        <InfoTooltip
          title={`Canceled At: ${formatDateTimeWithTimezone(
            canceledAt,
            getLocalTimezone(),
          )} ${cancellationReason ? `- Reason: ${cancellationReason}` : ''}`}
        />
      )}
    </Row>
  )
}
