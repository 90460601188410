import { Badge, Fab } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import { NoteType } from '@traba/types'
import Quill from 'quill'
import { useEffect, useRef, useState } from 'react'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useNotes from 'src/hooks/useNotes'
import { useSlackUsers } from 'src/hooks/useSlack'
import { useMobile } from '../../hooks/useMobile'
import { Button, Col, Icon, Row } from '../base'
import { ButtonVariant } from '../base/Button/types'
import { SearchSelect } from '../base/SearchSelect/SearchSelect'
import { IMenuItem } from '../base/Select/Select'
import { TextEditor } from '../TextEditor/TextEditor'
import * as S from './NoteDrawer.styles'

export type CreateNoteParameters = { id: string; noteType: NoteType }
interface NoteDrawerProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: JSX.Element | JSX.Element[] | React.ReactNode
  createNoteParameters: CreateNoteParameters
  title?: string
  onClose?: () => void
  hideFAB?: boolean
  dualNote?: boolean
  noCloseButton?: boolean
  noteCount?: number
  companyNotes?: number
}

enum TextEditorType {
  RICH = 'rich',
  BASIC = 'basic',
}

const NoteDrawer = (props: NoteDrawerProps) => {
  const {
    isOpen,
    onClose,
    children,
    setIsOpen,
    createNoteParameters,
    hideFAB,
    dualNote,
    noCloseButton,
    noteCount,
    companyNotes,
  } = props
  const { isMobile } = useMobile()
  const { createNote } = useNotes()
  const [note, setNote] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showError } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()
  const { users = [] } = useSlackUsers()
  const userOptions: IMenuItem[] = (users || []).map((user) => {
    return { label: user.real_name, value: user.id }
  })
  const [selectedUsers, setSelectedUsers] = useState<IMenuItem[]>([])
  const [editorMode, setEditorMode] = useState<TextEditorType>(
    TextEditorType.RICH,
  )
  const textEditorRef = useRef<Quill>(null)

  useEffect(() => {
    const savedMode = localStorage.getItem('editorMode')
    if (savedMode) {
      setEditorMode(savedMode as TextEditorType)
    }
  }, [])

  const toggleEditorMode = () => {
    const newMode =
      editorMode === TextEditorType.RICH
        ? TextEditorType.BASIC
        : TextEditorType.RICH
    setEditorMode(newMode)
    localStorage.setItem('editorMode', newMode)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      if (!note) {
        showError('Can not create an empty note', 'Error Creating Note')
        throw new Error('Can not create an empty note')
      }
      await createNote(
        createNoteParameters.id,
        note,
        createNoteParameters.noteType,
        selectedUsers.map((user) => user.value),
      )
      setNote('')
      setSelectedUsers([])
      refetchActiveQueries()
      textEditorRef.current?.setText('')
    } catch {
      //Already handled in createNote
    }
    setIsLoading(false)
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        variant={isMobile ? 'temporary' : 'persistent'}
      >
        <S.DrawerHeader>
          <Row alignCenter justifyBetween>
            <Text variant="h4" style={{ marginLeft: theme.space.med }}>
              {props.title}
            </Text>
            {noCloseButton ? (
              <span style={{ height: 48 }} />
            ) : (
              <Button
                variant={ButtonVariant.TRANSPARENT}
                onClick={() => setIsOpen(!isOpen)}
                leftIcon={<Icon name="cancel" />}
              >
                Close
              </Button>
            )}
          </Row>
        </S.DrawerHeader>
        <Row>
          <S.DrawerContainer>
            <Col>
              <>
                {editorMode === 'basic' ? (
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    style={{
                      width: '100%',
                      height: '200px',
                      border: '1px solid #ccc',
                      padding: theme.space.xxs,
                      fontSize: theme.space.xs,
                      fontWeight: 500,
                    }}
                  />
                ) : (
                  <TextEditor
                    ref={textEditorRef}
                    defaultValue={note}
                    onTextChange={(text: string) => {
                      setNote(text)
                    }}
                    textAreaStyle={{
                      height: '100%',
                      overflow: 'auto',
                      resize: 'vertical',
                      minHeight: '160px',
                    }}
                    containerStyle={{
                      marginBottom: theme.space.sm,
                      overflowY: 'auto',
                    }}
                  />
                )}
                <Button
                  onClick={onSubmit}
                  full
                  loading={isLoading}
                  disabled={
                    note.length === 0 || /^<\w+?><\/\w+?>$/.test(note.trim()) // match any single empty HTML tag (ie <p></p> or <h1></h1>)
                  }
                >
                  Submit
                </Button>
              </>
            </Col>
            <Row
              fullWidth
              justifyBetween
              mt={theme.space.xs}
              px={theme.space.xxs}
            >
              <Text variant="label">
                {editorMode === 'rich'
                  ? 'Use basic mode if the editor is not working'
                  : ''}
              </Text>
              <Text
                onClick={toggleEditorMode}
                variant="link"
                style={{
                  cursor: 'pointer',
                }}
              >
                {editorMode === 'rich' ? 'Basic mode' : 'Rich mode'}
              </Text>
            </Row>
            <Col mt={theme.space.sm}>
              <SearchSelect
                multiple
                options={userOptions}
                selectedItems={selectedUsers}
                handleSelectMultiple={setSelectedUsers}
                label="Tagged Users"
                onlyShowLabel
              />
            </Col>
            <S.ExpandedWrapper>{children}</S.ExpandedWrapper>
          </S.DrawerContainer>
          {dualNote && <span style={{ width: 500 }}></span>}
        </Row>
      </Drawer>
      {!hideFAB && (
        <Badge
          badgeContent={companyNotes}
          color="warning"
          style={{
            position: 'fixed',
            top: 30,
            right: 30,
            zIndex: Z_INDEXES.FLOATING_BUTTON,
          }}
        >
          <Badge
            badgeContent={noteCount || 0}
            color="error"
            style={{
              position: 'fixed',
              top: 30,
              right: 65,
              zIndex: Z_INDEXES.FLOATING_BUTTON,
            }}
          ></Badge>
          <Fab
            color="default"
            style={{
              margin: 0,
              top: 20,
              right: 20,
              bottom: 'auto',
              left: 'auto',
              height: '50px',
              overflow: 'auto',
              position: 'fixed',
              zIndex: -1,
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <S.Rotated>
              <Icon name="message" size={18} />
            </S.Rotated>
          </Fab>
        </Badge>
      )}
    </>
  )
}

export default NoteDrawer
