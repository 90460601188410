import { BACKEND_URL } from '@traba/api-utils'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { handleDates } from 'src/shared/utils/dateUtils'

export const trabaApi = axios.create({
  baseURL: BACKEND_URL,
})

async function createGoogleTokenConfig(
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> {
  const googleToken = localStorage.getItem('opsConsoleIdToken')
  config.headers['Authorization'] = `Bearer ${googleToken}`
  config.headers['Accept'] = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  return config
}

trabaApi.interceptors.request.use(createGoogleTokenConfig, (error) => {
  Promise.reject(error)
})

trabaApi.interceptors.response.use(
  (response) => {
    handleDates(response.data)
    return response
  },
  (error) => {
    if (error.response) {
      console.error(error.response.data)
      throw error.response.data
    } else {
      console.error('Error', error.message)
      throw error
    }
  },
)
