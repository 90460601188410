import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { ModalButtons, Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { ShiftTime } from '@traba/types'
import {
  getBreaksForWorkerShift,
  shouldUseBreaksBeforeWorkerEdit,
} from '@traba/utils'
import { useEffect, useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Icon, Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { BreakAdjuster } from 'src/components/BreakAdjuster/BreakAdjuster'
import { JobStatus } from 'src/types'

type AdjustBreakModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
}

export default function AdjustBreakModal({
  isOpen,
  handleClose,
  workerShift,
}: AdjustBreakModalProps) {
  const { hotSettings } = useHotSettings()
  const enableWorkerEditTime = hotSettings?.enableWorkerEditTime
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showError, showSuccess } = useAlert()
  const [breaks, setBreaks] = useState<ShiftTime[]>(
    getBreaksForWorkerShift(workerShift, enableWorkerEditTime),
  )

  const hasNullBreakEndTime = breaks.find((b) => b.endTime === null) ?? false
  const updatedJobStatus = hasNullBreakEndTime
    ? JobStatus.OnBreak
    : JobStatus.InProgress

  async function handleAdjustBreaks() {
    try {
      setIsLoading(true)
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}`,
        {
          ...(shouldUseBreaksBeforeWorkerEdit(workerShift, enableWorkerEditTime)
            ? { breaksBeforeWorkerEdit: breaks }
            : { breaks }),
          jobStatus: updatedJobStatus,
        },
      )
      handleClose()
      showSuccess('Breaks were successfully adjusted', 'Success!')
    } catch (err: any) {
      showError(err.message, 'There was an error editing the breaks')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setBreaks(getBreaksForWorkerShift(workerShift, enableWorkerEditTime))
  }, [workerShift, enableWorkerEditTime])

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={'Adjust Breaks'}
    >
      <Row flexCol fullWidth justifyBetween>
        <Row flexCol>
          <BreakAdjuster
            breaks={breaks}
            setBreaks={setBreaks}
            timezone={workerShift.shiftInfo.timezone}
            defaultBreakTime={workerShift.shiftInfo.startTime}
          />

          {workerShift.jobStatus !== updatedJobStatus && (
            <Row mt={32}>
              <Icon name="info" />
              <Text variant="h7">
                Job status will be set to {updatedJobStatus}
              </Text>
            </Row>
          )}
        </Row>

        <Row>
          <ModalButtons
            confirmText={'Save Changes'}
            handleClose={handleClose}
            handleConfirm={handleAdjustBreaks}
            loading={isLoading}
          />
        </Row>
      </Row>
    </Modal>
  )
}
