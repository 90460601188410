import {
  SentinelNotification,
  ShiftNotificationSettingsForShift,
} from '@traba/types'
import { RoleAttribute } from '@traba/types'
import { useCallback, useMemo } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import { useBulkSentinelNotificationSettings } from 'src/hooks/useSentinelNotifications'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { FieldMonitorFiltersType as FieldMonitorFilters } from 'src/screens/FieldMonitorScreen/components/FieldMonitorFilters/FieldMonitorFilters'
import { Table } from '../base'
import { IMenuItem } from '../base/Select/Select'
import { CollapsibleShiftRow } from '../CollapsibleShiftRow'

export interface CollapsibleShiftTableProps {
  shifts: OpsExtendedShift[]
  sentinelNotifications?: {
    shiftId: string
    notifications: SentinelNotification[]
  }[]
  fieldMonitorFilters?: FieldMonitorFilters
  activeFilterCount?: number
  workerFirstName?: string
  workerLastName?: string
  disableCollapsible?: boolean
  roleAttributes?: RoleAttribute[]
  selectedShiftIds?: string[]
  setSelectedShiftIds?: React.Dispatch<React.SetStateAction<string[]>>
  assigneeDisplay?: IMenuItem[]
  assignedTo?: IMenuItem[]
  showSelect?: boolean
  ffMax?: IMenuItem[]
  uncollapseRow?: boolean
}

const isSentinelNotificationsMuted = (
  shiftId: string,
  sentinelNotificationSettings:
    | Record<string, ShiftNotificationSettingsForShift>
    | undefined,
  internalUserId?: string,
) => {
  return (
    sentinelNotificationSettings?.[shiftId]?.shiftSettings.shiftSilenced ||
    sentinelNotificationSettings?.[shiftId]?.userSpecificSettings[
      internalUserId ?? ''
    ]?.shiftSilenced
  )
}

export const CollapsibleShiftTable = (props: CollapsibleShiftTableProps) => {
  const { selectedShiftIds, setSelectedShiftIds, showSelect, shifts } = props
  const { sentinelNotifications, ...rest } = props

  const handleSelectShiftId = useCallback(
    (shiftId: string) => {
      setSelectedShiftIds &&
        setSelectedShiftIds((prevShiftIds) =>
          prevShiftIds.includes(shiftId)
            ? prevShiftIds.filter((id) => id !== shiftId)
            : [...prevShiftIds, shiftId],
        )
    },
    [setSelectedShiftIds],
  )

  const headers = [
    '',
    'Employer Name',
    'Short Location / Region',
    'Role',
    'date',
    'start time',
    'end time',
    'reliability',
    'on the way',
    'confirmed',
    'clock in',
    'clock out',
    'slots filled',
    'paid',
    'notes',
    '',
  ]
  const modifiedHeaders = showSelect ? headers : headers.slice(1)

  const shiftsToDisplay = useMemo(() => shifts, [shifts])

  const { state } = useUserContext()

  const { data: sentinelNotificationSettings } =
    useBulkSentinelNotificationSettings({
      shiftIds: shiftsToDisplay.map((shift) => shift.id),
      internalUserId: state.userProfile?.internalUser?.id,
    })

  const shiftComponents = useMemo(() => {
    return shiftsToDisplay.map((shift: OpsExtendedShift) => {
      const workersMovingOrArrived =
        shift.workerShifts?.filter(
          (ws) => !!ws.workerShiftTimeToDestination?.id,
        ).length || 0
      const propsList = {
        shift: { ...shift, workersMovingOrArrived },
        onSelectShift: setSelectedShiftIds ? handleSelectShiftId : undefined,
        isSelected: !!selectedShiftIds?.includes(shift.id),
        isSentinelNotificationsMuted: isSentinelNotificationsMuted(
          shift.id,
          sentinelNotificationSettings,
          state.userProfile?.internalUser?.id,
        ),
        sentinelNotifications: sentinelNotifications
          ?.filter((s) => s.shiftId === shift.id)
          .flatMap((s) => s.notifications)
          .filter(
            (notification) =>
              notification.sentinelNotificationToUser?.internalUserId &&
              notification.sentinelNotificationToUser.internalUserId ===
                state.userProfile?.internalUser?.id,
          ),
        ...rest,
      }
      return (
        <CollapsibleShiftRow
          {...propsList}
          key={`${shift.id}-${shift.createdAt}`}
        />
      )
    })
  }, [
    shiftsToDisplay,
    sentinelNotificationSettings,
    state.userProfile?.internalUser?.id,
    sentinelNotifications,
    setSelectedShiftIds,
    rest,
    selectedShiftIds,
    handleSelectShiftId,
  ])

  return <Table headers={modifiedHeaders}>{shiftComponents}</Table>
}
