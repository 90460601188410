import { Button, ButtonVariant, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AddRecurringWorkerShiftResponse } from '@traba/types'
import { truncateString } from '@traba/utils'
import { CopyTextIcon, DataTable } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface Props {
  responses: AddRecurringWorkerShiftResponse[]
  checkedWorkers: PopulatedWorker[]
  onDone: () => void
}

export const AddWorkerToScheduleResults = ({
  responses,
  checkedWorkers,
  onDone,
}: Props) => {
  return (
    <>
      <DataTable
        headers={['Name', 'Worker ID', 'Results']}
        rows={responses?.map((response) => {
          const { workerId, fulfilled, rejected } = response
          const worker = checkedWorkers.find((cw) => cw.id === workerId)
          if (!worker) {
            throw new Error(
              `Worker ${workerId} not found from the original add list`,
            )
          }
          const isNotAdded = !fulfilled || fulfilled.length === 0

          const isPartiallyAdded =
            fulfilled && rejected && fulfilled.length > 0 && rejected.length > 0
          const isFullyAdded =
            fulfilled &&
            fulfilled.length > 0 &&
            (!rejected || rejected.length === 0)

          return {
            key: workerId,
            cells: [
              {
                renderFn: () => (
                  <div>
                    {worker.firstName} {worker.lastName}
                  </div>
                ),
              },
              {
                renderFn: () => (
                  <>
                    {truncateString(workerId)}
                    <CopyTextIcon textToCopy={workerId} />{' '}
                  </>
                ),
              },
              {
                renderFn: () => {
                  return (
                    <>
                      {isNotAdded && (
                        <Text variant="error">
                          Worker is not added to schedule, nor any single shifts
                        </Text>
                      )}
                      {isPartiallyAdded && (
                        <>
                          <Text variant="error">
                            Worker is added to schedule, but is not added to
                            following dates
                          </Text>
                          {rejected.map((r) => (
                            <Text variant="body2" key={r}>
                              {r}
                            </Text>
                          ))}
                        </>
                      )}
                      {isFullyAdded && (
                        <Text variant="body1" color={theme.colors.Green80}>
                          Worker has been added to full schedule
                        </Text>
                      )}
                    </>
                  )
                },
              },
            ],
          }
        })}
      />
      <Row justifyEnd mt={theme.space.lg}>
        <Button variant={ButtonVariant.FILLED} onClick={onDone}>
          Done
        </Button>
      </Row>
    </>
  )
}
