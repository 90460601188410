import { List } from '@mui/material'
import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { memo, useState } from 'react'
import { Icon, Col, Row, IconButton, CopyTextIcon } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import { WorkerListItem } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/WorkerListItem'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { truncateString } from 'src/utils/stringUtils'
import { getWorkersPlurality } from './utils/utils'

interface RosterRowProps {
  id: string
  name: string
  numWorkers: number
  selected: boolean
  workers: PopulatedWorker[]
  onClick: () => void
}

export const RosterRow: React.FC<RosterRowProps> = memo((props) => {
  const { id, name, numWorkers, selected, workers, onClick } = props
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <Card
      key={id}
      style={{
        padding: theme.space.xs,
        marginBottom: theme.space.xxs,
      }}
    >
      <Row alignCenter fullWidth>
        <IconButton
          mr={theme.space.xxs}
          style={{
            width: theme.space.lg,
            height: theme.space.lg,
          }}
          onClick={() => setExpanded((prevState) => !prevState)}
          iconName={expanded ? 'chevronUp' : 'chevronDown'}
        />
        <Row
          alignCenter
          fullWidth
          style={{ marginRight: theme.space.xxs, cursor: 'pointer' }}
          onClick={onClick}
        >
          <Row
            alignCenter
            justifyCenter
            style={{
              width: theme.space.lg,
              height: theme.space.lg,
              borderRadius: theme.border.radius,
              border: `2px solid ${theme.colors.Grey20}`,
            }}
          >
            <Icon name="workers" />
          </Row>
          <Col
            style={{
              marginLeft: theme.space.xs,
              bottom: 3,
            }}
          >
            <Row alignCenter>
              <Text
                color={theme.colors.MidnightBlue}
                variant="h6"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {name}
              </Text>
              <Text variant="caption" style={{ marginLeft: theme.space.xxs }}>
                {truncateString(id)}
                <CopyTextIcon textToCopy={id} />
              </Text>
            </Row>
            <Text
              variant="body3"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${numWorkers} ${getWorkersPlurality(numWorkers)}`}
            </Text>
          </Col>
        </Row>
        <Checkbox
          onChange={onClick}
          checked={selected}
          style={{ bottom: 12, marginLeft: 'auto' }}
        />
      </Row>
      {expanded && (
        <List
          sx={{
            maxHeight: 500,
            overflow: 'auto',
            padding: 0,
            marginTop: 2,
          }}
        >
          {workers.map((worker: PopulatedWorker, i: number) => (
            <WorkerListItem
              key={`${id}-${worker.id}`}
              customKey={`checkbox-${id}-${worker.id}`}
              worker={worker}
              index={i}
            />
          ))}
        </List>
      )}
    </Card>
  )
})
