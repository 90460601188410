import { FormControlLabel, Switch, Tabs, Tooltip } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { TabPanel, TabsContainer } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  IncentiveRules,
  IncentiveStatus,
  IncentiveTypeIds,
  RuleLine,
  ValueType,
} from '@traba/types'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant } from 'src/components/base/Button/types'
import { internalUserHasOpsLeadPrivileges } from 'src/utils/sentinelNotificationUtils'
import {
  Badge,
  Button,
  CopyTextIcon,
  Icon,
  Input,
  Row,
} from '../../components/base'
import Pagination from '../../components/base/Pagination/Pagination'
import { SearchSelect } from '../../components/base/SearchSelect/SearchSelect'
import { IMenuItem } from '../../components/base/Select/Select'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import { useUserContext } from '../../context/user/UserContext'
import {
  IncentiveFromSearch,
  IncentiveOrFields,
  IncentivesSearchParams,
  runSearchIncentives,
  useSearchIncentives,
} from '../../hooks/useIncentives'
import { SortOrder } from '../../hooks/usePagination'
import { useRegions } from '../../hooks/useRegions'
import { getMoneyString, truncateString } from '../../utils/stringUtils'
import * as S from './styles'

export interface IncentivesFormData {
  [key: string]: any
}

const getBadgeColor = (status: string) => {
  switch (status) {
    case 'EXPIRED':
      return 'warning'
    case 'INACTIVE':
      return 'disabled'
    case 'ACTIVE':
      return 'success'
    default:
      return 'info'
  }
}

const BaseRenderCell = ({ value }: { value: any }) => (
  <Row justifyBetween fullWidth>
    <Tooltip title={value}>
      <div>
        <Text
          style={{
            maxWidth: '30ch',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Text>
      </div>
    </Tooltip>
  </Row>
)

const RegionRenderCell = ({ value }: { value: any }) => {
  const regions = value
  const additionalRegions = regions.length - 1

  if (!regions.length) {
    return (
      <Row justifyBetween>
        <Tooltip
          title={
            'This incentive is not locked to a specific set of regions and should be manually granted'
          }
        >
          <div>
            <Badge
              variant={'info'}
              title={'Global'}
              style={{ minWidth: 80, height: 25, borderRadius: 8 }}
            />
          </div>
        </Tooltip>
      </Row>
    )
  }
  return (
    <Row justifyBetween>
      <Tooltip title={regions.join(', ')}>
        <div>
          <Row alignCenter>
            <Badge
              variant={'info'}
              title={regions[0]}
              style={{ minWidth: 80, height: 25, borderRadius: 8 }}
            />
            {additionalRegions > 0 && (
              <Text
                style={{
                  marginLeft: theme.space.xxxs,
                }}
              >
                +{additionalRegions}
              </Text>
            )}
          </Row>
        </div>
      </Tooltip>
    </Row>
  )
}

const columnsCommon: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 240,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
    flex: 1,
  },
  {
    field: 'internalMemo',
    headerName: 'Internal Memo',
    width: 160,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
    flex: 1,
  },
  {
    field: 'incentiveId',
    headerName: 'Incentive ID',
    width: 160,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{truncateString(params.value, 12)}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'total',
    headerName: 'Amount',
    width: 80,
    renderCell: (params) => params.value,
  },
  {
    field: 'rules',
    headerName: 'Rules count',
    width: 120,
    renderCell: (params) => {
      const rules = params.value
        .map((rule: { ruleLine: RuleLine }) => rule.ruleLine.type)
        .join(', ')
      return (
        <Row justifyBetween>
          <Tooltip title={rules}>
            <div>
              <Text>{params.value.length}</Text>
            </div>
          </Tooltip>
        </Row>
      )
    },
  },
  {
    field: 'regionIds',
    headerName: 'Regions',
    width: 120,
    renderCell: (params) => <RegionRenderCell value={params.value} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween>
        <Badge
          variant={getBadgeColor(params.value)}
          title={params.value}
          style={{ minWidth: 80, height: 25, borderRadius: 8 }}
        />
      </Row>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Date',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 200 }}>
        <Text>{format(new Date(params.value), 'MMM dd, yyyy hh:mm a')}</Text>
      </Row>
    ),
    minWidth: 200,
  },
]

const columnsShifts: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 240,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
    flex: 1,
  },
  {
    field: 'internalMemo',
    headerName: 'Internal Memo',
    width: 160,
    renderCell: (params) => <BaseRenderCell value={params.value} />,
    flex: 1,
  },
  {
    field: 'shiftId',
    headerName: 'Shift ID',
    width: 160,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{params.value ? truncateString(params.value, 12) : '-'}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'total',
    headerName: 'Amount\n(hr or cash)',
    width: 80,
    renderCell: (params) => params.value,
  },
  {
    field: 'regionIds',
    headerName: 'Regions',
    width: 120,
    renderCell: (params) => <RegionRenderCell value={params.value} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween>
        <Badge
          variant={getBadgeColor(params.value)}
          title={params.value}
          style={{ minWidth: 80, height: 25, borderRadius: 8 }}
        />
      </Row>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Date',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 200 }}>
        <Text>{format(new Date(params.value), 'MMM dd, yyyy hh:mm a')}</Text>
      </Row>
    ),
    minWidth: 200,
  },
]

const makeIncentivesRow = (incentive: IncentiveFromSearch) => {
  return {
    id: incentive.id,
    title: incentive.title,
    description: incentive.description,
    internalMemo: incentive.internalMemo,
    total:
      incentive.totalAmount &&
      incentive.totalCurrency &&
      getMoneyString({
        amount: incentive.totalAmount,
        currency: incentive.totalCurrency,
      }),
    shiftId: incentive.shiftId,
    rules: incentive.rules,
    status: incentive.status,
    endTime: incentive.endTime,
    incentiveId: incentive.id,
    regionIds: incentive.regionIds,
  }
}

const SELECT_FIELDS = {
  incentive: [
    'id',
    'status',
    'title',
    'description',
    'internalMemo',
    'typeId',
    'valueType',
    'startTime',
    'endTime',
    'shiftId',
    'totalAmount',
    'totalCurrency',
    'regionIds',
  ],
}

const SELECT_FIELDS_FOR_COUNT = {
  incentive: ['id'],
}

const ACTIVE_OR_FIELDS: IncentiveOrFields[] = [
  IncentiveOrFields.id,
  IncentiveOrFields.shiftId,
  IncentiveOrFields.title,
  IncentiveOrFields.description,
  IncentiveOrFields.ruleType,
]

enum TabNames {
  referral = 'referral',
  shift = 'shift',
  appeasement = 'appeasement',
  engagement = 'engagement',
  misc = 'misc',
}

/**
 * Incentives are grouped in 4 tabs with specific filtering criteria
 * We need to always show the total found for each tab, but we only fetch and paginate the incentives for the active tab
 * > Referrals: has the rule type 'worker_referral' and the typeId 'dXgY'
 * > Shift Incentives: has the typeId 'shift_cash' or 'shift_hrly'
 * > Appeasements: has the typeId 'appeasement'
 * > Engagement: has the typeId 'engagement'
 * > Misc: all other incentives
 */
const incentivesQueries = {
  referral: {
    ruleTypes: [IncentiveRules.WorkerReferral],
    typeIds: [IncentiveTypeIds.dXgY],
  },
  shift: {
    typeIds: [IncentiveTypeIds.shift_cash, IncentiveTypeIds.shift_hrly],
  },
  appeasement: {
    typeIds: [IncentiveTypeIds.appeasement],
    valueTypes: [ValueType.Voucher],
  },
  engagement: {
    typeIds: [IncentiveTypeIds.engagement],
    valueTypes: [ValueType.Voucher],
  },
  misc: {
    valueTypes: [ValueType.Voucher, ValueType.Cash, ValueType.Rate],
    typeIds: [IncentiveTypeIds.dXgY, IncentiveTypeIds.engagement],
    // All types but worker referral
    ruleTypes: [
      IncentiveRules.CertificationRequired,
      IncentiveRules.CompanyExclusive,
      IncentiveRules.HasCompletedIncentive,
      IncentiveRules.HasCompletedShift,
      IncentiveRules.LifeTimeShiftCompletions,
      IncentiveRules.NewAccount,
      IncentiveRules.ProgressOnly,
      IncentiveRules.ShiftGroup,
      IncentiveRules.ShiftsCompletionRequired,
    ],
  },
}

function mapTabIndexToTabName(index: number) {
  switch (index) {
    case 0:
      return TabNames.referral
    case 1:
      return TabNames.shift
    case 2:
      return TabNames.appeasement
    case 3:
      return TabNames.engagement
    case 4:
      return TabNames.misc
    default:
      return TabNames.referral
  }
}

export default function IncentivesMonitor() {
  const { regions = [], isLoading: isLoadingRegions } = useRegions()
  const [form, setForm] = useState<IncentivesFormData>({})
  const [currentTab, setCurrentTab] = useState(0)
  const [activeOnly, setActiveOnly] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText)
  const [counters, setCounters] = useState({
    referral: 0,
    shift: 0,
    appeasement: 0,
    engagement: 0,
    misc: 0,
  })
  const [isLoadingCounters, setIsLoadingCounters] = useState(false)
  const { state } = useUserContext()

  const navigate = useNavigate()

  const PAGE_SIZE = 20

  const handleRegionSelect = (items: IMenuItem[]) => {
    setForm((prevForm) => ({ ...prevForm, regionIds: items }))
  }

  const handleActiveOnlyToggle = () => {
    setActiveOnly(!activeOnly)
  }

  const activeSearchParams: IncentivesSearchParams = {
    statuses: activeOnly
      ? [IncentiveStatus.Active]
      : [
          IncentiveStatus.Active,
          IncentiveStatus.Inactive,
          IncentiveStatus.Expired,
        ],
    regionIds: form.regionIds?.length
      ? form.regionIds.map((r: { value: string }) => r.value)
      : undefined,
    ...incentivesQueries[mapTabIndexToTabName(currentTab)],
  }

  /**
   * Fetch the total count of incentives for each tab except the active one
   */
  const fetchInactiveCounters = async () => {
    setIsLoadingCounters(true)
    const inactiveTabs = [0, 1, 2, 3, 4].filter((tab) => tab !== currentTab)

    /** Build the specific parameters for each tab */
    const inactiveSearchParams = inactiveTabs.map((tab) => {
      const tabName = mapTabIndexToTabName(tab)
      return {
        name: tabName,
        params: {
          statuses: activeOnly
            ? [IncentiveStatus.Active]
            : [
                IncentiveStatus.Active,
                IncentiveStatus.Inactive,
                IncentiveStatus.Expired,
              ],
          regionIds: form.regionIds?.length
            ? form.regionIds.map((r: { value: string }) => r.value)
            : undefined,
          ...incentivesQueries[tabName],
        },
      }
    })

    /** Fetch the counter for each inactive tab */
    const inactiveIncentives = await Promise.all(
      inactiveSearchParams.map(async (tabParams) => {
        const data = await runSearchIncentives(
          tabParams.params,
          {
            sortBy: 'startTime',
            sortOrder: SortOrder.asc,
            limit: 1,
            offset: 0,
          },
          ACTIVE_OR_FIELDS,
          searchText,
          SELECT_FIELDS_FOR_COUNT,
        )
        return {
          tabName: tabParams.name,
          count: data?.count,
        }
      }),
    )

    /** Map the counters */
    const inactiveCounters = inactiveIncentives.reduce(
      (acc, { tabName, count }) => ({
        ...acc,
        [tabName]: count,
      }),
      {},
    ) as {
      referral: number
      shift: number
      appeasement: number
      engagement: number
      misc: number
    }
    setCounters({
      referral: inactiveCounters?.referral ?? counters?.referral,
      shift: inactiveCounters?.shift ?? counters?.shift,
      appeasement: inactiveCounters?.appeasement ?? counters?.appeasement,
      engagement: inactiveCounters?.engagement ?? counters?.engagement,
      misc: inactiveCounters?.misc ?? counters?.misc,
    })
    setIsLoadingCounters(false)
  }

  const {
    incentives: incentivesFound,
    isLoading: isLoadingIncentives,
    totalFound,
    goToPreviousPage,
    goToNextPage,
    setCurrentPage,
    currentPage,
  } = useSearchIncentives({
    textSearchValue: debouncedSearchText,
    params: activeSearchParams,
    activeOrFields: ACTIVE_OR_FIELDS,
    paginationParams: {
      sortBy: 'startTime',
      sortOrder: SortOrder.asc,
      limit: PAGE_SIZE,
      offset: 0,
    },
    select: SELECT_FIELDS,
    fullTextSearchParam: debouncedSearchText,
  })

  useEffect(() => {
    fetchInactiveCounters()
  }, [currentTab, form.regionIds, activeOnly, incentivesFound])

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    setSearchText(text)
  }

  const debouncedSetSearchText = useMemo(
    () => debounce(setDebouncedSearchText, 500),
    [],
  )

  useEffect(() => {
    debouncedSetSearchText(searchText)
  }, [searchText])

  const isLoading = isLoadingRegions || isLoadingIncentives

  const rows = useMemo(() => {
    return incentivesFound.map(makeIncentivesRow)
  }, [incentivesFound])

  const CounterBadge = ({
    tabName,
    isActive,
  }: {
    tabName: TabNames
    isActive: boolean
  }) => {
    const tabCounter =
      isLoadingCounters || isLoadingIncentives
        ? '...'
        : mapTabIndexToTabName(currentTab) === tabName
          ? totalFound
          : counters[tabName]
    return (
      <Badge
        variant={isActive ? 'brand' : 'disabled'}
        title={`${tabCounter}`}
        style={{ minWidth: 40, height: 25, borderRadius: 8 }}
      />
    )
  }

  const handleTabChange = (newValue: number) => {
    setCurrentTab(newValue)
    setCurrentPage(0)
  }

  const sx = {
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
  }

  const canCreateIncentives = internalUserHasOpsLeadPrivileges(
    state.userProfile?.internalUser,
  )

  return (
    <MainLayout title="Incentives Monitor">
      <Text variant="h4">Incentives Monitor</Text>
      <Row alignCenter justifyBetween mt={theme.space.med} fullWidth>
        <Row alignCenter>
          <div />
          <Input
            label={'Search by Title, Description, Rule or shift ID'}
            id="search_worker"
            width="500px"
            containerStyle={{
              width: 500,
              marginRight: theme.space.xs,
            }}
            value={searchText}
            onChange={handleInput}
            autoComplete="off"
          />
          <Button onClick={() => setSearchText('')}>Clear</Button>
        </Row>
        <Row alignCenter>
          <SearchSelect
            options={regions.map((r) => ({
              label: r.displayName,
              value: r.regionId,
            }))}
            selectedItems={
              form && form.regionIds?.length > 0 ? form.regionIds : []
            }
            handleSelectMultiple={handleRegionSelect}
            label={'Target regions'}
            onlyShowLabel
            multiple
            width={200}
            disabled={isLoading}
          />
          {canCreateIncentives && (
            <Button
              variant={ButtonVariant.FILLED}
              leftIcon={<Icon name="plus" />}
              onClick={() => navigate('/incentives/new')}
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 180 }}
            >
              Create New
            </Button>
          )}
        </Row>
      </Row>

      <TabsContainer>
        <Tabs value={currentTab}>
          <Row alignCenter>
            <S.Tab label="Referrals" onClick={() => handleTabChange(0)} />
            <CounterBadge
              tabName={TabNames.referral}
              isActive={currentTab === 0}
            />
          </Row>
          <Row alignCenter>
            <S.Tab
              label="Shift Incentives"
              onClick={() => handleTabChange(1)}
            />
            <CounterBadge
              tabName={TabNames.shift}
              isActive={currentTab === 1}
            />
          </Row>
          <Row alignCenter>
            <S.Tab label="Appeasements" onClick={() => handleTabChange(2)} />
            <CounterBadge
              tabName={TabNames.appeasement}
              isActive={currentTab === 2}
            />
          </Row>
          <Row alignCenter>
            <S.Tab label="Engagement" onClick={() => handleTabChange(3)} />
            <CounterBadge
              tabName={TabNames.engagement}
              isActive={currentTab === 3}
            />
          </Row>
          <Row alignCenter>
            <S.Tab label="Misc" onClick={() => handleTabChange(4)} />
            <CounterBadge tabName={TabNames.misc} isActive={currentTab === 4} />
          </Row>
        </Tabs>
      </TabsContainer>

      <Row alignCenter fullWidth justifyBetween mb={theme.space.med}>
        <Row alignCenter>
          <Text variant="h6" mr={theme.space.xs}>
            {isLoadingIncentives ? `...` : `${totalFound} found`}
          </Text>
          <FormControlLabel
            control={
              <Switch
                checked={activeOnly}
                onChange={handleActiveOnlyToggle}
                color="primary"
              />
            }
            label="Show Only Active Incentives"
            style={{ marginLeft: theme.space.xs }}
          />
        </Row>

        <Pagination
          dataSize={incentivesFound.length}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          page={currentPage}
          pageSize={PAGE_SIZE}
          totalFound={totalFound}
        />
      </Row>
      <TabPanel value={currentTab} index={0}>
        <DataGrid
          onRowClick={(row) => navigate(`/incentives/${row.id}`)}
          rows={rows}
          columns={columnsCommon}
          loading={isLoading}
          autoHeight
          hideFooterPagination
          disableColumnFilter
          sx={sx}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <DataGrid
          onRowClick={(row) => navigate(`/incentives/${row.id}`)}
          rows={rows}
          columns={columnsShifts}
          loading={isLoading}
          autoHeight
          hideFooterPagination
          disableColumnFilter
          sx={sx}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <DataGrid
          onRowClick={(row) => navigate(`/incentives/${row.id}`)}
          rows={rows}
          columns={columnsCommon}
          loading={isLoading}
          autoHeight
          hideFooterPagination
          disableColumnFilter
          sx={sx}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <DataGrid
          onRowClick={(row) => navigate(`/incentives/${row.id}`)}
          rows={rows}
          columns={columnsCommon}
          loading={isLoading}
          autoHeight
          hideFooterPagination
          disableColumnFilter
          sx={sx}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={4}>
        <DataGrid
          onRowClick={(row) => navigate(`/incentives/${row.id}`)}
          rows={rows}
          columns={columnsCommon}
          loading={isLoading}
          autoHeight
          hideFooterPagination
          disableColumnFilter
          sx={sx}
        />
      </TabPanel>
    </MainLayout>
  )
}
