import { theme } from '@traba/theme'
import styled from 'styled-components'

export const ParamsContainer = styled.div`
  margin-top: ${theme.space.xs}px;
  margin-bottom: ${theme.space.xxs}px;
  background-color: ${({ theme }) => theme.colors.Grey10};
  border-radius: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
`

export const InputsWrapper = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(220px, 260px));
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: ${theme.space.xs}px;
`
