import { useAlert } from '@traba/context'
import { CreateRosterData, Roster } from '@traba/types'
import { WorkerDetails } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export type RosterWithWorkers = {
  id: string
  rosterName: string
  companyId: string
  workers: WorkerDetails[]
}

export type UpdateRosterDto = {
  rosterId?: string
  rosterName?: string
  addWorkers?: string[]
  removeWorkers?: string[]
}

export function getRostersPath(companyId: string) {
  return `/companies/${companyId}/rosters`
}

const getRosters = async (companyId: string) => {
  const response = await trabaApi.get(getRostersPath(companyId))
  return response.data
}

const deleteRoster = async (companyId: string, rosterId: string) => {
  const response = await trabaApi.delete(
    `/companies/${companyId}/rosters/${rosterId}`,
  )
  return response.data
}

export const useRosters = (companyId: string) => {
  const queryClient = useQueryClient()
  const { handleError } = useAlert()
  const {
    isLoading,
    isError,
    data: rosters,
    error,
  } = useQuery<Roster[], Error>(getRostersPath(companyId), () => {
    try {
      return getRosters(companyId)
    } catch (error) {
      handleError(
        error,
        'useRosters -> deleteRoster()',
        'There was an error deleting the roster. Please try again or contact support if the issue persists.',
        'Error deleting roster',
      )
      return []
    }
  })

  const createRosterMutation = useMutation<
    Roster,
    AxiosError,
    CreateRosterData
  >(
    async (newRoster) => {
      const res = await trabaApi.post(
        `companies/${companyId}/rosters`,
        newRoster,
      )
      return res.data
    },
    {
      onSuccess: (data: Roster) => {
        queryClient.setQueryData(
          getRostersPath(companyId),
          (currentRosters: Roster[] | undefined) => {
            return [...(currentRosters || []), data]
          },
        )
      },
    },
  )

  const deleteRosterMutation = useMutation<Roster, AxiosError, string, Roster>(
    (rosterId: string) => deleteRoster(companyId, rosterId),
    {
      onSuccess: (response: Roster) => {
        queryClient.setQueryData(getRostersPath(companyId), (rosters: any) =>
          rosters.filter((roster: Roster) => roster.id !== response.id),
        )
      },
      onError: (error) => {
        handleError(
          error,
          'useRosters -> deleteRoster()',
          'There was an error deleting the roster. Please try again or contact support if the issue persists.',
          'Error deleting roster',
        )
      },
    },
  )

  const updateRosterMutation = useMutation<Roster, AxiosError, UpdateRosterDto>(
    async (req) => {
      const { rosterId, ...rest } = req
      const res = await trabaApi.patch(
        `/companies/${companyId}/rosters/${rosterId}`,
        rest,
      )
      return res.data
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          getRostersPath(companyId),
          (currentRosters: any) =>
            currentRosters.map((currentRoster: { id: string }) =>
              currentRoster.id === response.id ? response : currentRoster,
            ),
        )
      },
      onError: (error) => {
        handleError(
          error,
          'useRosters -> updateRoster()',
          'There was an error updating the roster. Please try again or contact support if the issue persists.',
          'Error updating roster',
        )
      },
    },
  )

  return {
    isLoading,
    isError,
    error,
    rosters: rosters || [],
    createRoster: createRosterMutation.mutateAsync,
    removeRoster: deleteRosterMutation.mutateAsync,
    updateRoster: updateRosterMutation.mutateAsync,
  }
}
