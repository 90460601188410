import { CircularProgress } from '@mui/material'
import { theme } from '@traba/theme'
import {
  CancellationSource,
  IMenuItem,
  JobStatus,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { useMemo, useState } from 'react'
import {
  Button,
  CopyTextIcon,
  DataTable,
  Icon,
  Link,
  Row,
} from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { SearchSelect } from 'src/components/base/SearchSelect/SearchSelect'
import { TableCell, TableRow } from 'src/components/base/Table/DataTable'
import EarningsSummaryPaymentIcon from 'src/components/EarningsSummaryPaymentIcon'
import InlineClockIn from 'src/components/InlineClockIn'
import InlineClockOut from 'src/components/InlineClockOut'
import JobStatusBadge, { getBadgeTitle } from 'src/components/JobStatusBadge'
import {
  WorkerShiftDateCell,
  WorkerShiftStartTimeCell,
} from 'src/components/ShiftTableCells'
import { WorkerAction } from 'src/components/WorkerAction/WorkerAction'
import { WorkerNoteDrawer } from 'src/components/WorkerNoteDrawer'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useShift } from 'src/hooks/useShifts'
import { AdjustmentModal, ClockInModal, ClockOutModal } from 'src/modals'
import BulkActionModal, {
  CancelInfo,
} from 'src/modals/BulkActionModal/BulkActionModal'
import { toDate } from 'src/utils/dateUtils'
import { getPayRateString, truncateString } from 'src/utils/stringUtils'
import Toggle from '../../../components/base/Toggle'

const shiftStatusOptions: IMenuItem[] = Object.values(JobStatus)
  .flatMap<Parameters<typeof getBadgeTitle>>((status) => {
    if (status === JobStatus.Canceled) {
      return Object.values(CancellationSource).flatMap<
        Parameters<typeof getBadgeTitle>
      >((source) => {
        // if the shift was canceled by the worker, we want to show both the non-late and late statuses
        if (source === CancellationSource.Worker) {
          return [
            [status, source, false],
            [status, source, true],
          ]
        }
        return [[status, source]]
      })
    }
    return [[status]]
  })
  .map(([status, source, late]) => ({
    value: getBadgeTitle(status, source, late),
    label: getBadgeTitle(status, source, late),
  }))

interface ShiftsTabProps {
  workerId: string
  workerShifts?: WorkerShift[]
  isLoadingWorkerShifts: boolean
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
}

function getShiftsTableRow(
  workerShift: WorkerShift,
  openClockInModal: (workerShift: WorkerShift) => void,
  openClockOutModal: (workerShift: WorkerShift) => void,
  openAdjustmentModal: (workerShift: WorkerShift) => void,
  allowTableSelect: boolean,
) {
  const cells: TableCell[] = [
    ...(!allowTableSelect
      ? [
          {
            renderFn: () => {
              return (
                <WorkerAction
                  workerShift={workerShift}
                  openClockInModal={openClockInModal}
                  openClockOutModal={openClockOutModal}
                  openAdjustmentModal={openAdjustmentModal}
                />
              )
            },
          },
        ]
      : []),
    {
      renderFn: () => {
        return (
          <Link to={`/field-monitor/${workerShift.shiftId}`}>
            <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
              {workerShift.shiftInfo.shiftRole}{' '}
              {workerShift.shiftInfo.shortLocation
                ? ` @ ${workerShift.shiftInfo.shortLocation}`
                : ''}
            </Button>
          </Link>
        )
      },
    },
    {
      renderFn: () => {
        return (
          <Row style={{ width: 80 }} alignCenter>
            {truncateString(workerShift.shiftId ?? '')}
            <CopyTextIcon textToCopy={workerShift.shiftId} />
          </Row>
        )
      },
    },
    {
      sortKey: workerShift.shiftInfo.employerName,
      renderFn: () => {
        return '' + workerShift.shiftInfo.employerName
      },
    },
    {
      sortKey: toDate(workerShift.shiftInfo.startTime).toISOString(),
      renderFn: () => <WorkerShiftDateCell workerShift={workerShift} />,
    },
    {
      renderFn: () => <WorkerShiftStartTimeCell workerShift={workerShift} />,
    },
    {
      sortKey: getBadgeTitle(
        workerShift.jobStatus,
        workerShift.cancellationSource,
        workerShift.canceledLate,
      ),
      renderFn: () => {
        return (
          <JobStatusBadge
            jobStatus={workerShift.jobStatus}
            cancellationSource={workerShift.cancellationSource}
            lateCancellation={workerShift.canceledLate}
            cancellationReason={workerShift.cancellationReason}
            canceledAt={workerShift.canceledAt}
          />
        )
      },
    },
    {
      renderFn: () => (
        <InlineClockIn workerShift={workerShift} openModal={openClockInModal} />
      ),
    },
    {
      renderFn: () => (
        <InlineClockOut
          workerShift={workerShift}
          openAdjustmentModal={openAdjustmentModal}
          openClockOutModal={openClockOutModal}
        />
      ),
    },
    {
      renderFn: () => {
        return getPayRateString(
          workerShift.shiftInfo.payType,
          workerShift.shiftInfo.payRate,
        )
      },
    },
    {
      renderFn: () => {
        return (
          <EarningsSummaryPaymentIcon
            workerId={workerShift.workerId}
            shiftId={workerShift.shiftId}
            earningsSummary={workerShift.earningsSummary}
          />
        )
      },
    },
  ]
  return {
    cells,
    key: workerShift.shiftId,
  }
}

const AdjustmentModalWrapper = ({
  workerShift,
  adjustmentModal,
}: {
  workerShift: WorkerShift
  adjustmentModal: {
    handleClose: () => void
    isOpen: boolean
  }
}) => {
  const { shift, isLoading } = useShift(workerShift.shiftId)

  return (
    <AdjustmentModal
      {...adjustmentModal}
      shift={shift}
      workerShift={workerShift}
      key={workerShift.workerId}
      isLoading={isLoading}
    />
  )
}

export const WorkerShiftsTab = ({
  workerId,
  workerShifts,
  isLoadingWorkerShifts,
  dateRange,
  setDateRange,
}: ShiftsTabProps) => {
  const [selectedRows, setSelectedRows] = useState<TableRow[]>([])
  const [notesOpen, setNotesOpen] = useState<boolean>(false)
  const [allowTableSelect, setAllowTableSelect] = useState<boolean>(false)
  const [initialSortByColumnIndex, setInitialSortByColumnIndex] =
    useState<number>(4)
  const [currentWorkerShift, setCurrentWorkerShift] =
    useState<WorkerShift | null>(null)
  const [hideCanceled, setHideCanceled] = useState<boolean>(false)
  const [shiftStatuses, setShiftStatuses] =
    useState<IMenuItem[]>(shiftStatusOptions)

  const bulkCancelModal = useModal()
  const clockInModal = useModal()
  const clockOutModal = useModal()
  const adjustmentModal = useModal()

  const openClockInModal = (workerShift: WorkerShift) => {
    setCurrentWorkerShift(workerShift)
    clockInModal.open()
  }

  const openClockOutModal = (workerShift: WorkerShift) => {
    setCurrentWorkerShift(workerShift)
    clockOutModal.open()
  }

  const openAdjustmentModal = (workerShift: WorkerShift) => {
    setCurrentWorkerShift(workerShift)
    adjustmentModal.open()
  }

  const workerShiftsByShiftId = new Map<string, WorkerShift>(
    workerShifts?.map((ws) => [ws.shiftId, ws]) || [],
  )

  const { refetchLoading, refetchActiveQueries } = useActiveQueries()

  const getSelectedWorkerShifts = () => {
    const result: WorkerShift[] = []
    for (const row of selectedRows) {
      const workerShift = workerShiftsByShiftId.get(row.key)
      if (workerShift) {
        result.push(workerShift)
      }
    }
    return result
  }

  const shiftsTableHeaders = [
    ...(!allowTableSelect ? ['Action'] : []),
    { label: 'Shift Name', key: 'shiftName' },
    { label: 'Shift ID', key: 'shiftId' },
    { label: 'Company', key: 'company', sortable: true },
    { label: 'Date', key: 'date', sortable: true },
    { label: 'Time', key: 'time' },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Clock In', key: 'clockIn' },
    { label: 'Clock Out', key: 'clockOut' },
    { label: 'Pay Rate', key: 'payRate' },
    { label: 'Paid', key: 'paid' },
  ]
  const shiftsTableRows = useMemo(() => {
    return (
      workerShifts
        ?.filter((workerShift) => {
          return shiftStatuses.some(
            (status) =>
              status.value ===
              getBadgeTitle(
                workerShift.jobStatus,
                workerShift.cancellationSource,
                workerShift.canceledLate,
              ),
          )
        })
        .map((workerShift) =>
          getShiftsTableRow(
            workerShift,
            openClockInModal,
            openClockOutModal,
            openAdjustmentModal,
            allowTableSelect,
          ),
        ) || []
    )
  }, [
    workerShifts,
    shiftStatuses,
    allowTableSelect,
    openClockInModal,
    openClockOutModal,
    openAdjustmentModal,
  ])

  const selectShiftOnClick = () => {
    // we want the table to always sort by date initially
    if (allowTableSelect) {
      // Date is in index 4 when allow table select is false
      setInitialSortByColumnIndex(4)
      setAllowTableSelect(false)
    } else {
      // Date is in index 3 when allow table select is true
      setInitialSortByColumnIndex(3)
      setAllowTableSelect(true)
    }
  }

  const handleHideCanceledToggle = () => {
    setHideCanceled(!hideCanceled)

    // let selected shift statuses handle the filtering
    if (!hideCanceled) {
      setShiftStatuses((prevStatuses) =>
        prevStatuses.filter(
          (status) =>
            // we don't want to hide late cancellations
            status.value.startsWith('LATE') ||
            !status.value.includes('CANCELED'),
        ),
      )
    } else {
      setShiftStatuses((prevStatuses) => {
        const canceledStatuses = shiftStatusOptions.filter(
          (status) =>
            // exclude late cancellations since we didn't hide them before
            !status.value.startsWith('LATE') &&
            status.value.includes('CANCELED'),
        )
        const existingNonCanceledStatuses = prevStatuses.filter(
          (status) =>
            status.value.startsWith('LATE') ||
            !status.value.includes('CANCELED'),
        )
        return [...existingNonCanceledStatuses, ...canceledStatuses]
      })
    }
  }

  return (
    <>
      <Row mb={theme.space.sm} justifyBetween alignCenter mt={theme.space.xxs}>
        <Row alignCenter>
          <Button
            slim
            onClick={selectShiftOnClick}
            variant={ButtonVariant.OUTLINED}
          >
            {allowTableSelect ? 'Selecting' : 'Select'} Shifts
            {allowTableSelect && '...'}
          </Button>
          <DateRangePicker
            label="Date range"
            inlineLabel={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
            granularity="day"
            style={{ marginLeft: theme.space.xs }}
          />
          <SearchSelect
            multiple
            options={shiftStatusOptions}
            selectedItems={shiftStatuses}
            handleSelectMultiple={setShiftStatuses}
            onlyShowLabel={true}
            label={'Status'}
            width={200}
            showClearButton
            style={{ marginLeft: theme.space.xs }}
          />
        </Row>
        <Row>
          {selectedRows.length > 0 && (
            <Button
              mx={theme.space.xxs}
              leftIcon={<Icon name="cancel" />}
              slim
              variant={ButtonVariant.OUTLINED}
              onClick={bulkCancelModal.open}
              mr={theme.space.xxs}
            >
              Cancel
            </Button>
          )}
          <Toggle
            label="Hide canceled"
            buttonState={hideCanceled}
            runOnChange={() => handleHideCanceledToggle()}
          />
          <Button
            slim
            leftIcon={<Icon name="refresh" />}
            onClick={async () => await refetchActiveQueries()}
            variant={ButtonVariant.OUTLINED}
            loading={refetchLoading}
            mx={theme.space.xxs}
          >
            Refresh
          </Button>
        </Row>
      </Row>
      {isLoadingWorkerShifts || !workerShifts ? (
        <CircularProgress
          size={36}
          sx={{
            left: '50%',
          }}
        />
      ) : (
        <DataTable
          allowSelect={allowTableSelect}
          initialSortByColumnIndex={initialSortByColumnIndex}
          key={initialSortByColumnIndex} // this tells the table to re-render when the initialSortByColumnIndex changes
          initialSortByAscOrder={false}
          headers={shiftsTableHeaders}
          onSelectRows={(selectedRows: TableRow[]) => {
            setSelectedRows(selectedRows)
          }}
          selectedRows={selectedRows}
          rows={shiftsTableRows}
        />
      )}
      <BulkActionModal
        {...bulkCancelModal}
        action="cancel"
        workerShifts={getSelectedWorkerShifts()}
        cancelInfoToShow={CancelInfo.Shift}
      />
      {currentWorkerShift && (
        <>
          <ClockInModal
            {...clockInModal}
            workerShift={currentWorkerShift}
            key={currentWorkerShift.shiftId}
          />
          <ClockOutModal
            {...clockOutModal}
            workerShift={currentWorkerShift}
            key={currentWorkerShift.shiftId}
          />
          <AdjustmentModalWrapper
            adjustmentModal={adjustmentModal}
            workerShift={currentWorkerShift}
          />
        </>
      )}
      {!!workerId && (
        <WorkerNoteDrawer
          workerId={workerId}
          isOpen={notesOpen}
          setIsOpen={setNotesOpen}
        />
      )}
    </>
  )
}
