import { captureException } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { PendingClockOutsResponse } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { QueryParamArray } from 'src/types'
import { getQueryParams } from './useApi'

export const PENDING_CLOCK_OUTS_QUERY_KEY = 'pendingClockOutsCacheKey'

export type PendingClockOutsParams = {
  shiftId?: string
  workerId?: string
  startAfter?: string
  startBefore?: string
  endAfter?: string
  endBefore?: string
  clockOutTimeBeforeWorkerEditBefore?: string
  clockOutTimeBeforeWorkerEditAfter?: string
  regionIds?: string[]
  companyIds?: string[]
  excludeCompanyIds?: string[]
  order?: 'asc' | 'desc'
  limit?: number
}

async function getPendingClockOuts({
  shiftId,
  workerId,
  startAfter,
  startBefore,
  endAfter,
  endBefore,
  clockOutTimeBeforeWorkerEditBefore,
  clockOutTimeBeforeWorkerEditAfter,
  regionIds,
  companyIds,
  excludeCompanyIds,
  order,
  limit,
}: PendingClockOutsParams): Promise<PendingClockOutsResponse[] | undefined> {
  const regionIdsArray = [
    ...((regionIds
      ? regionIds?.map((regionId) => ['regionIds[]', regionId])
      : []) as QueryParamArray),
  ]

  const companyIdsArray = [
    ...((companyIds
      ? companyIds?.map((companyId) => ['companyIds[]', companyId])
      : []) as QueryParamArray),
  ]

  const excludeCompanyIdsArray = [
    ...((excludeCompanyIds
      ? excludeCompanyIds?.map((excludeCompanyId) => [
          'excludeCompanyIds[]',
          excludeCompanyId,
        ])
      : []) as QueryParamArray),
  ]

  const queryString = getQueryParams([
    ['shiftId', shiftId],
    ['workerId', workerId],
    ['startAfter', startAfter],
    ['startBefore', startBefore],
    ['startBefore', startBefore],
    ['endAfter', endAfter],
    ['endBefore', endBefore],
    ['clockOutTimeBeforeWorkerEditBefore', clockOutTimeBeforeWorkerEditBefore],
    ['clockOutTimeBeforeWorkerEditAfter', clockOutTimeBeforeWorkerEditAfter],
    ['orderByStartTime', order || 'asc'],
    ['limit', limit ? `${limit}` : null],
    ...companyIdsArray,
    ...regionIdsArray,
    ...excludeCompanyIdsArray,
  ])

  try {
    const res = await trabaApi.get(
      `worker-shifts/pending-clock-outs${queryString}`,
    )
    return res.data
  } catch (error: any) {
    console.error(
      'getPendingClockOuts -> getPendingClockOuts() ERROR',
      error.message ?? error,
    )
    captureException(error)
    throw error
  }
}

export function usePendingClockOuts(params: PendingClockOutsParams) {
  const {
    isLoading,
    isError,
    data: pendingClockOuts,
    error,
    isFetched,
    isRefetching,
    refetch,
  } = useQuery<PendingClockOutsResponse[] | undefined, Error>({
    queryKey: [PENDING_CLOCK_OUTS_QUERY_KEY, params],
    queryFn: () => getPendingClockOuts(params),
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: 'always',
  })

  return {
    isLoading,
    isError,
    pendingClockOuts,
    error,
    isFetched,
    isRefetching,
    refetch,
  }
}
