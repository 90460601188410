import * as Sentry from '@sentry/react'
import {
  ShiftWorkerShiftTimeToDestinationResponse,
  WorkerShiftTimeToDestination,
} from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { ONE_MINUTE_IN_MS } from '../libs/constants'

async function getWorkerShiftTimeToDestinations(
  shiftId: string,
): Promise<ShiftWorkerShiftTimeToDestinationResponse | undefined> {
  try {
    const res = await trabaApi.get(
      `worker-shift-time-to-destination/shift?shiftId=${shiftId}`,
    )
    return res.data
  } catch (e) {
    Sentry.captureException(e)
  }
}

async function parseWorkerShiftTimeToDestinations(shiftId: string) {
  try {
    const res = await getWorkerShiftTimeToDestinations(shiftId)
    const newRes = res?.workerShiftTimeToDestinations.map(
      (workerShiftTimeToDestination) => {
        return {
          ...workerShiftTimeToDestination,
          arrivedAt: workerShiftTimeToDestination.arrivedAt
            ? new Date(Date.parse(workerShiftTimeToDestination.arrivedAt))
            : undefined,
          recordedAt: new Date(
            Date.parse(workerShiftTimeToDestination.recordedAt),
          ),
        }
      },
    )
    return newRes
  } catch (e) {
    Sentry.captureException(e)
  }
}

export function useWorkerShiftTimeToDestinations(shiftId: string) {
  const {
    isLoading,
    isError,
    data: workerShiftTimeToDestinations,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerShiftTimeToDestination[] | undefined, Error>(
    `workerShiftTimeToDestinations_${shiftId}`,
    () => parseWorkerShiftTimeToDestinations(shiftId),
    {
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    workerShiftTimeToDestinations,
    error,
    isFetched,
    refetch,
  }
}
