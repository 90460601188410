import * as Sentry from '@sentry/react'
import { CompanyInvitation, UserRole } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'

async function getInvitations(
  companyId: string,
): Promise<CompanyInvitation[] | undefined> {
  if (!companyId) {
    return []
  }
  try {
    // Retrieve unaccepted invitations
    const res = await trabaApi.get(`companies/${companyId}/invitations`)
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useInvitations ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
  }
}

export async function generateInvitationLink(
  companyId: string,
  invitationId: string,
): Promise<string | undefined> {
  try {
    if (!companyId || !invitationId) {
      return
    }
    const res = await trabaApi.get(
      `companies/${companyId}/invitations/${invitationId}/generate-link`,
    )
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `generateInvitationLink ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
  }
}

export function useCompanyInvitations(companyId: string) {
  const {
    isLoading,
    isError,
    data: invitations,
    error,
    isFetched,
    refetch,
  } = useQuery<CompanyInvitation[] | undefined, Error>(
    ['company', companyId, 'invitations'],
    () => getInvitations(companyId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const createInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    { companyId: string; email: string; role: UserRole }
  >(
    async ({ companyId, email, role }) => {
      const res = await trabaApi.post(`companies/${companyId}/invitations`, {
        email,
        role,
      })
      return res.data
    },
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  const resendInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    { companyId: string; invitationId: string }
  >(
    async ({ companyId, invitationId }) => {
      const response = await trabaApi.patch(
        `companies/${companyId}/invitations/${invitationId}/resend`,
      )
      return response.data
    },
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  const rescindInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    { companyId: string; invitationId: string }
  >(
    async ({ companyId, invitationId }) => {
      const response = await trabaApi.patch(
        `companies/${companyId}/invitations/${invitationId}/rescind`,
      )
      return response.data
    },
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  return {
    isLoading,
    isError,
    invitations,
    createInvitationMutation: createInvitationMutation.mutateAsync,
    resendInvitationMutation: resendInvitationMutation.mutateAsync,
    rescindInvitationMutation: rescindInvitationMutation.mutateAsync,
    error,
    isFetched,
    refetch,
  }
}
