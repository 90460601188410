import {
  Button,
  ButtonVariant,
  InvitationsTable,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScheduleInvitations } from 'src/hooks/useScheduleInvitations'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { Col } from '../base'
import { ScheduleDetailsInvitesModal } from './ScheduleDetailsInvitesModal'

interface Props {
  shiftRequestParentId: string
}

export const ScheduleDetailsInvitesTab = (props: Props) => {
  const { shiftRequestParentId } = props
  const navigate = useNavigate()
  const { scheduleInvitations, isLoading, rescindScheduleInvitation } =
    useScheduleInvitations(shiftRequestParentId)
  const { shiftRequestParent } = useShiftRequestParent(shiftRequestParentId)
  const [showInvitationModal, setShowInvitationModal] = useState(false)

  const onClickWorker = (workerId: string) => {
    navigate(`/worker/${workerId}`)
  }

  if (isLoading || !shiftRequestParent) {
    return <LoadingSpinner />
  }

  const onClickInviteWorkers = () => {
    setShowInvitationModal(true)
  }

  const onRescindScheduleInvitation = async ({
    shiftRequestId,
    workerId,
  }: {
    shiftRequestId: string
    workerId: string
  }) => {
    rescindScheduleInvitation({ shiftRequestId, workerId })
  }

  return (
    <>
      <Row alignCenter justifyBetween mb={theme.space.sm}>
        <Col>
          <Text variant="h4">Schedule invitations</Text>
          <Text variant="body2" color={theme.colors.Grey60}>
            Invite workers to one or more roles in this schedule.
          </Text>
        </Col>
        <Button variant={ButtonVariant.OUTLINED} onClick={onClickInviteWorkers}>
          Invite workers to schedule
        </Button>
      </Row>

      {scheduleInvitations && scheduleInvitations.length > 0 ? (
        scheduleInvitations.map((si) => (
          <InvitationsTable
            invitations={si.invitations}
            roleName={si.shiftRole}
            onClickWorker={onClickWorker}
            rescindScheduleInvitation={onRescindScheduleInvitation}
          />
        ))
      ) : (
        <Text variant="body2">No invitations found for this schedule</Text>
      )}
      <ScheduleDetailsInvitesModal
        companyId={shiftRequestParent.companyId}
        showInvitationModal={showInvitationModal}
        setShowInvitationModal={setShowInvitationModal}
        shiftRequestParentId={shiftRequestParentId}
        shiftRequests={shiftRequestParent?.shiftRequests}
      />
    </>
  )
}
