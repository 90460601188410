import { Tooltip, Typography } from '@mui/material'
import { Text } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Locations } from '@traba/types'
import { Anchor, Button, CopyTextIcon, Image, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import { truncateString } from 'src/utils/stringUtils'

interface CompanyLocationsTableProps {
  locations: Locations[]
  handleEditLocationClick: (location: Locations) => void
  handleArchiveLocationClick: (location: Locations) => void
}

export default function CompanyLocationsTable({
  handleEditLocationClick,
  handleArchiveLocationClick,
  locations,
}: CompanyLocationsTableProps) {
  const headers: (DataTableHeader | string)[] = [
    {
      key: 'address',
      label: 'Address',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'shortLocation',
      label: 'Short Location',
      sortable: true,
    },
    {
      key: 'neighborhoodName',
      label: 'Hidden Location Description',
      sortable: true,
    },
    {
      key: 'locationId',
      label: 'Location ID',
      sortable: true,
    },
    {
      key: 'regionId',
      label: 'Region ID',
      sortable: true,
    },
    {
      key: 'coords',
      label: 'Coordinates',
    },
    {
      key: 'locationInstructions',
      label: 'Location Instructions',
    },
    {
      key: 'additionalOpsDetails',
      label: 'Additional Ops Details',
    },
    {
      key: 'media',
      label: 'Media',
    },
    {
      key: '',
      label: '',
    },
  ]

  const rows: TableRow[] = locations?.map((location, index) => {
    const {
      name,
      neighborhoodName,
      shortLocation,
      coords,
      locationId,
      locationInstructions,
      opsLocationDetails,
      regionId,
      address,
      media,
    } = location
    const { street1, city, state, postalCode } = address || {}
    const streetAddress = `${street1}, ${city}, ${state}, ${postalCode}`

    const cells: TableCell[] = [
      {
        sortKey: streetAddress,
        renderFn: () => (
          <Anchor
            href={`https://www.google.com/maps/search/?api=1&query=${coords.latitude}%2C${coords.longitude}`}
          >
            <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
              {streetAddress}
            </Button>
          </Anchor>
        ),
      },
      {
        sortKey: name,
        renderFn: () => <Text variant="h7">{name}</Text>,
      },
      {
        sortKey: shortLocation,
        renderFn: () => shortLocation,
      },
      {
        renderFn: () => (
          <Text>
            {neighborhoodName ? truncateString(neighborhoodName, 40) : '-'}
          </Text>
        ),
      },
      {
        sortKey: locationId,
        renderFn: () => (
          <>
            {truncateString(locationId)}{' '}
            <CopyTextIcon textToCopy={locationId} />
          </>
        ),
      },
      {
        sortKey: `${regionId}_${index}`,
        renderFn: () => regionId,
      },
      {
        renderFn: () => `(${coords.latitude}, ${coords.longitude})`,
      },
      {
        renderFn: () => (
          <Tooltip arrow title={locationInstructions}>
            <Typography style={{ fontWeight: 300 }} variant="body2">
              {truncateString(locationInstructions, 40)}
            </Typography>
          </Tooltip>
        ),
      },
      {
        renderFn: () =>
          opsLocationDetails ? (
            <Tooltip arrow title={opsLocationDetails}>
              <Typography style={{ fontWeight: 300 }} variant="body2">
                {truncateString(opsLocationDetails, 40)}
                <CopyTextIcon textToCopy={opsLocationDetails} />
              </Typography>
            </Tooltip>
          ) : (
            ''
          ),
      },
      {
        renderFn: () => (
          <Row style={{ maxWidth: '300px' }}>
            {media?.map((img, idx) => (
              <Image
                key={idx}
                src={img.imageUrl}
                alt={img.imageUrl}
                style={{
                  borderRadius: '10px',
                  margin: theme.space.xxxs,
                  maxWidth: '50px',
                  maxHeight: '50px',
                }}
              />
            ))}
          </Row>
        ),
      },
      {
        renderFn: () => (
          <DotMenu
            type="locations"
            dotMenuKey={locationId}
            menuItems={[
              {
                title: 'Edit location',
                onClick: () => handleEditLocationClick(location),
              },
              {
                title: 'Archive location',
                onClick: () => handleArchiveLocationClick(location),
              },
            ]}
          />
        ),
      },
    ]
    return {
      key: location.locationId,
      cells,
    }
  })

  return (
    <DataTable
      headers={headers}
      rows={rows}
      initialSortByColumnIndex={0} // sort by address column
    />
  )
}
