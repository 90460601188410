import { Avatar } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getAccountStatusColor } from '../../utils/workerUtils'
import { Col, Row, Link } from '../base'
import * as S from './styles'

interface WorkerCardProps {
  worker: PopulatedWorker
}

const WorkerCard = ({ worker }: WorkerCardProps) => {
  return (
    <S.WorkerCardContainer>
      <S.AvatarWrapper>
        <Avatar src={worker.photoUrl} style={{ width: 120, height: 120 }} />
      </S.AvatarWrapper>
      <S.InfoWrapper>
        <Col mb={theme.space.xxs}>
          <Text variant="h7">Name</Text>
          <Text>
            {worker.firstName} {worker.lastName}
          </Text>
        </Col>
        <Col mb={theme.space.xxs}>
          <Text variant="h7">Phone</Text>
          <Text>{worker.phoneNumber}</Text>
        </Col>
        <Col mb={theme.space.xxs}>
          <Text variant="h7">Id</Text>
          <Text>{worker.id}</Text>
        </Col>
        <Row justifyBetween fullWidth mb={theme.space.xxs}>
          <Col>
            <Text variant="h7">Status</Text>
            <Text
              variant="h7"
              style={{
                color: getAccountStatusColor(
                  worker.accountStatus?.accountStatus,
                ),
              }}
            >
              {worker.accountStatus?.accountStatus}
            </Text>
          </Col>
        </Row>
        <Row justifyBetween fullWidth mb={theme.space.xxs}>
          <Col>
            <Text variant="h7">Tier</Text>
            <Text>{worker.workerMetric?.tierLevel}</Text>
          </Col>
          <Col>
            <Text variant="h7">Region</Text>
            <Text>{worker.regionId}</Text>
          </Col>
        </Row>
      </S.InfoWrapper>
      <Row my={theme.space.xs}>
        <Link to={`/workers/${worker.id}`} target="_blank">
          Go to profile
        </Link>
      </Row>
    </S.WorkerCardContainer>
  )
}

export default WorkerCard
